import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box } from "@mui/material";
import { useAppDispatch } from "../../store/hooks";
import { ComposeValidators, EmailDomain, Required } from "../../shared/form-validations/form-validations";
import { addEmailDomainBlocklist } from "../../store/thunks/email-domain-blocklist/email-domain-blocklist-thunks";
import { IEmailDomainBlocklist } from "../../shared/interfaces/email-domain-blocklist.interface";

export const AddEmailDomainBlocklistForm = (): ReactElement => {
  const dispatch = useAppDispatch();
    
  const onSubmit = (data: IEmailDomainBlocklist) => {
      dispatch(addEmailDomainBlocklist(data));
  };

  return (
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit} data-testid="email-domain-blocklist-form">
            <Field name="emailDomain" validate={ComposeValidators(Required, EmailDomain)}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.emailDomain" />}
                    variant="standard"
                    fullWidth
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '15px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>

            <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
              <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }} data-testid="reset-button">
                <FormattedMessage id="components.reset" />
              </Button>
              <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
                <FormattedMessage id="components.add" />
              </Button>
            </Box>

          </form>
        )}
      />
  );
};
