import { createSlice } from '@reduxjs/toolkit';
import { IHardwareConfigSettingIsModified } from '../../shared/interfaces/hardware-config-setting-modified.interface';
import { IHardware } from "../../shared/interfaces/hardware.interface"
import { addHardware, checkIfHardwareConfigSettingIsModified, deleteHardware, getHardwareConfigSettingSchema, getHardwares, updateHardware } from '../thunks/hardwares/hardwares-thunks';

interface HardwareState {
    hardwares: IHardware[],
    selectedHardware: any,
    selectedVendor: any,
    openViewHardwareDialog: boolean,
    openAddUpdateHardwareDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    editHardware: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined,
    hardwareConfigSettingSchemaStatus: 'idle' | 'loading' | 'succeeded' | 'failed',
    hardwareConfigSettingSchema: string,
    hardwareConfigSettingIsModified: IHardwareConfigSettingIsModified | null,
}

const initialState: HardwareState = {
    hardwares: [],
    selectedHardware: null,
    selectedVendor: null,
    openViewHardwareDialog: false,
    openAddUpdateHardwareDialog: false,
    openDeleteConfirmationDialog: false,
    editHardware: false,
    status: 'idle',
    error: undefined,
    hardwareConfigSettingSchemaStatus: 'idle',
    hardwareConfigSettingSchema: "",
    hardwareConfigSettingIsModified: null,
}

export const hardwaresSlice = createSlice({    
    name: "hardware",
    initialState,
    reducers: {
        setHardwares: (state, action) => {
            state.hardwares = action.payload
        },
        setSelectedHardware: (state, action) => {
            state.selectedHardware = action.payload
        },
        setSelectedVendor: (state, action) => {
            state.selectedVendor = action.payload
        },
        setOpenViewHardwareDialog: (state, action) => {
            state.openViewHardwareDialog = action.payload
        },
        setOpenAddUpdateHardwareDialog: (state, action) => {
            state.openAddUpdateHardwareDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditHardware: (state, action) => {
            state.editHardware = action.payload
        },
        setHardwareConfigSettingSchema: (state, action) => {
            state.hardwareConfigSettingSchema = action.payload
        },
        setHardwareConfigSettingIsModified: (state, action) => {
            state.hardwareConfigSettingIsModified = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getHardwares.fulfilled, (state, action) => {            
            state.hardwares = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getHardwares.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getHardwares.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addHardware.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addHardware.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addHardware.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updateHardware.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(updateHardware.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateHardware.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deleteHardware.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deleteHardware.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteHardware.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getHardwareConfigSettingSchema.fulfilled, (state, action) => {  
            state.hardwareConfigSettingSchemaStatus = 'succeeded'          
            state.hardwareConfigSettingSchema = action.payload;
        })
        .addCase(getHardwareConfigSettingSchema.pending, (state, action) => {
            state.hardwareConfigSettingSchemaStatus = 'loading'
        })
        .addCase(getHardwareConfigSettingSchema.rejected, (state, action) => {
            state.hardwareConfigSettingSchemaStatus = 'failed'
            state.error = action.error.message
        })
        .addCase(checkIfHardwareConfigSettingIsModified.fulfilled, (state, action) => {            
            state.hardwareConfigSettingIsModified = action.payload;
        })
        .addCase(checkIfHardwareConfigSettingIsModified.pending, (state, action) => {
        })
        .addCase(checkIfHardwareConfigSettingIsModified.rejected, (state, action) => {
            state.error = action.error.message
        })
    }
})

export const { 
    setHardwares,
    setSelectedHardware,
    setSelectedVendor,
    setOpenViewHardwareDialog,
    setOpenAddUpdateHardwareDialog,
    setOpenDeleteConfirmationDialog,
    setEditHardware,
    setHardwareConfigSettingIsModified,
    setHardwareConfigSettingSchema
} = hardwaresSlice.actions;
export default hardwaresSlice.reducer;
