import { ReactElement, useEffect, useState } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { appendNotifications } from "../../store/slices/profile";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FormattedMessage } from "react-intl";

const apiUrl = process.env.REACT_APP_BASE_API_URL;

export const SignalRConnection = (): ReactElement => {
    const dispatch = useAppDispatch();
    const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
    const user = JSON.parse(String(sessionStorage.getItem("user")));
    const onlyurl = `${apiUrl}response?userid=${loggedInUser?.userId}&sessionId=${user.sessionId}`;
    const [downloadDialogOpen, setDownloadDialogOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    let connection: any;

    useEffect(() => {
        if (loggedInUser) {
            connection = new HubConnectionBuilder()
                .configureLogging(LogLevel.Debug)
                .withUrl(onlyurl)
                .withAutomaticReconnect()
                .build();

            connection.start()
                .then(() => {
                    console.log('SignalRConnection', 'Connected successfully');
                })
                .catch((err: any) => {
                    console.log('SignalRConnection', 'Error while establishing connection : ' + err.message)
                });

        }
    }, [loggedInUser]);

    useEffect(() => {
        if (connection) {
            connection.on('ClientNotification', (receiveMessage: any) => {
                //console.log("receiveMessage=", receiveMessage);
                if (receiveMessage.actionName === "Starting the tenant file(s) download.") {
                    setDownloadDialogOpen(true);
                    setMessage(receiveMessage.messageText);
                }
                else {
                    dispatch(appendNotifications(receiveMessage));
                }
            });

            connection.on('TenantFileNotification', (receiveMessage: any) => {
                console.log('TenantFileNotification = ' + JSON.stringify(receiveMessage));
                var a = document.createElement("a");
                a.href = receiveMessage.fileUrl;
                a.download = receiveMessage.fileName;
                a.click();
            });
        }
    });

    return (
        <>
            <Dialog open={downloadDialogOpen} >
                <DialogTitle><FormattedMessage id="components.downloads" /></DialogTitle>
                <DialogContent>
                    {message}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setDownloadDialogOpen(false)}>
                        <FormattedMessage id="components.ok" />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
