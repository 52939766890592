import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IDataCenter } from "../../shared/interfaces/data-center.interface";
import { addDataCenter, updateDataCenter } from "../../store/thunks/data-centers/data-centers-thunks";
import { ComposeValidators, IpAddress, Required } from "../../shared/form-validations/form-validations";

export const AddUpdateDataCenterForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedDataCenter = useAppSelector(state => state.dataCenter.selectedDataCenter);
  const editDataCenter = useAppSelector(state => state.dataCenter.editDataCenter);
    
  const onSubmit = (data: IDataCenter) => {
    if (editDataCenter) {
      dispatch(updateDataCenter(data));
    }
    else {
      dispatch(addDataCenter(data));
    }
  };

  return (
      <Form
        onSubmit={onSubmit}
        initialValues={selectedDataCenter}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit} data-testid="data-centers-form">
            <Field name="name" validate={Required}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.dataCenter" />}
                    variant="standard"
                    role="textbox"
                    aria-label="dc-name"
                    fullWidth
                    error={meta.error && meta.touched}
                    disabled={editDataCenter}
                    autoComplete='off'
                    sx={{ marginTop: '0px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Field name="region" validate={Required}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.region" />}
                    variant="standard"
                    fullWidth
                    role="textbox"
                    aria-label="dc-region"
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '15px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Field name="fleetName" validate={Required}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.fleetName" />}
                    variant="standard"
                    fullWidth
                    role="textbox"
                    aria-label="dc-fleet"
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '15px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Field name="stackName" validate={Required}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.stackName" />}
                    variant="standard"
                    fullWidth
                    role="textbox"
                    aria-label="dc-stack"
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '15px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Field name="ec2ip" validate={ComposeValidators(Required, IpAddress)}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}             
                    label={<FormattedMessage id="components.ec2IP" />}
                    variant="standard"
                    role="textbox"
                    aria-label="dc-ip"
                    fullWidth
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '15px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
              <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
                <FormattedMessage id="components.reset" />
              </Button>
              <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
                {editDataCenter ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
              </Button>
            </Box>

          </form>
        )}
      />
  );
};
