import { MouseEvent, ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Field } from "react-final-form";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  Button,
  TextField,
  FormLabel,
  Grid,
  Link,
  DialogContent
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ITemplate, ITemplateModule } from "../../../shared/interfaces/template.interface";
import { addTemplate, getTemplateConfigChangeDetail, updateTemplate } from "../../../store/thunks/templates/templates-thunks";
import { Required } from "../../../shared/form-validations/form-validations";
import { getComparator, stableSort } from "../../../shared/table-sorting/table-sort";
import { getDeviceLicensedModules } from "../../../store/thunks/modules/modules-thunks";
import { setModuleConfigurationForTemplateModule, setOpenModuleConfigSettingsDialog, setSelectedModuleForEditConfig, setSelectedTemplateModules } from "../../../store/slices/templates";
import { SortableTableHead } from "../../../shared/table-sorting/sortable-table-head";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../shared/dialog-components/custom-dialog";
import { ModuleConfigSettingsForm } from "./module-config-settings-form.component";

type Order = 'asc' | 'desc';

type Column = {
  id: string,
  label: string,
  sortable?: string
}

const ModuleSelections = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ITemplateModule>('moduleName');
  const selectedTemplateModules = useAppSelector(state => state.template.selectedTemplateModules);
  const selectedTemplate = useAppSelector(state => state.template.selectedTemplate);
  const editTemplate = useAppSelector(state => state.template.editTemplate);
  const deviceLicensedModules = useAppSelector(state => state.module.deviceLicensedModules);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);

  const columns: Column[] = [
    { id: '', label: '', sortable: 'disable' },
    { id: 'moduleName', label: intl.formatMessage({ id: 'components.module' }) },
    { id: 'code', label: intl.formatMessage({ id: 'components.code' }) },
    { id: 'applicationName', label: intl.formatMessage({ id: 'components.application' }) },
    { id: 'settings', label: intl.formatMessage({ id: 'components.settings' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(getDeviceLicensedModules(cId));
    }
  }, [selectedAppCustomer]);

  useEffect(() => {
    if (editTemplate) {
      const templateModules = selectedTemplate?.viewTemplateModules.map((item: { [x: string]: any; }) => {
        return item['moduleId'];
      });
      dispatch(setSelectedTemplateModules(templateModules));
    }
    else {
      dispatch(setSelectedTemplateModules([]));
    }
  }, []);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof ITemplateModule,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (moduleId: string) => selectedTemplateModules.indexOf(moduleId) !== -1;

  const handleClick = (event: MouseEvent<unknown>, moduleId: string) => {
    const selectedIndex = selectedTemplateModules.indexOf(moduleId);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTemplateModules, moduleId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTemplateModules.slice(1));
    } else if (selectedIndex === selectedTemplateModules.length - 1) {
      newSelected = newSelected.concat(selectedTemplateModules.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTemplateModules.slice(0, selectedIndex),
        selectedTemplateModules.slice(selectedIndex + 1),
      );
    }
    dispatch(setSelectedTemplateModules(newSelected));
  };

  const opnEditConfigSetting = (event: MouseEvent<unknown>, data: any) => {
    event.stopPropagation();
    dispatch(setOpenModuleConfigSettingsDialog(true));
    dispatch(setSelectedModuleForEditConfig(data));
  }

  const actionTemplate = (rowData: any) => {
    if (rowData.hasConfigSetting) {
      return <Link href="#!" onClick={(e: any) => opnEditConfigSetting(e, rowData)} ><FormattedMessage id="components.edit" /></Link>
    }
    else {
      return <span>Not Applicable</span>
    }
  }

  return (
    <Box>
      <FormLabel><FormattedMessage id="components.modules" /></FormLabel>
      <TableContainer component={Paper} sx={{ maxHeight: 240 }}>
        <Table stickyHeader data-testid="module-table" aria-label="module table">
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
          />
          <TableBody>
            {stableSort(deviceLicensedModules, getComparator(order, orderBy))
              .map((row, index) => {
                const isItemSelected = isSelected(row.moduleId);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    data-testid="module-node"
                    key={row.moduleId}
                    hover
                    onClick={(event) => handleClick(event, row.moduleId)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.moduleName}</TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.applicationName}</TableCell>
                    <TableCell>{actionTemplate(row)}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export const AddUpdateTemplateForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedTemplate = useAppSelector(state => state.template.selectedTemplate);
  const selectedTemplateModules = useAppSelector(state => state.template.selectedTemplateModules);
  const editTemplate = useAppSelector(state => state.template.editTemplate);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const openModuleConfigSettingsDialog =  useAppSelector(state => state.template.openModuleConfigSettingsDialog );
  const templateConfigChangeDetail = useAppSelector(state => state.template.templateConfigChangeDetail);

  useEffect(() => {
    const templateId = editTemplate ? selectedTemplate.templateId : '';
    dispatch(getTemplateConfigChangeDetail(templateId));
  }, []);

  const onSubmit = (data: ITemplate) => {
    let finalData: any = data;
    finalData = { 
      ...finalData, 
      modules: selectedTemplateModules,
      changeDetailId: templateConfigChangeDetail.templateConfigChangeDetailsId
     }
 
    if (editTemplate) {
      dispatch(updateTemplate(finalData));
    }
    else {
      finalData = { ...finalData, customerId: selectedAppCustomer?.customerId }
      dispatch(addTemplate(finalData));
    }
  };

  const resetSelectedModules = () => {
    if(editTemplate){
      const templateModules = selectedTemplate?.viewTemplateModules.map((item: { [x: string]: any; }) => {
        return item['moduleId'];
      });
      dispatch(setSelectedTemplateModules(templateModules));
    }
    else {
      dispatch(setSelectedTemplateModules([]));
    }    
  }

  const handleCloseModuleConfigDialog = () => {
    dispatch(setOpenModuleConfigSettingsDialog(false));  
    dispatch(setSelectedModuleForEditConfig({}));
    dispatch(setModuleConfigurationForTemplateModule({}));
  }

  return (
    <>
    <Form
      onSubmit={onSubmit}
      initialValues={selectedTemplate}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} data-testid="templates-form">
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6}>
              <Field name="name" validate={Required}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.template" />}
                      variant="standard"
                      role="textbox"
                      aria-label="t-name"
                      fullWidth
                      error={meta.error && meta.touched}
                      disabled={editTemplate}
                      autoComplete='off'
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={selectedAppCustomer?.name ? selectedAppCustomer?.name : ""}
                label={<FormattedMessage id="components.customer" />}
                variant="standard"
                role="textbox"
                aria-label="po-customer"
                fullWidth
                autoComplete='off'
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="description" validate={Required}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.description" />}
                      variant="standard"
                      fullWidth
                      role="textbox"
                      multiline
                      maxRows={3}
                      error={meta.error && meta.touched}
                      autoComplete='off'
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ModuleSelections />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button autoFocus onClick={() => {form.reset(); resetSelectedModules()}} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
              <FormattedMessage id="components.reset" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
              {editTemplate ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
            </Button>
          </Box>
        </form>
      )}
    />

    {/*start of module config dialog */}
    <BootstrapDialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseModuleConfigDialog}
        aria-labelledby="dialog-title"
        open={openModuleConfigSettingsDialog}
        data-testid="module-config-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseModuleConfigDialog}>
            <FormattedMessage id="components.updateModuleConfigSettings" />
        </BootstrapDialogTitle>
        <DialogContent>
          <ModuleConfigSettingsForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of module config dialog */}
    </>
  );
};
