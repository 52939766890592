import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Link, Paper, Typography } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ComposeValidators, ConfirmPassword, Password, Required } from "../../shared/form-validations/form-validations";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { changeExistingPassword, changePassword } from "../../store/thunks/user-management/user-management-thunks";
import { resetProfile, setChangePasswordCompleted, setRedirectToRoot } from "../../store/slices/profile";
import { Role } from "../../shared/enums/role.enum";

export const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(state => state.profile.loggedInUser);
  const changePasswordCompleted = useAppSelector((state) => state.profile.changePasswordCompleted);
  const roles = useAppSelector((state) => state.profile.roles);
  const redirectToRoot = useAppSelector(state => state.profile.redirectToRoot);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => () => {
    dispatch(setRedirectToRoot(false));
    dispatch(setChangePasswordCompleted(false));
  }, []);

  useEffect(() => {
    let timeOutId: any;
    if (redirectToRoot) {
      timeOutId = setTimeout(() => {
        sessionStorage.clear();
        dispatch(resetProfile());
        navigate("/signin");
      }, 2000);
    }
    return () => clearTimeout(timeOutId);
  }, [redirectToRoot]);

  const onSubmit = (data: any) => {
    if(roles.includes(Role.PASSWORD_CHANGE_ONLY)){
      let finalData: any = {
        userName: user?.userName,
        newPassword: data.newPassword
      };
      dispatch(changePassword(finalData));
    }
    else {
      let finalData: any = {
        userName: user?.userName,
        password: data.password,
        newPassword: data.newPassword
      };
      dispatch(changeExistingPassword(finalData));
    }
    
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="change-password">
        <div className="page-header">
          <h2><FormattedMessage id="components.changePassword" /></h2>
        </div>
        {user && !changePasswordCompleted &&
          <Paper elevation={3} sx={{ p: 2 }}>
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit} data-testid="change-password-form">
                  <Grid container spacing={2} item md={6}>
                    {!roles.includes(Role.PASSWORD_CHANGE_ONLY) &&
                      <Grid item sm={12}>
                        <Field name="password" validate={ComposeValidators(Required)}>
                          {({ input, meta }) => (
                            <FormControl variant="standard" fullWidth>
                              <InputLabel htmlFor="password"><FormattedMessage id="components.currentPassword" /></InputLabel>
                              <Input
                                {...input}
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                error={meta.error && meta.touched}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                            </FormControl>
                          )}
                        </Field>
                      </Grid>
                    }
                    <Grid item sm={12}>
                      <Field name="newPassword" validate={ComposeValidators(Required, Password)}>
                        {({ input, meta }) => (
                          <FormControl variant="standard" fullWidth>
                            <InputLabel htmlFor="newPassword"><FormattedMessage id="components.newPassword" /></InputLabel>
                            <Input
                              {...input}
                              id="newPassword"
                              type={showNewPassword ? 'text' : 'password'}
                              error={meta.error && meta.touched}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                          </FormControl>

                        )}
                      </Field>
                    </Grid>
                    <Grid item sm={12}>
                      <Field name="confirmPassword" validate={ComposeValidators(Required, ConfirmPassword(values.newPassword))}>
                        {({ input, meta }) => (
                          <FormControl variant="standard" fullWidth>
                            <InputLabel htmlFor="confirmPassword"><FormattedMessage id="components.confirmPassword" /></InputLabel>
                            <Input
                              {...input}
                              id="confirmPassword"
                              type={showConfirmPassword ? 'text' : 'password'}
                              error={meta.error && meta.touched}
                              sx={{ mt: 2 }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                  >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                  <Box display="flex" justifyContent="flex-start" sx={{ pt: 4 }}>
                    <Button onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
                      <FormattedMessage id="components.reset" />
                    </Button>
                    <Button type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
                      <FormattedMessage id="components.update" />
                    </Button>
                  </Box>
                </form>
              )}
            />
          </Paper>
        }
        {changePasswordCompleted &&
          <Paper elevation={3} sx={{ pt: 6, pb: 6, pl: 2, pr: 2 }}>
            <Typography variant="body1"><FormattedMessage id="components.passwordUpdatedSuccessfully" /> <Link href="/" ><FormattedMessage id="components.clickHere" /></Link> <FormattedMessage id="components.togotoHomePage" />.</Typography>
          </Paper>
        }
      </div>
    </>
  );
};
