import { createAsyncThunk } from "@reduxjs/toolkit";
import { IVendor } from "../../../shared/interfaces/vendor.interface";
import { dataAPI } from "../../../shared/dataApi";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setEditVendor, setOpenAddUpdateVendorDialog, setOpenDeleteConfirmationDialog, setSelectedVendor } from "../../slices/vendors";

export const getVendors = createAsyncThunk(
  "vendor/getVendors",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Vendor/GetVendors`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addVendor = createAsyncThunk(
  'vendor/addVendor',
  async (requestData: IVendor, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`Vendor/AddVendor`, requestData);
      dispatch(setOpenAddUpdateVendorDialog(false));
      dispatch(getVendors());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.vendorCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateVendor = createAsyncThunk(
  'vendor/updateVendor',
  async (requestData: IVendor, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`Vendor/UpdateVendor`, requestData);
      dispatch(setSelectedVendor({}));
      dispatch(setOpenAddUpdateVendorDialog(false));
      dispatch(setEditVendor(false));
      dispatch(getVendors());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.vendorUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteVendor = createAsyncThunk(
  'vendor/deleteVendor',
  async (requestData: IVendor, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`Vendor/DeleteVendor?VendorId=` + requestData.vendorId);
      dispatch(setSelectedVendor({}));
      dispatch(setOpenDeleteConfirmationDialog(false));
      dispatch(getVendors());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.vendorDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
