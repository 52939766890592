import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Field } from "react-final-form";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
  Checkbox,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  SelectChangeEvent
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IPackage } from "../../shared/interfaces/package.interface";
import { addPackage, getPackageWithModules, updatePackage } from "../../store/thunks/packages/packages-thunks";
import { AlphaNumaric, ComposeValidators, Required } from "../../shared/form-validations/form-validations";
import { getVendors } from "../../store/thunks/vendors/vendors-thunks";
import { getCustomLicenseApplication } from "../../store/thunks/applications/applications-thunks";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";
import { setStandardVendors } from "../../store/slices/vendors";
import { IStandardModule } from "../../shared/interfaces/standard-module.interface";
import { getStandardLicenseModule } from "../../store/thunks/modules/modules-thunks";
import { setSelectedPackageModules } from "../../store/slices/packages";

type Order = 'asc' | 'desc';

interface ColumnData {
  name: string;
  code: string;
  applicationName: string;
}

interface Column {
  id: keyof ColumnData,
  label: string
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof ColumnData
  ) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const intl = useIntl();
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof ColumnData) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const columns: readonly Column[] = [
    { id: 'name', label: intl.formatMessage({ id: 'components.module' }) },
    { id: 'code', label: intl.formatMessage({ id: 'components.code' }) },
    { id: 'applicationName', label: intl.formatMessage({ id: 'components.application' }) },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all modules',
            }}
          />
        </TableCell>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}

            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ModuleSelections = (): ReactElement => {
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IStandardModule>('name');
  const standardVendors = useAppSelector(state => state.vendor.standardVendors);
  const standardModuleStatus = useAppSelector(state => state.module.standardModuleStatus);
  const standardLicenseModule = useAppSelector(state => state.module.standardLicenseModule);
  const [filteredModules, setFilteredModules] = useState<IStandardModule[]>([]);
  const [selectedVendor, setSelectedVendor] = useState('all');
  const selectedPackageModules = useAppSelector(state => state.package.selectedPackageModules);
  const selectedPackage = useAppSelector(state => state.package.selectedPackage);
  const editPackage = useAppSelector(state => state.package.editPackage);

  useEffect(() => {
    if(selectedVendor === 'all'){
      setFilteredModules(standardLicenseModule);
    }      
  }, [standardLicenseModule]);

  useEffect(() => {
    if (standardModuleStatus === 'idle' || standardModuleStatus === 'failed') {
      dispatch(getStandardLicenseModule());
    }
  }, []); 

  useEffect(() => {
    if (editPackage) {
      dispatch(getPackageWithModules(selectedPackage));
    }
  }, []);  

  const handleVendorChange = (event: SelectChangeEvent) => {
    setSelectedVendor(event.target.value);
    if (event.target.value === 'all') {
      setFilteredModules(standardLicenseModule);
    }
    else {
      const filteredData = standardLicenseModule.filter((obj)=> {
        return obj.vendorId === event.target.value;
      });
      setFilteredModules(filteredData);
    }
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IStandardModule,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (moduleId: string) => selectedPackageModules.indexOf(moduleId) !== -1;

  const handleClick = (event: MouseEvent<unknown>, moduleId: string) => {
    const selectedIndex = selectedPackageModules.indexOf(moduleId);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedPackageModules, moduleId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedPackageModules.slice(1));
    } else if (selectedIndex === selectedPackageModules.length - 1) {
      newSelected = newSelected.concat(selectedPackageModules.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedPackageModules.slice(0, selectedIndex),
        selectedPackageModules.slice(selectedIndex + 1),
      );
    }

    dispatch(setSelectedPackageModules(newSelected));
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = standardLicenseModule.map((n) => n.moduleId);
      dispatch(setSelectedPackageModules(newSelected));
      return;
    }
    dispatch(setSelectedPackageModules([]));
  };

  return (
    <Box>
      <FormControl fullWidth variant="standard" sx={{ mb: 2 }}>
        <InputLabel id="select-vendor"><FormattedMessage id="components.filterModulesBasedOnVendor" /></InputLabel>
        <Select
          labelId="select-vendor"
          value={selectedVendor}
          onChange={handleVendorChange}
        >
          <MenuItem key="all" value="all">All Vendors</MenuItem>
          {standardVendors?.map((row) => (
            <MenuItem key={row.vendorId} value={row.vendorId}>{row.name}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormLabel><FormattedMessage id="components.modules" /></FormLabel>
      <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
        <Table stickyHeader data-testid="module-table" aria-label="module table">
          <EnhancedTableHead
            numSelected={selectedPackageModules.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={filteredModules.length}
          />
          <TableBody>
            {stableSort(filteredModules, getComparator(order, orderBy))
              .map((row, index) => {
                const isItemSelected = isSelected(row.moduleId);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    data-testid="module-node"
                    key={row.moduleId}
                    hover
                    onClick={(event) => handleClick(event, row.moduleId)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.applicationName}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export const AddUpdatePackageForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedPackage = useAppSelector(state => state.package.selectedPackage);
  const selectedPackageModules = useAppSelector(state => state.package.selectedPackageModules);
  const initialPackageModules = useAppSelector(state => state.package.initialPackageModules);
  const editPackage = useAppSelector(state => state.package.editPackage);
  const vendorStatus = useAppSelector(state => state.vendor.status);
  const vendors = useAppSelector(state => state.vendor.vendors);
  const customApplicationStatus = useAppSelector(state => state.application.customApplicationStatus);
  const customLicenseApplication = useAppSelector(state => state.application.customLicenseApplication);
  
  useEffect(() => {
    if (vendorStatus === 'idle' || vendorStatus === 'failed') {
      dispatch(getVendors());
    }
  }, []);

  useEffect(() => {
    if (vendors) {
      let vendorsData: any = [];
      vendorsData = vendors.filter((obj) => {
        return obj.externalLicensing !== true && obj.vendorType.toLowerCase() === "Software".toLowerCase();
      });
      dispatch(setStandardVendors(vendorsData));
    }
  }, [vendors]);

  useEffect(() => {
    if (customApplicationStatus === 'idle' || customApplicationStatus === 'failed') {
      dispatch(getCustomLicenseApplication());
    }
  }, []);

  const onSubmit = (data: IPackage) => {
    
    const finalData: any = {
      packageId: data.packageId,
      name: data.name,
      description: data.description,
      materialNumber: data.materialNumber,
      externalLicensing: data.license === 'Standard' ? false : true,
      applicationId: data.license === 'Standard' ? null : data.applicationId,
      modules: data.license === 'Standard' ? selectedPackageModules : null
    };

    if (editPackage) {
      dispatch(updatePackage(finalData));
    }
    else {
      dispatch(addPackage(finalData));
    }
  };

  const resetModules = () => {
    if (editPackage) {
      dispatch(setSelectedPackageModules(initialPackageModules))
    }
    else {
      dispatch(setSelectedPackageModules([]))
    }    
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={selectedPackage}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} data-testid="packages-form">
          <>{console.log(pristine)}</>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6}>
              <Field name="name" validate={Required}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.package" />}
                      variant="standard"
                      role="textbox"
                      aria-label="p-name"
                      fullWidth
                      error={meta.error && meta.touched}
                      disabled={editPackage}
                      autoComplete='off'
                      sx={{ marginTop: '0px' }}
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>

              <Field name="materialNumber" validate={ComposeValidators(Required, AlphaNumaric)}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.materialNumber" />}
                      variant="standard"
                      fullWidth
                      role="textbox"
                      aria-label="pack-material-number"
                      error={meta.error && meta.touched}
                      autoComplete='off'
                      sx={{ marginTop: '15px' }}
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>

              <Field name="description" validate={Required}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.description" />}
                      variant="standard"
                      fullWidth
                      role="textbox"
                      multiline
                      maxRows={3}
                      error={meta.error && meta.touched}
                      autoComplete='off'
                      sx={{ marginTop: '15px' }}
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>

              <Field name="license" validate={Required}>
                {({ input, meta }) => (
                  <FormControl variant="standard" sx={{ marginTop: '15px' }}>
                    <FormLabel id="license" error={meta.error && meta.touched}><FormattedMessage id="components.license" /></FormLabel>
                    <RadioGroup {...input} row aria-labelledby="license" name="license"  >
                      <FormControlLabel value="Standard" disabled={editPackage} control={<Radio />} label={<FormattedMessage id="components.standard" />} />
                      <FormControlLabel value="Custom" disabled={editPackage} control={<Radio />} label={<FormattedMessage id="components.custom" />} />
                    </RadioGroup>
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </FormControl>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              {values.license === 'Custom' &&
                  <Field name="applicationId" validate={Required}>
                    {({ input, meta }) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="application" error={meta.error && meta.touched}><FormattedMessage id="components.application" /></InputLabel>
                        <Select
                          {...input}
                          labelId="application"
                          error={meta.error && meta.touched}
                        >
                          {customLicenseApplication?.map((row: any) => (
                            <MenuItem key={row.applicationId} value={row.applicationId}>{row.name}</MenuItem>
                          ))}
                        </Select>
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </FormControl>
                    )}
                  </Field>
              }
              {values.license === 'Standard' &&
                <ModuleSelections />
              }
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button autoFocus onClick={() => {form.reset();resetModules() }} disabled={!(!pristine || (editPackage && selectedPackageModules !== initialPackageModules))} variant="contained" color="error" sx={{ marginRight: '5px' }}>
              <FormattedMessage id="components.reset" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
              {editPackage ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
            </Button>
          </Box>

        </form>
      )}
    />
  );
};
