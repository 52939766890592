import { MouseEvent, ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box, Grid, FormControl, InputLabel, Input, InputAdornment, IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Select, MenuItem } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { addEdgeDevice, getEdgeDeviceDetails, updateEdgeDevice } from "../../../store/thunks/edge-devices/edge-devices-thunks";
import { Required } from "../../../shared/form-validations/form-validations";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { IHardware } from "../../../shared/interfaces/hardware.interface";
import { getHardwares } from "../../../store/thunks/hardwares/hardwares-thunks";

export const AddUpdateEdgeDeviceForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedEdgeDevice = useAppSelector(state => state.edgeDevice.selectedEdgeDevice);
  const editEdgeDevice = useAppSelector(state => state.edgeDevice.editEdgeDevice);
  const hardwares = useAppSelector(state => state.hardware.hardwares);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const [showTeamViewerPassword, setShowTeamViewerPassword] = useState(false);
  const [selectedHardware, setSelectedHardware] = useState<IHardware | undefined>(undefined);

  useEffect(() => {
    if (editEdgeDevice && selectedEdgeDevice) {
      dispatch(getHardwares(""));
      dispatch(getEdgeDeviceDetails(selectedEdgeDevice.edgeDeviceId));
    }
    else{
      dispatch(getHardwares(""));
    }
  }, []);

  const onChangeHardware = (data: any) => {
    const hardware = hardwares.find(obj => obj.oemPartNumber === data.oempartNumber);
    setSelectedHardware(hardware);
  }

  const handleClickShowTeamViewerPassword = () => setShowTeamViewerPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = (data: any) => {
    let finalData = data;
    finalData = { 
      ...finalData, 
      hardware: null,
      customerId: selectedAppCustomer?.customerId
     }

    if (editEdgeDevice) {
      dispatch(updateEdgeDevice(finalData));
    }
    else {
      dispatch(addEdgeDevice(finalData));
    }
  };

  const imageTemplate = (rowData: IHardware) => {
    return <img src={`data:image/png;base64,${rowData.thumbNailImage}`} width="50" alt={rowData.thumbNailImageName} />;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={selectedEdgeDevice}
      render={({ handleSubmit, form, submitting, pristine, values }) => {
        onChangeHardware(values);
        return (
          <form onSubmit={handleSubmit} data-testid="edge-devices-form">
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <Field name="azureIotdeviceName" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.iotDeviceName" />}
                        variant="standard"
                        role="textbox"
                        fullWidth
                        error={meta.error && meta.touched}
                        disabled={editEdgeDevice}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="oempartNumber" validate={Required}>
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="oempartNumber" error={meta.error && meta.touched}><FormattedMessage id="components.oemPartNumber" /></InputLabel>
                      <Select
                        {...input}
                        labelId="oempartNumber"
                        error={meta.error && meta.touched}
                        disabled={editEdgeDevice}
                      >
                        {hardwares.map((row) => (
                          <MenuItem data-testid="select-option" key={row.hardwareId} value={row.oemPartNumber}>{row.oemPartNumber}</MenuItem>
                        ))}
                      </Select>
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                {selectedHardware &&
                  <TableContainer component={Paper}>
                    <Table data-testid="hardware-table" aria-label="hardware table">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell><FormattedMessage id="components.hardware" /></TableCell>
                          <TableCell><FormattedMessage id="components.oemPartNumber" /></TableCell>
                          <TableCell><FormattedMessage id="components.classification" /></TableCell>
                          <TableCell><FormattedMessage id="components.createdTime" /></TableCell>
                          <TableCell><FormattedMessage id="components.active" /></TableCell>
                          <TableCell><FormattedMessage id="components.vendor" /></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{imageTemplate(selectedHardware)}</TableCell>
                          <TableCell>{selectedHardware.name}</TableCell>
                          <TableCell>{selectedHardware.oemPartNumber}</TableCell>
                          <TableCell>{selectedHardware.hardwareClassification}</TableCell>
                          <TableCell>{selectedHardware.createDtTm}</TableCell>
                          <TableCell>{selectedHardware.isActive ? "Yes" : "No"}</TableCell>
                          <TableCell>{selectedHardware.vendorName}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
              </Grid>
              <Grid item xs={6}>
              <TextField
                value={selectedAppCustomer?.name ? selectedAppCustomer?.name : ""}
                label={<FormattedMessage id="components.customer" />}
                variant="standard"
                role="textbox"
                aria-label="po-customer"
                fullWidth
                autoComplete='off'
                disabled={true}
              />
              </Grid>
              <Grid item xs={6}>
                <Field name="oemserialNumber" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.oemSerialNumber" />}
                        variant="standard"
                        role="textbox"
                        fullWidth
                        error={meta.error && meta.touched}
                        disabled={editEdgeDevice}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="azureIothubName" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.iotHubName" />}
                        variant="standard"
                        role="textbox"
                        fullWidth
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="teamViewerId" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.teamViewerID" />}
                        variant="standard"
                        role="textbox"
                        fullWidth
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field name="teamViewerUserName" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.teamViewerUserName" />}
                        variant="standard"
                        role="textbox"
                        fullWidth
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="teamViewerPassword" validate={Required}>
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel htmlFor="teamViewerPassword"><FormattedMessage id="components.teamViewerPassword" /></InputLabel>
                      <Input
                        {...input}
                        id="teamViewerPassword"
                        type={showTeamViewerPassword ? 'text' : 'password'}
                        error={meta.error && meta.touched}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowTeamViewerPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showTeamViewerPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </FormControl>

                  )}
                </Field>

              </Grid>
              <Grid item xs={6}>
                <Field name="description" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.description" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        multiline
                        maxRows={3}
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
            </Grid>


            <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
              <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
                <FormattedMessage id="components.reset" />
              </Button>
              <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
                {editEdgeDevice ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
              </Button>
            </Box>

          </form>
        )
      }}
    />
  );
};
