import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Grid, Button, TextField, Box, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ComposeValidators, EmailAddress, Required, AllowedEmailDomains } from "../../shared/form-validations/form-validations";
import { IUser } from "../../shared/interfaces/user.interface";
import { updateUserProfile } from "../../store/thunks/user-management/user-management-thunks";

export const UpdateProfileForm = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.profile.loggedInUser);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const language = [
    { label: 'English', value: 'ENGLISH' },
    { label: 'Deutsch', value: 'GERMAN' }
  ];

  const onSubmit = (data: IUser) => {
    let finalData: any = {
      userId: data.userId,
      firstName: data.firstName,
      lastName: data.lastName,
      darkMode: data.darkMode,
      language: data.language
    };
    dispatch(updateUserProfile(finalData));   
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={user}
      render={({ handleSubmit, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit} data-testid="users-form">
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <Field name="userName">
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.username" />}
                      variant="standard"
                      fullWidth
                      role="textbox"
                      error={meta.error && meta.touched}
                      autoComplete='off'
                      disabled
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
            </Grid>
              <Grid item xs={12}>
                <Field name="email" >
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.emailAddress" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                        disabled
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="firstName" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.firstName" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="lastName" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.lastName" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
              <Field name="darkMode">
                {({ input, meta }) => (
                  <FormControl variant="standard">
                    <FormLabel><FormattedMessage id="components.theme" /></FormLabel>
                    <RadioGroup {...input} row aria-labelledby="theme" name="theme"  >
                      <FormControlLabel value={false} control={<Radio />} label={<FormattedMessage id="components.light" />} />
                      <FormControlLabel value={true} control={<Radio />} label={<FormattedMessage id="components.dark" />} />
                    </RadioGroup>
                  </FormControl>
                )}
              </Field>
              </Grid>
              <Grid item xs={12}>
              <Field name="language">
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="language" error={meta.error && meta.touched}><FormattedMessage id="components.language" /></InputLabel>
                      <Select
                        {...input}
                        labelId="language"
                        error={meta.error && meta.touched}
                      >
                        {language.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Grid>
              
          </Grid>

          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
              <FormattedMessage id="components.reset" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
              <FormattedMessage id="components.update" />
            </Button>
          </Box>
        </form>
      )}
    />
  );
};
