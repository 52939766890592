import { createSlice } from '@reduxjs/toolkit';
import { IIotPlatform, IIotPlatformMicroServices } from "../../shared/interfaces/iot-platform.interface";
import { addIotPlatform, deleteIotPlatform, getIotPlatformMicroServices, getIotPlatforms, updateIotPlatform } from '../thunks/iot-platforms/iot-platforms-thunks';

interface IotPlatformState {
    iotPlatforms: IIotPlatform[],
    iotPlatformMicroServices: IIotPlatformMicroServices[],
    selectedIotPlatform: any,
    openViewIotPlatformDialog: boolean,
    openAddUpdateIotPlatformDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    editIotPlatform: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: IotPlatformState = {
    iotPlatforms: [],
    iotPlatformMicroServices:[],
    selectedIotPlatform: null,
    openViewIotPlatformDialog: false,
    openAddUpdateIotPlatformDialog: false,
    openDeleteConfirmationDialog: false,
    editIotPlatform: false,
    status: 'idle',
    error: undefined
}

export const iotPlatformsSlice = createSlice({    
    name: "iotPlatform",
    initialState,
    reducers: {
        setIotPlatforms: (state, action) => {
            state.iotPlatforms = action.payload
        },
        setIotPlatformMicroServices: (state, action) => {
            state.iotPlatformMicroServices = action.payload
        },
        setSelectedIotPlatform: (state, action) => {
            state.selectedIotPlatform = action.payload
        },
        setOpenViewIotPlatformDialog: (state, action) => {
            state.openViewIotPlatformDialog = action.payload
        },
        setOpenAddUpdateIotPlatformDialog: (state, action) => {
            state.openAddUpdateIotPlatformDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditIotPlatform: (state, action) => {
            state.editIotPlatform = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getIotPlatforms.fulfilled, (state, action) => {            
            state.iotPlatforms = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getIotPlatforms.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getIotPlatforms.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getIotPlatformMicroServices.fulfilled, (state, action) => {            
            state.iotPlatformMicroServices = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getIotPlatformMicroServices.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getIotPlatformMicroServices.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addIotPlatform.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addIotPlatform.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addIotPlatform.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updateIotPlatform.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(updateIotPlatform.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateIotPlatform.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deleteIotPlatform.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deleteIotPlatform.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteIotPlatform.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setIotPlatforms,
    setIotPlatformMicroServices,
    setSelectedIotPlatform,
    setOpenViewIotPlatformDialog,
    setOpenAddUpdateIotPlatformDialog,
    setOpenDeleteConfirmationDialog,
    setEditIotPlatform
} = iotPlatformsSlice.actions;
export default iotPlatformsSlice.reducer;
