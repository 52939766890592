import { createSlice } from '@reduxjs/toolkit';
import { IApplication } from "../../shared/interfaces/application.interface"
import { addApplication, deleteApplication, getApplications, getCustomLicenseApplication, updateApplication } from '../thunks/applications/applications-thunks';

interface ApplicationState {
    applications: IApplication[],
    selectedApplication: any,
    openViewApplicationDialog: boolean,
    openAddUpdateApplicationDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    editApplication: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined,
    customLicenseApplication: IApplication[]
    customApplicationStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
}

const initialState: ApplicationState = {
    applications: [],
    selectedApplication: null,
    openViewApplicationDialog: false,
    openAddUpdateApplicationDialog: false,
    openDeleteConfirmationDialog: false,
    editApplication: false,
    status: 'idle',
    error: undefined,
    customLicenseApplication: [],
    customApplicationStatus: 'idle'
}

export const applicationsSlice = createSlice({    
    name: "application",
    initialState,
    reducers: {
        setApplications: (state, action) => {
            state.applications = action.payload
        },
        setSelectedApplication: (state, action) => {
            state.selectedApplication = action.payload
        },
        setOpenViewApplicationDialog: (state, action) => {
            state.openViewApplicationDialog = action.payload
        },
        setOpenAddUpdateApplicationDialog: (state, action) => {
            state.openAddUpdateApplicationDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditApplication: (state, action) => {
            state.editApplication = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getApplications.fulfilled, (state, action) => {            
            state.applications = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getApplications.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getApplications.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addApplication.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addApplication.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addApplication.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updateApplication.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(updateApplication.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateApplication.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deleteApplication.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deleteApplication.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteApplication.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getCustomLicenseApplication.fulfilled, (state, action) => {
            state.customLicenseApplication = action.payload;
            state.customApplicationStatus = 'succeeded'
        })
        .addCase(getCustomLicenseApplication.pending, (state, action) => {
            state.customApplicationStatus = 'loading'
        })
        .addCase(getCustomLicenseApplication.rejected, (state, action) => {
            state.customApplicationStatus = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setApplications,
    setSelectedApplication,
    setOpenViewApplicationDialog,
    setOpenAddUpdateApplicationDialog,
    setOpenDeleteConfirmationDialog,
    setEditApplication
} = applicationsSlice.actions;
export default applicationsSlice.reducer;
