import { createSlice } from '@reduxjs/toolkit';
import { IUserDetails } from '../../shared/interfaces/user-details.interface';
import { IUserCredentials } from '../../shared/interfaces/user-credentials.interface';
import { authenticateUrlToken, getLoggedInUser, login } from '../thunks/user-management/user-management-thunks';

interface ProfileState {
    notifications: any[];
    profile?: any,
    loggedInUser: IUserDetails | null,
    loginStatus: boolean,
    resetPasswordEmailSent: boolean,
    invalidResetPasswordToken: boolean,
    passwordResetSucess: boolean,
    redirectToRoot: boolean,
    changePasswordCompleted: boolean,
    roles: string[],
    authLink: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined,
    userCredentials: IUserCredentials,
    updateProfileDialog: boolean
}

const initialState: ProfileState = {
    profile: undefined,
    notifications: [],
    loggedInUser: null,
    loginStatus: false,
    redirectToRoot: false,
    changePasswordCompleted: false,
    resetPasswordEmailSent: false,
    invalidResetPasswordToken: false,
    passwordResetSucess: false,
    updateProfileDialog: false,
    roles: [],
    authLink: false,
    status: 'idle',
    error: undefined,
    userCredentials: {
        userName: '',
        password: ''
    }
}

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setProfile: (state, action) => {
            state.profile = action.payload
            state.loginStatus = true;
            state.roles = action.payload.roles
        },
        resetProfile: (state) => {
            state.profile = undefined
            state.loginStatus = false
            state.roles = []
        },
        addRole: (state, action) => {
            state.roles = [action.payload, ...state.roles]
        },
        setLoginStatus: (state, action) => {
            state.loginStatus = action.payload
        },
        setAuthLink: (state, action) => {
            state.authLink = action.payload
        },
        setUserCredentials: (state, action) => {
            state.userCredentials = action.payload
        },
        setLoggedInUser: (state, action) => {
            state.loggedInUser = action.payload
        },
        setUpdateProfileDialog: (state, action) => {
            state.updateProfileDialog = action.payload
        },
        setRedirectToRoot: (state, action) => {
            state.redirectToRoot = action.payload
        },
        setChangePasswordCompleted: (state, action) => {
            state.changePasswordCompleted = action.payload
        },
        setResetPasswordEmailSent: (state, action) => {
            state.resetPasswordEmailSent = action.payload
        },
        setInvalidResetPasswordToken: (state, action) => {
            state.invalidResetPasswordToken = action.payload
        },
        setPasswordResetSucess: (state, action) => {
            state.passwordResetSucess = action.payload
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload
        },
        appendNotifications: (state, {payload}) => {
            state.notifications = [...state.notifications, payload]
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => ({ ...state }))
            .addCase(authenticateUrlToken.fulfilled, (state, action) => {
                state.profile = action.payload;
                state.loginStatus = true;
                state.roles = action.payload.roles
            })
            .addCase(authenticateUrlToken.rejected, (state, action) => {
                state.error = action.error.message
            })
            .addCase(getLoggedInUser.fulfilled, (state, action) => {
                state.loggedInUser = action.payload;
                state.status = 'succeeded'
            })
            .addCase(getLoggedInUser.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getLoggedInUser.rejected, (state, action) => {
                state.loggedInUser = null;
                state.status = 'failed'
                state.error = action.error.message
            });
    }
})

export const {
    setProfile,
    resetProfile,
    setLoginStatus,
    addRole,
    setAuthLink,
    setUserCredentials,
    setLoggedInUser,
    setUpdateProfileDialog,
    appendNotifications,
    setRedirectToRoot,
    setChangePasswordCompleted,
    setResetPasswordEmailSent,
    setInvalidResetPasswordToken,
    setPasswordResetSucess,
    setNotifications
} = profileSlice.actions;
export default profileSlice.reducer;