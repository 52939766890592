import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IPurchaseOrder } from "../../shared/interfaces/purchase-order.interface";
import { Required } from "../../shared/form-validations/form-validations";
import { duplicatePurchaseOrder } from "../../store/thunks/purchase-orders/purchase-orders-thunks";

export const DuplicateOrderForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const purchaseOrders = useAppSelector(state => state.purchaseOrder.purchaseOrders);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
    
  const onSubmit = (data: IPurchaseOrder) => {
    const values = {
      purchaseOrderId: "",
      originalPurchaseOrderId: data.purchaseOrderId,
      orderNo: data.orderNo,
      customerId: selectedAppCustomer ? selectedAppCustomer?.customerId : '',
      customerPo: data.customerPo
    }
      dispatch(duplicatePurchaseOrder(values));
  };

  return (
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit} data-testid="purchase-orders-form">
            <TextField
              value={selectedAppCustomer?.name}
              label={<FormattedMessage id="components.customer" />}
              variant="standard"
              role="textbox"
              aria-label="po-customer"
              fullWidth
              autoComplete='off'
              sx={{ marginTop: '0px' }}
              disabled={true}
            />
            <Field name="purchaseOrderId" validate={Required}>
            {({ input, meta }) => (
              <FormControl variant="standard" fullWidth sx={{ marginTop: '15px' }}>
                <InputLabel id="order" error={meta.error && meta.touched}><FormattedMessage id="components.selectOrderCopy" /></InputLabel>
                <Select
                  {...input}
                  labelId="order"
                  error={meta.error && meta.touched}
                >
                  {purchaseOrders.map((row: any) => (
                    <MenuItem key={row.purchaseOrderId} value={row.purchaseOrderId}>{row.orderNo}</MenuItem>
                  ))}
                </Select>
                <span style={{ fontSize: '12px' }}><FormattedMessage id="components.copyOrderMessage" /></span>
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </FormControl>
            )}
          </Field>

            <Field name="orderNo" validate={Required}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.order#" />}
                    variant="standard"
                    role="textbox"
                    aria-label="po-orderno"
                    fullWidth
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '15px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Field name="customerPo" validate={Required}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.customerPO" />}
                    variant="standard"
                    fullWidth
                    role="textbox"
                    aria-label="po-customerpo"
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '15px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
              <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
                <FormattedMessage id="components.reset" />
              </Button>
              <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
                <FormattedMessage id="components.add" />
              </Button>
            </Box>

          </form>
        )}
      />
  );
};
