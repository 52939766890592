import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box, Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IToolingObject } from "../../shared/interfaces/tooling-object.interface";
import { addToolingObject } from "../../store/thunks/tooling-objects/tooling-objects-thunks";
import { Required } from "../../shared/form-validations/form-validations";
import { Role } from "../../shared/enums/role.enum";

export const AddUpdateToolingObjectForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedToolingObject = useAppSelector(state => state.toolingObject.selectedToolingObject);
  const editToolingObject = useAppSelector(state => state.toolingObject.editToolingObject);
  const selectedCustomer = useAppSelector(state => state.toolingObject.selectedCustomer);
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);

  const onSubmit = (data: IToolingObject) => {
    if (editToolingObject) {
      let finalData = selectedToolingObject;
      finalData = { ...finalData, customerPartNumber: data.customerPartNumber };
      dispatch(addToolingObject(finalData));
    }
    else {
      let finalData: any = data;
      if (loggedInUser?.userRoleNames.includes(Role.GLEASON_VENDOR)) {
        finalData = {
          ...finalData,
          customerNumber: selectedCustomer?.companyCode,
          customerName: selectedCustomer?.name,
          oem: loggedInUser?.vendorName
        };
      }
      else {
        finalData = {
          ...finalData,
          customerNumber: selectedCustomer?.companyCode,
          customerName: selectedCustomer?.name
        };
      }

      dispatch(addToolingObject(finalData));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={selectedToolingObject}
      render={({ handleSubmit, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit} data-testid="tooling-objects-form">
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

            {!(loggedInUser?.userRoleNames.includes(Role.GLEASON_VENDOR)) &&
              <Grid item xs={6}>
                <Field name="oem" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.vendor" />}
                        variant="standard"
                        role="textbox"
                        aria-label="to-oem"
                        fullWidth
                        error={meta.error && meta.touched}
                        disabled={editToolingObject}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
            }

            <Grid item xs={6}>
              <Field name="oemPartNumber" validate={Required}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.oemPartNumber" />}
                      variant="standard"
                      fullWidth
                      role="textbox"
                      aria-label="to-oemPartNumber"
                      error={meta.error && meta.touched}
                      disabled={editToolingObject}
                      autoComplete='off'
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
            </Grid>
            {(loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) || loggedInUser?.userRoleNames.includes(Role.GLEASON_VENDOR)) &&
              <Grid item xs={6}>
                <Field name="oemSerialNumber" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.oemSerialNumber" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        aria-label="to-oemSerialNumber"
                        error={meta.error && meta.touched}
                        disabled={editToolingObject}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
            }
            <Grid item xs={6}>
              <Field name="customerPartNumber">
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.customerPartNumber" />}
                      variant="standard"
                      fullWidth
                      role="textbox"
                      aria-label="to-customerPartNumber"
                      error={meta.error && meta.touched}
                      autoComplete='off'
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
            </Grid>
            {(loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) || loggedInUser?.userRoleNames.includes(Role.GLEASON_VENDOR)) &&
              <>
                <Grid item xs={6}>
                  <Field name="orderNumber" validate={Required}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.orderNumber" />}
                          variant="standard"
                          role="textbox"
                          aria-label="to-orderNumber"
                          fullWidth
                          error={meta.error && meta.touched}
                          disabled={editToolingObject}
                          autoComplete='off'
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="productionState" validate={Required}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.productionState" />}
                          variant="standard"
                          role="textbox"
                          aria-label="to-productionState"
                          fullWidth
                          error={meta.error && meta.touched}
                          disabled={editToolingObject}
                          autoComplete='off'
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="trackingNumber" validate={Required}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.trackingNumber" />}
                          variant="standard"
                          role="textbox"
                          aria-label="to-trackingNumber"
                          fullWidth
                          error={meta.error && meta.touched}
                          disabled={editToolingObject}
                          autoComplete='off'
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="locationType" validate={Required}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.locationType" />}
                          variant="standard"
                          role="textbox"
                          aria-label="to-locationType"
                          fullWidth
                          error={meta.error && meta.touched}
                          disabled={editToolingObject}
                          autoComplete='off'
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="locationSubType" validate={Required}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.locationSubType" />}
                          variant="standard"
                          role="textbox"
                          aria-label="to-locationSubType"
                          fullWidth
                          error={meta.error && meta.touched}
                          disabled={editToolingObject}
                          autoComplete='off'
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="locationValue" validate={Required}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.locationValue" />}
                          variant="standard"
                          role="textbox"
                          aria-label="to-locationValue"
                          fullWidth
                          error={meta.error && meta.touched}
                          disabled={editToolingObject}
                          autoComplete='off'
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="customerDescription" validate={Required}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.customerDescription" />}
                          variant="standard"
                          role="textbox"
                          aria-label="to-customerDescription"
                          fullWidth
                          error={meta.error && meta.touched}
                          disabled={editToolingObject}
                          autoComplete='off'
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="comments" validate={Required}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.comments" />}
                          variant="standard"
                          role="textbox"
                          aria-label="to-comments"
                          fullWidth
                          error={meta.error && meta.touched}
                          disabled={editToolingObject}
                          autoComplete='off'
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="type" validate={Required}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.type" />}
                          variant="standard"
                          role="textbox"
                          aria-label="to-type"
                          fullWidth
                          error={meta.error && meta.touched}
                          disabled={editToolingObject}
                          autoComplete='off'
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  <Field name="subType" validate={Required}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.subType" />}
                          variant="standard"
                          role="textbox"
                          aria-label="to-subType"
                          fullWidth
                          error={meta.error && meta.touched}
                          disabled={editToolingObject}
                          autoComplete='off'
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
              </>
            }
          </Grid>
          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
              <FormattedMessage id="components.reset" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
              {editToolingObject ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
            </Button>
          </Box>

        </form>
      )}
    />
  );
};
