import { ReactElement, SyntheticEvent, useState } from "react";
import { FormattedMessage } from "react-intl";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Paper, Box, Tab } from "@mui/material";
import { GrantPermissions } from "./grant-permissions.component";
import { Users } from "./users.component";
import { VendorUsers } from "./vendor-users.component";
import { Role } from "../../shared/enums/role.enum";
import { useAppSelector } from "../../store/hooks";

export const UserManagement = (): ReactElement => {
  const [value, setValue] = useState('1');
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);

  const handleUserManagementTabChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="user-management">
        <div className="page-header">
          <h2><FormattedMessage id="components.userManagement" /></h2>
        </div>
        <Paper>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleUserManagementTabChange} variant="fullWidth" aria-label="user management tabs" data-testid="user-management-tabs">
                <Tab label={<FormattedMessage id="components.users" />} value="1" />
                <Tab label={<FormattedMessage id="components.grantPermissions" />} value="2" />
                {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
                  <Tab label={<FormattedMessage id="components.vendorUser" />} value="3" />
                }
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: 0, pt:3 }} data-testid="users-panel"><Users  /></TabPanel>
            <TabPanel value="2" sx={{ p: 0, pt:3 }} data-testid="grant-permissions-panel"><GrantPermissions /></TabPanel>
            { loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
              <TabPanel value="3" sx={{ p: 0, pt:3 }} data-testid="vendor-users-panel"><VendorUsers /></TabPanel>
            }
          </TabContext>
        </Paper>
      </div>
    </>
  );
};
