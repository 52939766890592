/**
 * @description Define supported roles
 */
 export enum Role {
    GLOBAL_GLEASON_ADMIN = "Global Gleason Admin",
    USER = "User",
    CUSTOMER_ADMIN = "Customer Admin",
    GLEASON_REGINAL_SALES_MANAGER = "Gleason Regional Sales Manager (RSM)",
    GLEASON_INTERNAL_SALES = "Gleason Internal Sales",
    GLEASON_ENGINEER = "Gleason Engineer / Service Engineer",
    GLEASON_VENDOR = "Gleason Vendor",
    PASSWORD_CHANGE_ONLY = "PasswordChangeOnly",
    COMMON = "Common",
    TRIAL_EXPIRED ="trialExpired",
    TERMS ="Terms"
  }