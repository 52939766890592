import { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field, FieldInputProps } from "react-final-form";
import { Button, TextField, Box, Select, InputLabel, MenuItem, FormControl, Grid, Link, FormControlLabel, Checkbox, Input, FormLabel } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IModule } from "../../shared/interfaces/module.interface";
import { addModule, checkIfModuleConfigSettingIsModified, getModuleConfigSettingSchema, updateModule } from "../../store/thunks/modules/modules-thunks";
import { AlphaNumaric, CommaSeperatedPermissions, ComposeValidators, MaxLengthValidation63, Required, RequiredConfigFile, RFC1123LabelValidation } from "../../shared/form-validations/form-validations";
import { getApplications } from "../../store/thunks/applications/applications-thunks";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { setModuleConfigSettingIsModified } from "../../store/slices/modules";

interface IProps {
  input: FieldInputProps<any, HTMLElement>,
  moduleName: string,
  applicationId: string
}

const StoragePathField = ({ input, moduleName, applicationId }: IProps) => {
  const applications = useAppSelector(state => state.application.applications);

  useEffect(() => {
    const modName = moduleName ? moduleName : "";
    const application = applications.find(a => {
      return a.applicationId === applicationId;
    });
    const appName = application ? application.name : "";
    let val = "gleason-applications/" + appName + "_" + modName + ".zip";
    val = val.toLowerCase().replace(/\s/g, '');
    input.onChange({ target: { value: val } });
  }, [moduleName, applicationId]);

  return <TextField
    label={<FormattedMessage id="components.storagePath" />}
    variant="standard"
    fullWidth
    role="textbox"
    disabled={true}
    {...input}
  />
};

export const AddUpdateModuleForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const selectedModule = useAppSelector(state => state.module.selectedModule);
  const editModule = useAppSelector(state => state.module.editModule);
  const moduleConfigSettingIsModified = useAppSelector(state => state.module.moduleConfigSettingIsModified);
  const applications = useAppSelector(state => state.application.applications);
  const applicationStatus = useAppSelector(state => state.application.status);
  const moduleConfigSettingSchemaStatus = useAppSelector(state => state.module.moduleConfigSettingSchemaStatus);
  const configSettingSchema = useAppSelector(state => state.module.moduleConfigSettingSchema);
  const [uploadConfigSettingsVisible, setUploadConfigSettingsVisible] = useState(false);
  const platforms = [
    { label: 'Edge', value: 'Edge' },
    { label: 'Cloud', value: 'Cloud' },
    { label: 'Machine', value: 'Machine' },
    { label: 'Enterprise', value: 'Enterprise' }
  ];
  const [openDialog, setOpenDialog] = useState(false);
  const [updateModuleData, setUpdateModuleData] = useState<any>(null);

  useEffect(() => {
    let finalData = updateModuleData;

    if (moduleConfigSettingIsModified?.isModified === false) {
      finalData = { ...finalData, 'removeExistingModuleConfiguration': false }
      dispatch(updateModule(finalData));
    }
    if ((moduleConfigSettingIsModified?.isModified === true)) {
      finalData = { ...finalData, 'removeExistingModuleConfiguration': true }
      setUpdateModuleData(finalData);
      handleClickOpenDialog();
    }
  }, [moduleConfigSettingIsModified?.isModified]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCancelDialog = () => {
    setOpenDialog(false);
    dispatch(setModuleConfigSettingIsModified(false));
  };

  const onConfirmDialog = () => {
    dispatch(updateModule(updateModuleData));
  }

  useEffect(() => {
    if (applicationStatus === 'idle' || applicationStatus === 'failed') {
      dispatch(getApplications());
    }
    if (moduleConfigSettingSchemaStatus === 'idle' || moduleConfigSettingSchemaStatus === 'failed') {
      dispatch(getModuleConfigSettingSchema());
    }
  }, []);

  const onSubmit = async (data: IModule) => {
    if (editModule) {
      onUpdateModule(data);
    }
    else {
      onAddModule(data);
    }
  };

  const onAddModule = async (data: IModule) => {
    let finalData: IModule | any = data;
    if (data.configSettingsUploaded) {
      finalData = await configSettingsUpload(data);
    }
    else {
      finalData.configSettings = null;
      finalData.configFileName = null;
    }
    finalData = { ...finalData, currentUserId: loggedInUser?.userId }
    dispatch(addModule(finalData));
  };

  const onUpdateModule = async (data: IModule) => {
    let finalData: IModule | any = data;
    if (data.configSettingsUploaded && data.configSettingsUploaded !== "") {
      finalData = await configSettingsUpload(data);
      finalData = { ...finalData, currentUserId: loggedInUser?.userId }
      setUpdateModuleData(finalData);
      const payloadData = {
        moduleId: finalData.moduleId,
        moduleName: finalData.name,
        moduleConfiguration: finalData.configSettings, 
        currentUserId: loggedInUser?.userId 
      }
      dispatch(checkIfModuleConfigSettingIsModified(payloadData));
    }
    else {
      finalData = { ...finalData, currentUserId: loggedInUser?.userId, removeExistingModuleConfiguration: false }
      dispatch(updateModule(finalData));
    }
  }

  //async file reader
  const configSettingsUpload = (data: IModule) => {
    let file = data.configSettingsUploaded[0];
    let finalData: IModule = data;
    let reader = new FileReader();
    if (file) {
      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          const result = reader.abort;
          reject("Error in reading file");
        };
        reader.onload = () => {
          finalData.configSettings = reader.result?.toString().split(',').pop();
          finalData.configFileName = file.name;
          resolve(finalData);
        };
        reader.readAsDataURL(file);
      });
    }
    else {
      finalData.configSettings = null;
      finalData.configFileName = null;
      return finalData;
    }
  }
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={selectedModule}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} data-testid="module-form">
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <Field name="name" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.module" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                        disabled={editModule}
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field name="applicationId" validate={Required}>
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="application" error={meta.error && meta.touched}><FormattedMessage id="components.application" /></InputLabel>
                      <Select
                        {...input}
                        labelId="application"
                        error={meta.error && meta.touched}
                      >
                        {applications?.map((row) => (
                          <MenuItem key={row.applicationId} value={row.applicationId}>{row.name}</MenuItem>
                        ))}
                      </Select>
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </FormControl>
                  )}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field name="code" validate={AlphaNumaric}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.code" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field name="platform" validate={Required}
                  format={value => Array.isArray(value) ? value : []}
                >
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="platform" error={meta.error && meta.touched}><FormattedMessage id="components.platform" /></InputLabel>
                      <Select
                        {...input}
                        labelId="platform"
                        error={meta.error && meta.touched}
                        multiple
                      >
                        {platforms.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Grid>

              {values.platform?.includes("Edge") &&
                <>
                  <Grid item xs={6}>
                    <Field name="isDocker" type="checkbox" >
                      {({ input }) => (
                        <FormControlLabel sx={{ display: 'block', marginTop: '6px' }}
                          control={<Checkbox {...input.onChange} {...input} />}
                          label={<FormattedMessage id="components.dockerImage" />}
                        />
                      )}
                    </Field>
                  </Grid>
                  {values.isDocker === true &&
                    <>
                      <Grid item xs={6}>
                        <Field name="containerRegistryPath" validate={Required}>
                          {({ input, meta }) => (
                            <>
                              <TextField
                                {...input}
                                label={<FormattedMessage id="components.containerRegistryPath" />}
                                variant="standard"
                                fullWidth
                                role="textbox"
                                error={meta.error && meta.touched}
                                autoComplete='off'
                              />
                              {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                            </>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={6}>
                        <Field name="serviceName" validate={ComposeValidators(Required, MaxLengthValidation63, RFC1123LabelValidation)}>
                          {({ input, meta }) => (
                            <>
                              <TextField
                                {...input}
                                label={<FormattedMessage id="components.moduleServiceName" />}
                                variant="standard"
                                fullWidth
                                role="textbox"
                                error={meta.error && meta.touched}
                                autoComplete='off'
                              />
                              {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                            </>
                          )}
                        </Field>
                      </Grid>
                    </>
                  }
                  {(values.isDocker === undefined || values.isDocker === false) &&
                    <>
                      <Grid item xs={6}>
                        <Field name="storagePath" >
                          {({ input, meta }) => (
                            <StoragePathField input={input} moduleName={values.name} applicationId={values.applicationId} />
                          )}
                        </Field>
                      </Grid>
                    </>
                  }
                </>
              }

              <Grid item xs={6}>
                <Field name="permissions" validate={ComposeValidators(CommaSeperatedPermissions)}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.permissions" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field name="description">
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.description" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        multiline
                        maxRows={3}
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                {values.configFileName && values.configFileName !== '' ?
                  <>
                    {!uploadConfigSettingsVisible &&
                      <>
                        <Link href="#!" style={{ marginBottom: '20px' }} onClick={() => setUploadConfigSettingsVisible(!uploadConfigSettingsVisible)} ><UploadIcon sx={{ position: "relative", top: "6px" }} /> <FormattedMessage id="components.newConfigSettings" /> </Link>
                        <br />
                      </>
                    }
                    {uploadConfigSettingsVisible &&
                      <Field name="configSettingsUploaded">
                        {({ input: { value, onChange, ...input }, meta }) => (
                          <>
                            <InputLabel error={meta.error && meta.touched}><FormattedMessage id="components.newConfigSettings" /></InputLabel>
                            <input onChange={({ target }) => onChange(target.files)} {...input} type="file" aria-label="upload file" accept=".json" />
                            <div style={{ fontSize: "11px", opacity: "0.5" }}><FormattedMessage id="components.uploadConfigSettings" /></div>
                            {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                          </>
                        )}
                      </Field>
                    }
                  </>
                  :
                  <Field name="configSettingsUploaded" validate={RequiredConfigFile(values.platform, values.isDocker)}>
                    {({ input: { value, onChange, ...input }, meta }) => (
                      <>
                        <InputLabel error={meta.error && meta.touched}><FormattedMessage id="components.configSettings" /></InputLabel>
                        <input onChange={({ target }) => onChange(target.files)} {...input} type="file" aria-label="upload file" accept=".json" />
                        <div style={{ fontSize: "11px", opacity: "0.5" }}><FormattedMessage id="components.uploadConfigSettings" /></div>
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                }

                {editModule && values.configFileName && values.configFileName !== '' &&
                  <Link download={values.configFileName}
                    href={`data:text/json;base64,${values.configSettings}`}
                    onClick={(event) => event.stopPropagation()} >
                    <DownloadIcon sx={{ position: "relative", top: "6px" }} /> <FormattedMessage id="components.configSettings" />
                  </Link>
                }
                <br />
                <Link download="Schema.json"
                  href={`data:text/json;base64,${configSettingSchema}`}
                  onClick={(event) => event.stopPropagation()} >
                  <DownloadIcon sx={{ position: "relative", top: "6px" }} /> <FormattedMessage id="components.jsonSchema" />
                </Link>

              </Grid>

            </Grid>
            <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
              <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }} data-testid="reset-button">
                <FormattedMessage id="components.reset" />
              </Button>
              <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button">
                {editModule ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
              </Button>
            </Box>

          </form>
        )}
      />
      <Dialog
        open={openDialog}
        onClose={handleCancelDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="delete-dlg"
      >
        <DialogTitle id="alert-dialog-title"><FormattedMessage id="components.moduleConfigSettingsPopupHead" /></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage id="components.moduleConfigSettingsPopupMessage" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialog} variant="contained" color="error">
            <FormattedMessage id="components.cancel" />
          </Button>
          <Button onClick={onConfirmDialog} variant="contained" color="primary" autoFocus>
            <FormattedMessage id="components.yes" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
