import DashboardIcon from '@mui/icons-material/Dashboard';
import AppsIcon from '@mui/icons-material/Apps';
import PinDropIcon from '@mui/icons-material/PinDrop';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import GroupIcon from '@mui/icons-material/Group';
import StarIcon from '@mui/icons-material/Star';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import WorkIcon from '@mui/icons-material/Work';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import LayersIcon from '@mui/icons-material/Layers';
import CloudIcon from '@mui/icons-material/Cloud';
import ComputerIcon from '@mui/icons-material/Computer';
import DownloadIcon from '@mui/icons-material/Download';
import ConstructionIcon from '@mui/icons-material/Construction';
import { FormattedMessage } from 'react-intl';
import { Role } from '../../shared/enums/role.enum';

// nav links
export const navLinks =  [
    {
      "menu_title": <FormattedMessage id="components.dashboard" />,
      "menu_icon": <DashboardIcon/>,
      "path": "/dashboard",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.applications" />,
			"menu_icon": <AppsIcon/>,
      "path": "/applications",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN, Role.USER, Role.CUSTOMER_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.dataCenters" />,
      "menu_icon": <PinDropIcon/>,
      "path": "/data-centers",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.customers" />,
      "menu_icon": <AssignmentIndIcon/>,
      "path": "/customers",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.userManagement" />,
      "menu_icon": <GroupIcon/>,
      "path": "/user-management",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN, Role.CUSTOMER_ADMIN]
    },    
    {
      "menu_title": <FormattedMessage id="components.vendors" />,
      "menu_icon": <StarIcon/>,
      "path": "/vendors",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.applicationCatalog" />,
      "menu_icon": <ViewCarouselIcon/>,
      "path": "/application-catalog",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.modules" />,
      "menu_icon": <ViewModuleIcon/>,
      "path": "/modules",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.packages" />,
      "menu_icon": <WorkIcon/>,
      "path": "/packages",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.purchases" />,
      "menu_icon": <ShoppingCartIcon/>,
      "path": "/purchases",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN, Role.CUSTOMER_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.licensedUsers" />,
      "menu_icon": <VerifiedUserIcon/>,
      "path": "/licensed-users",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN, Role.CUSTOMER_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.edgeDeviceManagement" />,
      "menu_icon": <ChromeReaderModeIcon/>,
      "path": "/edge-device-management",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN, Role.CUSTOMER_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.machineManagement" />,
      "menu_icon": <LayersIcon/>,
      "path": "/machine-management",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN, Role.CUSTOMER_ADMIN],
      "child": true
    },
    {
      "menu_title": <FormattedMessage id="components.cloudServerManagement" />,
      "menu_icon": <CloudIcon/>,
      "path": "/cloud-server-management",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.enterpriseServerManagement" />,
      "menu_icon": <ComputerIcon/>,
      "path": "/enterprise-server-management",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.downloads" />,
      "menu_icon": <DownloadIcon/>,
      "path": "/downloads",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN, Role.CUSTOMER_ADMIN]
    },
    {
      "menu_title": <FormattedMessage id="components.tools" />,
      "menu_icon": <ConstructionIcon/>,
      "path": "/tools",
      "allowedRoles": [Role.GLOBAL_GLEASON_ADMIN, Role.CUSTOMER_ADMIN, Role.GLEASON_VENDOR]
    }
  ]
