import { MouseEvent, ReactElement, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import GleasonLogo from "../../assets/images/gleason-logo.svg";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { Field, Form } from "react-final-form";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ComposeValidators, ConfirmPassword, Password, Required } from "../../shared/form-validations/form-validations";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { passwordUpdate, validatePasswordResetRecoveryToken } from "../../store/thunks/user-management/user-management-thunks";
import { useLocation } from "react-router-dom";

export const ResetPassword = (): ReactElement => {
  const dispatch = useAppDispatch();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const recoveryToken = params.get('id') ? params.get('id') : '';
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const invalidResetPasswordToken = useAppSelector(state => state.profile.invalidResetPasswordToken);
  const passwordResetSucess = useAppSelector(state => state.profile.passwordResetSucess);

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  let initialized = false;

  useEffect(() => {
    if (!initialized) {
      initialized = true
      if (recoveryToken && recoveryToken !== '') {
        dispatch(validatePasswordResetRecoveryToken(recoveryToken));
      }
    }
  }, []);
  
  const onSubmit = (data: any) => {
    const finalData = {
      recoveryToken: recoveryToken,
      newPassword: data.newPassword
    }
    dispatch(passwordUpdate(finalData));
  };

  return (
    <>
      <div style={{ backgroundColor: "#f4f7fa", width: "100%", height: "100vh" }}>
        <Box sx={{ padding: "30px 0 30px 100px" }}>
          <div className="session-logo">
            <Link underline="none" href="/">
              <img src={GleasonLogo} alt="gleason-logo" className="img-fluid" width="110" height="35" />
            </Link>
          </div>
        </Box>
        <Card sx={{ margin: "0 auto", maxWidth: "400px", backgroundColor: "#ffffff", padding: "20px 80px" }}>
          <div style={{ marginBottom: "20px", textAlign: "center" }}>
            <Typography variant="h5" component="h5"> GEMS Web Portal </Typography>
            <Typography component="p"> Reset Password </Typography>
          </div>
          <>
            {passwordResetSucess && !invalidResetPasswordToken &&
              <Typography variant="body1" style={{ color: "green", margin: "1em auto", fontSize: "1em", textAlign: "center" }}>
                Password Updated Successfully! {' '}
                <Link
                  underline="hover"
                  href="/signin"
                  aria-label="signin"
                >
                  Click here to sign in
                </Link>.
              </Typography>
            }
            {invalidResetPasswordToken &&
              <Typography variant="body1" style={{ color: "green", margin: "1em auto", fontSize: "1em", textAlign: "center" }}>
                Invalid password reset URL or URL is expired !!!
              </Typography>
            }
            { !invalidResetPasswordToken &&
              !passwordResetSucess &&
              recoveryToken &&
              recoveryToken !== '' &&
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, form, submitting, values }) => (
                  <form onSubmit={handleSubmit} data-testid="reset-password-form">
                    <Field name="newPassword" validate={ComposeValidators(Required, Password)}>
                      {({ input, meta }) => (
                        <FormControl sx={{ marginBottom: "10px", width: "100%" }} variant="outlined" >
                          <InputLabel htmlFor="new-password">New Password</InputLabel>
                          <OutlinedInput
                            id="new-password"
                            type={showNewPassword ? "text" : "password"}
                            {...input}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="new password"
                                  onClick={handleClickShowNewPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showNewPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="New Password"
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                        </FormControl>
                      )}
                    </Field>
                    <Field name="confirmPassword" validate={ComposeValidators(Required, ConfirmPassword(values.newPassword))}>
                      {({ input, meta }) => (
                        <FormControl sx={{ marginBottom: "10px", width: "100%" }} variant="outlined" >
                          <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                          <OutlinedInput
                            id="confirm-password"
                            type={showConfirmPassword ? "text" : "password"}
                            {...input}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="confirm password"
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Confirm Password"
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                        </FormControl>
                      )}
                    </Field>
                    <Button type="submit" disabled={submitting} size="large" variant="contained" sx={{ width: "100%", marginBottom: "10px" }} aria-label="request password" >Reset Password</Button>
                  </form>
                )}
              />
            }

          </>
        </Card>
      </div>
    </>
  );
};
