import { createSlice } from '@reduxjs/toolkit';
import { IAssignedLicense } from '../../shared/interfaces/assigned-license.interface';
import { IPurchaseOrder } from '../../shared/interfaces/purchase-order.interface';
import { IUserLicense } from "../../shared/interfaces/user-license.interface";
import { generateCustomerLicense, generateUserLicense, getAssignedLicenses, getUserLicenses, pushLicenses, updateAssignedLicenses } from '../thunks/license-users/license-users-thunks';

interface UserLicenseState {
    userLicense: any,
    openSendEmailDialog: boolean,
    userLicenses: IUserLicense[],
    assignedLicenses: IAssignedLicense[],
    selectedOrder: IPurchaseOrder | null,
    selectedUserLicense: any,
    openViewUserLicenseDialog: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: UserLicenseState = {
    userLicense: null,
    assignedLicenses: [],
    selectedOrder: null,
    openSendEmailDialog: false,
    userLicenses: [],
    selectedUserLicense: null,
    openViewUserLicenseDialog: false,
    status: 'idle',
    error: undefined,
}

export const userLicensesSlice = createSlice({    
    name: "userLicense",
    initialState,
    reducers: {
        setAssignedLicenses: (state, action) => {
            state.assignedLicenses = action.payload
        },
        setUserLicenses: (state, action) => {
            state.userLicenses = action.payload
        },
        setSelectedUserLicense: (state, action) => {
            state.selectedUserLicense = action.payload
        },
        setOpenViewUserLicenseDialog: (state, action) => {
            state.openViewUserLicenseDialog = action.payload
        },
        setOpenSendEmailDialog: (state, action) => {
            state.openSendEmailDialog = action.payload
        },
        setSelectedOrder: (state, action) => {
            state.selectedOrder = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAssignedLicenses.fulfilled, (state, action) => {            
            state.assignedLicenses = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getAssignedLicenses.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getAssignedLicenses.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getUserLicenses.fulfilled, (state, action) => {            
            state.userLicenses = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getUserLicenses.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getUserLicenses.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(pushLicenses.fulfilled, (state, action) => { 
            state.status = 'succeeded'
        })
        .addCase(pushLicenses.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(pushLicenses.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(generateCustomerLicense.fulfilled, (state, action) => {            
            state.status = 'succeeded'
        })
        .addCase(generateCustomerLicense.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(generateCustomerLicense.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(generateUserLicense.fulfilled, (state, action) => {            
            state.status = 'succeeded'
        })
        .addCase(generateUserLicense.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(generateUserLicense.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updateAssignedLicenses.fulfilled, (state, action) => {            
            state.status = 'succeeded'
        })
        .addCase(updateAssignedLicenses.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateAssignedLicenses.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setAssignedLicenses,
    setUserLicenses,
    setSelectedUserLicense,
    setOpenSendEmailDialog,
    setOpenViewUserLicenseDialog,
    setSelectedOrder
} = userLicensesSlice.actions;
export default userLicensesSlice.reducer;
