import { ReactElement, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-final-form";
import { Button, TextField, Box, Grid, Select, MenuItem, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getHardwareConfigurationForEdgeDevice, stageEdgeDeviceHardwareConfiguration } from "../../../store/thunks/provision-edge-devices/provision-edge-devices-thunks";
import { setHardwareCongifurationSettings, setOpenHardwareConfigSettingsDialog, setSelectedHardwareForEditConfig } from "../../../store/slices/provision-edge-devices";

export const HardwareConfigSettingsForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const selectedEdgeDevice = useAppSelector(state => state.edgeDevice.selectedEdgeDevice);
  const edgeDeviceConfigChangeDetail = useAppSelector(state => state.provisionEdgeDevice.edgeDeviceConfigChangeDetail);
  const hardwareCongifurationSettings = useAppSelector(state => state.provisionEdgeDevice.hardwareCongifurationSettings);
  const selectedHardwareForEditConfig = useAppSelector(state => state.provisionEdgeDevice.selectedHardwareForEditConfig);

  useEffect(() => {
    if (edgeDeviceConfigChangeDetail) {
      const requestData = {
        oemPartNumber: selectedHardwareForEditConfig.oemPartNumber,
        edgeDeviceId: selectedEdgeDevice.edgeDeviceId,
        changeDetailId: edgeDeviceConfigChangeDetail.edgeDeviceConfigChangeDetailsId,
      }
      dispatch(getHardwareConfigurationForEdgeDevice(requestData));
    }
  }, []);

  const handleCloseHardwareConfigDialog = () => {
    dispatch(setOpenHardwareConfigSettingsDialog(false));
    dispatch(setSelectedHardwareForEditConfig({}));
  }

  const validateBeforSubmit = () => {
    let flag: boolean = false;
    const hardwareCS = hardwareCongifurationSettings?.configuration.map((item: any) =>
      item.required && (item.value === '' || !item.value) ? { ...item, validated: true } : item
    );
    const finalData = {
      configuration: hardwareCS,
      stagingId: hardwareCongifurationSettings.stagingId
    };
    dispatch(setHardwareCongifurationSettings(finalData));

    hardwareCS.forEach((item: any) => {
      if (item.validated) {
        flag = true;
      }
    });
    return flag;
  }

  const onSubmit = () => {
    if (validateBeforSubmit()) {
      return;
    }

    const finalData = {
      edgeDeviceHardwareConfigStagingId: hardwareCongifurationSettings.stagingId,
      hardwareConfigurationSettings: hardwareCongifurationSettings.configuration
    }
    dispatch(stageEdgeDeviceHardwareConfiguration(finalData));
  };

  const inputValidate = (data: any, val: any) => {
    const hardwareCS = hardwareCongifurationSettings?.configuration.map((item: any) =>
      data.key === item.key && data.required ?
        val === '' ? { ...item, validated: true } : { ...item, validated: false }
        : item
    );

    const finalData = {
      configuration: hardwareCS,
      stagingId: hardwareCongifurationSettings.stagingId
    };
    dispatch(setHardwareCongifurationSettings(finalData));
  };

  const onChangeInput = (data: any, newValue: any) => {
    console.log(newValue);
    const hardwareCS = hardwareCongifurationSettings?.configuration.map((item: any) =>
      data.key === item.key ?
        { ...item, value: newValue } : item
    );
    const finalData = {
      configuration: hardwareCS,
      stagingId: hardwareCongifurationSettings.stagingId
    };
    dispatch(setHardwareCongifurationSettings(finalData));
  };

  const renderInputComponent = (rowData: any) => {
    let data = { ...rowData, configFileChanges: null };

    switch (data.dataType) {
      case 'String': return (
        <div>
          <TextField size="small" fullWidth value={data.value} type="text"
            onChange={(event) => { onChangeInput(data, event.currentTarget.value); }}
            onBlur={(event) => { inputValidate(data, event.currentTarget.value); }}
            error={data.validated}
          />
          {data.validated &&
            <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
          }
        </div>
      );

      case 'Double': return (
        <div>
          <TextField size="small" fullWidth value={data.value} type="number"
            onChange={(event) => { onChangeInput(data, event.currentTarget.value); }}
            onBlur={(event) => { inputValidate(data, event.currentTarget.value); }}
            error={data.validated}
          />
          {data.validated &&
            <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
          }
        </div>
      );

      case 'Integer': return (
        <div>
          <TextField size="small" fullWidth value={data.value} type="number" //min="1" //onInput={event => event.currentTarget.value.replace(/\D/, '')}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(event) => { onChangeInput(data, event.currentTarget.value); }}
            onBlur={(event) => { inputValidate(data, event.currentTarget.value); }}
            error={data.validated}
          />
          {data.validated &&
            <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
          }
        </div>
      );

      case 'Boolean': return (
        <div>
          <RadioGroup row
            name={data.key}
            value={data.value}
            onBlur={(event) => { inputValidate(data, (event.target as HTMLInputElement).value) }}
            onChange={(event) => { onChangeInput(data, (event.target as HTMLInputElement).value) }}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
          {data.validated &&
            <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
          }
        </div>
      );

      case 'SingleChoice': return (
        <div>
          <Select size="small" fullWidth
            value={data.value}
            multiple={false}
            onChange={(event) => { onChangeInput(data, event.target.value) }}
            onBlur={(event) => { inputValidate(data, event.target.value); }}
            error={data.validated}
          >
            <MenuItem value="">{intl.formatMessage({ id: "components.select" })}</MenuItem>
            {data.choices.map((item: any) => (<MenuItem key={item} value={item}>{item}</MenuItem>))}
          </Select>
          {data.validated &&
            <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
          }
        </div>
      );

      case 'MultiChoice': return (
        <div>
          <Select size="small" fullWidth
            value={data.value}
            multiple={true}
            onChange={(event) => { onChangeInput(data, event.target.value) }}
            onBlur={(event) => { inputValidate(data, event.target.value) }}
            error={data.validated}
          >
            <MenuItem value="">{intl.formatMessage({ id: "components.select" })}</MenuItem>
            {data.choices.map((item: any) => (<MenuItem key={item} value={item}>{item}</MenuItem>))}
          </Select>
          {data.validated &&
            <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
          }
        </div>
      );

      default: return (
        <div>
          <TextField size="small" fullWidth value={data.value} type="text"
            onChange={(event) => { onChangeInput(data, event.currentTarget.value); }}
            onBlur={(event) => { inputValidate(data, event.currentTarget.value); }}
            error={data.validated}
          />
          {data.validated &&
            <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
          }
        </div>
      );
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={selectedHardwareForEditConfig}
      render={({ handleSubmit, form, submitting }) => (
        <form onSubmit={handleSubmit} data-testid="hardware-config-settings-form">
          <Grid container rowSpacing={2} sx={{ mb: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}><FormattedMessage id="components.oemPartNumber" />: <span style={{ fontWeight: 'bold' }}>{selectedHardwareForEditConfig.oemPartNumber}</span></Grid>
          </Grid>
          <table className="table borderless" style={{ width: '100%' }}>
            <tbody>
              {hardwareCongifurationSettings?.configuration?.map((row: any, index: number) => {
                return (<tr key={index} style={{ marginBottom: '10px' }}>
                  <td style={{ padding: '0px', paddingBottom: '10px' }}>{row.key} :</td>
                  <td style={{ padding: '0px', paddingBottom: '10px' }}>
                    {renderInputComponent(row)}
                  </td>
                </tr>
                )
              })
              }
            </tbody>
          </table>
          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button autoFocus onClick={handleCloseHardwareConfigDialog} variant="contained" color="error" sx={{ mr: 2 }}>
              <FormattedMessage id="components.cancel" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
              <FormattedMessage id="components.save" />
            </Button>
          </Box>
        </form>
      )}
    />
  );
};
