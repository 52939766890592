import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSnackbarAlert } from "../../store/app-slice";
import Slide, { SlideProps } from '@mui/material/Slide';
import { FormattedMessage } from "react-intl";
import { forwardRef, SyntheticEvent } from 'react';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type TransitionProps = Omit<SlideProps, 'direction'>;

const transition = (props: TransitionProps) => {
    return <Slide {...props} direction="left" />;
}

export const SnackbarAlert = () => {
    const dispatch = useAppDispatch();
    const snackbarAlert = useAppSelector(state => state.app.snackbarAlert);

    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        dispatch(setSnackbarAlert(null));
    };

    return (
        <>
            <Snackbar
                open={snackbarAlert ? true : false}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                TransitionComponent={transition}
            >
                <Alert onClose={handleClose} severity={snackbarAlert?.messageType} sx={{ width: '100%' }}>
                    {snackbarAlert?.messageType === 'success' && snackbarAlert?.message ?
                        <FormattedMessage id={snackbarAlert?.message} />
                    :
                        <>{snackbarAlert?.message}</>
                    }
                </Alert>
            </Snackbar>
        </>
    );
}
