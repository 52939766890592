import { createSlice } from '@reduxjs/toolkit';
import { IPackage } from "../../shared/interfaces/package.interface";
import { addPackage, deletePackage, getPackages, getPackageWithModules, updatePackage } from '../thunks/packages/packages-thunks';

interface PackageState {
    packages: IPackage[],
    selectedPackage: any,
    openViewPackageDialog: boolean,
    openAddUpdatePackageDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    editPackage: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined,
    selectedPackageModules: string[],
    initialPackageModules: string[],
    packageModulesStatus: 'idle' | 'loading' | 'succeeded' | 'failed',
}

const initialState: PackageState = {
    packages: [],
    selectedPackage: null,
    openViewPackageDialog: false,
    openAddUpdatePackageDialog: false,
    openDeleteConfirmationDialog: false,
    editPackage: false,
    status: 'idle',
    error: undefined,
    selectedPackageModules: [],
    initialPackageModules:[],
    packageModulesStatus: 'idle'
}

export const packagesSlice = createSlice({    
    name: "package",
    initialState,
    reducers: {
        setPackages: (state, action) => {
            state.packages = action.payload
        },
        setSelectedPackage: (state, action) => {
            state.selectedPackage = action.payload
        },
        setOpenViewPackageDialog: (state, action) => {
            state.openViewPackageDialog = action.payload
        },
        setOpenAddUpdatePackageDialog: (state, action) => {
            state.openAddUpdatePackageDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditPackage: (state, action) => {
            state.editPackage = action.payload
        },
        setSelectedPackageModules: (state, action) => {
            state.selectedPackageModules = action.payload
        },
        setInitialPackageModules: (state, action) => {
            state.selectedPackageModules = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getPackages.fulfilled, (state, action) => {            
            state.packages = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getPackages.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getPackages.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getPackageWithModules.fulfilled, (state, action) => {  
            state.initialPackageModules = action.payload;          
            state.selectedPackageModules = action.payload;
            state.packageModulesStatus = 'succeeded'
        })
        .addCase(getPackageWithModules.pending, (state, action) => {
            state.packageModulesStatus = 'loading'
        })
        .addCase(getPackageWithModules.rejected, (state, action) => {
            state.packageModulesStatus = 'failed'
            state.error = action.error.message
        })
        .addCase(addPackage.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addPackage.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addPackage.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updatePackage.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(updatePackage.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updatePackage.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deletePackage.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deletePackage.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deletePackage.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setPackages,
    setSelectedPackage,
    setOpenViewPackageDialog,
    setOpenAddUpdatePackageDialog,
    setOpenDeleteConfirmationDialog,
    setEditPackage,
    setSelectedPackageModules,
    setInitialPackageModules
} = packagesSlice.actions;
export default packagesSlice.reducer;
