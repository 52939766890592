/**
 * Footer
 */
import { Box, Link, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Role } from '../../shared/enums/role.enum';
import { useAppSelector } from "../../store/hooks";

export const Footer = (): ReactElement => {
    const theme = useTheme();
    const user = JSON.parse(String(sessionStorage.getItem("user")));
    const loggedInUser = useAppSelector((state) => state.profile.loggedInUser);

    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: theme.palette.secondary.main,
            boxShadow: 2,
            p:3, 
            mt: 3
        }}>
            <Box sx={{ justifyContent: "flex-start" }}>
                {!user?.roles.includes(Role.PASSWORD_CHANGE_ONLY) &&
                    <>
                        { loggedInUser?.termsAccepted &&
                        loggedInUser?.customerId &&
                        !(loggedInUser?.trial && moment(loggedInUser?.trialExpiration).isBefore(moment())) &&
                            <Link href="/applications" underline="hover" sx={{mr: 2}}>
                                <FormattedMessage id="components.applications" />
                            </Link>
                        }
                        <Link href="/terms-conditions" underline="hover">
                            <FormattedMessage id="components.termsConditions" />
                        </Link>
                    </>
                }
            </Box>
            <Typography variant="body1"  sx={{ justifyContent: "flex-end" }}>© 2023 Gleason Corporation</Typography>
        </Box>
    );
};
