import { ReactElement } from "react";
import { LayoutComponent } from "../../layout/layout.component";
import { Outlet } from "react-router-dom";

export const RootPage = (): ReactElement => {
  return (
    <LayoutComponent>      
      <Outlet />
    </LayoutComponent>
  );
};
