export const de_DE =  {
  "components.applications": "Anwendungen",
  "components.application": "Anwendung",
  "components.dashboard": "Armaturenbrett",
  "components.users": "Benutzer",
  "components.user": "Benutzer",
  "components.modules": "Module",
  "components.module": "Modul",
  "components.packages": "Paket",
  "components.package": "Pakete",
  "components.dataCenters": "Daten Center",
  "components.dataCenter": "Daten Center",
  "components.app": "App",
  "components.region": "Region",
  "components.fleetName": "Fleet Name",
  "components.stackName": "Stack Name",
  "components.ec2IP": "Ec2 IP",
  "components.actions": "Aktionen",
  "components.addNew": "Neue hinzuf\u00FCgen",
  "components.update": "Aktualisieren",
  "components.delete": "L\u00F6schen",
  "components.edit": "Bearbeiten",
  "components.save": "Speichern",
  "components.submit": "Einreichen",
  "components.noRecordsFound": "Keine Aufzeichnungen gefunden",
  "components.wantToDelete": "M\u00F6chten Sie wirklich l\u00F6schen?",
  "components.thisWillDelete": "Dies wird gel\u00F6scht",
  "components.permanently": "permanent.",
  "components.vmAssociated": "Azure VM '",
  "components.vmAssociatedContd": " ist dem Kunden zugeordnet.",
  "components.vmDeleteWarning":
    "Der Benutzer muss die Azure VM manuell l\u00F6schen.",
  "components.searchHere": "Suche hier...",
  "components.reset": "Zur\u00FCcksetzen",
  "components.add": "Hinzuf\u00FCgen",
  "components.cancel": "Absagen",
  "components.ok": "OK",
  "components.close": "Nah dran",
  "components.yes": "Ja",
  "components.customer": "Kunde",
  "components.customers": "Kunden",
  "components.companyCode": "Firmencode",
  "components.isolatedEc2IP": "Isolated Ec2 IP",
  "components.allowedEmailDomains": "Zul\u00E4ssige E-Mail-Dom\u00E4nen",
  "components.tenantType": "Mietertyp",
  "components.userManagement": "Benutzerverwaltung",
  "components.viewUsers": "Benutzer anzeigen",
  "components.grantPermissions": "Berechtigungen erteilen",
  "components.trialUser": "Testbenutzer",
  "components.select": "Ausw\u00E4hlen",
  "components.roles": "Roles",
  "components.emailAddress": "E-Mail-Addresse",
  "components.email": "Email",
  "components.username": "Nutzername",
  "components.trialExpiration": "Testablauf",
  "components.name": "Name",
  "components.firstName": "Vorname",
  "components.lastName": "Nachname",
  "components.grantedPermissions": "Erteilte Berechtigungen",
  "components.availablePermissions": "Verf\u00FCgbare Berechtigungen",
  "components.code": "Code",
  "components.platform": "Plattform",
  "components.edgeModule": "Edge-Modul",
  "components.selectCPM":
    "Bitte w\u00E4hlen Sie Benutzer und Plattform aus, um Module anzuzeigen",
  "components.updatePermissions": "Berechtigungen aktualisieren",
  "components.permissions": "Berechtigungen",
  "components.license": "Lizenz",
  "components.description": "Beschreibung",
  "components.vendors": "Anbieter",
  "components.vendor": "Verk\u00E4ufer",
  "components.customLicensing": "Benutzerdefinierte Lizenzierung",
  "components.vendorCode": "Herstellerk\u00FCrzel",
  "components.applicationCatalog": "Anwendungskatalog",
  "components.all": "Alle",
  "components.filterByApplication": "Nach Anwendung filtern",
  "components.materialNumber": "Artikelnummer",
  "components.standard": "Standard",
  "components.custom": "Brauch",
  "components.filterModulesBasedOnVendor":
    "Filtern Sie Module basierend auf dem Anbieter",
  "components.customApplication": "Benutzerdefinierte Anwendung",
  "components.purchases": "Eink\u00E4ufe",
  "components.purchase": "Kaufen",
  "components.orders": "Auftr\u00E4ge",
  "components.order": "Befehl",
  "components.order#": "Befehl#",
  "components.customerPO": "Kunde PO",
  "components.orderPackage": "Paket bestellen",
  "components.pleaseSelectCO":
    "Bitte w\u00E4hlen Sie die Bestellung aus, um die Pakete anzuzeigen.",
  "components.pleaseSelectCustomer":
    "Bitte w\u00E4hlen Sie Kunde, um Bestellungen anzuzeigen",
  "components.userLimit": "Grenze",
  "components.licenseType": "Lizenz-Typ",
  "components.startDate": "Anfangsdatum",
  "components.endDate": "Endtermin",
  "components.licensedUsers": "Lizenzierte Benutzer",
  "components.assignLicense": "Lizenz zuweisen",
  "components.viewAssignedLicense": "Zugewiesene Lizenz anzeigen",
  "components.reloadLicenses": "Lizenz neu laden",
  "components.generateLicense": "Lizenz generieren",
  "components.unsavedInformation":
    "Sie haben ungespeicherte Informationen auf der Registerkarte �Lizenz zuweisen�.",
  "components.assignment": "Abtretung",
  "components.customLicense": "Benutzerdefinierte Lizenz",
  "components.selectCUtoviewLA":
    "W\u00E4hlen Sie Kunde und Benutzer aus, um den Lizenzantrag anzuzeigen.",
  "components.noLicensedApplications":
    "Keine lizenzierten Anwendungen f\u00FCr den ausgew\u00E4hlten Benutzer.",
  "components.pushLicense": "Push-Lizenz",
  "components.selectCustomerUserForJSONfile":
    "Bitte w\u00E4hlen Sie Kunde und Benutzer aus, um die jeweilige .json-Lizenzdatei zu generieren und herunterzuladen",
  "components.enterEmailtoSendLicense":
    "Geben Sie die E-Mail-Adresse ein, um die Lizenzdatei zu senden",
  "components.generateUserLicense": "Benutzerlizenz generieren",
  "components.downloadUserLicense":
    "Laden Sie die Benutzerlizenzdatei herunter",
  "components.generateCustomerLicense": "Kundenlizenz generieren",
  "components.downloadCustomerLicense":
    "Laden Sie die Kundenlizenzdatei herunter",
  "components.emailLicense": "E-Mail-Lizenz",
  "components.edgeDeviceManagement": "Verwaltung von Edge-Ger\u00E4ten",
  "components.edgeDevice": "Edge-Ger\u00E4t",
  "components.edgeDevices": "Edge-Ger\u00E4te",
  "components.viewEdgeDevices": "Edge-Ger\u00E4te anzeigen",
  "components.assignModules": "Module zuweisen",
  "components.filterByCustomer": "Nach Kunde filtern",
  "components.iotDeviceName": "IOT-Ger\u00E4tename",
  "components.oemPartNumber": "OEM-Teilenummer",
  "components.oemSerialNumber": "OEM-Seriennummer",
  "components.iotHubName": "IOT-Hub-Name",
  "components.teamViewerID": "TeamViewer-ID",
  "components.teamViewerUserName": "TeamViewer-Benutzername",
  "components.teamViewerPassword": "TeamViewer-Passwort",
  "components.blocklistEmailDomains": "Sperrlisten-E-Mail-Domain",
  "components.blocklistEmailDomain": "Sperrlisten-E-Mail-Domain",
  "components.emailDomain": "E-Mail-Dom\u00E4ne",
  "components.userProfile": "Benutzerprofil",
  "components.personalDetails": "Pers\u00F6nliche Daten",
  "components.emailId": "E-Mail-ID",
  "components.profile": "Profil",
  "components.changePassword": "Passwort \u00E4ndern",
  "components.logOut": "Ausloggen",
  "components.currentPassword": "Jetziges Passwort",
  "components.newPassword": "Neues Passwort",
  "components.confirmPassword": "Passwort best\u00E4tigen",
  "components.updatePassword": "Kennwort aktualisieren",
  "components.miniSidebar": "Mini-Seitenleiste",
  "components.boxLayout": "Box-Layout",
  "components.rtlLayout": "RTL-Layout",
  "components.darkMode": "Dunkler Modus",
  "components.fullScreen": "Ganzer Bildschirm",
  "components.sidebarToggle": "Seitenleiste umschalten",
  "components.userTrialExpiresOn": "Benutzer-Testversion l\u00E4uft ab am",
  "components.language": "Sprache",
  "components.languages": "Sprachen",
  "components.appSetting": "App-Einstellung",
  "components.theme": "Thema",
  "components.termsConditions": "Terms & Bedingungen",
  "components.userTrialExpired": "Benutzertest abgelaufen",
  "components.trialExpiredMsg":
    "Ihre Testversion ist abgelaufen. Wenden Sie sich daher bitte an Gleason, um die Testversion zu verl\u00E4ngern oder eine Lizenz zu erwerben.",
  "components.acceptAndProceed": "Akzeptieren und fortfahren",
  "components.termsConditionsAcceptanceMsg":
    "Klicken Sie hier, um anzugeben, dass Sie die Bedingungen der Allgemeinen Gesch\u00E4ftsbedingungen gelesen haben und ihnen zustimmen",
  "components.sorryPageNotFound": "Entschuldigung, Seite nicht gefunden",
  "components.goToHomePage": "Gehen Sie zur Startseite",
  "components.enter": "Eintreten",
  "components.startDateValidation":
    "Das Startdatum sollte heute oder in der Zukunft liegen",
    "components.endDateValidation":
    "Das Enddatum sollte in der Zukunft liegen und größer als das Startdatum sein",
  "components.endDateValidationDevice":
    "Das Enddatum sollte heute oder in der Zukunft liegen",
  "components.required": "Erforderlich",
  "components.requiredAtleastOne": "Mindestens eine Rolle erforderlich",
  "components.confirmPasswordValidation":
    "Neues Passwort und Passwort best\u00E4tigen m\u00FCssen identisch sein",
  "components.passwordValidation":
    "Das Passwort muss mindestens 6 Zeichen enthalten, darunter mindestens ein Kleinbuchstabe, ein Gro\u00DFbuchstabe, eine Ziffer und ein Sonderzeichen",
  "components.vmPasswordValidation":
    "Das Passwort muss 12 bis 72 Zeichen lang sein, die mindestens einen Kleinbuchstaben, einen Gro\u00DFbuchstaben, eine Ziffer und ein Sonderzeichen enthalten",
  "components.vmUserName":
    "Der Benutzername muss 1 bis 64 Zeichen lang sein, darf nur Buchstaben, Ziffern, Bindestriche und Unterstriche enthalten und darf nicht mit einem Bindestrich oder einer Ziffer beginnen.",
  "components.ipAddressValidation": "ist keine g\u00FCltige IP-Adresse.",
  "components.subnetIpAddressValidation":
    "ist keine g\u00FCltige Subnetz-IP-Adresse",
  "components.allowedEmailDomainsValidation":
    "Domain ist f\u00FCr ausgew\u00E4hlten Kunden nicht erlaubt",
  "components.domainsCustomerValidation":
    "Domain ist f\u00FCr ausgew\u00E4hlten Kunden nicht erlaubt",
  "components.selectCustomerFirst": "W\u00E4hlen Sie zuerst den Kunden aus",
  "components.userCountValidation":
    "Der Wert sollte nicht kleiner sein als die Anzahl der zugewiesenen Benutzer f\u00FCr das Paket",
  "components.deviceCountValidation":
    "Der Wert sollte nicht kleiner sein als die Anzahl der aus dem Paket bereitgestellten Module",
  "components.onlyNumbersAllowed": "Nur Zahlen erlaubt",
  "components.onlyAlphanumericAllowed": "Nur alphanumerische Zeichen erlaubt",
  "components.commaSeperatedPermissions":
    "Geben Sie g\u00FCltige Berechtigungen mit Komma (,) als Trennzeichen ein",
  "components.emailDomainValidation":
    "Geben Sie eine g\u00FCltige E-Mail-Dom\u00E4ne ein",
  "components.domainBlocked": "Dom\u00E4ne ist gesperrt",
  "components.commaSeperatedEmailDomains":
    "Geben Sie g&#252;ltige E-Mail-Dom\u00E4ne(n) mit Komma (,) als Trennzeichen ein",
  "components.isNotValidEmail": "ist keine g\u00FCltige E-Mail.",
  "components.appstreamTemporarilyUnavailable":
    "Appstream vor\u00FCbergehend nicht verf\u00FCgbar",
  "components.somethingWentWrong":
    "Etwas ist schief gelaufen! Versuchen Sie es nochmal.",
  "components.referenceOccuredDB":
    "Verweis in anderen Tabellen aufgetreten, daher nicht l\u00F6schbar",
  "components.created": "Erstellt!",
  "components.updated": "Aktualisiert!",
  "components.deleted": "Gel\u00F6scht!",
  "components.passwordUpdatedSuccessfullyReSign":
    "Passwort erfolgreich aktualisiert! Bitte melden Sie sich erneut mit neuen Anmeldeinformationen an",
  "components.cannotUseCurrentPassword":
    "Das aktuelle Passwort kann nicht f\u00FCr das neue Passwort verwendet werden",
  "components.invalidCurrentPassword": "Ung\u00FCltiges aktuelles Passwort",
  "components.licensefileSentToEmail": "Lizenzdatei an E-Mail gesendet",
  "components.licenseUpdatedSuccessfully": "Lizenz erfolgreich aktualisiert",
  "components.selectOrderAndRespectiveUsers":
    "Auftrag und jeweilige Benutzer ausw\u00E4hlen",
  "components.deleteLicenseFile": "Lizenzdatei l\u00F6schen",
  "components.downloadLicenseFile": "Lizenzdatei herunterladen",
  "components.noFileChoosen": "Keine Datei ausgew\u00E4hlt",
  "components.selectTCcheckbox":
    "Aktivieren Sie das Kontrollk\u00E4stchen Allgemeine Gesch\u00E4ftsbedingungen, um fortzufahren",
  "components.modulePermissionsUpdated":
    "Modulberechtigungen f\u00FCr Benutzer erfolgreich aktualisiert!",
  "components.profileUpdatedSucessfully": "Profil erfolgreich aktualisiert!",
  "components.languageSavedSucessfully": "Sprache erfolgreich gespeichert!",
  "components.wentWrongWhenLanguageSaved":
    "Beim Speichern der Sprache ist etwas schief gelaufen",
  "components.themeSavedSucessfully": "Design erfolgreich gespeichert!",
  "components.wentWrongWhenThemeSaved":
    "Beim Speichern der Designeinstellungen ist etwas schief gelaufen",
  "components.containerRegistryPath": "Containerregistrierungspfad",
  "components.requestedForProvision": "Zur Bereitstellung angefordert",
  "components.requestedForProvisionWithWarning":
    "Zur Bereitstellung angefordert. \u00DCberpr\u00FCfen Sie die Benachrichtigung auf Warnung.",
  "components.sapMasterNumber": "SAP-Stammnummer",
  "components.provisionDevices": "Ger\u00E4te bereitstellen",
  "components.provisionDevice": "Ger\u00E4t bereitstellen",
  "components.provisionHistory": "Bereitstellungshistorie",
  "components.viewManifest": "Manifest anzeigen",
  "components.provision": "Bestimmung",
  "components.dateAndTime": "Datum und Uhrzeit",
  "components.templates": "Vorlagen",
  "components.template": "Schablone",
  "components.isDocker": "Ist Docker",
  "components.dockerImage": "Docker-Image",
  "components.standardFile": "Standarddatei",
  "components.fileType": "Dateityp",
  "components.storagePath": "Speicherpfad",
  "components.iotPlatforms": "IoT-Plattformen",
  "components.iotPlatform": "IoT-Plattform",
  "components.filePath": "Dateipfad",
  "components.version": "Version",
  "components.versionValidation": "ist keine g\u00FCltige Version",
  "components.assignModulesToDevice": "Module dem Ger\u00E4t zuweisen",
  "components.selectCustomerToDisplayTemplates":
    "W\u00E4hlen Sie Kunde aus, um Vorlagen anzuzeigen",
  "components.selectCustomerToDisplayPovisionDevices":
    "W\u00E4hlen Sie Kunde aus, um Povision-Ger\u00E4te anzuzeigen",
  "components.defaultSelected": "Standard ausgew\u00E4hlt",
  "components.availableTemplates": "Verf\u00FCgbare Vorlagen",
  "components.deploymentType": "Bereitstellungstyp",
  "components.deploy": "Einsetzen",
  "components.provisionDeviceDetails": "Ger\u00E4tedetails bereitstellen",
  "components.provisionDetailsSaved": "Bereitstellungsdetails gespeichert",
  "components.updateModuleConfigSettings":
    "Modulkonfigurationseinstellungen aktualisieren",
  "components.moduleConfigurationSettings": "Modulkonfigurationseinstellungen",
  "components.moduleConfigSettingsPopupHead":
    "Sind Sie sicher, dass Sie die Konfigurationseinstellungen ersetzen m\u00F6chten?",
  "components.moduleConfigSettingsPopupMessage":
    "Diese Modulkonfigurationseinstellungen werden verwendet, um das Edge-Ger\u00E4temodul und das Vorlagenmodul bereitzustellen. Wenn Sie mit der Aktualisierung fortfahren, werden die vorherigen Konfigurationseinstellungen des Moduls gel\u00F6scht, und der Benutzer muss die Modulkonfiguration der Module erneut aktualisieren. Sind Sie sicher, dass Sie fortfahren m\u00F6chten.",
  "components.configSettings": "Konfigurationseinstellungen",
  "components.newConfigSettings": "Neue Konfigurationseinstellungen",
  "components.uploadConfigSettings":
    "Laden Sie die JSON-Datei mit den Modulkonfigurationseinstellungen hoch",
  "components.uploadedConfigSettings":
    "Hochgeladene Konfigurationseinstellungen",
  "components.uploadNewConfigSettings":
    "Neue Konfigurationseinstellungen hochladen",
  "components.jsonSchema": "Json-Schema",
  "components.manifestJSON": "Manifestieren Sie JSON",
  "components.vendorType": "Anbietertyp",
  "components.software": "Software",
  "components.hardware": "Hardware",
  "components.hardwares": "Hardware",
  "components.hardwareConfiguration": "Hardwarekonfiguration",
  "components.classification": "Einstufung",
  "components.createdTime": "Geschaffene Zeit",
  "components.active": "Aktiv",
  "components.selectVendorToDisplayHardwares":
    "W\u00E4hlen Sie Anbieter aus, um Hardware anzuzeigen",
  "components.uploadHardwareImage": "Hardware-Image hochladen",
  "components.hardwareImage": "Hardware-Image",
  "components.hardwareConfigSettings": "Hardware-Konfigurationseinstellungen",
  "components.hardwareConfigSettingsPopupHead":
    "Sind Sie sicher, die Hardwarekonfigurationseinstellungen zu ersetzen?",
  "components.hardwareConfigSettingsPopupMessage":
    "Diese Hardwarekonfigurationseinstellungen werden vom Edge-Ger\u00E4t verwendet",
  "components.hardwareVendor": "Hardware-Anbieter",
  "components.updateHardwareConfigurations":
    "Hardware Konfigurationen aktualisieren",
  "components.hardwareConfigurationSettings":
    "Hardware Konfigurationseinstellungen",
  "components.settings": "Einstellungen",
  "components.configurations": "Konfigurationen",
  "components.maxLengthValidation63": "L\u00E4nge darf nicht mehr als 63 sein",
  "components.rfc1123LabelValidation":
    "Geben Sie einen g\u00FCltigen Modulnamen mit alphanumerischen Kleinbuchstaben oder '-' ein und sollte mit einem Buchstaben beginnen",
  "components.recentNotifications": "Letzte Benachrichtigungen",
  "components.viewAll": "Alle ansehen",
  "components.viewNotifications": "Benachrichtigungen anzeigen",
  "components.notifications": "Benachrichtigungen",
  "components.time": "Zeit",
  "components.actionName": "Aktionsname",
  "components.message": "Nachricht",
  "components.assetManagement": "Anlagenmanagement",
  "components.assetObject": "Asset-Objekt",
  "components.assetObjects": "Asset-Objekte",
  "components.selectCustomerToDisplayAssetObjects":
    "W\u00E4hlen Sie Kunde aus, um Asset-Objekte anzuzeigen",
  "components.customerAssetId": "Kunden-Asset-ID",
  "components.assetId": "Asset-ID",
  "components.customerName": "Kundenname",
  "components.type": "Typ",
  "components.subType": "Untertyp",
  "components.locationType": "Standorttyp",
  "components.locationSubType": "Standort-Untertyp",
  "components.locationValue": "Standortwert",
  "components.oem": "OEM",
  "components.productionState": "Produktionszustand",
  "components.url": "URL",
  "components.accessKey": "Zugangsschl\u00FCssel",
  "components.secretKey": "Geheimer Schl\u00FCssel",
  "components.confirmSecretKey": "Best\u00E4tigen Sie den Geheimschl\u00FCssel",
  "components.vmName": "VM-Name",
  "components.tenantUrl": "Mandanten-URL",
  "components.tenantAccessKey": "Mandantenzugriffsschl\u00FCssel",
  "components.tenantSecretKey": "Mieter-Geheimschl\u00FCssel",
  "components.tenantDetails": "Mieterdetails",
  "components.confirmSecretKeyValidation":
    "Best\u00E4tigen Sie, dass der geheime Schl\u00FCssel nicht mit dem geheimen Schl\u00FCssel \u00FCbereinstimmt",
  "components.changeCustomer": "Kunde wechseln",
  "components.location": "Ort",
  "components.subnetIP": "Subnetz-IP mit CIDR-Subnetzmaske",
  "components.privateIP": "Private IP",
  "components.osType": "Betriebssystemtyp",
  "components.vmSize": "VM-Gr\u00F6\u00DFe",
  "components.vmOsDiskSizeinGB":
    "Gr\u00F6\u00DFe des VM-Betriebssystemdatentr\u00E4gers in GiB",
  "components.vmStorageAccountType": "Typ des VM-Speicherkontos",
  "components.vmAdminUsername": "Benutzername des VM-Administrators",
  "components.vmAdminPassword": "VM-Admin-Passwort",
  "components.publicIP": "\u00D6ffentliche IP",
  "components.tenantVmFqdn": "Mandanten-VM-FQDN",
  "components.tenantVmDeploymentDtTm":
    "Datum und Uhrzeit der Bereitstellung der Mandanten-VM",
  "components.cloudServerManagement": "Cloud-Server-Management",
  "components.enterpriseServerManagement": "Enterprise-Server-Management",
  "components.dataTransferPolicies": "Daten\u00FCbertragungsrichtlinien",
  "components.gleason40Subsystem": "Gleason 4.0-Subsystem",
  "components.dataValidation": "Datenvalidierung",
  "components.className": "Klassenname",
  "components.transferSchemes": "\u00DCbertragungsschemata",
  "components.transferScheme": "\u00DCbertragungsschema",
  "components.subSystem": "Subsystem",
  "components.enable": "Erm\u00F6glichen",
  "components.set": "Satz",
  "components.dryRun": "DryRun",
  "components.sweep": "Sweep",
  "components.withDryRun": "Mit Trockenlauf",
  "components.withoutDryRun": "Ohne Trockenlauf",
  "components.upload": "Hochladen",
  "components.id": "ID",
  "components.appMetadatas": "App-Metadaten",
  "components.appMetadata": "App-Metadaten",
  "components.sweepWithDryRunCompleted": "Sweep mit abgeschlossenem DryRun",
  "components.sweepWithoutDryRunCompleted": "Sweep ohne DryRun abgeschlossen",
  "components.vmNameValidation":
    "Der Name der virtuellen Maschine sollte 1 bis 15 Zeichen lang sein und darf keine Sonderzeichen (au\u00DFer _), Leerzeichen oder mit Sonderzeichen beginnen oder enden",
  "components.vmDeployment": "Bereitstellung der Mandanten-VM gestartet.",
  "components.assetObjectFile": "Asset-Objektdatei",
  "components.override": "\u00DCberschreiben",
  "components.resourceKey": "Ressourcenschl\u00FCssel",
  "components.appDataKey": "App-Datenschl\u00FCssel",
  "components.uploadedSuccessfully": "Erfolgreich hochgeladen",
  "components.tenantVMName": "Mandanten-VM-Name",
  "components.assetObjectAttachment": "Asset-Objekt-Anhang",
  "components.assetObjectDeployment":
    "Bereitstellung von Asset Object gestartet",
  "components.remove": "Entfernen",
  "components.download": "Download",
  "components.removed": "Entfernt",
  "components.wantToRemove": "Entfernen m\u00F6chten",
  "components.thisWillRemove": "dies wird entfernt",
  "components.translations": "\u00DCbersetzungen",
  "components.translation": "\u00DCbersetzung",
  "components.clientObjectRegistry": "Clientobjektregistrierung",
  "components.vmLinuxOSDiskSizeMinLimit":
    "Die Gr\u00F6\u00DFe des Betriebssystemdatentr\u00E4gers f\u00FCr die Linux-VM sollte gr\u00F6\u00DFer oder gleich sein ",
  "components.vmWindowsOSDiskSizeMinLimit":
    "Die Gr\u00F6\u00DFe des Betriebssystemdatentr\u00E4gers f\u00FCr die Windows-VM sollte gr\u00F6\u00DFer oder gleich sein ",
  "components.translationType": "\u00DCbersetzungstyp",
  "components.groupID": "Gruppen-ID",
  "components.isCoreService": "Kerndienst",
  "components.microServiceName": "Mikrodienstname",
  "components.dependsOn": "Kommt drauf an",
  "components.microServices": "Mikrodienste",
  "components.coreMicroServices": "Kernmikrodienste",
  "components.nonCoreMicroServices": "Non-Core-Micro-Services",
  "components.confirmNoServicesText":
    "Es werden keine Nicht-Kerndienste f\u00FCr die IoT-Plattform ausgew\u00E4hlt. M\u00F6chten Sie fortfahren?",
  "components.linkToGetMicroServices":
    "Geben Sie den Dateipfad ein und klicken Sie hier, um verf\u00FCgbare Micro Services zu erhalten.",
  "components.machine": "Maschine",
  "components.machines": "Maschinen",
  "components.machineManagement": "Maschinenverwaltung",
  "components.serialNumber": "Seriennummer",
  "components.downloads": "Downloads",
  "components.selectedDownloads": "ausgew\u00E4hlte Downloads",
  "components.system": "System",
  "components.payloadBucketName": "Nutzlast-Bucket-Name",
  "components.targetBucketName": "Ziel-Bucket-Name",
  "components.targetBucket": "Ziel-Bucket",
  "components.tenantDownloads": "Mieter-Downloads",
  "components.tenantDownload": "Mieter-Download",
  "components.successfull": "Erfolgreich",
  "components.expired": "Abgelaufen",
  "components.downloadedTime": "Heruntergeladene Zeit",
  "components.fileName": "Dateiname",
  "components.moduleServiceName": "Dienstname des Moduls",
  "components.downloadHistory": "Geschichte herunterladen",
  "components.downloadAttemtFailed": "Download-Versuch fehlgeschlagen",
  "components.downloadCount": "Graf herunterladen",
  "components.downloadStarted": "Download gestartet",
  "components.selectUsertoviewLicenseDetails":
    "Bitte w\u00E4hlen Sie Benutzer aus, um Lizenzdetails anzuzeigen",
  "components.pleaseSelectOrder": "Bitte w\u00E4hlen Sie Bestellen",
  "components.send": "Senden",
  "components.of": "von",
  "components.rowsPerPage": "Zeilen pro Seite:",
  "components.emailDomainBlocklist": "E-Mail-Domain-Blocklist",
  "components.dataCenterCreated": "Daten Center erstellt!",
  "components.dataCenterUpdated": "Daten Center aktualisiert!",
  "components.dataCenterDeleted": "Daten Center gel00F6scht!",
  "components.customerCreated": "Kunde erstellt!",
  "components.customerUpdated": "Kunde aktualisiert!",
  "components.customerDeleted": "Kunde gel\u00F6scht!",
  "components.emailDomainBlocklistCreated": "E-Mail-Domain-Blocklist erstellt!",
  "components.emailDomainBlocklistDeleted": "E-Mail-Domain-Blocklist gel\u00F6scht!",
  "components.vendorCreated": "Verk\u00E4ufer erstellt!",
  "components.vendorUpdated": "Verk\u00E4ufer aktualisiert!",
  "components.vendorDeleted": "Verk\u00E4ufer gel\u00F6scht!",
  "components.applicationCreated": "Anwendung erstellt!",
  "components.applicationUpdated": "Anwendung aktualisiert!",
  "components.applicationDeleted": "Anwendung gel\u00F6scht!",
  "components.moduleCreated": "Modul erstellt!",
  "components.moduleUpdated": "Modul aktualisiert!",
  "components.moduleDeleted": "Modul gel\u00F6scht!",
  "components.packageCreated": "Pakete erstellt!",
  "components.packageUpdated": "Pakete aktualisiert!",
  "components.packageDeleted": "Pakete gel\u00F6scht!",
  "components.orderCreated":"Befehl erstellt!",
  "components.orderDeleted": "Befehl gel\u00F6scht!",
  "components.open": "Offen",
  "components.lease": "Mieten",
  "components.device": "Ger\u00E4t",
  "components.hardwareCreated": "Hardware erstellt!",
  "components.hardwareUpdated": "Hardware aktualisiert!",
  "components.hardwareDeleted": "Hardware gel\u00F6scht!",
  "components.iotPlatformCreated": "IoT-Plattform erstellt!",
  "components.iotPlatformUpdated": "IoT-Plattform aktualisiert!",
  "components.iotPlatformDeleted": "IoT-Plattform gel\u00F6scht!",
  "components.templateCreated": "Schablone erstellt!",
  "components.templateUpdated": "Schablone aktualisiert!",
  "components.templateDeleted": "Schablone gel\u00F6scht!",
  "components.moduleConfigurationSaved": "Modulkonfiguration gespeichert!",
  "components.hardwareDetails": "Hardware-Details",
  "components.edgeDeviceCreated": "Edge-Ger\u00E4t erstellt!",
  "components.edgeDeviceUpdated": "Edge-Ger\u00E4t aktualisiert!",
  "components.edgeDeviceDeleted": "Edge-Ger\u00E4t gel\u00F6scht!",
  "components.selectEdgeDeviceToDisplayHistory": "w\u00E4hlen Sie Edge-Ger\u00E4t aus, um den Verlauf des Edge-Ger\u00E4ts anzuzeigen",
  "components.hardwareConfigurationSaved": "Hardwarekonfiguration gespeichert",
  "components.tenantDetailsSaved": "Mandanten-VM-Details gespeichert",
  "components.userCreated": "Benutzer erstellt!",
  "components.userUpdated": "Benutzer aktualisiert!",
  "components.userDeleted": "Benutzer gel\u00F6scht!",
  "components.profileUpdated": "Profil aktualisiert!",
  "components.light": "Licht",
  "components.dark": "Dunkel",
  "components.updatedSuccessfully": "Erfolgreich geupdated!",
  "components.customerPartNumber": "Kundenteilenummer",
  "components.orderNumber": "Bestellnummer",
  "components.productionState": "Produktionsstatus",
  "components.trackingNumber": "Auftragsnummer, Frachtnummer, Sendungscode",
  "components.tools": "Werkzeug",
  "components.tool": "Werkzeug",
  "components.toolUpdated": "Werkzeug aktualisiert!",
  "components.customerNumber": "Kundennummer",
  "components.customerDescription": "Kundenbeschreibung",
  "components.comments": "Kommentare",
  "components.passwordUpdatedSuccessfully": "Passwort erfolgreich aktualisiert!",
  "components.clickHere":"klicken Sie hier",
  "components.togotoHomePage":"um zur Startseite zu gelangen",
  "components.vendorUser":"Vendor User",
  "components.selectCustomerToAddTools": "w\u00E4hlen Sie Kunde aus, um das Tool hinzuzuf\u00FCgen",
  "components.toolingObjectCreated": "Werkzeug aktualisiert!!",
  "components.uploadFile": "Datei hochladen",
  "components.catagory": "Kategorie",
  "components.model": "Modell",
  "components.editMultipleRows": "Bearbeiten Sie mehrere Zeilen",
  "components.updateMultipleOrderPackages": "Aktualisieren Sie mehrere Bestellpakete",
  "components.packagesUpdated": "Pakete erfolgreich aktualisiert!",
  "components.copy": "Kopieren",
  "components.copyOrderMessage": "*Alle Pakete mit Details werden aus der ausgewählten Bestellung kopiert oder dupliziert.",
  "components.selectOrderCopy": "Wählen Sie die Bestellnummer zum Kopieren aus",

  "components.dummy": "Dummy",
};
