import { createAsyncThunk } from "@reduxjs/toolkit";
import { dataAPI, dataSessionAPI } from "../../../shared/dataApi";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setEditHardware, setHardwareConfigSettingIsModified, setOpenAddUpdateHardwareDialog, setOpenDeleteConfirmationDialog, setSelectedHardware } from "../../slices/hardwares";
import moment from "moment";

export const getHardwares = createAsyncThunk(
  "hardware/getHardwares",
  async (vendorId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Hardware/GetHardwares?VendorId=` + vendorId);
      dispatch(setLoading(false));
      let finalData = result.data;
      finalData.map((item: any) => {
        item.createDtTm = item.createDtTm ? moment(item.createDtTm).format("MM/DD/YYYY, H:mm:ss") : "";
      });
      return finalData;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addHardware = createAsyncThunk(
  'hardware/addHardware',
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataSessionAPI(user).post(`Hardware/AddHardware`, requestData);
      dispatch(setOpenAddUpdateHardwareDialog(false));
      dispatch(getHardwares(requestData.vendorId));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.hardwareCreated" }));

      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const checkIfHardwareConfigSettingIsModified = createAsyncThunk(
  'hardware/checkIfHardwareConfigSettingIsModified',
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataSessionAPI(user).post(`Hardware/CheckIfHardwareConfigSettingIsModified`, requestData);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateHardware = createAsyncThunk(
  'hardware/updateHardware',
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataSessionAPI(user).post(`Hardware/UpdateHardware`, requestData);
      dispatch(setSelectedHardware({}));
      dispatch(setOpenAddUpdateHardwareDialog(false));
      dispatch(setEditHardware(false));
      dispatch(setHardwareConfigSettingIsModified(false));
      dispatch(getHardwares(requestData.vendorId));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.hardwareUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteHardware = createAsyncThunk(
  'hardware/deleteHardware',
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`Hardware/DeleteHardware?HardwareId=` + requestData.hardwareId + `&OemPartNumber=` + requestData.oemPartNumber);
      dispatch(setSelectedHardware({}));
      dispatch(setOpenDeleteConfirmationDialog(false));
      dispatch(getHardwares(requestData.vendorId));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.hardwareDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getHardwareConfigSettingSchema = createAsyncThunk(
  "hardware/getHardwareConfigSettingSchema",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Hardware/GetHardwareConfigSettingSchema`);
      dispatch(setLoading(false));
      return result.data.configSettingSchema;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
