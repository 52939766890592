import { createSlice } from '@reduxjs/toolkit';
import { ITemplate } from "../../shared/interfaces/template.interface";
import { addTemplate, deleteTemplate, getModuleConfigurationForTemplateModule, getTemplateConfigChangeDetail, getTemplates, updateTemplate } from '../thunks/templates/templates-thunks';

interface TemplateState {
    templates: ITemplate[],
    selectedTemplate: any,
    selectedModuleForEditConfig: any,
    selectedTemplateModules: any,
    templateConfigChangeDetail: any,
    moduleCongifurationSettings: any,
    openModuleConfigSettingsDialog: boolean,
    openViewTemplateDialog: boolean,
    openAddUpdateTemplateDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    editTemplate: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: TemplateState = {
    templates: [],
    selectedTemplate: null,
    selectedModuleForEditConfig: null,
    selectedTemplateModules: [],
    templateConfigChangeDetail: null,
    moduleCongifurationSettings: null,
    openModuleConfigSettingsDialog: false,
    openViewTemplateDialog: false,
    openAddUpdateTemplateDialog: false,
    openDeleteConfirmationDialog: false,
    editTemplate: false,
    status: 'idle',
    error: undefined
}

export const templatesSlice = createSlice({    
    name: "template",
    initialState,
    reducers: {
        setTemplates: (state, action) => {
            state.templates = action.payload
        },
        setSelectedTemplate: (state, action) => {
            state.selectedTemplate = action.payload
        },
        setSelectedModuleForEditConfig: (state, action) => {
            state.selectedModuleForEditConfig = action.payload
        },
        setSelectedTemplateModules: (state, action) => {
            state.selectedTemplateModules = action.payload
        },
        setModuleConfigurationForTemplateModule: (state, action) => {
            state.moduleCongifurationSettings = action.payload
        },
        setTemplateConfigChangeDetail: (state, action) => {
            state.templateConfigChangeDetail = action.payload
        },
        setOpenViewTemplateDialog: (state, action) => {
            state.openViewTemplateDialog = action.payload
        },
        setOpenAddUpdateTemplateDialog: (state, action) => {
            state.openAddUpdateTemplateDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditTemplate: (state, action) => {
            state.editTemplate = action.payload
        },
        setOpenModuleConfigSettingsDialog: (state, action) => {
            state.openModuleConfigSettingsDialog = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getTemplates.fulfilled, (state, action) => {            
            state.templates = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getTemplates.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getTemplates.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addTemplate.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addTemplate.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addTemplate.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updateTemplate.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(updateTemplate.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateTemplate.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deleteTemplate.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deleteTemplate.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteTemplate.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getTemplateConfigChangeDetail.fulfilled, (state, action) => {            
            state.templateConfigChangeDetail = action.payload;
        })
        .addCase(getTemplateConfigChangeDetail.pending, (state, action) => {
        })
        .addCase(getTemplateConfigChangeDetail.rejected, (state, action) => {
            state.error = action.error.message
        })
        .addCase(getModuleConfigurationForTemplateModule.fulfilled, (state, action) => {            
            state.moduleCongifurationSettings = action.payload;
        })
        .addCase(getModuleConfigurationForTemplateModule.pending, (state, action) => {
        })
        .addCase(getModuleConfigurationForTemplateModule.rejected, (state, action) => {
            state.error = action.error.message
        });
    }
})

export const { 
    setTemplates,
    setSelectedTemplate,
    setSelectedModuleForEditConfig,
    setSelectedTemplateModules,
    setTemplateConfigChangeDetail,
    setModuleConfigurationForTemplateModule,
    setOpenModuleConfigSettingsDialog,
    setOpenViewTemplateDialog,
    setOpenAddUpdateTemplateDialog,
    setOpenDeleteConfirmationDialog,
    setEditTemplate
} = templatesSlice.actions;
export default templatesSlice.reducer;
