import { ReactElement, useEffect, useState, MouseEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-final-form";
import { Button, TextField, Box, Select, InputLabel, MenuItem, FormControl, Grid, Link, FormControlLabel, Checkbox, Input, FormLabel, TableContainer, Paper, Table, TableBody, TableHead, TableRow, TableCell } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IHardware } from "../../../shared/interfaces/hardware.interface";
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { getIotPlatforms } from "../../../store/thunks/iot-platforms/iot-platforms-thunks";
import { SortableTableHead } from "../../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../../shared/table-sorting/table-sort";
import { setEdgeDeviceConfigChangeDetail, setIotPlatformMicroServiceForEdgeDevice, setModuleCongifurationSettings, setOpenHardwareConfigSettingsDialog, setOpenModuleConfigSettingsDialog, setOpenViewProvisionDeviceDialog, setSelectedHardwareForEditConfig, setSelectedModuleForEditConfig, setSelectedProvisionModules, setSelectedTemplate } from "../../../store/slices/provision-edge-devices";
import { deleteEdgeDeviceConfigChangeDetails, getEdgeDeviceConfigChangeDetail, getIotPlatformMicroServiceForEdgeDevice, provisionEdgeDevice, saveEdgeDeviceProvisioningDetails } from "../../../store/thunks/provision-edge-devices/provision-edge-devices-thunks";
import { ITemplateModule } from "../../../shared/interfaces/template.interface";
import { getDeviceLicensedModules } from "../../../store/thunks/modules/modules-thunks";
import { getEdgeDeviceDetails } from "../../../store/thunks/edge-devices/edge-devices-thunks";
import { setSelectedEdgeDevice } from "../../../store/slices/edge-devices";
import { getTemplates } from "../../../store/thunks/templates/templates-thunks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../shared/dialog-components/custom-dialog";
import { ModuleConfigSettingsForm } from "./module-config-settings-form.component";
import { HardwareConfigSettingsForm } from "./hardware-config-settings-form.component";

type Order = 'asc' | 'desc';

type Column = {
  id: string,
  label: string,
  sortable?: string
}

const ModuleSelections = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ITemplateModule>('moduleName');
  const deviceLicensedModules = useAppSelector(state => state.module.deviceLicensedModules);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const selectedEdgeDevice = useAppSelector(state => state.edgeDevice.selectedEdgeDevice);
  const selectedProvisionModules = useAppSelector(state => state.provisionEdgeDevice.selectedProvisionModules);

  const columns: Column[] = [
    { id: '', label: '', sortable: 'disable' },
    { id: 'moduleName', label: intl.formatMessage({ id: 'components.module' }) },
    { id: 'code', label: intl.formatMessage({ id: 'components.code' }) },
    { id: 'applicationName', label: intl.formatMessage({ id: 'components.application' }) },
    { id: 'configurations', label: intl.formatMessage({ id: 'components.configurations' }), sortable: 'disable' }
  ];

  useEffect(() => {
    dispatch(getEdgeDeviceDetails(selectedEdgeDevice.edgeDeviceId));
  }, []);

  useEffect(() => {
    dispatch(setSelectedProvisionModules(selectedEdgeDevice.provisionedModules));
  }, [selectedEdgeDevice]);

  useEffect(() => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(getDeviceLicensedModules(cId));
    }
  }, [selectedAppCustomer]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof ITemplateModule,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = (moduleId: string) => {
    return selectedProvisionModules?.indexOf(moduleId) !== -1;
  }

  const handleClick = (event: any, moduleId: string) => {
    const selectedIndex = selectedProvisionModules.indexOf(moduleId);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedProvisionModules, moduleId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedProvisionModules.slice(1));
    } else if (selectedIndex === selectedProvisionModules.length - 1) {
      newSelected = newSelected.concat(selectedProvisionModules.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedProvisionModules.slice(0, selectedIndex),
        selectedProvisionModules.slice(selectedIndex + 1),
      );
    }
    const finalData: any = { ...selectedEdgeDevice, provisionedModules: newSelected }
    dispatch(setSelectedEdgeDevice(finalData));
  };

  const openEditConfigSetting = (event: MouseEvent<unknown>, data: any) => {
    event.stopPropagation();
    dispatch(setOpenModuleConfigSettingsDialog(true));
    dispatch(setSelectedModuleForEditConfig(data));
  }

  const actionTemplate = (rowData: any) => {
    if (rowData.hasConfigSetting) {
      return <Link href="#!" onClick={(e: any) => openEditConfigSetting(e, rowData)} ><FormattedMessage id="components.edit" /></Link>
    }
    else {
      return <span>Not Applicable</span>
    }
  }

  return (
    <Box>
      <FormLabel><FormattedMessage id="components.modules" /></FormLabel>
      <TableContainer component={Paper} sx={{ maxHeight: 240 }}>
        <Table size="small" stickyHeader data-testid="module-table" aria-label="module table">
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
          />
          <TableBody>
            {stableSort(deviceLicensedModules, getComparator(order, orderBy))
              .map((row, index) => {
                const isItemSelected = isSelected(row.moduleId);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow data-testid="module-node" key={row.moduleId}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={(event) => handleClick(event, row.moduleId)}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.moduleName}</TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>{row.applicationName}</TableCell>
                    <TableCell>{actionTemplate(row)}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export const ProvisionEdgeDeviceForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const iotPlatforms = useAppSelector(state => state.iotPlatform.iotPlatforms);
  const iotPlatformStatus = useAppSelector(state => state.iotPlatform.status);
  const templates = useAppSelector(state => state.template.templates);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const selectedEdgeDevice = useAppSelector(state => state.edgeDevice.selectedEdgeDevice);
  const iotPlatformMicroServiceForEdgeDevice = useAppSelector(state => state.provisionEdgeDevice.iotPlatformMicroServiceForEdgeDevice);
  const selectedProvisionModules = useAppSelector(state => state.provisionEdgeDevice.selectedProvisionModules);
  const edgeDeviceConfigChangeDetail = useAppSelector(state => state.provisionEdgeDevice.edgeDeviceConfigChangeDetail);
  const openModuleConfigSettingsDialog =  useAppSelector(state => state.provisionEdgeDevice.openModuleConfigSettingsDialog );
  const selectedTemplate = useAppSelector(state => state.provisionEdgeDevice.selectedTemplate);
  const openHardwareConfigSettingsDialog =  useAppSelector(state => state.provisionEdgeDevice.openHardwareConfigSettingsDialog );
  
  const [selectedIotPlatform, setSelectedIotPlatform] = useState<string>('');
  const [confirmServicesValue, setConfirmServicesValue] = useState<string>('');
  const [openConfirmNoServices, setOpenConfirmNoServices] = useState<boolean>(false);

  useEffect(() => {
    if (iotPlatformStatus === 'idle' || iotPlatformStatus === 'failed') {
      dispatch(getIotPlatforms());
    }
  }, []);

  useEffect(() => {
    dispatch(getEdgeDeviceConfigChangeDetail(selectedEdgeDevice.edgeDeviceId));
  }, []);

  useEffect(() => {
    if (selectedEdgeDevice.ioTplatformId && selectedEdgeDevice.ioTplatformId !== '') {
      setSelectedIotPlatform(selectedEdgeDevice.ioTplatformId);
      const finalData = {
        edgeDeviceId: selectedEdgeDevice.edgeDeviceId,
        ioTplatformId: selectedEdgeDevice.ioTplatformId
      }
      dispatch(getIotPlatformMicroServiceForEdgeDevice(finalData));
    }
  }, []);

  useEffect(() => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(getTemplates(cId));
    }
  }, [selectedAppCustomer]);

  const onCancelProvisionDetails = () => {
    dispatch(deleteEdgeDeviceConfigChangeDetails(edgeDeviceConfigChangeDetail.edgeDeviceConfigChangeDetailsId));
    dispatch(setOpenViewProvisionDeviceDialog(false));
    dispatch(setSelectedEdgeDevice({}));
    dispatch(setIotPlatformMicroServiceForEdgeDevice(null));
    dispatch(setEdgeDeviceConfigChangeDetail({}));
    dispatch(setSelectedTemplate(''));
  }

  const handleIotPlatformChange = (data: string) => {
    setSelectedIotPlatform(data);
    if(data === ''){
      dispatch(setIotPlatformMicroServiceForEdgeDevice(null));
    }
    else{
      const finalData = {
        edgeDeviceId: selectedEdgeDevice.edgeDeviceId,
        ioTplatformId: data
      }    
      dispatch(getIotPlatformMicroServiceForEdgeDevice(finalData));
    }
  }

  const handleTemplateChange = (data: string) => {
    dispatch(setSelectedTemplate(data));
    if (data !== '') {
      let templateModules: string[] = [];
      templates.map(obj => {
        if (obj.templateId === data) {
          templateModules = obj.viewTemplateModules.map(item => item.moduleId);
        }
      })
      dispatch(setSelectedProvisionModules(templateModules));
    }
    else {
      dispatch(setSelectedProvisionModules(selectedEdgeDevice.provisionedModules));
    }
  }

  const onSaveProvisionDetails = () => {
    if (selectedIotPlatform !== '') {
      confirmNoServices('save');
    }
    else {
      saveProvisionDetails();
    }
  };

  const confirmNoServicesOk = () => {
    closeConfirmNoServices();
    if (confirmServicesValue === 'save') {
      saveProvisionDetails();
    }
    else if (confirmServicesValue === 'deploy') {
      requestProvision();
    }
  }

  const closeConfirmNoServices = () => {
    setOpenConfirmNoServices(false);
    setConfirmServicesValue('');
  }

  const onSubmit = () => {
    if (selectedIotPlatform !== '') {
      confirmNoServices('deploy');
    }
    else {
      requestProvision();
    }
  };

  const saveProvisionDetails = () => {
    let finalData = {
      edgeDeviceId: selectedEdgeDevice.edgeDeviceId,
      ioTplatformId: selectedIotPlatform,
      templateId: selectedTemplate,
      configChangeId: edgeDeviceConfigChangeDetail.edgeDeviceConfigChangeDetailsId,
      coreMicroService: iotPlatformMicroServiceForEdgeDevice?.coreMicroService,
      nonCoreMicroService: iotPlatformMicroServiceForEdgeDevice?.nonCoreMicroService,
      moduleIds: selectedProvisionModules
    }
    dispatch(saveEdgeDeviceProvisioningDetails(finalData))
  }

  const requestProvision = () => {
    let finalData = {
      currentUserId: loggedInUser?.userId, 
      customerId: selectedAppCustomer?.customerId,
      oemPartNumber: selectedEdgeDevice.oempartNumber,
      edgeDeviceId: selectedEdgeDevice.edgeDeviceId,
      ioTplatformId: selectedIotPlatform,
      templateId: selectedTemplate,
      configChangeId: edgeDeviceConfigChangeDetail.edgeDeviceConfigChangeDetailsId,
      coreMicroService: iotPlatformMicroServiceForEdgeDevice?.coreMicroService,
      nonCoreMicroService: iotPlatformMicroServiceForEdgeDevice?.nonCoreMicroService,
      moduleIds: selectedProvisionModules
    }    
    dispatch(provisionEdgeDevice(finalData));
    dispatch(setOpenViewProvisionDeviceDialog(false));
    dispatch(setSelectedEdgeDevice({}));
    dispatch(setIotPlatformMicroServiceForEdgeDevice(null));
    dispatch(setEdgeDeviceConfigChangeDetail({}));
    dispatch(setSelectedTemplate(''));
  }

  /*
  * confirmation check for non selection of non core micro srvices
  */
  const confirmNoServices = (value: string) => {
    const selected = iotPlatformMicroServiceForEdgeDevice?.nonCoreMicroService.filter((obj: any) => obj.isCoreService === true);

    if (selected.length > 0) {
      setConfirmServicesValue('');
      if (value === 'save') {
        saveProvisionDetails();
      }
      else if (value === 'deploy') {
        requestProvision();
      }
    }
    else {
      setConfirmServicesValue(value);
      setOpenConfirmNoServices(true);
    }
  }

  const actionDependsOn = (data: any) => {
    return data.dependsOn.join(", ");
  }

  const handleClick = (event: MouseEvent<unknown>, data: any) => {
    const nonCoreMS = iotPlatformMicroServiceForEdgeDevice?.nonCoreMicroService.map((obj: any) =>
      obj.microServiceName === data.microServiceName
        ? { ...obj, isCoreService: !obj.isCoreService } : obj
    );

    const finalData: any = { ...iotPlatformMicroServiceForEdgeDevice, nonCoreMicroService: nonCoreMS }
    dispatch(setIotPlatformMicroServiceForEdgeDevice(finalData));
  };

  const imageTemplate = (data: IHardware) => {
    return <img src={`data:image/png;base64,${data.thumbNailImage}`} width="50" alt={data.thumbNailImageName} />;
  }

  const actionHardwareTemplate = (data: IHardware) => {
    return <Link href="#!" onClick={(e: any) => openEditHardwareConfigSettingModal(e, data)} ><FormattedMessage id="components.edit" /></Link>
  }

  const openEditHardwareConfigSettingModal = (event: MouseEvent<unknown>, data: any) => {
    event.stopPropagation();
    dispatch(setOpenHardwareConfigSettingsDialog(true));
    dispatch(setSelectedHardwareForEditConfig(data));
  }

  const handleCloseHardwareConfigDialog = () => {
    dispatch(setOpenHardwareConfigSettingsDialog(false));
    dispatch(setSelectedHardwareForEditConfig({}));
  };

  const handleCloseModuleConfigDialog = () => {
    dispatch(setOpenModuleConfigSettingsDialog(false));    
    dispatch(setSelectedModuleForEditConfig({}));
    dispatch(setModuleCongifurationSettings({}));
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={selectedEdgeDevice}
        render={({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} data-testid="provision-device-form">
              <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <TextField
                    value={selectedEdgeDevice.azureIotdeviceName}
                    label={<FormattedMessage id="components.iotDeviceName" />}
                    variant="standard"
                    role="textbox"
                    aria-label="iotDeviceName"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={selectedEdgeDevice.oempartNumber}
                    label={<FormattedMessage id="components.oemPartNumber" />}
                    variant="standard"
                    role="textbox"
                    aria-label="oemPartNumber"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={selectedEdgeDevice.oemserialNumber}
                    label={<FormattedMessage id="components.oemSerialNumber" />}
                    variant="standard"
                    role="textbox"
                    aria-label="oemSerialNumber"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={selectedAppCustomer?.name ? selectedAppCustomer?.name : ""}
                    label={<FormattedMessage id="components.customer" />}
                    variant="standard"
                    role="textbox"
                    aria-label="po-customer"
                    fullWidth
                    autoComplete='off'
                    disabled={true}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth variant="standard" >
                    <InputLabel id="select-iotPlatform"><FormattedMessage id="components.iotPlatform" /></InputLabel>
                    <Select
                      labelId="select-iotPlatform"
                      role="combobox"
                      aria-label="select iotPlatform"
                      fullWidth
                      inputProps={{ "data-testid": "select-iotPlatform" }}
                      value={selectedIotPlatform}
                      onChange={(e) => handleIotPlatformChange(e.target.value)}
                    >
                      <MenuItem value="">No Selection</MenuItem>
                      {iotPlatforms.map((row: any) => (
                        <MenuItem key={row.ioTplatformId} value={row.ioTplatformId}>{row.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="standard" >
                    <InputLabel id="select-template"><FormattedMessage id="components.availableTemplates" /></InputLabel>
                    <Select
                      labelId="select-template"
                      role="combobox"
                      aria-label="select template"
                      fullWidth
                      inputProps={{ "data-testid": "select-template" }}
                      value={selectedTemplate}
                      onChange={(e) => handleTemplateChange(e.target.value)}
                    >
                      <MenuItem value=""><FormattedMessage id="components.defaultSelected" /></MenuItem>
                      {templates.map((row: any) => (
                        <MenuItem key={row.templateId} value={row.templateId}>{row.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {iotPlatformMicroServiceForEdgeDevice &&
                  <Grid item xs={12} sm={6}>
                    <FormLabel><FormattedMessage id="components.coreMicroServices" /></FormLabel>
                    <TableContainer component={Paper} sx={{ mt: 0, height: 240 }}>
                      <Table size="small" stickyHeader data-testid="core-micro-services-table" aria-label="core micro services table">
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: '116px' }}><FormattedMessage id="components.isCoreService" /></TableCell>
                            <TableCell sx={{ width: '170px' }}><FormattedMessage id="components.microServiceName" /></TableCell>
                            <TableCell><FormattedMessage id="components.dependsOn" /></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {iotPlatformMicroServiceForEdgeDevice && iotPlatformMicroServiceForEdgeDevice?.coreMicroService
                            .map((row: any, index: number) => {
                              return (
                                <TableRow data-testid="cms-node" key={index}>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      checked={row.isCoreService}
                                      inputProps={{
                                        'aria-labelledby': `checkbox-${index}`,
                                      }}
                                      disabled
                                    />
                                  </TableCell>
                                  <TableCell>{row.microServiceName}</TableCell>
                                  <TableCell>{actionDependsOn(row)}</TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                }
                {iotPlatformMicroServiceForEdgeDevice &&
                  <Grid item xs={12} sm={6}>
                    <FormLabel><FormattedMessage id="components.nonCoreMicroServices" /></FormLabel>
                    <TableContainer component={Paper} sx={{ mt: 0, height: 240 }}>
                      <Table size="small" stickyHeader data-testid="non-core-micro-services-table" aria-label="non core micro services table">
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: '116px' }}><FormattedMessage id="components.isCoreService" /></TableCell>
                            <TableCell sx={{ width: '170px' }}><FormattedMessage id="components.microServiceName" /></TableCell>
                            <TableCell><FormattedMessage id="components.dependsOn" /></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {iotPlatformMicroServiceForEdgeDevice && iotPlatformMicroServiceForEdgeDevice?.nonCoreMicroService
                            .map((row: any, index: number) => {
                              return (
                                <TableRow data-testid="ncms-node" key={index}>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      color="primary"
                                      checked={row.isCoreService}
                                      onClick={(event) => handleClick(event, row)}
                                      inputProps={{
                                        'aria-labelledby': `checkbox-${index}`,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>{row.microServiceName}</TableCell>
                                  <TableCell>{actionDependsOn(row)}</TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                }
                <Grid item xs={12}>
                  <ModuleSelections />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel><FormattedMessage id="components.hardware" /></FormLabel>
                  {selectedEdgeDevice.hardware &&
                    <TableContainer component={Paper}>
                      <Table size="small" data-testid="hardware-table" aria-label="hardware table">
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell><FormattedMessage id="components.hardware" /></TableCell>
                            <TableCell><FormattedMessage id="components.oemPartNumber" /></TableCell>
                            <TableCell><FormattedMessage id="components.classification" /></TableCell>
                            <TableCell><FormattedMessage id="components.createdTime" /></TableCell>
                            <TableCell><FormattedMessage id="components.active" /></TableCell>
                            <TableCell><FormattedMessage id="components.vendor" /></TableCell>
                            <TableCell><FormattedMessage id="components.configurations" /></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>{imageTemplate(selectedEdgeDevice.hardware)}</TableCell>
                            <TableCell>{selectedEdgeDevice.hardware.name}</TableCell>
                            <TableCell>{selectedEdgeDevice.hardware.oemPartNumber}</TableCell>
                            <TableCell>{selectedEdgeDevice.hardware.hardwareClassification}</TableCell>
                            <TableCell>{selectedEdgeDevice.hardware.createDtTm}</TableCell>
                            <TableCell>{selectedEdgeDevice.hardware.isActive ? "Yes" : "No"}</TableCell>
                            <TableCell>{selectedEdgeDevice.hardware.vendorName}</TableCell>
                            <TableCell>{actionHardwareTemplate(selectedEdgeDevice.hardware)}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
                <Button onClick={() => onCancelProvisionDetails()} variant="contained" color="error" sx={{ mr: 1 }} data-testid="cancel-button">
                  <FormattedMessage id="components.cancel" />
                </Button>
                <Button onClick={() => onSaveProvisionDetails()} variant="contained" color="success" sx={{ mr: 1 }} data-testid="save-button">
                  <FormattedMessage id="components.save" />
                </Button>
                <Button type="submit" disabled={submitting} variant="contained" color="primary" data-testid="deploy-button">
                  <FormattedMessage id="components.deploy" />
                </Button>
              </Box>
            </form>
          )
        }}
      />

      <Dialog
        open={openConfirmNoServices}
        onClose={closeConfirmNoServices}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="confirm-dlg"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage id="components.confirmNoServicesText" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmNoServices} variant="contained" color="error">
            <FormattedMessage id="components.cancel" />
          </Button>
          <Button onClick={confirmNoServicesOk} variant="contained" color="primary" autoFocus>
            <FormattedMessage id="components.yes" />
          </Button>
        </DialogActions>
      </Dialog>

      {/*start of module config dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseModuleConfigDialog}
        aria-labelledby="dialog-title"
        open={openModuleConfigSettingsDialog}
        data-testid="module-config-dlg"
      >
        <BootstrapDialogTitle id="module-dialog-title" onClose={handleCloseModuleConfigDialog}>
            <FormattedMessage id="components.updateModuleConfigSettings" />
        </BootstrapDialogTitle>
        <DialogContent>
          <ModuleConfigSettingsForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of module config dialog */}

      {/*start of hardware config dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseHardwareConfigDialog}
        aria-labelledby="dialog-title"
        open={openHardwareConfigSettingsDialog}
        data-testid="hardware-config-dlg"
      >
        <BootstrapDialogTitle id="hardware-dialog-title" onClose={handleCloseHardwareConfigDialog}>
            <FormattedMessage id="components.updateHardwareConfigurations" />
        </BootstrapDialogTitle>
        <DialogContent>
          <HardwareConfigSettingsForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of hardware config dialog */}

    </>
  );
};
