import { dataAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setEditTemplate, setModuleConfigurationForTemplateModule, setOpenAddUpdateTemplateDialog, setOpenDeleteConfirmationDialog, setOpenModuleConfigSettingsDialog, setSelectedModuleForEditConfig, setSelectedTemplate, setTemplateConfigChangeDetail } from "../../slices/templates";

export const getTemplates = createAsyncThunk(
  "template/getTemplates",
  async (customerId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Template/GetTemplatesByCustomerId?CustomerId=`+ customerId);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addTemplate = createAsyncThunk(
  "template/addTemplate",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`Template/AddTemplate`, requestData);
      dispatch(setOpenAddUpdateTemplateDialog(false));
      dispatch(getTemplates(requestData.customerId));
      dispatch(setTemplateConfigChangeDetail({}));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.templateCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateTemplate = createAsyncThunk(
  "template/updateTemplate",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`Template/UpdateTemplate`, requestData);
      dispatch(setSelectedTemplate({}));
      dispatch(setOpenAddUpdateTemplateDialog(false));
      dispatch(setEditTemplate(false));
      dispatch(getTemplates(requestData.customerId));
      dispatch(setTemplateConfigChangeDetail({}));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.templateUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  "template/deleteTemplate",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`Template/DeleteTemplate?TemplateId=` + requestData.templateId);
        dispatch(setSelectedTemplate({}));
        dispatch(setOpenDeleteConfirmationDialog(false));
        dispatch(getTemplates(requestData.customerId));
        dispatch(setSnackbarAlert({ messageType: 'success', message: "components.templateDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getTemplateConfigChangeDetail = createAsyncThunk(
  "template/getTemplateConfigChangeDetail",
  async (templateId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Template/GetTemplateConfigChangeDetail?TemplateId=${templateId}`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getModuleConfigurationForTemplateModule = createAsyncThunk(
  "template/getModuleConfigurationForTemplateModule",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Template/GetModuleConfigurationForTemplateModule?ModuleId=${requestData.moduleId}&ChangeDetailsId=${requestData.changeDetailsId}&TemplateId=${requestData.templateId}`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const stageTemplateModuleConfiguration = createAsyncThunk(
  "template/stageTemplateModuleConfiguration",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`Template/StageTemplateModuleConfiguration`, requestData);
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.moduleConfigurationSaved" }));
      dispatch(setModuleConfigurationForTemplateModule({}));
      dispatch(setModuleConfigurationForTemplateModule(null));
      dispatch(setSelectedModuleForEditConfig({}));
      dispatch(setOpenModuleConfigSettingsDialog(false));
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteTemplateConfigChangeDetails = createAsyncThunk(
  "template/deleteTemplateConfigChangeDetails",
  async (templateConfigChangeDetailsId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`Template/DeleteTemplateConfigChangeDetails?TemplateConfigChangeDetailsId=` + templateConfigChangeDetailsId);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
