import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Grid, Button, TextField, Box, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ComposeValidators, EmailAddress, Required, StartDateValidation, AllowedEmailDomains } from "../../shared/form-validations/form-validations";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { IUser } from "../../shared/interfaces/user.interface";
import { addUser, getRoles, updateUser } from "../../store/thunks/user-management/user-management-thunks";
import { Role } from "../../shared/enums/role.enum";

export const AddUpdateUserForm = () => {
  const dispatch = useAppDispatch();
  const rolesStatus = useAppSelector(state => state.userManagement.rolesStatus);
  const roles = useAppSelector(state => state.userManagement.roles);
  const selectedUser = useAppSelector(state => state.userManagement.selectedUser);
  const editUser = useAppSelector(state => state.userManagement.editUser);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);

  useEffect(() => {
    if (rolesStatus === 'idle' || rolesStatus === 'failed') {
      dispatch(getRoles());
    }
  }, []);

  const onSubmit = (data: IUser) => {
    let finalData: any = data;
    finalData = {
      ...finalData,
      trialExpiration: data.trialExpiration ? moment(data.trialExpiration).format("MM/DD/YYYY, H:mm:ss") : null,
      terms: data.trial ? 'trial' : 'default'
    }
    if (editUser) {
      dispatch(updateUser(finalData));
    }
    else {
      finalData = {
        ...finalData,
        customerId: selectedAppCustomer?.customerId,
      }
      dispatch(addUser(finalData));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={selectedUser}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} data-testid="users-form">
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <TextField
                value={selectedAppCustomer?.name ? selectedAppCustomer?.name : ""}
                label={<FormattedMessage id="components.customer" />}
                variant="standard"
                role="textbox"
                aria-label="user-customer"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Field name="userName" validate={Required}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.username" />}
                      variant="standard"
                      fullWidth
                      role="textbox"
                      error={meta.error && meta.touched}
                      autoComplete='off'
                      disabled={editUser}
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
            </Grid>
            <Grid item xs={6} container rowSpacing={2}>
              <Grid item xs={12}>
                <Field name="email" validate={ComposeValidators(Required, EmailAddress, AllowedEmailDomains(selectedAppCustomer?.emailDomainWhiteList))}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.emailAddress" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                        disabled={editUser}
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="firstName" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.firstName" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="lastName" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.lastName" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="trial" type="checkbox" >
                  {({ input }) => (
                    <FormControlLabel sx={{ display: 'block', marginTop: '6px' }}
                      control={<Checkbox {...input.onChange} {...input} />}
                      label={<FormattedMessage id="components.trialUser" />}
                    />
                  )}
                </Field>
                {values.trial === true &&
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Field name="trialExpiration" validate={ComposeValidators(Required, StartDateValidation)}>
                      {({ input, meta }) => (
                        <>
                          <DesktopDatePicker
                            label={<FormattedMessage id="components.trialExpiration" />}
                            inputFormat="MM/DD/YYYY"
                            {...input}
                            renderInput={(params: any) =>
                              <TextField
                                {...params}
                                variant="standard"
                                fullWidth
                                error={meta.error && meta.touched}
                              />
                            }
                          />
                          {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                        </>
                      )}
                    </Field>
                  </LocalizationProvider>
                }
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Field name="userRoles" validate={Required}>
                {({ input, meta }) => {
                  return <>
                    <FormLabel error={meta.error && meta.touched}><FormattedMessage id="components.roles" /></FormLabel>
                    {roles.map((option, index) => {
                      return (
                        <>
                          {option.name !== Role.GLEASON_VENDOR &&
                            <div key={index}>
                              <Checkbox
                                sx={{ p: 0.5 }}
                                name={`${input.name}[${index}]`}
                                value={option.roleId}
                                checked={input.value.indexOf(option.roleId) !== -1}
                                onChange={(event) => {
                                  const newValue = [...input.value];
                                  if (event.target.checked) {
                                    newValue.push(option.roleId);
                                  } else {
                                    newValue.splice(newValue.indexOf(option.roleId), 1);
                                  }
                                  return input.onChange(newValue);
                                }} />
                              <FormLabel>{option.name}</FormLabel>
                            </div>
                          }
                        </>
                        )
                      }
                    )}
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                }
                }
              </Field>

            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
              <FormattedMessage id="components.reset" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
              {editUser ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
            </Button>
          </Box>
        </form>
      )}
    />
  );
};
