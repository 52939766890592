import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination
} from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import { INotification } from "../../shared/interfaces/notification.interface";
import { onSearchInTable } from "../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getNotifications } from "../../store/thunks/notifications/notifications-thunks";
import { TablePaginationActions } from "../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const ViewNotifications = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const notifications = useAppSelector(state => state.notification.notifications);
  const notificationStatus = useAppSelector(state => state.notification.status);
  const [searchNotificationsList, setSearchNotificationsList] = useState<INotification[]>(notifications);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof INotification>('notificationDtTm');

  const columns: Column[] = [
    { id: 'actionName', label: intl.formatMessage({ id: 'components.actionName' }) },    
    { id: 'notificationCode', label: intl.formatMessage({ id: 'components.message' }) },
    { id: 'notificationDtTm', label: intl.formatMessage({ id: 'components.time' }) },   
  ];

  useEffect(() => {
      loadNotifications();
  }, [loggedInUser]);

  useEffect(() => {
    setSearchNotificationsList(notifications);   
  }, [notifications]);

  useEffect(() => {
    setPage(0);
  },[searchNotificationsList]);

  const loadNotifications = () => {
    const userId = loggedInUser ? loggedInUser?.userId : '';
    dispatch(getNotifications(userId));
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchNotificationsList(onSearchInTable(notifications, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof INotification,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="notifications">
        <div className="page-header">
          <h2><FormattedMessage id="components.notifications" /></h2>
        </div>
        <Box>
          <TableContainer component={Paper}>
            <div className="table-head-links">
              <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadNotifications()} > <CachedIcon /> </Link></div>
            </div>
            <div data-testid="searchbox" style={{ padding: "10px" }}>
              <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
            </div>
            <Table sx={{ minWidth: 650 }} data-testid="notification-table" aria-label="notification table">
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
              />
              <TableBody>
                {stableSort(searchNotificationsList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow data-testid="notification-node" key={row.notificationId} >
                        <TableCell>{row.actionName}</TableCell>
                        <TableCell>{row.messageText}</TableCell>
                        <TableCell>{row.notificationDtTm}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    data-testid="pagination-control"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={columns.length} 
                    count={searchNotificationsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </div>

    </>
  );
};
