import { ReactElement, useEffect, useState } from "react";
import { Box, Grid, Button, FormControl, TextField, InputLabel, Select, MenuItem, DialogContent } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { generateCustomerLicense, generateUserLicense, sendLicense } from "../../store/thunks/license-users/license-users-thunks";
import { getUsers } from "../../store/thunks/user-management/user-management-thunks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { Field, Form } from "react-final-form";
import { AllowedEmailDomains, ComposeValidators, EmailAddress, Required } from "../../shared/form-validations/form-validations";
import { setOpenSendEmailDialog } from "../../store/slices/license-users";

export const GenerateLicense = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const openSendEmailDialog = useAppSelector(state => state.licenseUser.openSendEmailDialog);
  const users = useAppSelector(state => state.userManagement.users);
  const [selectedUser, setSelectedUser] = useState('');

  useEffect(() => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(getUsers(cId));
    }
  }, [selectedAppCustomer]);

  const onGenerateCustomerLicense = () => {
    const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
    dispatch(generateCustomerLicense(cId));    
  }

  const onGenerateUserLicense = () => {
    const data = {
      customerId: selectedAppCustomer ? selectedAppCustomer.customerId : "",
      userId: selectedUser
    }
    dispatch(generateUserLicense(data));     
  }

  const handleUserChange = (value: string) => {
    setSelectedUser(value);
  }

  const handleCloseDialog = () => {
    dispatch(setOpenSendEmailDialog(false));
  }

  const onOpenSendEmailDialog = () => {
    dispatch(setOpenSendEmailDialog(true));
  }

  const onSubmit = (data: any) => {
    const finalData = {
      emailAddress: data.email,
      customerId: selectedAppCustomer?.customerId
    }
    dispatch(sendLicense(finalData));
  };

  return (
    <>
      <div data-testid="generate-license">
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" sx={{ m: 2, mt: 0, width: 350 }} data-testid="customer-selected">
              <TextField
                id="customer"
                label={<FormattedMessage id="components.customer" />}
                defaultValue={selectedAppCustomer?.name}
                variant="standard"
                disabled
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" justifyContent="flex-start" sx={{ m: 2, pb: 2 }}>
              <Button sx={{ mr: 2 }} onClick={() => onGenerateCustomerLicense()} variant="contained" color="primary" data-testid="generate-customer-license-button" >
                <FormattedMessage id="components.generateCustomerLicense" />
              </Button>
              <Button onClick={() => onOpenSendEmailDialog()} variant="contained" color="success" data-testid="email-license-button" >
                <FormattedMessage id="components.emailLicense" />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
          <FormControl variant="standard" sx={{ m: 2, mt: 0, width: 350 }} data-testid="user-select-combobox">
            <InputLabel id="select-user"><FormattedMessage id="components.user" /></InputLabel>
            <Select
              labelId="select-user"
              role="combobox"
              aria-label="select user"
              inputProps={{ "data-testid": "select-user" }}
              value={selectedUser}
              onChange={(e) => handleUserChange(e.target.value)}
            >
              {users.map((row) => (
                <MenuItem key={row.userId} value={row.userId}>{row.userName}</MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          { selectedUser !== '' &&
            <Box display="flex" justifyContent="flex-start" sx={{ m: 2, pb: 2 }}>
              <Button onClick={() => onGenerateUserLicense()} variant="contained" color="primary" data-testid="generate-customer-license-button" >
                <FormattedMessage id="components.generateUserLicense" />
              </Button>
            </Box>
          }
          </Grid>
        </Grid>         
      </div>

      {/*start of end email dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openSendEmailDialog}
        data-testid="send-email-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
            <FormattedMessage id="components.enterEmailtoSendLicense" /> 
        </BootstrapDialogTitle>
        <DialogContent>
        <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit} data-testid="send-email-form">
            <Field name="email" validate={ComposeValidators(Required, EmailAddress, AllowedEmailDomains(selectedAppCustomer?.emailDomainWhiteList))}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.email" />}
                    variant="standard"
                    fullWidth
                    role="textbox"
                    aria-label="gl-email"
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ mt: 3 }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>

            <Box display="flex" justifyContent="flex-end" sx={{ pt: 3 }}>
              <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ mr: 1 }}>
                <FormattedMessage id="components.reset" />
              </Button>
              <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
                <FormattedMessage id="components.send" /> 
              </Button>
            </Box>

          </form>
        )}
      />
        </DialogContent>
      </BootstrapDialog>
      {/*end of end email dialog */}
    </>
  );
};