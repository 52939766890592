//removes duplicate objects from the array
export const getUniqueListBy = (arr: any[], predicate: string | number) => {
    const cb =
        typeof predicate === "function"
            ? predicate
            : (o: { [x: string]: any }) => o[predicate];

    return [
        ...arr
            .reduce(
                (
                    map: { has: (arg0: any) => any; set: (arg0: any, arg1: any) => any },
                    item: null | undefined
                ) => {
                    const key = item === null || item === undefined ? item : cb(item);

                    map.has(key) || map.set(key, item);

                    return map;
                },
                new Map()
            )
            .values(),
    ];
};