import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IAssetObject } from "../../shared/interfaces/asset-object.interface";
import { onSearchInTable } from "../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { DeleteConfirmationDialog } from "../../shared/dialog-components/delete-confirmation-dialog";
import { TablePaginationActions } from "../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";
import { setEditAssetObject, setOpenAddUpdateAssetObjectDialog, setOpenDeleteConfirmationDialog, setOpenViewAssetObjectDialog, setSelectedAssetObject } from "../../store/slices/machine-management";
import { AddUpdateAssetObjectForm } from "./add-update-asset-object-form.component";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const MachineManagement = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const assetObjects = useAppSelector(state => state.machineManagement.assetObjects);
  const assetObjectStatus = useAppSelector(state => state.machineManagement.status);
  const selectedAssetObject = useAppSelector(state => state.machineManagement.selectedAssetObject);
  const openViewAssetObjectDialog = useAppSelector(state => state.machineManagement.openViewAssetObjectDialog);
  const openAddUpdateAssetObjectDialog = useAppSelector(state => state.machineManagement.openAddUpdateAssetObjectDialog);
  const openDeleteConfirmationDialog = useAppSelector(state => state.machineManagement.openDeleteConfirmationDialog);
  const editAssetObject = useAppSelector(state => state.machineManagement.editAssetObject);
  const [searchAssetObjectsList, setSearchAssetObjectsList] = useState<IAssetObject[]>(assetObjects);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IAssetObject>('name');

  const columns: Column[] = [
    { id: 'serialNumber', label: intl.formatMessage({ id: 'components.serialNumber' }) },
    { id: 'catagory', label: intl.formatMessage({ id: 'components.catagory' }) },
    { id: 'model', label: intl.formatMessage({ id: 'components.model' }) },
    { id: 'customerId', label: intl.formatMessage({ id: 'components.customer' }) },
    { id: 'productionState', label: intl.formatMessage({ id: 'components.productionState' }) },
    { id: 'description', label: intl.formatMessage({ id: 'components.description' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  // useEffect(() => {
  //   if(assetObjectStatus === 'idle' || assetObjectStatus === 'failed'){
  //     loadAssetObjects();
  //   }    
  // }, []);

  useEffect(() => {
    setSearchAssetObjectsList(assetObjects);   
  }, [assetObjects]);

  useEffect(() => {
    setPage(0);
  },[searchAssetObjectsList]);

  const loadAssetObjects = () => {
    //dispatch(getAssetObjects())
  }

  const handleCloseDialog = () => {
    dispatch(setOpenViewAssetObjectDialog(false));
    dispatch(setOpenAddUpdateAssetObjectDialog(false));
    dispatch(setOpenDeleteConfirmationDialog(false));
    dispatch(setSelectedAssetObject(null));
    dispatch(setEditAssetObject(false));
  }

  const onViewAssetObjectDetails = (data: IAssetObject) => {
    dispatch(setOpenViewAssetObjectDialog(true));
    dispatch(setSelectedAssetObject(data));
    dispatch(setEditAssetObject(false));
  }

  const addAssetObject = () => {
    dispatch(setOpenAddUpdateAssetObjectDialog(true));
    dispatch(setEditAssetObject(false));
  }

  const onUpdateAssetObject = (data: IAssetObject) => {
    dispatch(setSelectedAssetObject(data));
    dispatch(setOpenAddUpdateAssetObjectDialog(true));
    dispatch(setEditAssetObject(true));
  }

  const onDeleteAssetObject = (data: IAssetObject) => {
    dispatch(setOpenDeleteConfirmationDialog(true));
    dispatch(setSelectedAssetObject(data));
  }

  const deleteAssetObjectPermanently = () => {
    //dispatch(deleteAssetObject(selectedAssetObject));
  }

  const actionTemplateAssetObjects = (rowData: IAssetObject) => {
    return <span data-testid="ao-actions" className="list-action">
      <Link role="button" data-testid="view-ao" aria-label="view-ao" component="button" variant="body2" onClick={() => onViewAssetObjectDetails(rowData)} > <VisibilityIcon /> </Link>
      <Link role="button" data-testid="update-ao" component="button" variant="body2" onClick={() => onUpdateAssetObject(rowData)} > <EditIcon /> </Link>
      <Link role="button" data-testid="delete-ao" component="button" variant="body2" onClick={() => onDeleteAssetObject(rowData)} > <ClearIcon /> </Link>
    </span>;
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchAssetObjectsList(onSearchInTable(assetObjects, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IAssetObject,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="asset-objects">
        <div className="page-header">
          <h2><FormattedMessage id="components.assetObjects" /></h2>
        </div>
        <Box>
          <TableContainer component={Paper}>
            <div className="table-head-links">
              <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadAssetObjects()} > <CachedIcon /> </Link></div>
              <div data-testid="add-new-btn" className="add-link"><Link component="button" role="button" aria-label="add-ao" variant="body1" underline="none" onClick={() => addAssetObject()} ><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.assetObject" /> <AddIcon className="add-icon" /> </Link></div>
            </div>
            <div data-testid="searchbox" style={{ padding: "10px" }}>
              <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
            </div>
            <Table sx={{ minWidth: 650 }} data-testid="asset-object-table" aria-label="data centers table">
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
              />
              <TableBody>
                {stableSort(searchAssetObjectsList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow data-testid="ao-node" key={row.assetObjectId} >
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{actionTemplateAssetObjects(row)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    data-testid="pagination-control"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={columns.length} 
                    count={searchAssetObjectsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </div>

      {/*start of add/update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openAddUpdateAssetObjectDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
          {editAssetObject ?
            <><FormattedMessage id="components.update" /> <FormattedMessage id="components.assetObject" /></>
            : <><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.assetObject" /></>
          }
        </BootstrapDialogTitle>
        <DialogContent>
          <AddUpdateAssetObjectForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of add/update dialog */}

      {/*start of view details dialog */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewAssetObjectDialog}
        data-testid="view-ao-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedAssetObject &&
            <>
              <p><FormattedMessage id="components.assetObject" />: <span style={{ fontWeight: 'bold' }}>{selectedAssetObject.name}</span></p>
              <p><FormattedMessage id="components.region" />: <span style={{ fontWeight: 'bold' }}>{selectedAssetObject.region}</span></p>
              <p><FormattedMessage id="components.fleetName" />: <span style={{ fontWeight: 'bold' }}>{selectedAssetObject.fleetName}</span></p>
              <p><FormattedMessage id="components.stackName" />: <span style={{ fontWeight: 'bold' }}>{selectedAssetObject.stackName}</span></p>
              <p><FormattedMessage id="components.ec2IP" />: <span style={{ fontWeight: 'bold' }}>{selectedAssetObject.ec2ip}</span></p>
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

      {/*Start of delete confirmation dialog */}
      <DeleteConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleCancel={handleCloseDialog}
        title={<FormattedMessage id="components.wantToDelete" />}
        message={<span><FormattedMessage id="components.thisWillDelete" /> <FormattedMessage id="components.assetObject" /> <FormattedMessage id="components.permanently" /></span>}
        onConfirm={deleteAssetObjectPermanently}
      />
      {/*end of delete confirmation dialog */}

    </>
  );
};
