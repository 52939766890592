import { ReactElement, useEffect, useState, MouseEvent, ChangeEvent } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  TableHead
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { ITemplate } from "../../../shared/interfaces/template.interface";
import { onSearchInTable } from "../../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../shared/dialog-components/custom-dialog";
import { DeleteConfirmationDialog } from "../../../shared/dialog-components/delete-confirmation-dialog";
import { AddUpdateTemplateForm } from "./add-update-template-form.component";
import { deleteTemplate, deleteTemplateConfigChangeDetails, getTemplates } from "../../../store/thunks/templates/templates-thunks";
import {
  setSelectedTemplate,
  setOpenAddUpdateTemplateDialog,
  setOpenViewTemplateDialog,
  setOpenDeleteConfirmationDialog,
  setEditTemplate,
  setSelectedTemplateModules,
  setTemplateConfigChangeDetail
} from "../../../store/slices/templates";
import { TablePaginationActions } from "../../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../../shared/table-sorting/table-sort";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const Templates = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const templates = useAppSelector(state => state.template.templates);
  const selectedTemplate = useAppSelector(state => state.template.selectedTemplate);
  const openViewTemplateDialog = useAppSelector(state => state.template.openViewTemplateDialog);
  const openAddUpdateTemplateDialog = useAppSelector(state => state.template.openAddUpdateTemplateDialog);
  const openDeleteConfirmationDialog = useAppSelector(state => state.template.openDeleteConfirmationDialog);
  const editTemplate = useAppSelector(state => state.template.editTemplate);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const templateConfigChangeDetail = useAppSelector(state => state.template.templateConfigChangeDetail);
  const [searchTemplatesList, setSearchTemplatesList] = useState<ITemplate[]>(templates);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ITemplate>('name');
  

  const columns: Column[] = [
    { id: 'name', label: intl.formatMessage({ id: 'components.template' }) },
    { id: 'moduleList', label: intl.formatMessage({ id: 'components.modules' }), sortable: 'disable' },
    { id: 'description', label: intl.formatMessage({ id: 'components.description' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(getTemplates(cId));
    }
  }, [selectedAppCustomer]);

  useEffect(() => {
    setSearchTemplatesList(templates);   
  }, [templates]);

  useEffect(() => {
    setPage(0);
  },[searchTemplatesList]);

  const loadTemplates = () => {
    const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
    dispatch(getTemplates(cId));
  }

  const handleCloseDialog = () => {
    dispatch(setOpenViewTemplateDialog(false));
    dispatch(setOpenDeleteConfirmationDialog(false));
    dispatch(setSelectedTemplate({}));
    dispatch(setSelectedTemplateModules([]));
  }
  const handleAddUpdateCloseDialog = () => {
    dispatch(deleteTemplateConfigChangeDetails(templateConfigChangeDetail.templateConfigChangeDetailsId));
    dispatch(setOpenAddUpdateTemplateDialog(false));
    dispatch(setSelectedTemplate({}));
    dispatch(setSelectedTemplateModules([]));
    dispatch(setTemplateConfigChangeDetail({}));
  }

  const onViewTemplateDetails = (data: ITemplate) => {
    dispatch(setOpenViewTemplateDialog(true));
    dispatch(setSelectedTemplate(data));
    dispatch(setEditTemplate(false));
  }

  const addTemplate = () => {
    dispatch(setOpenAddUpdateTemplateDialog(true));
    dispatch(setEditTemplate(false));
  }

  const onUpdateTemplate = (data: ITemplate) => {
    dispatch(setSelectedTemplate(data));
    dispatch(setOpenAddUpdateTemplateDialog(true));
    dispatch(setEditTemplate(true));
  }

  const onDeleteTemplate = (data: ITemplate) => {
    dispatch(setOpenDeleteConfirmationDialog(true));
    dispatch(setSelectedTemplate(data));
  }

  const deleteTemplatePermanently = () => {
    dispatch(deleteTemplate(selectedTemplate));
  }

  const actionTemplateTemplates = (rowData: ITemplate) => {
    return <span data-testid="t-actions" className="list-action">
      <Link role="button" data-testid="view-t" aria-label="view-t" component="button" variant="body2" onClick={() => onViewTemplateDetails(rowData)} > <VisibilityIcon /> </Link>
      {!rowData.isSystemGenerated &&
        <Link role="button" data-testid="update-t" component="button" variant="body2" onClick={() => onUpdateTemplate(rowData)} > <EditIcon /> </Link>
      }
      <Link role="button" data-testid="delete-t" component="button" variant="body2" onClick={() => onDeleteTemplate(rowData)} > <ClearIcon /> </Link>
    </span>;
  }

  const modulesListTemplates = (rowData: ITemplate) => {
    return rowData.viewTemplateModules.map(item => item.moduleName).join(", ");
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchTemplatesList(onSearchInTable(templates, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof ITemplate,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div data-testid="templates">
        <Box>
          <TableContainer component={Paper}>
            <div className="table-head-links">
              <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadTemplates()} > <CachedIcon /> </Link></div>
              <div data-testid="add-new-btn" className="add-link"><Link component="button" role="button" aria-label="add-t" variant="body1" underline="none" onClick={() => addTemplate()} ><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.template" /> <AddIcon className="add-icon" /> </Link></div>
            </div>
            <div data-testid="searchbox" style={{ padding: "10px" }}>
              <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
            </div>
            <Table sx={{ minWidth: 650 }} data-testid="template-table" aria-label="templates table">
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
              />
              <TableBody>
                {stableSort(searchTemplatesList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow data-testid="t-node" key={row.templateId} >
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{modulesListTemplates(row)}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{actionTemplateTemplates(row)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    data-testid="pagination-control"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={columns.length} 
                    count={searchTemplatesList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </div>

      {/*start of add/update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleAddUpdateCloseDialog}
        aria-labelledby="dialog-title"
        open={openAddUpdateTemplateDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleAddUpdateCloseDialog}>
          {editTemplate ?
            <><FormattedMessage id="components.update" /> <FormattedMessage id="components.template" /></>
            : <><FormattedMessage id="components.addNew" />  <FormattedMessage id="components.template" /></>
          }
        </BootstrapDialogTitle>
        <DialogContent>
          <AddUpdateTemplateForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of add/update dialog */}

      {/*start of view details dialog */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewTemplateDialog}
        data-testid="view-t-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedTemplate && Object.keys(selectedTemplate).length > 0 &&
            <>
              <p><FormattedMessage id="components.template" />: <span style={{ fontWeight: 'bold' }}>{selectedTemplate.name}</span></p>
              <p><FormattedMessage id="components.customer" />: <span style={{ fontWeight: 'bold' }}>{selectedTemplate.customerName}</span></p>
              <p><FormattedMessage id="components.description" />: <span style={{ fontWeight: 'bold' }}>{selectedTemplate.description}</span></p>
              <FormattedMessage id="components.modules" />:
              <TableContainer component={Paper} sx={{ maxHeight: 240 }}>
                <Table stickyHeader data-testid="module-table" aria-label="module table">
                  <TableHead>
                    <TableRow>
                      <TableCell><FormattedMessage id="components.module" /></TableCell>
                      <TableCell><FormattedMessage id="components.code" /></TableCell>
                      <TableCell><FormattedMessage id="components.application" /></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedTemplate.viewTemplateModules.map((row: any) => {
                      return (
                        <TableRow
                          data-testid="module-node"
                          key={row.moduleId}
                          hover
                        >
                          <TableCell>{row.moduleName}</TableCell>
                          <TableCell>{row.code}</TableCell>
                          <TableCell>{row.applicationName}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

      {/*Start of delete confirmation dialog */}
      <DeleteConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleCancel={handleCloseDialog}
        title={<FormattedMessage id="components.wantToDelete" />}
        message={<span><FormattedMessage id="components.thisWillDelete" /> <FormattedMessage id="components.template" /> <FormattedMessage id="components.permanently" /></span>}
        onConfirm={deleteTemplatePermanently}
      />
      {/*end of delete confirmation dialog */}
    </>
  );
};
