import { dataAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPurchaseOrder } from "../../../shared/interfaces/purchase-order.interface";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setOpenAddPurchaseOrderDialog, setOpenDeleteConfirmationDialog, setOpenDuplicateOrderDialog, setSelectedPurchaseOrder } from "../../slices/purchase-orders";

export const getPurchaseOrders = createAsyncThunk(
  "purchaseOrder/getPurchaseOrderByCustomerId",
  async (customerId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`PurchaseOrder/GetPurchaseOrderByCustomerId?CustomerId=` + customerId);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addPurchaseOrder = createAsyncThunk(
  "purchaseOrder/addPurchaseOrder",
  async (requestData: IPurchaseOrder, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`PurchaseOrder/AddPurchaseOrder`, requestData);
      dispatch(setOpenAddPurchaseOrderDialog(false));
      dispatch(getPurchaseOrders(requestData.customerId));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.orderCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const duplicatePurchaseOrder = createAsyncThunk(
  "purchaseOrder/duplicatePurchaseOrder",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`PurchaseOrder/DuplicatePurchaseOrder`, requestData);
      dispatch(setOpenDuplicateOrderDialog(false));
      dispatch(getPurchaseOrders(requestData.customerId));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.orderCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deletePurchaseOrder = createAsyncThunk(
  "purchaseOrder/deletePurchaseOrder",
  async (requestData: IPurchaseOrder, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`PurchaseOrder/DeletePurchaseOrder?PurchaseOrderId=` + requestData.purchaseOrderId);
      dispatch(setSelectedPurchaseOrder({}));
      dispatch(setOpenDeleteConfirmationDialog(false));
      dispatch(getPurchaseOrders(requestData.customerId));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.orderDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
