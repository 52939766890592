import { ReactElement } from "react";
import { styled } from "@mui/material/styles";
import { DrawerHeader } from "./drawer-header.component";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import { Footer } from "./footer.component";

const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface Props {
  children: any;
}
export const MainComponent = ({ children }: Props): ReactElement<Props> => {
  const isSidebarOpen: boolean = useAppSelector(
    (state: RootState) => state.app.isSidebarOpen
  );

  return (
    <Main open={isSidebarOpen}>
      <DrawerHeader />
      {children}
      <Footer />
    </Main>
  );
};
