import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import { IUserLicense } from "../../shared/interfaces/user-license.interface";
import { onSearchInTable } from "../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { getUserLicenses } from "../../store/thunks/license-users/license-users-thunks";
import {
  setSelectedUserLicense,
  setOpenViewUserLicenseDialog,
  setUserLicenses
} from "../../store/slices/license-users"
import { TablePaginationActions } from "../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";
import { getUsers } from "../../store/thunks/user-management/user-management-thunks";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const ViewUserLicense = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const users = useAppSelector(state => state.userManagement.users);
  const userLicenses = useAppSelector(state => state.licenseUser.userLicenses);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const selectedUserLicense = useAppSelector(state => state.licenseUser.selectedUserLicense);
  const openViewUserLicenseDialog = useAppSelector(state => state.licenseUser.openViewUserLicenseDialog);
  const [searchUserLicensesList, setSearchUserLicensesList] = useState<IUserLicense[]>(userLicenses);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IUserLicense>('moduleName');
  const [selectedUser, setSelectedUser] = useState('');

  const columns: Column[] = [
    { id: 'moduleName', label: intl.formatMessage({ id: 'components.module' }) },
    { id: 'applicationName', label: intl.formatMessage({ id: 'components.application' }) },
    { id: 'orderNo', label: intl.formatMessage({ id: 'components.order#' }) },
    { id: 'packageName', label: intl.formatMessage({ id: 'components.package' }) },
    { id: 'license', label: intl.formatMessage({ id: 'components.license' }) },
    { id: 'licenseType', label: intl.formatMessage({ id: 'components.licenseType' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(getUsers(cId));
      dispatch(setUserLicenses([]));
    }
  }, [selectedAppCustomer]);

  useEffect(() => {
    setSearchUserLicensesList(userLicenses);   
  }, [userLicenses]);

  useEffect(() => {
    setPage(0);
  },[searchUserLicensesList]);

  const handleUserChange = (value: string) => {
    dispatch(setUserLicenses([]));
    setSelectedUser(value);
    dispatch(getUserLicenses(value));
  }

  const handleCloseDialog = () => {
    dispatch(setOpenViewUserLicenseDialog(false));
    dispatch(setSelectedUserLicense(null));
  }

  const onViewUserLicenseDetails = (data: IUserLicense) => {
    dispatch(setOpenViewUserLicenseDialog(true));
    dispatch(setSelectedUserLicense(data));
  }

  const actionTemplateUserLicenses = (rowData: IUserLicense) => {
    return <span data-testid="ul-actions" className="list-action">
      <Link role="button" data-testid="view-ul" aria-label="view-ul" component="button" variant="body2" onClick={() => onViewUserLicenseDetails(rowData)} > <VisibilityIcon /> </Link>
    </span>;
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchUserLicensesList(onSearchInTable(userLicenses, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IUserLicense,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div data-testid="user-licenses">
        <Box>
          <FormControl variant="standard" sx={{ m: 2, mt: 0, width: 350 }} data-testid="user-select-combobox">
            <InputLabel id="select-user"><FormattedMessage id="components.user" /></InputLabel>
            <Select
              labelId="select-user"
              role="combobox"
              aria-label="select user"
              inputProps={{ "data-testid": "select-user" }}
              value={selectedUser}
              onChange={(e) => handleUserChange(e.target.value)}
            >
              {users.map((row) => (
                <MenuItem key={row.userId} value={row.userId}>{row.userName}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedUser !== '' &&
            <TableContainer component={Paper}>
              <div data-testid="searchbox" style={{ padding: "10px" }}>
                <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
              </div>
              <Table sx={{ minWidth: 650 }} data-testid="user-license-table" aria-label="user licenses table">
                <SortableTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                />
                <TableBody>
                  {stableSort(searchUserLicensesList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow data-testid="ul-node" key={index} >
                          <TableCell>{row.moduleName}</TableCell>
                          <TableCell>{row.applicationName}</TableCell>
                          <TableCell>{row.orderNo}</TableCell>
                          <TableCell>{row.packageName}</TableCell>
                          <TableCell>{row.license}</TableCell>
                          <TableCell>{row.licenseType}</TableCell>
                          <TableCell>{actionTemplateUserLicenses(row)}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      data-testid="pagination-control"
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={columns.length} 
                      count={searchUserLicensesList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            }
            {selectedUser === '' &&
            <Box sx={{ p: 2, pb: 3 }}>
              <FormattedMessage id="components.selectUsertoviewLicenseDetails" />
            </Box>
            }
        </Box>
      </div>

      {/*start of view details dialog */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewUserLicenseDialog}
        data-testid="view-ul-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedUserLicense &&
            <>
              <p><FormattedMessage id="components.module" />: <span style={{ fontWeight: 'bold' }}>{selectedUserLicense.moduleName}</span></p>
              <p><FormattedMessage id="components.application" />: <span style={{ fontWeight: 'bold' }}>{selectedUserLicense.applicationName}</span></p>
              <p><FormattedMessage id="components.order#" />: <span style={{ fontWeight: 'bold' }}>{selectedUserLicense.orderNo}</span></p>
              <p><FormattedMessage id="components.package" />: <span style={{ fontWeight: 'bold' }}>{selectedUserLicense.packageName}</span></p>
              <p><FormattedMessage id="components.license" />: <span style={{ fontWeight: 'bold' }}>{selectedUserLicense.license}</span></p>
              <p><FormattedMessage id="components.licenseType" />: <span style={{ fontWeight: 'bold' }}>{selectedUserLicense.licenseType}</span></p>
              <p><FormattedMessage id="components.startDate" />: <span style={{ fontWeight: 'bold' }}>{selectedUserLicense.startDate}</span></p>
              <p><FormattedMessage id="components.endDate" />: <span style={{ fontWeight: 'bold' }}>{selectedUserLicense.endDate}</span></p>
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

    </>
  );
};
