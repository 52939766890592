import { dataAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IPackage } from "../../../shared/interfaces/package.interface";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setEditPackage, setOpenAddUpdatePackageDialog, setOpenDeleteConfirmationDialog, setSelectedPackage, setSelectedPackageModules, setInitialPackageModules } from "../../slices/packages";

export const getPackages = createAsyncThunk(
  "package/getPackages",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Package/GetPackages`);
      dispatch(setLoading(false));
      let finalData = result.data;
      finalData.map((item: any) => {
        item.license = item.externalLicensing ? 'Custom' : 'Standard';
      });
      return finalData;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getPackageWithModules = createAsyncThunk(
  "package/getPackageWithModules",
  async (requestData: IPackage, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Package/GetPackageWithModules?PackageId=` + requestData.packageId);
      dispatch(setLoading(false));
      return result.data.modules;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addPackage = createAsyncThunk(
  "package/addPackage",
  async (requestData: IPackage, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`Package/AddPackage`, requestData);
      dispatch(setOpenAddUpdatePackageDialog(false));
      dispatch(setSelectedPackageModules([]));
      dispatch(setInitialPackageModules([]));
      dispatch(getPackages());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.packageCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updatePackage = createAsyncThunk(
  "package/updatePackage",
  async (requestData: IPackage, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`Package/UpdatePackage`, requestData);
      dispatch(setSelectedPackage({}));
      dispatch(setOpenAddUpdatePackageDialog(false));
      dispatch(setEditPackage(false));
      dispatch(setSelectedPackageModules([]));
      dispatch(setInitialPackageModules([]));
      dispatch(getPackages());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.packageUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deletePackage = createAsyncThunk(
  "package/deletePackage",
  async (requestData: IPackage, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`Package/DeletePackage?PackageId=` + requestData.packageId);
      dispatch(setSelectedPackage({}));
      dispatch(setOpenDeleteConfirmationDialog(false));
      dispatch(getPackages());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.packageDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
