import { dataAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IOrderPackage } from "../../../shared/interfaces/order-package.interface";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setEditOrderPackage, setOpenAddUpdateOrderPackageDialog, setOpenDeleteConfirmationDialog, setOpenUpdateMultiRowOrderPackageDialog, setSelectedOrderPackage, setSelectedOrderPackages } from "../../slices/order-packages";
import moment from "moment";

export const getOrderPackages = createAsyncThunk(
  "orderPackage/getOrderPackageByPurchaseOrder",
  async (purchaseOrderId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`OrderPackage/GetOrderPackageByPurchaseOrder?PurchaseOrderId=`+ purchaseOrderId);
      dispatch(setLoading(false));
      let finalResult = result.data;
      finalResult.map((item: any) => {
        item.license = item.externalLicensing ? 'Custom' : 'Standard';
        item.startDate = item.startDate ? moment(item.startDate).format("MM/DD/YYYY, H:mm:ss") : "";
        item.endDate = item.endDate ? moment(item.endDate).format("MM/DD/YYYY, H:mm:ss") : "";
      });   
      return finalResult;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getOrderPackageMinLimit = createAsyncThunk(
  "orderPackage/getMinimumRequiredLicenseLimit",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`OrderPackage/GetMinimumRequiredLicenseLimit?OrderPackageId=` + requestData.orderPackageId);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addOrderPackage = createAsyncThunk(
  "orderPackage/addOrderPackage",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`OrderPackage/AddOrderPackage`, requestData);
      dispatch(setOpenAddUpdateOrderPackageDialog(false));
      dispatch(getOrderPackages(requestData.purchaseOrderId));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.packageCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateOrderPackage = createAsyncThunk(
  "orderPackage/updateOrderPackage",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`OrderPackage/UpdateOrderPackage`, requestData);
      dispatch(setSelectedOrderPackage({}));
      dispatch(setOpenAddUpdateOrderPackageDialog(false));
      dispatch(setEditOrderPackage(false));
      dispatch(getOrderPackages(requestData.purchaseOrderId));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.packageUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteOrderPackage = createAsyncThunk(
  "orderPackage/deleteOrderPackage",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`OrderPackage/DeleteOrderPackage?OrderPackageId=` + requestData.orderPackageId);
        dispatch(setSelectedOrderPackage({}));
        dispatch(setOpenDeleteConfirmationDialog(false));
        dispatch(getOrderPackages(requestData.purchaseOrderId));
        dispatch(setSnackbarAlert({ messageType: 'success', message: "components.packageDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateOrderPackages = createAsyncThunk(
  "orderPackage/updateOrderPackages",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`OrderPackage/UpdateOrderPackages`, requestData.orderPackages);
      dispatch(setSelectedOrderPackages([]));
      dispatch(setOpenUpdateMultiRowOrderPackageDialog(false));
      dispatch(getOrderPackages(requestData.purchaseOrderId));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.packagesUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
