import { ReactElement } from "react";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import { DrawerHeader } from "./drawer-header.component";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import { setSidebarClose } from "../../store/app-slice";
import GleasonLogo from "../../assets/images/gleason-logo.svg";
import { navLinks } from "./nav-links.config";
import { Role } from '../../shared/enums/role.enum';
import { NavLink } from 'react-router-dom';
import moment from "moment";
import MachineObjectTreeView from "./machine-object-tree-view.component";
const drawerWidth = 240;

export const SidebarDrawer = (): ReactElement => {
  const user = JSON.parse(String(sessionStorage.getItem("user")));
  const loggedInUser = useAppSelector((state) => state.profile.loggedInUser);
  
  const theme = useTheme();
  const isSidebarOpen: boolean = useAppSelector(
    (state: RootState) => state.app.isSidebarOpen
  );
  const dispatch = useAppDispatch();

  const handleDrawerClose = () => {
    dispatch(setSidebarClose());
  };
  return (
    <Drawer
      sx={styles.root}
      variant="persistent"
      anchor="left"
      open={isSidebarOpen}
    >
      <DrawerHeader className="drawer-header" sx={{ justifyContent: "space-between" }}>
        <img src={GleasonLogo} alt="logo" style={{ maxWidth: "150px" }} />
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <List>
        {navLinks.map((item, key) => {
            let found = false;
            user?.roles.forEach((role: any) => {
              if(item.allowedRoles){
                const arr = item.allowedRoles!.find(allowedRole => allowedRole === role )
                if(found) {return}; 
                found = (arr ? true : false);                               
              }
            })

            if(found){
              return (
                <NavLink key={`${item.path}__${key}`} to={item.path} style={{color:"inherit", textDecoration:"none"}}>
                {!user?.roles.includes(Role.PASSWORD_CHANGE_ONLY) && 
                loggedInUser?.termsAccepted &&
                !(loggedInUser?.trial && moment(loggedInUser?.trialExpiration).isBefore(moment())) &&
                  <ListItem disablePadding> 
                  {!item.child ?
                  <ListItemButton>
                  <ListItemIcon sx={{ minWidth: "40px" }}>
                    {item.menu_icon}
                  </ListItemIcon>
                  <ListItemText primary={item.menu_title} />
                </ListItemButton>
                :
                <MachineObjectTreeView/>
                }
                </ListItem>
                }
                </NavLink>
              )
            }
          })}
      </List>
    </Drawer>
  );
};

const styles: any = {
  root: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      boxSizing: "border-box",
      marginTop: "64px",
      borderTop: "0",
      height: "calc(100% - 64px)",
    },
    "& .drawer-header": {
      width: drawerWidth,
      backgroundColor: "primary.light",
      borderRight: "1px",
      borderBottom: "1px",
      zIndex: "1",
      position: "fixed",
      top: "0",
      left: "0",
      borderRightColor: "secondary.light",
      borderBottomColor: "secondary.light",
      borderRightStyle: "solid",
      borderBottomStyle: "solid"
    },
    "& .MuiTypography-root": {
      lineHeight: "1.1em"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.1em"
    },
    "& .MuiListItemButton-root": {
      paddingTop: "12px",
      paddingBottom: "12px"
    }
  },
};
