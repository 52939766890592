import { createSlice } from '@reduxjs/toolkit';
import { IEdgeDevice } from "../../shared/interfaces/edge-device.interface";
import { addEdgeDevice, deleteEdgeDevice, getEdgeDeviceDetails, getEdgeDevices, updateEdgeDevice } from '../thunks/edge-devices/edge-devices-thunks';

interface EdgeDeviceState {
    edgeDevices: IEdgeDevice[],
    selectedEdgeDevice: any,
    openViewEdgeDeviceDialog: boolean,
    openAddUpdateEdgeDeviceDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    editEdgeDevice: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: EdgeDeviceState = {
    edgeDevices: [],
    selectedEdgeDevice: null,
    openViewEdgeDeviceDialog: false,
    openAddUpdateEdgeDeviceDialog: false,
    openDeleteConfirmationDialog: false,
    editEdgeDevice: false,
    status: 'idle',
    error: undefined
}

export const edgeDevicesSlice = createSlice({    
    name: "edgeDevice",
    initialState,
    reducers: {
        setEdgeDevices: (state, action) => {
            state.edgeDevices = action.payload
        },
        setSelectedEdgeDevice: (state, action) => {
            state.selectedEdgeDevice = action.payload
        },
        setOpenViewEdgeDeviceDialog: (state, action) => {
            state.openViewEdgeDeviceDialog = action.payload
        },
        setOpenAddUpdateEdgeDeviceDialog: (state, action) => {
            state.openAddUpdateEdgeDeviceDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditEdgeDevice: (state, action) => {
            state.editEdgeDevice = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getEdgeDevices.fulfilled, (state, action) => {            
            state.edgeDevices = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getEdgeDevices.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getEdgeDevices.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getEdgeDeviceDetails.fulfilled, (state, action) => {            
            state.selectedEdgeDevice = action.payload;
        })
        .addCase(getEdgeDeviceDetails.pending, (state, action) => {
        })
        .addCase(getEdgeDeviceDetails.rejected, (state, action) => {
            state.error = action.error.message
        })
        .addCase(addEdgeDevice.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addEdgeDevice.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addEdgeDevice.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updateEdgeDevice.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(updateEdgeDevice.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateEdgeDevice.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deleteEdgeDevice.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deleteEdgeDevice.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteEdgeDevice.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setEdgeDevices,
    setSelectedEdgeDevice,
    setOpenViewEdgeDeviceDialog,
    setOpenAddUpdateEdgeDeviceDialog,
    setOpenDeleteConfirmationDialog,
    setEditEdgeDevice
} = edgeDevicesSlice.actions;
export default edgeDevicesSlice.reducer;
