import { createSlice } from '@reduxjs/toolkit';
import { IRegisteredApplication } from "../../shared/interfaces/registered-application.interface"
import { getRegisteredApplications } from '../thunks/registered-application/registered-applications-thunks';

interface RegisteredApplicationState {
    registeredApplications: IRegisteredApplication[],
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined,
}

const initialState: RegisteredApplicationState = {
    registeredApplications: [],
    status: 'idle',
    error: undefined,
}

export const registeredApplicationsSlice = createSlice({    
    name: "registeredApplication",
    initialState,
    reducers: {
        setRegisteredApplications: (state, action) => {
            state.registeredApplications = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getRegisteredApplications.fulfilled, (state, action) => {            
            state.registeredApplications = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getRegisteredApplications.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getRegisteredApplications.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setRegisteredApplications,
} = registeredApplicationsSlice.actions;
export default registeredApplicationsSlice.reducer;
