import { createAsyncThunk } from "@reduxjs/toolkit";
import { IModule } from "../../../shared/interfaces/module.interface";
import { dataAPI, dataSessionAPI } from "../../../shared/dataApi";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setEditModule, setModuleConfigSettingIsModified, setOpenAddUpdateModuleDialog, setOpenDeleteConfirmationDialog, setSelectedModule } from "../../slices/modules";

export const getModules = createAsyncThunk(
    "module/getModules",
    async (requestData, { getState, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const user = JSON.parse(String(sessionStorage.getItem("user")));
            const result = await dataAPI(user).get(`Module/GetModules`);
            dispatch(setLoading(false));
            let finalData = result.data;
            finalData.map((item: any) => {
                item.code = item.code ? item.code : '';
            });
            return finalData;
        } catch (error: any) {
            if (error.response.data.responseStatus.message) {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
                );
            }
            else {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.statusText })
                );
            }
            dispatch(setLoading(false));
            throw error;
        }
    }
);

export const addModule = createAsyncThunk(
    'module/addModule',
    async (requestData: any, { getState, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const user = JSON.parse(String(sessionStorage.getItem("user")));
            const result = await dataSessionAPI(user).post(`Module/AddModule`, requestData);
            dispatch(setOpenAddUpdateModuleDialog(false));
            dispatch(getModules());
            dispatch(setSnackbarAlert({ messageType: 'success', message: "components.moduleCreated" }));

            return result.data;
        } catch (error: any) {
            if (error.response.data.responseStatus.message) {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
                );
            }
            else {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.statusText })
                );
            }
            dispatch(setLoading(false));
            throw error;
        }
    }
);

export const updateModule = createAsyncThunk(
    'module/updateModule',
    async (requestData: any, { getState, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataSessionAPI(user).post(`Module/UpdateModule`, requestData);
            dispatch(setSelectedModule({}));
            dispatch(setOpenAddUpdateModuleDialog(false));
            dispatch(setEditModule(false));
            dispatch(setModuleConfigSettingIsModified(false));
            dispatch(getModules());
            dispatch(setSnackbarAlert({ messageType: 'success', message: "components.moduleUpdated" }));
            return result.data;
        } catch (error: any) {
            if (error.response.data.responseStatus.message) {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
                );
            }
            else {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.statusText })
                );
            }
            dispatch(setLoading(false));
            throw error;
        }
    }
);

export const deleteModule = createAsyncThunk(
    'module/deleteModule',
    async (requestData: IModule, { getState, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`Module/DeleteModule?ModuleId=` + requestData.moduleId);
            dispatch(setSelectedModule({}));
            dispatch(setOpenDeleteConfirmationDialog(false));
            dispatch(getModules());
            dispatch(setSnackbarAlert({ messageType: 'success', message: "components.moduleDeleted" }));
            return result.data;
        } catch (error: any) {
            if (error.response.data.responseStatus.message) {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
                );
            }
            else {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.statusText })
                );
            }
            dispatch(setLoading(false));
            throw error;
        }
    }
);

export const getModuleConfigSettingSchema = createAsyncThunk(
    "module/getModuleConfigSettingSchema",
    async (requestData, { getState, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Module/GetModuleConfigSettingSchema`);
            dispatch(setLoading(false));
            return result.data.configSettingSchema;
        } catch (error: any) {
            if (error.response.data.responseStatus.message) {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
                );
            }
            else {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.statusText })
                );
            }
            dispatch(setLoading(false));
            throw error;
        }
    }
);

export const checkIfModuleConfigSettingIsModified = createAsyncThunk(
    'module/checkIfModuleConfigSettingIsModified',
    async (requestData: any, { getState, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataSessionAPI(user).post(`Module/CheckIfModuleConfigSettingIsModified`, requestData);
            dispatch(setLoading(false));
            return result.data;
        } catch (error: any) {
            if (error.response.data.responseStatus.message) {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
                );
            }
            else {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.statusText })
                );
            }
            dispatch(setLoading(false));
            throw error;
        }
    }
);

export const getConfigurationSettingParameter = createAsyncThunk(
    "module/getConfigurationSettingParameter",
    async (requestData, { getState, dispatch }) => {
      try {
        dispatch(setLoading(true));
        const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Module/GetConfigurationSettingParameter`);
        dispatch(setLoading(false));
        return result.data;
      } catch (error: any) {
        if (error.response.data.responseStatus.message) {
          dispatch(
            setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
          );
        }
        else {
          dispatch(
            setSnackbarAlert({ messageType: "error", message: error.response.statusText })
          );
        }
        dispatch(setLoading(false));
        throw error;
      }
    }
  );

export const getStandardLicenseModule = createAsyncThunk(
    "module/getStandardLicenseModule",
    async (requestData, { getState, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Module/GetStandardLicenseModule`);
            dispatch(setLoading(false));
            return result.data;
        } catch (error: any) {
            if (error.response.data.responseStatus.message) {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
                );
            }
            else {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.statusText })
                );
            }
            dispatch(setLoading(false));
            throw error;
        }
    }
);

export const getDeviceLicensedModules = createAsyncThunk(
    "module/getDeviceLicensedModules",
    async (customerId: string, { getState, dispatch }) => {
        try {
            dispatch(setLoading(true));
            const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Module/GetDeviceLicensedModuleForCustomer?CustomerId=`+ customerId);
            dispatch(setLoading(false));
            let finalData = result.data;
            finalData.map((item: any) => {
                item.code = item.code ? item.code : '';
            });
            return finalData;
        } catch (error: any) {
            if (error.response.data.responseStatus.message) {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
                );
            }
            else {
                dispatch(
                    setSnackbarAlert({ messageType: "error", message: error.response.statusText })
                );
            }
            dispatch(setLoading(false));
            throw error;
        }
    }
);

