import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IAssetObject } from "../../shared/interfaces/asset-object.interface";
import { ComposeValidators, IpAddress, Required } from "../../shared/form-validations/form-validations";
import { addAssetObject, updateAssetObject } from "../../store/thunks/machine-management/machine-management-thunks";

export const AddUpdateAssetObjectForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedAssetObject = useAppSelector(state => state.machineManagement.selectedAssetObject);
  const editAssetObject = useAppSelector(state => state.machineManagement.editAssetObject);
    
  const onSubmit = (data: IAssetObject) => {
    if (editAssetObject) {
      dispatch(updateAssetObject(data));
    }
    else {
      dispatch(addAssetObject(data));
    }
  };

  return (
      <Form
        onSubmit={onSubmit}
        initialValues={selectedAssetObject}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit} data-testid="asset-objects-form">
            <Field name="name" validate={Required}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.assetObject" />}
                    variant="standard"
                    role="textbox"
                    aria-label="ao-name"
                    fullWidth
                    error={meta.error && meta.touched}
                    disabled={editAssetObject}
                    autoComplete='off'
                    sx={{ marginTop: '0px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>

            <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
              <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
                <FormattedMessage id="components.reset" />
              </Button>
              <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
                {editAssetObject ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
              </Button>
            </Box>

          </form>
        )}
      />
  );
};
