import { createSlice } from '@reduxjs/toolkit';
import { IEmailDomainBlocklist } from "../../shared/interfaces/email-domain-blocklist.interface"
import { addEmailDomainBlocklist, deleteEmailDomainBlocklist, getEmailDomainBlocklist} from '../thunks/email-domain-blocklist/email-domain-blocklist-thunks';

interface EmailDomainBlocklistState {
    emailDomainBlocklist: IEmailDomainBlocklist[],
    selectedEmailDomainBlocklist: any,
    openAddEmailDomainBlocklistDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: EmailDomainBlocklistState = {
    emailDomainBlocklist: [],
    selectedEmailDomainBlocklist: null,
    openAddEmailDomainBlocklistDialog: false,
    openDeleteConfirmationDialog: false,
    status: 'idle',
    error: undefined
}

export const emailDomainBlocklistSlice = createSlice({    
    name: "emailDomainBlocklist",
    initialState,
    reducers: {
        setEmailDomainBlocklists: (state, action) => {
            state.emailDomainBlocklist = action.payload
        },
        setSelectedEmailDomainBlocklist: (state, action) => {
            state.selectedEmailDomainBlocklist = action.payload
        },
        setOpenAddEmailDomainBlocklistDialog: (state, action) => {
            state.openAddEmailDomainBlocklistDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getEmailDomainBlocklist.fulfilled, (state, action) => {            
            state.emailDomainBlocklist = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getEmailDomainBlocklist.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getEmailDomainBlocklist.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addEmailDomainBlocklist.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addEmailDomainBlocklist.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addEmailDomainBlocklist.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deleteEmailDomainBlocklist.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deleteEmailDomainBlocklist.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteEmailDomainBlocklist.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setEmailDomainBlocklists,
    setSelectedEmailDomainBlocklist,
    setOpenAddEmailDomainBlocklistDialog,
    setOpenDeleteConfirmationDialog
} = emailDomainBlocklistSlice.actions;
export default emailDomainBlocklistSlice.reducer;
