import { ChangeEvent, Fragment, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableHead,
  Checkbox,
  TableSortLabel
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IOrderPackage } from "../../shared/interfaces/order-package.interface";
import { onSearchInTable } from "../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { DeleteConfirmationDialog } from "../../shared/dialog-components/delete-confirmation-dialog";
import { AddUpdateOrderPackageForm } from "./add-update-order-package-form.component";
import { deleteOrderPackage, getOrderPackages } from "../../store/thunks/order-packages/order-packages-thunks";
import {
  setSelectedOrderPackage,
  setOpenAddUpdateOrderPackageDialog,
  setOpenViewOrderPackageDialog,
  setOpenDeleteConfirmationDialog,
  setEditOrderPackage,
  setOrderPackages,
  setSelectedOrderPackages,
  setOpenUpdateMultiRowOrderPackageDialog
} from "../../store/slices/order-packages"
import { TablePaginationActions } from "../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";
import { getPurchaseOrders } from "../../store/thunks/purchase-orders/purchase-orders-thunks";
import { Role } from "../../shared/enums/role.enum";
import { visuallyHidden } from '@mui/utils';
import { UpdateMultipleOrderPackageForm } from "./update-multiple-package-form.component";

interface Column {
  id: keyof ColumnData,
  label: string,
  sortable?: string,
  width?: string
}

type Order = 'asc' | 'desc';

interface ColumnData {
  packageName: string;
  deploymentType: string;
  licenseType: string;
  userLimit: string;
  startDate: string;
  endDate: string;
  actions: any;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof ColumnData
  ) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const intl = useIntl();
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof ColumnData) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const columns: readonly Column[] = [
    { id: 'packageName', label: intl.formatMessage({ id: 'components.package' }) },
    { id: 'deploymentType', label: intl.formatMessage({ id: 'components.deploymentType' }) },
    { id: 'licenseType', label: intl.formatMessage({ id: 'components.licenseType' }) },
    { id: 'userLimit', label: intl.formatMessage({ id: 'components.userLimit' }) },
    { id: 'startDate', label: intl.formatMessage({ id: 'components.startDate' }) },
    { id: 'endDate', label: intl.formatMessage({ id: 'components.endDate' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            disabled={!loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN)}
            inputProps={{
              'aria-label': 'select all',
            }}
          />
        </TableCell>
        {columns.map((headCell) => (
          <Fragment key={headCell.id}>
            {headCell.sortable === 'disable' ?
              <TableCell width={headCell.width}>
                {headCell.label}
              </TableCell>
              :
              <TableCell
                width={headCell.width}
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

            }
          </Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const OrderPackages = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const orderPackages = useAppSelector(state => state.orderPackage.orderPackages);
  const selectedOrderPackage = useAppSelector(state => state.orderPackage.selectedOrderPackage);
  const selectedOrderPackages = useAppSelector(state => state.orderPackage.selectedOrderPackages);
  const openViewOrderPackageDialog = useAppSelector(state => state.orderPackage.openViewOrderPackageDialog);
  const openAddUpdateOrderPackageDialog = useAppSelector(state => state.orderPackage.openAddUpdateOrderPackageDialog);
  const openUpdateMultiRowOrderPackageDialog = useAppSelector(state => state.orderPackage.openUpdateMultiRowOrderPackageDialog);
  const openDeleteConfirmationDialog = useAppSelector(state => state.orderPackage.openDeleteConfirmationDialog);
  const editOrderPackage = useAppSelector(state => state.orderPackage.editOrderPackage);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const purchaseOrders = useAppSelector(state => state.purchaseOrder.purchaseOrders);
  const [searchOrderPackagesList, setSearchOrderPackagesList] = useState<IOrderPackage[]>(orderPackages);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IOrderPackage>('packageName');
  const [selectedOrder, setSelectedOrder] = useState('');

  useEffect(() => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(getPurchaseOrders(cId));
      dispatch(setOrderPackages([]));
      setSelectedOrder('');
    }
  }, [selectedAppCustomer]);

  useEffect(() => {
    setSearchOrderPackagesList(orderPackages);
  }, [orderPackages]);

  useEffect(() => {
    setPage(0);
  }, [searchOrderPackagesList]);

  const loadOrderPackages = () => {
    if (selectedOrder !== '') {
      dispatch(getOrderPackages(selectedOrder));
    }
  }

  const handleOrderChange = (value: string) => {
    dispatch(setOrderPackages([]));
    setSelectedOrder(value);
    dispatch(getOrderPackages(value));
  }

  const editMultipleOrderPackage = () => {
    dispatch(setOpenUpdateMultiRowOrderPackageDialog(true));
  }

  const handleCloseMultiRowDialog = () => {
    dispatch(setOpenUpdateMultiRowOrderPackageDialog(false));
    dispatch(setSelectedOrderPackages([]));
  }

  const handleCloseDialog = () => {
    dispatch(setOpenViewOrderPackageDialog(false));
    dispatch(setOpenAddUpdateOrderPackageDialog(false));
    dispatch(setOpenDeleteConfirmationDialog(false));
    dispatch(setSelectedOrderPackage({}));
  }

  const onViewOrderPackageDetails = (data: IOrderPackage) => {
    dispatch(setSelectedOrderPackages([]));
    dispatch(setOpenViewOrderPackageDialog(true));
    dispatch(setSelectedOrderPackage(data));
    dispatch(setEditOrderPackage(false));
  }

  const addOrderPackage = () => {
    dispatch(setSelectedOrderPackages([]));
    dispatch(setOpenAddUpdateOrderPackageDialog(true));
    dispatch(setEditOrderPackage(false));
  }

  const onUpdateOrderPackage = (data: IOrderPackage) => {
    dispatch(setSelectedOrderPackages([]));
    dispatch(setSelectedOrderPackage(data));
    dispatch(setOpenAddUpdateOrderPackageDialog(true));
    dispatch(setEditOrderPackage(true));
  }

  const onDeleteOrderPackage = (data: IOrderPackage) => {
    dispatch(setSelectedOrderPackages([]));
    dispatch(setOpenDeleteConfirmationDialog(true));
    dispatch(setSelectedOrderPackage(data));
  }

  const deleteOrderPackagePermanently = () => {
    dispatch(deleteOrderPackage(selectedOrderPackage));
  }

  const actionTemplateOrderPackages = (rowData: IOrderPackage) => {
    return <span data-testid="op-actions" className="list-action">
      <Link role="button" data-testid="view-op" aria-label="view-op" component="button" variant="body2" onClick={() => onViewOrderPackageDetails(rowData)} > <VisibilityIcon /> </Link>
      {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
        <>
          <Link role="button" data-testid="update-op" component="button" variant="body2" onClick={() => onUpdateOrderPackage(rowData)} > <EditIcon /> </Link>
          <Link role="button" data-testid="delete-op" component="button" variant="body2" onClick={() => onDeleteOrderPackage(rowData)} > <ClearIcon /> </Link>
        </>
      }
    </span>;
  }

  const isSelected = (rowData: any) => {
    return selectedOrderPackages.indexOf(rowData) !== -1;
  }

  const handleClick = (event: MouseEvent<unknown>, rowData: any) => {
    const selectedIndex = selectedOrderPackages.indexOf(rowData);
    let newSelected: readonly any[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedOrderPackages, rowData);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedOrderPackages.slice(1));
    } else if (selectedIndex === selectedOrderPackages.length - 1) {
      newSelected = newSelected.concat(selectedOrderPackages.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedOrderPackages.slice(0, selectedIndex),
        selectedOrderPackages.slice(selectedIndex + 1),
      );
    }

    dispatch(setSelectedOrderPackages(newSelected));
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // const newSelected = orderPackages.filter(item => item.licenseType === 'lease');
      dispatch(setSelectedOrderPackages(orderPackages));
      return;
    }
    dispatch(setSelectedOrderPackages([]));
  };

  const onSearchChangeHandler = (searched: string) => {
    setSearchOrderPackagesList(onSearchInTable(orderPackages, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IOrderPackage,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div data-testid="order-packages">
        <Box>
          <FormControl variant="standard" sx={{ m: 2, mt: 0, width: 350 }} data-testid="order-select-combobox">
            <InputLabel id="select-order"><FormattedMessage id="components.order" /></InputLabel>
            <Select
              labelId="select-order"
              role="combobox"
              aria-label="select order"
              inputProps={{ "data-testid": "select-order" }}
              value={selectedOrder}
              onChange={(e) => handleOrderChange(e.target.value)}
            >
              {purchaseOrders.map((row) => (
                <MenuItem key={row.purchaseOrderId} value={row.purchaseOrderId}>{row.orderNo}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedOrder !== '' ?
            <TableContainer component={Paper}>
              <div className="table-head-links">
                <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadOrderPackages()} > <CachedIcon /> </Link></div>
                {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
                  <div className="add-link" style={{ alignContent: "flex-end" }}>
                    <span data-testid="edit-multiple"><Link component="button" disabled={selectedOrderPackages.length === 0} role="button" aria-label="edit-multiple-op" variant="body1" underline="none" onClick={() => editMultipleOrderPackage()} > <FormattedMessage id="components.editMultipleRows" /> <EditIcon className="add-icon" /> </Link></span>
                    &nbsp;&nbsp;{" | "}&nbsp;&nbsp;
                    <span data-testid="add-new-btn"><Link component="button" role="button" aria-label="add-op" variant="body1" underline="none" onClick={() => addOrderPackage()} ><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.orderPackage" /> <AddIcon className="add-icon" /> </Link></span>
                  </div>
                }
              </div>
              <div data-testid="searchbox" style={{ padding: "10px" }}>
                <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
              </div>
              <Table sx={{ minWidth: 650 }} data-testid="order-package-table" aria-label="order packages table">

                <EnhancedTableHead
                  numSelected={selectedOrderPackages.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={searchOrderPackagesList.length}
                />
                <TableBody>
                  {stableSort(searchOrderPackagesList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => {
                      const isItemSelected: boolean | undefined = isSelected(row);
                      return (
                        <TableRow data-testid="op-node" key={row.orderPackageId} >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={row.licenseType === 'open' ? false : isItemSelected}
                              onClick={(event) => handleClick(event, row)}
                              disabled={(!loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN)) || row.licenseType === 'open'}
                              inputProps={{
                                'aria-labelledby': `op-checkbox-${index}`,
                              }}
                            />
                          </TableCell>
                          <TableCell>{row.packageName}</TableCell>
                          <TableCell>{row.deploymentType}</TableCell>
                          <TableCell>{row.licenseType}</TableCell>
                          <TableCell>{row.userLimit}</TableCell>
                          <TableCell>{row.startDate}</TableCell>
                          <TableCell>{row.endDate}</TableCell>
                          <TableCell>{actionTemplateOrderPackages(row)}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      data-testid="pagination-control"
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={7}
                      count={searchOrderPackagesList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            :
            <Box sx={{ p: 2, pb: 3 }}>
              <FormattedMessage id="components.pleaseSelectCO" />
            </Box>
          }
        </Box>
      </div>

      {/*start of add/update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openAddUpdateOrderPackageDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
          {editOrderPackage ?
            <><FormattedMessage id="components.update" /> <FormattedMessage id="components.orderPackage" /></>
            : <><FormattedMessage id="components.addNew" />  <FormattedMessage id="components.orderPackage" /></>
          }
        </BootstrapDialogTitle>
        <DialogContent>
          <AddUpdateOrderPackageForm selectedOrder={selectedOrder} />
        </DialogContent>
      </BootstrapDialog>
      {/*end of add/update dialog */}

      {/*start of view details dialog */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewOrderPackageDialog}
        data-testid="view-op-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedOrderPackage &&
            <>
              <p><FormattedMessage id="components.package" />: <span style={{ fontWeight: 'bold' }}>{selectedOrderPackage.packageName}</span></p>
              <p><FormattedMessage id="components.materialNumber" />: <span style={{ fontWeight: 'bold' }}>{selectedOrderPackage.materialNumber}</span></p>
              <p><FormattedMessage id="components.license" />: <span style={{ fontWeight: 'bold' }}>{selectedOrderPackage.license}</span></p>
              <p><FormattedMessage id="components.deploymentType" />: <span style={{ fontWeight: 'bold' }}>{selectedOrderPackage.deploymentType}</span></p>
              <p><FormattedMessage id="components.userLimit" />: <span style={{ fontWeight: 'bold' }}>{selectedOrderPackage.userLimit}</span></p>
              <p><FormattedMessage id="components.licenseType" />: <span style={{ fontWeight: 'bold' }}>{selectedOrderPackage.licenseType}</span></p>
              <p><FormattedMessage id="components.startDate" />: <span style={{ fontWeight: 'bold' }}>{selectedOrderPackage.startDate}</span></p>
              <p><FormattedMessage id="components.endDate" />: <span style={{ fontWeight: 'bold' }}>{selectedOrderPackage.endDate}</span></p>
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

      {/*Start of delete confirmation dialog */}
      <DeleteConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleCancel={handleCloseDialog}
        title={<FormattedMessage id="components.wantToDelete" />}
        message={<span><FormattedMessage id="components.thisWillDelete" /> <FormattedMessage id="components.orderPackage" /> <FormattedMessage id="components.permanently" /></span>}
        onConfirm={deleteOrderPackagePermanently}
      />
      {/*end of delete confirmation dialog */}

      {/*start of multi row update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleCloseMultiRowDialog}
        aria-labelledby="dialog-title"
        open={openUpdateMultiRowOrderPackageDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseMultiRowDialog}>
          <FormattedMessage id="components.updateMultipleOrderPackages" />
        </BootstrapDialogTitle>
        <DialogContent>
          <UpdateMultipleOrderPackageForm selectedOrder={selectedOrder} />
        </DialogContent>
      </BootstrapDialog>
      {/*end of multi row update dialog */}

    </>
  );
};
