import { ReactElement } from "react";
import Button from "@mui/material/Button";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import GleasonLogo from "../../assets/images/gleason-logo.svg";
import { Box, CssBaseline } from "@mui/material";

export const NotFound = (): ReactElement => {
  return (
    <Box sx={{ paddingBottom: '15px' }} >
      <CssBaseline />
      <AppBar position="static" >
        <Toolbar>
          <Link to="/">
            <img src={GleasonLogo} alt="gleason-logo" width="110" height="35" />
          </Link>
        </Toolbar>
      </AppBar>
      <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
        <h2>Oops.. </h2>
        <h1>404</h1>
        <h2><FormattedMessage id="components.sorryPageNotFound" /></h2>
        <Button variant="contained" href="/signin" ><FormattedMessage id="components.goToHomePage" /></Button>
      </Box>
    </Box>
  );
}
