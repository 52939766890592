import { dataAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IDataCenter } from "../../../shared/interfaces/data-center.interface";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setEditDataCenter, setOpenAddUpdateDataCenterDialog, setOpenDeleteConfirmationDialog, setSelectedDataCenter } from "../../slices/data-centers";

export const getDataCenters = createAsyncThunk(
  "data-centers/getDataCenters",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`DataCenters/GetDataCenters`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addDataCenter = createAsyncThunk(
  "data-centers/addDataCenter",
  async (requestData: IDataCenter, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`DataCenters/AddDataCenter`, requestData);
      dispatch(setOpenAddUpdateDataCenterDialog(false));
      dispatch(getDataCenters());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.dataCenterCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateDataCenter = createAsyncThunk(
  "data-centers/updateDataCenter",
  async (requestData: IDataCenter, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`DataCenters/UpdateDataCenter`, requestData);
      dispatch(setSelectedDataCenter(null));
      dispatch(setOpenAddUpdateDataCenterDialog(false));
      dispatch(setEditDataCenter(false));
      dispatch(getDataCenters());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.dataCenterUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteDataCenter = createAsyncThunk(
  "data-centers/deleteDataCenter",
  async (requestData: IDataCenter, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`DataCenters/DeleteDataCenter?DataCenterId=` + requestData.dataCenterId);
        dispatch(setSelectedDataCenter(null));
        dispatch(setOpenDeleteConfirmationDialog(false));
        dispatch(getDataCenters());
        dispatch(setSnackbarAlert({ messageType: 'success', message: "components.dataCenterDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
