import { createSlice } from '@reduxjs/toolkit';
import { ICustomer } from "../../shared/interfaces/customer.interface"
import { addCustomer, deleteCustomer, getCustomerWithTenantTypeDetail, updateCustomer } from '../thunks/customers/customers-thunks';

interface CustomerState {
    customers: ICustomer[],
    selectedCustomer: any,
    openViewCustomerDialog: boolean,
    openAddUpdateCustomerDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    openTenantDetailsDialog: boolean,
    editCustomer: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: CustomerState = {
    customers: [],
    selectedCustomer: null,
    openViewCustomerDialog: false,
    openAddUpdateCustomerDialog: false,
    openDeleteConfirmationDialog: false,
    openTenantDetailsDialog: false,
    editCustomer: false,
    status: 'idle',
    error: undefined
}

export const customersSlice = createSlice({    
    name: "customer",
    initialState,
    reducers: {
        setCustomerWithTenantDetails: (state, action) => {
            state.customers = action.payload
        },
        setSelectedCustomer: (state, action) => {
            state.selectedCustomer = action.payload
        },
        setOpenViewCustomerDialog: (state, action) => {
            state.openViewCustomerDialog = action.payload
        },
        setOpenAddUpdateCustomerDialog: (state, action) => {
            state.openAddUpdateCustomerDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditCustomer: (state, action) => {
            state.editCustomer = action.payload
        },
        setOpenTenantDetailsDialog: (state, action) => {
            state.openTenantDetailsDialog = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getCustomerWithTenantTypeDetail.fulfilled, (state, action) => {            
            state.customers = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getCustomerWithTenantTypeDetail.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getCustomerWithTenantTypeDetail.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addCustomer.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addCustomer.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addCustomer.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updateCustomer.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(updateCustomer.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateCustomer.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deleteCustomer.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deleteCustomer.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteCustomer.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setCustomerWithTenantDetails,
    setSelectedCustomer,
    setOpenViewCustomerDialog,
    setOpenAddUpdateCustomerDialog,
    setOpenDeleteConfirmationDialog,
    setOpenTenantDetailsDialog,
    setEditCustomer
} = customersSlice.actions;
export default customersSlice.reducer;
