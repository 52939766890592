import { createSlice } from '@reduxjs/toolkit';
import { addClientObjectRegistryEntry, getAssetObjectsForSubType, getClientObjectRegistryTreeNode, removeClientObjectRegistryEntry } from '../thunks/machine-management/machine-management-thunks';

interface MachineManagementState {
    filteredMachineMenus: any[],
    machineMenus: any[],
    assetObjects: any[],
    selectedObject: any,
    selectedParent: any,
    selectedSubType: string,
    selectedAssetObject: any,
    openViewAssetObjectDialog: boolean,
    openAddUpdateAssetObjectDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    editAssetObject: boolean,
    addNewItem: boolean,
    subtypeGrid: boolean,
    inputValue: string,
    updateNameModal: boolean,
    selectedNode: any,
    clientObjectRegistryTreeNode: any,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: MachineManagementState = {
    filteredMachineMenus: [],
    machineMenus:[],
    assetObjects: [],
    selectedObject: null,
    selectedParent: null,    
    selectedSubType: '',
    selectedAssetObject: null,
    openViewAssetObjectDialog: false,
    openAddUpdateAssetObjectDialog: false,
    openDeleteConfirmationDialog: false,
    editAssetObject: false,
    addNewItem: false,
    subtypeGrid: false,
    inputValue: '',
    updateNameModal: false,
    selectedNode: null,
    clientObjectRegistryTreeNode: null,
    status: 'idle',
    error: undefined
}

export const machineManagementSlice = createSlice({    
    name: "machineManagement",
    initialState,
    reducers: {
        setClientObjectRegistryTreeNode: (state, action) => {
            state.clientObjectRegistryTreeNode = action.payload
        },
        setFilteredMachineMenus: (state, action) => {
            state.filteredMachineMenus = action.payload
        },
        setMachineMenus: (state, action) => {
            state.machineMenus = action.payload
        },
        setSelectedObject: (state, action) => {
            state.selectedObject = action.payload
        },
        setSelectedParent: (state, action) => {
            state.selectedParent = action.payload
        },
        setSelectedSubType: (state, action) => {
            state.selectedSubType = action.payload
        },
        setAddNewItem: (state, action) => {
            state.addNewItem = action.payload
        },
        setSubtypeGrid: (state, action) => {
            state.subtypeGrid = action.payload
        },
        setAssetObjects: (state, action) => {
            state.assetObjects = action.payload
        },
        setInputValue: (state, action) => {
            state.inputValue = action.payload
        },
        setUpdateNameModal: (state, action) => {
            state.updateNameModal = action.payload
        },
        setSelectedNode: (state, action) => {
            state.selectedNode = action.payload
        },
        setSelectedAssetObject: (state, action) => {
            state.selectedAssetObject = action.payload
        },
        setOpenViewAssetObjectDialog: (state, action) => {
            state.openViewAssetObjectDialog = action.payload
        },
        setOpenAddUpdateAssetObjectDialog: (state, action) => {
            state.openAddUpdateAssetObjectDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditAssetObject: (state, action) => {
            state.editAssetObject = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getClientObjectRegistryTreeNode.fulfilled, (state, action) => {            
            state.clientObjectRegistryTreeNode = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getClientObjectRegistryTreeNode.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getClientObjectRegistryTreeNode.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addClientObjectRegistryEntry.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addClientObjectRegistryEntry.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addClientObjectRegistryEntry.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(removeClientObjectRegistryEntry.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(removeClientObjectRegistryEntry.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(removeClientObjectRegistryEntry.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getAssetObjectsForSubType.fulfilled, (state, action) => {            
            state.assetObjects = action.payload;
        })
        .addCase(getAssetObjectsForSubType.pending, (state, action) => {
        })
        .addCase(getAssetObjectsForSubType.rejected, (state, action) => {
            state.error = action.error.message
        });
    }
})

export const { 
    setClientObjectRegistryTreeNode,
    setFilteredMachineMenus,
    setMachineMenus,
    setSelectedObject,
    setAddNewItem,
    setSelectedParent,
    setSelectedSubType,
    setSubtypeGrid,
    setAssetObjects,
    setInputValue,
    setUpdateNameModal,
    setSelectedNode,
    setEditAssetObject,
    setOpenAddUpdateAssetObjectDialog,
    setOpenDeleteConfirmationDialog,
    setOpenViewAssetObjectDialog,
    setSelectedAssetObject
} = machineManagementSlice.actions;
export default machineManagementSlice.reducer;
