import { dataAPI, sessionAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setDarkTheme, setLightTheme, setLoading, setSnackbarAlert, switchLocale } from "../../app-slice";
import { setEditUser, setOpenAddUpdateUserDialog, setOpenDeleteConfirmationDialog, setSelectedUser } from "../../slices/user-management";
import { setAuthLink, setChangePasswordCompleted, setInvalidResetPasswordToken, setPasswordResetSucess, setRedirectToRoot, setResetPasswordEmailSent, setUpdateProfileDialog, setUserCredentials } from "../../slices/profile";
import { Locale } from "../../../shared/enums/locale-enum";
import { Role } from "../../../shared/enums/role.enum";

export const getUsers = createAsyncThunk(
  "userManagement/getUsers",
  async (customerId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`UserManagement/GetUsers?CustomerId=` + customerId);
      dispatch(setLoading(false));      
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getVendorUsers = createAsyncThunk(
  "userManagement/getVendorUsers",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`UserManagement/GetVendorUsers`);
      dispatch(setLoading(false));      
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addUser = createAsyncThunk(
  "userManagement/addUser",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`UserManagement/AddUser`, requestData);
      dispatch(setOpenAddUpdateUserDialog(false));
      if(requestData.customerId){
        dispatch(getUsers(requestData.customerId));
      }
      else{
        dispatch(getVendorUsers());
      } 
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.userCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateUser = createAsyncThunk(
  "userManagement/updateUser",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`UserManagement/UpdateUser`, requestData);
      dispatch(setSelectedUser({}));
      dispatch(setOpenAddUpdateUserDialog(false));
      dispatch(setEditUser(false));
      if(requestData.customerId){
        dispatch(getUsers(requestData.customerId));
      }
      else{
        dispatch(getVendorUsers());
      }      
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.userUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteUser = createAsyncThunk(
  "userManagement/deleteUser",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`UserManagement/DeleteUser?UserId=` + requestData.userId);
      dispatch(setSelectedUser(null));
      dispatch(setOpenDeleteConfirmationDialog(false));
      if(requestData.customerId){
        dispatch(getUsers(requestData.customerId));
      }
      else{
        dispatch(getVendorUsers());
      } 
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.userDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getRoles = createAsyncThunk(
  "userManagement/getRoles",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`UserManagement/GetRoles`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const login = createAsyncThunk(
  "userManagement/login",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await sessionAPI.post(`UserManagement/AuthenticateCredentials`, requestData);
      dispatch(setAuthLink(true));
      dispatch(setUserCredentials({
        username: '',
        password: '',
        showPassword: false
      }));
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const authenticateUrlToken = createAsyncThunk(
  "userManagement/authenticateUrlToken",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await sessionAPI.post(`UserManagement/AuthenticateUrlToken`, requestData);
      sessionStorage.setItem("user", JSON.stringify(result.data));
      dispatch(setLoading(false));
      getLoggedInUser()
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getLoggedInUser = createAsyncThunk(
  "userManagement/getLoggedInUser",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`UserManagement/GetLoggedInUser`);
      const lang = result.data.language === "GERMAN" ? Locale.GERMAN : Locale.ENGLISH;
      dispatch(switchLocale(lang));
      if (result.data.darkMode) {
        dispatch(setDarkTheme());
      } else {
        dispatch(setLightTheme());
      }
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "userManagement/updateUserProfile",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`UserManagement/UpdateUserProfile`, requestData);
      dispatch(setUpdateProfileDialog(false));
      dispatch(getLoggedInUser());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.updatedSuccessfully" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const changePassword = createAsyncThunk(
  "userManagement/changePassword",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`UserManagement/ChangePassword`, requestData);
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.passwordUpdatedSuccessfully" }));
      dispatch(setChangePasswordCompleted(true));
      const newUserRoles: string[] = user.roles.filter((role: string) => role !== Role.PASSWORD_CHANGE_ONLY);
      const updatedUser = {
        ...user, roles: newUserRoles
      }
      sessionStorage.setItem("user", JSON.stringify(updatedUser));
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const changeExistingPassword = createAsyncThunk(
  "userManagement/changeExistingPassword",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`UserManagement/ChangeExistingPassword`, requestData);
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.passwordUpdatedSuccessfullyReSign" }));
      dispatch(setRedirectToRoot(true));
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const requestResetPassword = createAsyncThunk(
  "userManagement/requestResetPassword",
  async (emailAddress: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await sessionAPI.get(`UserManagement/RequestResetPassword?EmailAddress=${emailAddress}`);
      dispatch(setResetPasswordEmailSent(true));
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const validatePasswordResetRecoveryToken = createAsyncThunk(
  "userManagement/validatePasswordResetRecoveryToken",
  async (recoveryToken: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await sessionAPI.get(`UserManagement/ValidatePasswordResetRecoveryToken?RecoveryToken=${recoveryToken}`);
      
      if (result.data.succeeded === false) {
        dispatch(setInvalidResetPasswordToken(true));
        dispatch(setSnackbarAlert({ messageType: 'error', message: "Invalid password reset URL or URL is expired !!!" }));
      } else {
        dispatch(setInvalidResetPasswordToken(false));
      }
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setInvalidResetPasswordToken(true));
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const passwordUpdate = createAsyncThunk(
  "userManagement/passwordUpdate",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const result = await sessionAPI.post(`UserManagement/PasswordUpdate`, requestData);
      dispatch(setSnackbarAlert({ messageType: 'success', message: "Password Updated Successfully! Please Re-Sign In Again With New Credentials" }));
      dispatch(setPasswordResetSucess(true));
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const acceptTermsAndCondition = createAsyncThunk(
  "userManagement/acceptTermsAndCondition",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`UserManagement/AcceptTermsAndCondition`, requestData);
      dispatch(setRedirectToRoot(true));
      dispatch(getLoggedInUser());
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
