import { configureStore } from '@reduxjs/toolkit'
import { ThunkDispatch, AnyAction, Store } from '@reduxjs/toolkit';
import appReducer from "./app-slice";
import profileReducer from "./slices/profile";
import dataCentersReducer from './slices/data-centers';
import customersReducer from './slices/customers';
import tenantDetailReducer from './slices/tenant-detail';
import emailDomainBlocklistReducer from './slices/email-domain-blocklist';
import vendorsReducer from './slices/vendors';
import applicationsReducer from './slices/applications';
import modulesReducer from "./slices/modules";
import notificationsReducer from "./slices/notifications";
import packagesReducer from "./slices/packages";
import orderPackagesReducer from "./slices/order-packages";
import purchaseOrdersReducer from "./slices/purchase-orders";
import userManagementReducer from "./slices/user-management";
import licenseUsersReducer from "./slices/license-users";
import hardwaresReducer from "./slices/hardwares";
import iotPlatformsReducer from "./slices/iot-platforms";
import templatesReducer from "./slices/templates";
import edgeDevicesReducer from "./slices/edge-devices";
import provisionEdgeDevicesReducer from "./slices/provision-edge-devices";
import grantPermissionReducer from "./slices/grant-permission";
import registeredApplicationsReducer from "./slices/registered-applications";
import toolingObjectsReducer from "./slices/tooling-objects";
import machineManagementReducer from "./slices/machine-management";

export const store = configureStore({
  reducer: {
    app: appReducer,
    profile: profileReducer,
    dataCenter: dataCentersReducer,
    customer: customersReducer,
    tenantDetail: tenantDetailReducer,
    emailDomainBlocklist: emailDomainBlocklistReducer,
    vendor: vendorsReducer,
    application: applicationsReducer,
    module: modulesReducer,
    notification: notificationsReducer,
    package: packagesReducer,
    purchaseOrder: purchaseOrdersReducer,
    orderPackage: orderPackagesReducer,
    userManagement: userManagementReducer,
    licenseUser: licenseUsersReducer,
    hardware: hardwaresReducer,
    iotPlatform: iotPlatformsReducer,
    template: templatesReducer,
    edgeDevice: edgeDevicesReducer,
    provisionEdgeDevice: provisionEdgeDevicesReducer,
    grantPermission: grantPermissionReducer,
    registeredApplication: registeredApplicationsReducer,
    toolingObject: toolingObjectsReducer,
    machineManagement: machineManagementReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppDispatch = typeof store.dispatch

export type AppStore = Omit<Store<RootState, AnyAction>, "dispatch"> & {
  dispatch: AppThunkDispatch;
};