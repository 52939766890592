import { dataAPI, dataSessionAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IIotPlatform } from "../../../shared/interfaces/iot-platform.interface";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setEditIotPlatform, setIotPlatformMicroServices, setOpenAddUpdateIotPlatformDialog, setOpenDeleteConfirmationDialog, setSelectedIotPlatform } from "../../slices/iot-platforms";

export const getIotPlatforms = createAsyncThunk(
  "iot-platforms/getIotPlatforms",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`IoTplatform/GetAllIoTplatforms`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addIotPlatform = createAsyncThunk(
  "iot-platforms/addIotPlatform",
  async (requestData: IIotPlatform, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataSessionAPI(user).post(`IoTplatform/AddIoTplatform`, requestData);
      dispatch(setOpenAddUpdateIotPlatformDialog(false));
      dispatch(getIotPlatforms());
      dispatch(setIotPlatformMicroServices([]));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.iotPlatformCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateIotPlatform = createAsyncThunk(
  "iot-platforms/updateIotPlatform",
  async (requestData: IIotPlatform, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataSessionAPI(user).post(`IoTplatform/UpdateIoTplatform`, requestData);
      dispatch(setSelectedIotPlatform(null));
      dispatch(setOpenAddUpdateIotPlatformDialog(false));
      dispatch(setEditIotPlatform(false));
      dispatch(setIotPlatformMicroServices([]));
      dispatch(getIotPlatforms());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.iotPlatformUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteIotPlatform = createAsyncThunk(
  "iot-platforms/deleteIotPlatform",
  async (requestData: IIotPlatform, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`IoTplatform/DeleteIoTplatform?IotPlatformId=` + requestData.ioTplatformId);
      dispatch(setSelectedIotPlatform(null));
      dispatch(setOpenDeleteConfirmationDialog(false));
      dispatch(getIotPlatforms());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.iotPlatformDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getIotPlatformMicroServices = createAsyncThunk(
  "iot-platforms/getIotPlatformMicroServices",
  async (url: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`IoTplatform/GetIoTplatformMicroServices?Url=` + url);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }

      dispatch(setLoading(false));
      throw error;
    }
  }
);
