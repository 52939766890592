import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Fragment, MouseEvent } from 'react';

type Order = 'asc' | 'desc';

type Column = {
  id: string,
  label: string,
  sortable?: string,
  width?: string
}

interface SortableTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  columns: Column[]
}

export const SortableTableHead = (props: SortableTableProps) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: any) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {props.columns.map((headCell: Column) => (
          <Fragment key={headCell.id}>
            {headCell.sortable === 'disable' ?
              <TableCell width={headCell.width}>
                  {headCell.label}
              </TableCell>
              :
              <TableCell
                width={headCell.width}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            }
          </Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}