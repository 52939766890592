import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IPurchaseOrder } from "../../shared/interfaces/purchase-order.interface";
import { addPurchaseOrder } from "../../store/thunks/purchase-orders/purchase-orders-thunks";
import { Required } from "../../shared/form-validations/form-validations";

export const AddPurchaseOrderForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedPurchaseOrder = useAppSelector(state => state.purchaseOrder.selectedPurchaseOrder);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
    
  const onSubmit = (data: IPurchaseOrder) => {
    const values = {
      purchaseOrderId: "",
      orderNo: data.orderNo,
      customerId: selectedAppCustomer ? selectedAppCustomer?.customerId : '',
      customerPo: data.customerPo
    }
      dispatch(addPurchaseOrder(values));
  };

  return (
      <Form
        onSubmit={onSubmit}
        initialValues={selectedPurchaseOrder}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit} data-testid="purchase-orders-form">
            <TextField
              value={selectedAppCustomer?.name}
              label={<FormattedMessage id="components.customer" />}
              variant="standard"
              role="textbox"
              aria-label="po-customer"
              fullWidth
              autoComplete='off'
              sx={{ marginTop: '0px' }}
              disabled={true}
            />
            <Field name="orderNo" validate={Required}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.order#" />}
                    variant="standard"
                    role="textbox"
                    aria-label="po-orderno"
                    fullWidth
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '15px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Field name="customerPo" validate={Required}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.customerPO" />}
                    variant="standard"
                    fullWidth
                    role="textbox"
                    aria-label="po-customerpo"
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '15px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
              <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
                <FormattedMessage id="components.reset" />
              </Button>
              <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
                <FormattedMessage id="components.add" />
              </Button>
            </Box>

          </form>
        )}
      />
  );
};
