import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { dataAPI, dataSessionAPI } from "../../../shared/dataApi";
import { ICustomer } from "../../../shared/interfaces/customer.interface";
import { ITenantDetails } from "../../../shared/interfaces/tenant-details.interface";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setOpenTenantDetailsDialog, setSelectedCustomer } from "../../slices/customers";
import { setTenantDetails } from "../../slices/tenant-detail";

export const getTenantType = createAsyncThunk(
  'tenantDetail/getTenantType',
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`TenantDetail/GetTenantType`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getTenantDetails = createAsyncThunk(
  'tenantDetail/getTenantDetailForCustomer',
  async (requestData: ICustomer, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`TenantDetail/GetTenantDetailForCustomer?CustomerId=` + requestData.customerId);
      dispatch(setLoading(false));
      let finalResult = result.data;
      finalResult.name = requestData.name;
      finalResult.tenantVmDeploymentDtTm = finalResult.tenantVmDeploymentDtTm ? moment(finalResult.tenantVmDeploymentDtTm).format("MM/DD/YYYY, H:mm:ss") : "";
      return finalResult;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getTenantVmAllowedParameters = createAsyncThunk(
  'tenantDetail/getTenantVmAllowedParameters',
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`TenantDetail/GetTenantVmAllowedParameters`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getVmSizeDetails = createAsyncThunk(
  'tenantDetail/GetVmSizeDetail',
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`TenantDetail/GetVmSizeDetail?Location=` + requestData.location + `&StorageAccountTypeId=` + requestData.storageAccountTypeId);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const saveTenantDetails = createAsyncThunk(
  'tenantDetail/saveTenantDetails',
  async (requestData: ITenantDetails, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`TenantDetail/SaveTenantDetails`, requestData);
      dispatch(setLoading(false));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.tenantDetailsSaved" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deployTenantVm = createAsyncThunk(
  'tenantDetail/deployTenantVm',
  async (requestData: ITenantDetails, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataSessionAPI(user).post(`TenantDetail/DeployTenantVm`, requestData);
      dispatch(setOpenTenantDetailsDialog(false));
      dispatch(setTenantDetails({}));    
      dispatch(setSelectedCustomer(null));
      dispatch(setLoading(false));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.vmDeployment" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);