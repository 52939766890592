import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICustomer } from "../../../shared/interfaces/customer.interface";
import { dataAPI } from "../../../shared/dataApi";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setEditCustomer, setOpenAddUpdateCustomerDialog, setOpenDeleteConfirmationDialog, setSelectedCustomer } from "../../slices/customers";

export const getCustomerWithTenantTypeDetail = createAsyncThunk(
  "customer/getCustomerWithTenantTypeDetail",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Customer/GetCustomerWithTenantTypeDetail`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addCustomer = createAsyncThunk(
  'customer/addCustomer',
  async (requestData: ICustomer, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`Customer/AddCustomer`, requestData);
      dispatch(setOpenAddUpdateCustomerDialog(false));
      dispatch(getCustomerWithTenantTypeDetail());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.customerCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateCustomer = createAsyncThunk(
  'customer/updateCustomer',
  async (requestData: ICustomer, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`Customer/UpdateCustomer`, requestData);
      dispatch(setSelectedCustomer(null));
      dispatch(setOpenAddUpdateCustomerDialog(false));
      dispatch(setEditCustomer(false));
      dispatch(getCustomerWithTenantTypeDetail());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.customerUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  'customer/deleteCustomer',
  async (requestData: ICustomer, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`Customer/DeleteCustomer?CustomerId=` + requestData.customerId);
      dispatch(setSelectedCustomer(null));
      dispatch(setOpenDeleteConfirmationDialog(false));
      dispatch(getCustomerWithTenantTypeDetail());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.customerDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
