import { Box, Grid, useTheme } from "@mui/material";
import { FormattedMessage } from "react-intl";
import PeopleIcon from '@mui/icons-material/People';
import AppsIcon from '@mui/icons-material/Apps';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import WorkIcon from '@mui/icons-material/Work';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import { getModules } from "../../store/thunks/modules/modules-thunks";
import { getUsers } from "../../store/thunks/user-management/user-management-thunks";
import { getApplications } from "../../store/thunks/applications/applications-thunks";
import { getPackages } from "../../store/thunks/packages/packages-thunks";

export const Dashboard = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const users = useAppSelector(state => state.userManagement.users);
  const applications = useAppSelector(state => state.application.applications);
  const applicationStatus = useAppSelector(state => state.application.status);
  const modules = useAppSelector(state => state.module.modules);
  const moduleStatus = useAppSelector(state => state.module.status);
  const packages = useAppSelector(state => state.package.packages);
  const packageStatus = useAppSelector(state => state.package.status);

  useEffect(() => {
    dispatch(getUsers(''));
  }, []);

  useEffect(() => {
    if(applicationStatus === 'idle' || applicationStatus === 'failed'){
      dispatch(getApplications());
    }    
  }, []);

  useEffect(() => {
    if(moduleStatus === 'idle' || moduleStatus === 'failed'){
      dispatch(getModules());
    }    
  }, []);

  useEffect(() => {
    if(packageStatus === 'idle' || packageStatus === 'failed'){
      dispatch(getPackages());
    }    
  }, []);

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="dashboard">
        <div className="page-header">
          <h2><FormattedMessage id="components.dashboard" /></h2>
        </div>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} md={3} >
            <Box boxShadow={3} sx={{ borderRadius: 2, padding: '30px 20px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', backgroundColor: theme.palette.secondary.main }}>
              <div style={{ width: 'calc(100% - 60px)' }}>
                <div style={{ fontSize: '20px', fontWeight: 300, lineHeight: '1.5em' }}><FormattedMessage id="components.users" /></div>
                <div style={{ fontSize: '30px', fontWeight: 400, lineHeight: '1.5em' }}>{users.length}</div>
              </div>
              <div style={{ width: '60px' }}>
                <PeopleIcon sx={{ fontSize: '60px', color:theme.palette.secondary.light }} />
              </div>
            </Box>
          </Grid>
          <Grid item xs={6} md={3} >
            <Box boxShadow={3} sx={{ borderRadius: 2, padding: '30px 20px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', backgroundColor: theme.palette.secondary.main }}>
              <div style={{ width: 'calc(100% - 60px)' }}>
                <div style={{ fontSize: '20px', fontWeight: 300, lineHeight: '1.5em' }}><FormattedMessage id="components.applications" /></div>
                <div style={{ fontSize: '30px', fontWeight: 400, lineHeight: '1.5em' }}>{applications.length}</div>
              </div>
              <div style={{ width: '60px' }}>
                <AppsIcon sx={{ fontSize: '60px', color:theme.palette.secondary.light }} />
              </div>
            </Box>
          </Grid>
          <Grid item xs={6} md={3} >
            <Box boxShadow={3} sx={{ borderRadius: 2, padding: '30px 20px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', backgroundColor: theme.palette.secondary.main }}>
              <div style={{ width: 'calc(100% - 60px)' }}>
                <div style={{ fontSize: '20px', fontWeight: 300, lineHeight: '1.5em' }}><FormattedMessage id="components.modules" /></div>
                <div style={{ fontSize: '30px', fontWeight: 400, lineHeight: '1.5em' }}>{modules.length}</div>
              </div>
              <div style={{ width: '60px' }}>
                <ViewModuleIcon sx={{ fontSize: '60px', color:theme.palette.secondary.light }} />
              </div>
            </Box>
          </Grid>
          <Grid item xs={6} md={3} >
            <Box boxShadow={3} sx={{ borderRadius: 2, padding: '30px 20px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', backgroundColor: theme.palette.secondary.main }}>
              <div style={{ width: 'calc(100% - 60px)' }}>
                <div style={{ fontSize: '20px', fontWeight: 300, lineHeight: '1.5em' }}><FormattedMessage id="components.packages" /></div>
                <div style={{ fontSize: '30px', fontWeight: 400, lineHeight: '1.5em' }}>{packages.length}</div>
              </div>
              <div style={{ width: '60px' }}>
                <WorkIcon sx={{ fontSize: '60px', color:theme.palette.secondary.light }} />
              </div>
            </Box>
          </Grid>

        </Grid>
      </div>
    </>
  );
};
