import { ReactElement, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box, FormControl, RadioGroup, FormControlLabel, FormLabel, Radio, Checkbox } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IVendor } from "../../shared/interfaces/vendor.interface";
import { addVendor, updateVendor } from "../../store/thunks/vendors/vendors-thunks";
import { Required } from "../../shared/form-validations/form-validations";

export const AddUpdateVendorForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedVendor = useAppSelector(state => state.vendor.selectedVendor);
  const editVendor = useAppSelector(state => state.vendor.editVendor);

  const onSubmit = (data: IVendor) => {
    if (editVendor) {
      dispatch(updateVendor(data));
    }
    else {
      dispatch(addVendor(data));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={selectedVendor}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} data-testid="vendor-form">
          
          <Field name="name" validate={Required}>
            {({ input, meta }) => (
              <>
                <TextField
                  {...input}
                  label={<FormattedMessage id="components.vendor" />}
                  variant="standard"
                  fullWidth
                  role="textbox"
                  error={meta.error && meta.touched}
                  autoComplete='off'
                  sx={{ marginTop: '0px' }}
                  disabled={editVendor}
                />
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </>
            )}
          </Field>

          <Field name="vendorCode" validate={Required}>
            {({ input, meta }) => (
              <>
                <TextField
                  {...input}
                  label={<FormattedMessage id="components.vendorCode" />}
                  variant="standard"
                  fullWidth
                  role="textbox"
                  error={meta.error && meta.touched}
                  autoComplete='off'
                  sx={{ marginTop: '15px' }}
                  disabled={editVendor}
                />
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </>
            )}
          </Field>

          <Field name="description" validate={Required}>
            {({ input, meta }) => (
              <>
                <TextField
                  {...input}
                  label={<FormattedMessage id="components.description" />}
                  variant="standard"
                  fullWidth
                  role="textbox"
                  multiline
                  maxRows={3}
                  aria-label="vendor-description"
                  error={meta.error && meta.touched}
                  autoComplete='off'
                  sx={{ marginTop: '15px' }}
                />
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </>
            )}
          </Field>

          <Field name="vendorType" validate={Required}>
            {({ input, meta }) => (
              <FormControl variant="standard" sx={{ marginTop: '15px' }}>
                <FormLabel id="vendorType" error={meta.error && meta.touched}><FormattedMessage id="components.vendorType" /></FormLabel>
                <RadioGroup {...input} row aria-labelledby="vendor-type-label" name="vendorType" >
                  <FormControlLabel value="Software" control={<Radio />} label={<FormattedMessage id="components.software" />} />
                  <FormControlLabel value="Hardware" control={<Radio />} label={<FormattedMessage id="components.hardware" />} />
                  <FormControlLabel disabled value="Machine" control={<Radio />} label={<FormattedMessage id="components.machine" />} />
                  <FormControlLabel disabled value="Tool" control={<Radio />} label={<FormattedMessage id="components.tool" />} />
                </RadioGroup>
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </FormControl>
            )}
          </Field>

          {values.vendorType === "Software" &&
            <Field name="externalLicensing" >
              {({ input }) => (
                <FormControlLabel sx={{ display: 'block' }}
                  control={<Checkbox {...input} checked={values.externalLicensing} />}
                  label={<FormattedMessage id="components.customLicensing" />}
                />
              )}
            </Field>
          }

          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }} data-testid="reset-button">
              <FormattedMessage id="components.reset" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button">
              {editVendor ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
            </Button>
          </Box>

        </form>
      )}
    />
  );
};
