import { dataAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setOpenSendEmailDialog, setSelectedOrder } from "../../slices/license-users";

export const getAssignedLicenses = createAsyncThunk(
  "licenseUser/getAssignedLicenses",
  async (purchaseOrderId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`LicenseUser/GetAssignedLicenses?PurchaseOrderId=`+ purchaseOrderId);
      dispatch(setLoading(false));
      let finalData = result.data;
      finalData.map((item: any) => {
        item.license = item.externalLicensing ? 'Custom': 'Standard';
      });
      return finalData;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateAssignedLicenses = createAsyncThunk(
  'licenseUser/updateAssignedLicenses',
  async (requestData: any, { getState, dispatch }) => {
      try {
          dispatch(setLoading(true));
          const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`LicenseUser/UpdateAssignedLicenses`, requestData);
          dispatch(setLoading(false));
          dispatch(setSelectedOrder(null));
          dispatch(setSnackbarAlert({ messageType: 'success', message: "components.licenseUpdatedSuccessfully" }));
          return result.data;
      } catch (error: any) {
          if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
          dispatch(setLoading(false));
          throw error;
      }
  }
);


export const getUserLicenses = createAsyncThunk(
  "licenseUser/getUserLicenses",
  async (userId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`LicenseUser/GetUserLicenses?UserId=`+ userId);
      dispatch(setLoading(false));
      let finalData = result.data;
      finalData.map((item: any) => {
        item.license = item.isStandardLicense ? 'Standard' : 'Custom';
      });
      return finalData;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const pushLicenses = createAsyncThunk(
  "licenseUser/pushLicenses",
  async (customerId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`LicenseUser/PushLicenses?CustomerId=`+ customerId);
      dispatch(setLoading(false));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.licenseUpdatedSuccessfully" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const generateCustomerLicense = createAsyncThunk(
  "licenseUser/generateCustomerLicense",
  async (customerId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`LicenseUser/GenerateCustomerLicense?CustomerId=`+ customerId);
      dispatch(setLoading(false));
      const link = document.createElement('a');
      link.download = result.data.customerName + ' - License.json';
      link.href = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(result.data, null, "\t")
      )}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const generateUserLicense = createAsyncThunk(
  "licenseUser/generateUserLicense",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`LicenseUser/GenerateUserLicense?CustomerId=`+ requestData.customerId +`&UserId=`+ requestData.userId);
      dispatch(setLoading(false));
      const link = document.createElement('a');      
      link.download = result.data.email + ' - License.json';
      link.href = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(result.data, null, "\t")
      )}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const sendLicense = createAsyncThunk(
  "licenseUser/sendLicense",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`LicenseUser/SendLicense?CustomerId=`+ requestData.customerId +`&EmailAddress=`+ requestData.emailAddress);
      dispatch(setLoading(false));
      dispatch(setOpenSendEmailDialog(false));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.licensefileSentToEmail" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);