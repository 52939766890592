import { createSlice } from '@reduxjs/toolkit';
import { IRole } from '../../shared/interfaces/role.interface';
import { IUser } from "../../shared/interfaces/user.interface"
import { addUser, deleteUser, getRoles, getUsers, getVendorUsers, updateUser } from '../thunks/user-management/user-management-thunks';

interface UserManagementState {
    users: IUser[],
    vendorUsers: any[],
    roles: IRole[],
    selectedUser: any,
    openViewUserDialog: boolean,
    openAddUpdateUserDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    editUser: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    rolesStatus: 'idle' | 'loading' | 'succeeded' | 'failed',
    vendorUserStatus: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: UserManagementState = {
    users: [],
    vendorUsers: [],
    roles: [],
    selectedUser: null,
    openViewUserDialog: false,
    openAddUpdateUserDialog: false,
    openDeleteConfirmationDialog: false,
    editUser: false,
    status: 'idle',
    rolesStatus: 'idle',
    vendorUserStatus: 'idle',
    error: undefined
}

export const userManagementSlice = createSlice({    
    name: "user",
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload
        },
        setVendorUsers: (state, action) => {
            state.vendorUsers = action.payload
        },
        setRoles: (state, action) => {
            state.roles = action.payload
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload
        },
        setOpenViewUserDialog: (state, action) => {
            state.openViewUserDialog = action.payload
        },
        setOpenAddUpdateUserDialog: (state, action) => {
            state.openAddUpdateUserDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditUser: (state, action) => {
            state.editUser = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getUsers.fulfilled, (state, action) => {            
            state.users = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getUsers.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getUsers.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getVendorUsers.fulfilled, (state, action) => {            
            state.vendorUsers = action.payload;
            state.vendorUserStatus = 'succeeded'
        })
        .addCase(getVendorUsers.pending, (state, action) => {
            state.vendorUserStatus = 'loading'
        })
        .addCase(getVendorUsers.rejected, (state, action) => {
            state.vendorUserStatus = 'failed'
            state.error = action.error.message
        })
        .addCase(addUser.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addUser.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addUser.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updateUser.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(updateUser.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateUser.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deleteUser.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deleteUser.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteUser.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getRoles.fulfilled, (state, action) => {            
            state.roles = action.payload;
            state.rolesStatus = 'succeeded'
        })
        .addCase(getRoles.pending, (state, action) => {
            state.rolesStatus = 'loading'
        })
        .addCase(getRoles.rejected, (state, action) => {
            state.rolesStatus = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setUsers,
    setVendorUsers,
    setSelectedUser,
    setOpenViewUserDialog,
    setOpenAddUpdateUserDialog,
    setOpenDeleteConfirmationDialog,
    setEditUser,
    setRoles
} = userManagementSlice.actions;
export default userManagementSlice.reducer;
