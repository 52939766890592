import { MouseEvent, ReactElement, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import AccountCircle from "@mui/icons-material/AccountCircle";
import BlockIcon from '@mui/icons-material/Block';
import Avatar from '@mui/material/Avatar';
import Logout from '@mui/icons-material/Logout';
import PasswordIcon from '@mui/icons-material/Password';
import NotificationsIcon from "@mui/icons-material/Notifications";
import TranslateIcon from '@mui/icons-material/Translate';
import { FormattedMessage } from "react-intl";
import EnglishIcon from "../../assets/images/english-icon.png";
import GermanIcon from "../../assets/images/german-icon.png";
import { Locale } from "../../shared/enums/locale-enum";
import { ThemeType } from "../../shared/enums/theme-enum";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setLightTheme, setDarkTheme, setSelectedAppCustomer, setSidebarOpen, setSnackbarAlert, switchLocale } from "../../store/app-slice";
import { RootState } from "../../store/store";
import { resetProfile, setNotifications } from "../../store/slices/profile";
import { getCustomerWithTenantTypeDetail } from "../../store/thunks/customers/customers-thunks";
import { ICustomer } from "../../shared/interfaces/customer.interface";
import { Divider, Link, ListItemIcon, useTheme } from "@mui/material";
import moment from "moment";
import { getLoggedInUser, updateUserProfile } from "../../store/thunks/user-management/user-management-thunks";
import { markNotificationAsRead } from "../../store/thunks/notifications/notifications-thunks";
import { getUniqueListBy } from "../../shared/methods/unique-list";
import { Role } from "../../shared/enums/role.enum";

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const ApplicationBar = (): ReactElement => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector((state) => state.profile.loggedInUser);
  const notifications = useAppSelector(state => state.profile.notifications);
  const profile = useAppSelector((state) => state.profile.profile);
  const roles = useAppSelector((state) => state.profile.roles);
  const user = useAppSelector(state => state.profile.loggedInUser);
  const userStatus = useAppSelector((state) => state.profile.status);
  const customers = useAppSelector(state => state.customer.customers);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(
    null
  );
  const [anchorLanguageMenuEl, setAnchorLanguageMenuEl] = useState(null);
  const [anchorNotificationEl, setAnchorNotificationEl] = useState(null);
  const [bellNotifications, setBellNotifications] = useState<any[]>([]);

  useEffect(() => {
    const uniqueNotifications = getUniqueListBy(notifications, "notificationId");
    setBellNotifications(uniqueNotifications);
  }, [notifications]);

  useEffect(() => {
    if (userStatus === 'idle' || userStatus === 'failed') {
      dispatch(getLoggedInUser());
    }
  }, []);

  const currentTheme: string = useAppSelector(
    (state: RootState) => state.app.currentTheme
  );

  const icon =
    currentTheme === ThemeType.dark ? (
      <LightModeIcon data-testid="light-theme-icon" />
    ) : (
      <DarkModeIcon data-testid="dark-theme-icon" />
    );

  const isSidebarOpen: boolean = useAppSelector(
    (state: RootState) => state.app.isSidebarOpen
  );

  const open = Boolean(anchorLanguageMenuEl);
  const handleLanguageMenuClick = (event: any) => {
    setAnchorLanguageMenuEl(event.currentTarget);
  };
  const handleLanguageMenuClose = (locale: Locale) => {
    setAnchorLanguageMenuEl(null);
  };

  const handleLanguageChangeClose = (locale: Locale) => {
    let finalData: any = {
      userId: user?.userId,
      firstName: user?.firstName,
      lastName: user?.lastName,
      darkMode: user?.darkMode,
    };

    const lang = locale === "de-DE" ? "GERMAN" : "ENGLISH";
    dispatch(switchLocale(locale));
    dispatch(updateUserProfile({ ...finalData, language: lang }));
    setAnchorLanguageMenuEl(null);
  };
  const handleDrawerOpen = () => {
    dispatch(setSidebarOpen());
  };

  const changeTheme = () => {
    let finalData: any = {
      userId: user?.userId,
      firstName: user?.firstName,
      lastName: user?.lastName,
      language: user?.language
    };

    if (!user) { return }
    if (currentTheme === ThemeType.dark) {
      dispatch(setLightTheme());
      dispatch(updateUserProfile({ ...finalData, darkMode: false }));
    } else {
      dispatch(setDarkTheme());
      dispatch(updateUserProfile({ ...finalData, darkMode: true }));
    }
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logOut = () => {
    sessionStorage.clear();
    dispatch(resetProfile());
    navigate("/");
  }

  const navigateToProfile = () => {
    navigate("/user-profile");
  }

  const navigateToChangePassword = () => {
    navigate("/change-password");
  }

  const navigateToEmailDomainBlocklist = () => {
    navigate("/email-domain-blocklist");
  }

  const markAsReadNotification = () => {
    if (bellNotifications.length === 0) {
      return;
    }
    let notificationIds: string[] = [];
    bellNotifications.forEach(item => {
      notificationIds = [...notificationIds, item.notificationId];
    })
    const finalData = {
      userId: user?.userId,
      notificationIds: notificationIds
    }
    dispatch(markNotificationAsRead(finalData));
  }

  const handleOpenNotification = (event: any) => {
    setAnchorNotificationEl(event.currentTarget);
    if (event.currentTarget) {
      markAsReadNotification();
    }
  };

  const handleCloseNotification = () => {
    setAnchorNotificationEl(null);
    dispatch(setNotifications([]));
  }

  let initialized = false;

  useEffect(() => {
    if (loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) && !initialized) {
      initialized = true
      loadCustomers();
    }
  }, [loggedInUser]);

  const loadCustomers = () => {
    dispatch(getCustomerWithTenantTypeDetail());
  }

  useEffect(() => {
    if (user && customers.length > 0) {
      const customer = user && customers.find((obj: ICustomer) => {
        return obj.customerId === user?.customerId;
      });
      dispatch(setSelectedAppCustomer(customer));
    }
    if (user && customers.length === 0) {
      const customer = {
        customerId: user.customerId,
        name: user.customerName,
        companyCode: user.companyCode
      }
      dispatch(setSelectedAppCustomer(customer));
    }
  }, [customers, user]);

  const onChangeCustomer = (value: ICustomer | null) => {
    if (value) {
      const customer = customers.find((obj: ICustomer) => {
        return obj.customerId === value.customerId;
      });
      dispatch(setSelectedAppCustomer(customer));
    }
  }

  const customerProps = {
    options: customers,
    getOptionLabel: (option: ICustomer) => option.name,
  };


  return (
    <AppBar color="primary" position="fixed" open={isSidebarOpen}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        <Toolbar sx={{ justifyContent: "flex-start" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            data-testid="drawer-open-btn"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              color: "#808080",
              mr: 2,
              ...(isSidebarOpen && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {(loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) && selectedAppCustomer?.customerId) && !roles.includes(Role.PASSWORD_CHANGE_ONLY) &&
            <Autocomplete
              sx={{ width: 300 }}
              {...customerProps}
              defaultValue={selectedAppCustomer}
              onChange={(event: any, newValue: ICustomer | null) => {
                onChangeCustomer(newValue);
              }}
              id="change-customer"
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={<FormattedMessage id="components.customer" />}
                  variant="standard"
                />
              )}
            />
          }
          {(((!loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN)) || roles.includes(Role.PASSWORD_CHANGE_ONLY)) && !loggedInUser?.vendorName) &&
            <TextField
              value={loggedInUser ? loggedInUser?.customerName : ""}
              label={<FormattedMessage id="components.customer" />}
              variant="standard"
              role="textbox"
              aria-label="user-customer"
              fullWidth
              disabled
              autoFocus
            />
          }
          { loggedInUser?.vendorName &&
            <TextField
              value={loggedInUser ? loggedInUser?.vendorName : ""}
              label={<FormattedMessage id="components.vendor" />}
              variant="standard"
              role="textbox"
              aria-label="user-vendor"
              fullWidth
              disabled
              autoFocus
            />
          }
        </Toolbar>

        <Toolbar
          className="appbar-right-links"
          sx={{ justifyContent: "flex-end" }}
        >
          {user?.trial &&
            <span style={{ color: '#333' }}><FormattedMessage id="components.userTrialExpiresOn" />: <span style={{ fontWeight: 'bold', marginRight: '20px', color: '#fd5456' }}>{moment(user?.trialExpiration).format("MM/DD/YYYY, H:mm:ss")}</span></span>
          }
          <IconButton
            size="large"
            aria-label="new notifications"
            color="inherit"
            sx={{ marginRight: "10px" }}
            onClick={handleOpenNotification}
          >
            <Tooltip title={<FormattedMessage id="components.notifications" />}>
              <Badge badgeContent={bellNotifications.length} color="error">
                <NotificationsIcon />
              </Badge>
            </Tooltip>
          </IconButton>

          <Menu
            anchorEl={anchorNotificationEl}
            open={Boolean(anchorNotificationEl)}
            onClose={handleCloseNotification}
            sx={{ mt: "45px" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                width: 320,
                maxHeight: 350,
                paddingTop: 0
              }
            }}
            MenuListProps={{
              style: {
                paddingTop: 0
              }
            }}
          >

            <div
              style={{
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
                padding: "15px",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <FormattedMessage id="components.recentNotifications" />
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  navigate("/view-notifications"); handleCloseNotification()
                }}
              > <FormattedMessage id="components.viewAll" />
              </Link>
            </div>
            <Divider />
            {bellNotifications.map((item: any) => (
              <MenuItem key={item.notificationId} >
                <div style={{ whiteSpace: "break-spaces", margin: "0 0 10px 0" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }} >
                    <h5 style={{ padding: "0", margin: "0 0 2px 0" }}>{item.actionName}</h5>
                    <span style={{ opacity: "0.5", fontSize: "9px", paddingTop: "3px" }}>
                      {moment(item.notificationDtTm).fromNow()}
                    </span>
                  </div>
                  <span style={{ fontSize: "13px", opacity: "0.5" }}>{item.messageText}</span>
                </div>
              </MenuItem>
            ))}
          </Menu>

          <IconButton
            edge="end"
            color="inherit"
            aria-label="mode"
            onClick={changeTheme}
            sx={{ marginRight: "10px" }}
            data-testid="theme-switch-btn"
          >
            <Tooltip title={<FormattedMessage id="components.theme" />}>
              {icon}
            </Tooltip>
          </IconButton>

          <IconButton
            onClick={handleLanguageMenuClick}
            sx={{ marginRight: "10px" }}
          >
            <Tooltip title={<FormattedMessage id="components.language" />}>
              <TranslateIcon />
            </Tooltip>
          </IconButton>

          <Menu
            anchorEl={anchorLanguageMenuEl}
            open={open}
            onClose={handleLanguageMenuClose}
            sx={{ mt: "45px" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              style: {
                width: 240
              }
            }}
          >
            <div style={{
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.main,
              padding: "15px",
            }}>
              <FormattedMessage id="components.languages" />
            </div>
            <Divider />
            <MenuItem onClick={() => handleLanguageChangeClose(Locale.ENGLISH)}>
              <ListItemIcon>
                <img
                  src={EnglishIcon}
                  alt="english-icon"
                  width="26"
                  style={{ marginRight: "10px" }}
                />
              </ListItemIcon>
              English
            </MenuItem>
            <MenuItem onClick={() => handleLanguageChangeClose(Locale.GERMAN)}>
              <ListItemIcon>
                <img
                  src={GermanIcon}
                  alt="german-icon"
                  width="26"
                  style={{ marginRight: "10px" }}
                />
              </ListItemIcon>
              Deutsch
            </MenuItem>
          </Menu>

          <IconButton onClick={handleOpenUserMenu}>
            <Tooltip title={<FormattedMessage id="components.settings" />}>
              <Avatar aria-label="settings">
                {profile?.displayName?.charAt(0)}
              </Avatar>
            </Tooltip>
          </IconButton>

          <Menu
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            sx={{ mt: "45px" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={() => { navigateToProfile(); handleCloseUserMenu() }}>
              <ListItemIcon>
                <AccountCircle fontSize="small" />
              </ListItemIcon>
              <FormattedMessage id="components.profile" />
            </MenuItem>
            <MenuItem onClick={() => { navigateToChangePassword(); handleCloseUserMenu() }}>
              <ListItemIcon>
                <PasswordIcon fontSize="small" />
              </ListItemIcon>
              <FormattedMessage id="components.changePassword" />
            </MenuItem>
            <Divider />
            {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) && !roles.includes(Role.PASSWORD_CHANGE_ONLY) &&
              <MenuItem onClick={() => { navigateToEmailDomainBlocklist(); handleCloseUserMenu() }}>
                <ListItemIcon>
                  <BlockIcon fontSize="small" />
                </ListItemIcon>
                <FormattedMessage id="components.emailDomainBlocklist" />
              </MenuItem>
            }
            <MenuItem onClick={() => { logOut(); handleCloseUserMenu() }}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              <FormattedMessage id="components.logOut" />
            </MenuItem>
          </Menu>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
