import { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box, Select, InputLabel, MenuItem, FormControl, Grid, Link, FormControlLabel, Checkbox, Input, FormLabel } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IHardware } from "../../../shared/interfaces/hardware.interface";
import { addHardware, checkIfHardwareConfigSettingIsModified, getHardwareConfigSettingSchema, updateHardware } from "../../../store/thunks/hardwares/hardwares-thunks";
import { Required } from "../../../shared/form-validations/form-validations";
import { getVendors } from "../../../store/thunks/vendors/vendors-thunks";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { setHardwareConfigSettingIsModified } from "../../../store/slices/hardwares";
import moment from "moment";
import { ConvertFiletoBase64 } from "../../../shared/methods/convert-file-to-base64";
import userEvent from "@testing-library/user-event";

export const AddUpdateHardwareForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const selectedVendor = useAppSelector(state => state.hardware.selectedVendor);
  const selectedHardware = useAppSelector(state => state.hardware.selectedHardware);
  const editHardware = useAppSelector(state => state.hardware.editHardware);
  const hardwareConfigSettingIsModified = useAppSelector(state => state.hardware.hardwareConfigSettingIsModified);
  const vendors = useAppSelector(state => state.vendor.vendors);
  const vendorStatus = useAppSelector(state => state.vendor.status);
  const hardwareConfigSettingSchemaStatus = useAppSelector(state => state.hardware.hardwareConfigSettingSchemaStatus);
  const configSettingSchema = useAppSelector(state => state.hardware.hardwareConfigSettingSchema);
  const [uploadConfigSettingsVisible, setUploadConfigSettingsVisible] = useState(false);
  const [uploadImageVisible, setUploadImageVisible] = useState(false);
  const classifications = [
    { label: "Edge", value: "Edge" },
    { label: "Tablet", value: "Tablet" },
    { label: "Workstation", value: "Workstation" },
    { label: "Server", value: "Server" }
  ];
  const [openDialog, setOpenDialog] = useState(false);
  const [updateHardwareData, setUpdateHardwareData] = useState<any>(null);

  useEffect(() => {
    let finalData = updateHardwareData;

    if (hardwareConfigSettingIsModified?.isModified === false) {
      finalData = { ...finalData, 'removeExistingHardwareConfiguration': false }
      dispatch(updateHardware(finalData));
    }
    if ((hardwareConfigSettingIsModified?.isModified === true)) {
      finalData = { ...finalData, 'removeExistingHardwareConfiguration': true }
      setUpdateHardwareData(finalData);
      handleClickOpenDialog();
    }
  }, [hardwareConfigSettingIsModified?.isModified]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCancelDialog = () => {
    setOpenDialog(false);
    dispatch(setHardwareConfigSettingIsModified(false));
  };

  const onConfirmDialog = () => {
    let finalData = updateHardwareData;
    finalData.createDtTm = moment(); //TODO: remove this
    dispatch(updateHardware(finalData));
  }

  useEffect(() => {
    if (vendorStatus === 'idle' || vendorStatus === 'failed') {
      dispatch(getVendors());
    }
    if (hardwareConfigSettingSchemaStatus === 'idle' || hardwareConfigSettingSchemaStatus === 'failed') {
      dispatch(getHardwareConfigSettingSchema());
    }
  }, []);

  const onSubmit = async (data: IHardware) => {
    if (editHardware) {
      onUpdateHardware(data);
    }
    else {
      onAddHardware(data);
    }
  };

  const onAddHardware = async (data: IHardware) => {
    let finalData: IHardware | any = data;
    if (data.configSettingsUploaded[0]) {
      finalData.hardwareConfig = await ConvertFiletoBase64(data.configSettingsUploaded[0]);;
      finalData.hardwareConfigFileName = data.configSettingsUploaded[0].name;
    }
    else {
      finalData.hardwareConfig = null;
      finalData.hardwareConfigFileName = null;
    }
    finalData.createDtTm = moment() //TODO: need to Remove
    finalData.thumbNailImage = await ConvertFiletoBase64(data.thumbNailImageUpload[0]);
    finalData.thumbNailImageName = data.thumbNailImageUpload[0].name;
    finalData.vendorId = selectedVendor.vendorId;
    finalData.currentuserId= loggedInUser?.userId;
    dispatch(addHardware(finalData));
  };

  const onUpdateHardware = async (data: IHardware) => {
    let finalData: IHardware | any = data;

    if (data.thumbNailImageUpload) {
      finalData.thumbNailImage = await ConvertFiletoBase64(data.thumbNailImageUpload[0]);
      finalData.thumbNailImageName = data.thumbNailImageUpload[0].name;
    } else {
      finalData.thumbNailImage = finalData.thumbNailImage;
      finalData.thumbNailImageName = finalData.thumbNailImageName;
    }

    if (data.configSettingsUploaded && data.configSettingsUploaded !== "") {
      finalData.hardwareConfig = await ConvertFiletoBase64(data.configSettingsUploaded[0]);;
      finalData.hardwareConfigFileName = data.configSettingsUploaded[0].name;     
      setUpdateHardwareData(finalData);
      const payloadData = {
        hardwareId: finalData.hardwareId,
        oemPartNumber: finalData.oemPartNumber,
        hardwareName: finalData.name,
        hardwareConfig: finalData.hardwareConfig,
        currentuserId: loggedInUser?.userId
      }
      dispatch(checkIfHardwareConfigSettingIsModified(payloadData));
    }
    else {
      finalData.createDtTm = moment() //TODO: need to Remove
      finalData = { 
        ...finalData, 
        'removeExistingHardwareConfiguration': false,
        currentuserId: loggedInUser?.userId 
      }
      dispatch(updateHardware(finalData));
    }
  }  
 

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={selectedHardware}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} data-testid="hardware-form">
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <Field name="name" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.hardware" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                        disabled={editHardware}
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field name="vendorId">
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="vendor" error={meta.error && meta.touched}><FormattedMessage id="components.vendor" /></InputLabel>
                      <Select
                        {...input}
                        value={selectedVendor?.vendorId}
                        labelId="vendor"
                        error={meta.error && meta.touched}
                        disabled
                      >
                        {vendors?.map((row) => (
                          <MenuItem key={row.vendorId} value={row.vendorId}>{row.name}</MenuItem>
                        ))}
                      </Select>
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </FormControl>
                  )}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field name="oemPartNumber" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.oemPartNumber" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                        disabled={editHardware}
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field name="hardwareClassification" validate={Required} >
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="hardwareClassification" error={meta.error && meta.touched}><FormattedMessage id="components.classification" /></InputLabel>
                      <Select
                        {...input}
                        labelId="hardwareClassification"
                        error={meta.error && meta.touched}
                        disabled={editHardware}
                      >
                        {classifications.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Grid>

              <Grid item xs={6}>
                <Field name="isActive" type="checkbox" >
                  {({ input }) => (
                    <FormControlLabel sx={{ display: 'block', marginTop: '6px' }}
                      control={<Checkbox {...input.onChange} {...input} />}
                      label={<FormattedMessage id="components.active" />}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field name="description">
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.description" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        multiline
                        maxRows={3}
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6}>
                {values.hardwareConfigFileName && values.hardwareConfigFileName !== '' ?
                  <>
                    {!uploadConfigSettingsVisible &&
                      <>
                        <Link href="#!" style={{ marginBottom: '20px' }} onClick={() => setUploadConfigSettingsVisible(!uploadConfigSettingsVisible)} ><UploadIcon sx={{ position: "relative", top: "6px" }} /> <FormattedMessage id="components.newConfigSettings" /> </Link>
                        <br />
                      </>
                    }
                    {uploadConfigSettingsVisible &&
                      <Field name="configSettingsUploaded">
                        {({ input: { value, onChange, ...input }, meta }) => (
                          <>
                            <InputLabel error={meta.error && meta.touched}><FormattedMessage id="components.newConfigSettings" /></InputLabel>
                            <input onChange={({ target }) => onChange(target.files)} {...input} type="file" aria-label="upload file" accept=".json" />
                            <div style={{ fontSize: "11px", opacity: "0.5" }}><FormattedMessage id="components.uploadConfigSettings" /></div>
                            {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                          </>
                        )}
                      </Field>
                    }
                  </>
                  :
                  <Field name="configSettingsUploaded" validate={Required}>
                    {({ input: { value, onChange, ...input }, meta }) => (
                      <>
                        <InputLabel error={meta.error && meta.touched}><FormattedMessage id="components.configSettings" /></InputLabel>
                        <input onChange={({ target }) => onChange(target.files)} {...input} type="file" aria-label="upload file" accept=".json" />
                        <div style={{ fontSize: "11px", opacity: "0.5" }}><FormattedMessage id="components.uploadConfigSettings" /></div>
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>

                }

                {editHardware && values.hardwareConfigFileName && values.hardwareConfigFileName !== '' &&
                  <Link download={values.hardwareConfigFileName}
                    href={`data:text/json;base64,${values.hardwareConfig}`}
                    onClick={(event) => event.stopPropagation()} >
                    <DownloadIcon sx={{ position: "relative", top: "6px" }} /> <FormattedMessage id="components.configSettings" />
                  </Link>
                }
                <br />
                <Link download="Schema.json"
                  href={`data:text/json;base64,${configSettingSchema}`}
                  onClick={(event) => event.stopPropagation()} >
                  <DownloadIcon sx={{ position: "relative", top: "6px" }} /> <FormattedMessage id="components.jsonSchema" />
                </Link>

              </Grid>
              <Grid item xs={6}>
              <div>
              
              {values.thumbNailImage && values.thumbNailImage !== '' ?
                <div>
                  {!uploadImageVisible &&
                  <>
                    <Link href="#!" style={{ marginBottom: '20px' }} onClick={() => setUploadImageVisible(!uploadImageVisible)} >Upload new image </Link>
                    <br />
                  </>
                  }
                  {uploadImageVisible &&
                  <Field name="thumbNailImageUpload">
                  {({ input: { value, onChange, ...input }, meta }) => (
                    <>
                      <InputLabel error={meta.error && meta.touched}><FormattedMessage id="components.hardwareImage" /></InputLabel>
                      <input onChange={({ target }) => onChange(target.files)} {...input} type="file" aria-label="upload image" accept=".jpg, .png, .jpeg" />
                      <div style={{ fontSize: "11px", opacity: "0.5" }}><FormattedMessage id="components.uploadHardwareImage" /></div>
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                  </Field>
                  }
                </div>
                :
                <Field name="thumbNailImageUpload" validate={Required}>
                    {({ input: { value, onChange, ...input }, meta }) => (
                      <>
                        <InputLabel error={meta.error && meta.touched}><FormattedMessage id="components.hardwareImage" /></InputLabel>
                        <input onChange={({ target }) => onChange(target.files)} {...input} type="file" aria-label="upload image" accept=".jpg, .png, .jpeg" />
                        <div style={{ fontSize: "11px", opacity: "0.5" }}><FormattedMessage id="components.uploadHardwareImage" /></div>
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
              }
            </div>
            <div>
              {editHardware && values.thumbNailImage && values.thumbNailImage !== '' &&
                <img src={`data:image/png;base64,${values.thumbNailImage}`} style={{ maxWidth: '100px', maxHeight: '100px' }} alt={values.thumbNailImageName} />
              }
            </div>
              </Grid>

            </Grid>
            <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
              <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }} data-testid="reset-button">
                <FormattedMessage id="components.reset" />
              </Button>
              <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button">
                {editHardware ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
              </Button>
            </Box>

          </form>
        )}
      />
      <Dialog
        open={openDialog}
        onClose={handleCancelDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-testid="delete-dlg"
      >
        <DialogTitle id="alert-dialog-title"><FormattedMessage id="components.hardwareConfigSettingsPopupHead" /></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage id="components.hardwareConfigSettingsPopupMessage" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialog} variant="contained" color="error">
            <FormattedMessage id="components.cancel" />
          </Button>
          <Button onClick={onConfirmDialog} variant="contained" color="primary" autoFocus>
            <FormattedMessage id="components.yes" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
