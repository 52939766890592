import { ReactElement, useEffect, ChangeEvent, MouseEvent, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import GleasonLogo from "../../assets/images/gleason-logo.svg";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { setUserCredentials } from "../../store/slices/profile";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { authenticateUrlToken, login } from "../../store/thunks/user-management/user-management-thunks";
import { useLocation } from "react-router-dom";
import { Required } from "../../shared/form-validations/form-validations";
import { Field, Form } from "react-final-form";

export const Signin = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const username = params.get('username') ? params.get('username') : '';
  const password = params.get('password') ? params.get('password') : '';
  const auId = params.get('AuId') ? params.get('AuId') : '';
  const token = params.get('Token') ? params.get('Token') : '';
  const isLoggedIn = useAppSelector(state => state.profile.loginStatus);
  const authLink = useAppSelector(state => state.profile.authLink);
  const userCredentials = useAppSelector(state => state.profile.userCredentials);
  const [showPassword, setShowPassword] = useState(false);
  let initialized = false;

  useEffect(() => {
    if (!initialized) {
      initialized = true
      if (auId && token) {
        const requestData = {
          auId: auId,
          token: token
        }
        dispatch(authenticateUrlToken(requestData));
      }
      else if (username && password) {
        dispatch(setUserCredentials({
          userName: username,
          password: password
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword= (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = (values: any) => {
    const requestData: any = {
      userName: values.userName,
      password: values.password
    }
    dispatch(login(requestData));
  };

  return (
    <>
      <div style={{ backgroundColor: "#f4f7fa", width: "100%", height: "100vh" }}>
        <Box sx={{ padding: "30px 0 30px 100px" }}>
          <div className="session-logo">
            <Link underline="none" href="/">
              <img
                src={GleasonLogo}
                alt="gleason-logo"
                className="img-fluid"
                width="110"
                height="35"
              />
            </Link>
          </div>
        </Box>
        <Card
          sx={{
            margin: "0 auto",
            maxWidth: "400px",
            backgroundColor: "#ffffff",
            padding: "20px 80px",
          }}
        >
          <div style={{ marginBottom: "20px", textAlign: "center" }}>
            <Typography variant="h5" component="h5">
              {" "}GEMS Web Portal{" "}
            </Typography>
          </div>
          {authLink ? (
            <Typography variant="body1" sx={{ color: "green", margin: "1em auto", fontSize: "1em", textAlign: "center" }}>
              Click Authentication link in your email.
            </Typography>
          ) : (
            <>
              <Form
                initialValues={userCredentials}
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} data-testid="reset-password-form">
                    <Field name="userName" validate={Required}>
                      {({ input, meta }) => (
                        <FormControl
                          sx={{ marginBottom: "10px", width: "100%" }}
                          variant="outlined"
                        >
                          <InputLabel htmlFor="userName">Username</InputLabel>
                          <OutlinedInput
                            id="username"
                            type="text"
                            {...input}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="username"
                                  edge="end"
                                >
                                  <PersonIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Username"
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                        </FormControl>
                      )}
                    </Field>
                    <Field name="password" validate={Required}>
                      {({ input, meta }) => (
                        <FormControl
                          sx={{ marginBottom: "10px", width: "100%" }}
                          variant="outlined"
                        >
                          <InputLabel htmlFor="password">Password</InputLabel>
                          <OutlinedInput
                            id="password"
                            type={showPassword ? "text" : "password"}
                            {...input}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="password"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      size="large"
                      variant="contained"
                      sx={{ width: "100%", marginBottom: "10px" }}
                      aria-label="sign in"
                      type="submit"
                    >
                      Sign In
                    </Button>
                    <Link
                      underline="hover"
                      href="/forgot-password"
                      aria-label="forgot password"
                    >
                      Forgot password
                    </Link>
                    <Alert sx={{ margin: "20px 0" }} severity="info">
                      Authentication will be sent to your email
                    </Alert>
                  </form>
                )}
              />
            </>
          )}
        </Card>
      </div>
    </>
  );
};
