import { createSlice } from '@reduxjs/toolkit';
import { IToolingObject } from "../../shared/interfaces/tooling-object.interface";
import { addToolingObject, getToolingObjects, getToolingObjectForVendor } from '../thunks/tooling-objects/tooling-objects-thunks';

interface ToolingObjectState {
    toolingObjects: IToolingObject[],
    selectedToolingObject: any,
    selectedCustomer: any,
    openViewToolingObjectDialog: boolean,
    openAddUpdateToolingObjectDialog: boolean,
    editToolingObject: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: ToolingObjectState = {
    toolingObjects: [],
    selectedToolingObject: null,
    selectedCustomer: "all",
    openViewToolingObjectDialog: false,
    openAddUpdateToolingObjectDialog: false,
    editToolingObject: false,
    status: 'idle',
    error: undefined
}

export const toolingObjectsSlice = createSlice({    
    name: "toolingObject",
    initialState,
    reducers: {
        setToolingObjects: (state, action) => {
            state.toolingObjects = action.payload
        },
        setSelectedToolingObject: (state, action) => {
            state.selectedToolingObject = action.payload
        },
        setOpenViewToolingObjectDialog: (state, action) => {
            state.openViewToolingObjectDialog = action.payload
        },
        setOpenAddUpdateToolingObjectDialog: (state, action) => {
            state.openAddUpdateToolingObjectDialog = action.payload
        },
        setEditToolingObject: (state, action) => {
            state.editToolingObject = action.payload
        },
        setSelectedCustomer: (state, action) => {
            state.selectedCustomer = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getToolingObjects.fulfilled, (state, action) => {            
            state.toolingObjects = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getToolingObjects.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getToolingObjects.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addToolingObject.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addToolingObject.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addToolingObject.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getToolingObjectForVendor.fulfilled, (state, action) => {
            state.toolingObjects = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getToolingObjectForVendor.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getToolingObjectForVendor.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setToolingObjects,
    setSelectedToolingObject,
    setOpenViewToolingObjectDialog,
    setOpenAddUpdateToolingObjectDialog,
    setEditToolingObject,
    setSelectedCustomer
} = toolingObjectsSlice.actions;
export default toolingObjectsSlice.reducer;
