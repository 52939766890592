import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IVendor } from "../../shared/interfaces/vendor.interface";
import { onSearchInTable } from "../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { DeleteConfirmationDialog } from "../../shared/dialog-components/delete-confirmation-dialog";
import { AddUpdateVendorForm } from "./add-update-vendor-form.component";
import { deleteVendor, getVendors } from "../../store/thunks/vendors/vendors-thunks";
import {
  setSelectedVendor,
  setOpenAddUpdateVendorDialog,
  setOpenViewVendorDialog,
  setOpenDeleteConfirmationDialog,
  setEditVendor
} from "../../store/slices/vendors"
import { TablePaginationActions } from "../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";
import { setLoading } from "../../store/app-slice";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const Vendors = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const vendors = useAppSelector(state => state.vendor.vendors);
  const vendorStatus = useAppSelector(state => state.vendor.status);
  const selectedVendor = useAppSelector(state => state.vendor.selectedVendor);
  const openViewVendorDialog = useAppSelector(state => state.vendor.openViewVendorDialog);
  const openAddUpdateVendorDialog = useAppSelector(state => state.vendor.openAddUpdateVendorDialog);
  const openDeleteConfirmationDialog = useAppSelector(state => state.vendor.openDeleteConfirmationDialog);
  const editVendor = useAppSelector(state => state.vendor.editVendor);
  const [searchVendorsList, setSearchVendorsList] = useState<IVendor[]>(vendors);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IVendor>('name');

  const columns: Column[] = [
    { id: 'name', label: intl.formatMessage({ id: 'components.vendor' }) },    
    { id: 'vendorCode', label: intl.formatMessage({ id: 'components.vendorCode' }) },
    { id: 'vendorType', label: intl.formatMessage({ id: 'components.vendorType' }) },   
    { id: 'description', label: intl.formatMessage({ id: 'components.description' }) }, 
    { id: 'license', label: intl.formatMessage({ id: 'components.license' }) },    
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if(vendorStatus === 'idle' || vendorStatus === 'failed'){
      loadVendors();
    }
  }, []);

  useEffect(() => {
    setSearchVendorsList(vendors);   
  }, [vendors]);

  useEffect(() => {
    setPage(0);
  },[searchVendorsList]);

  const loadVendors = () => {
    dispatch(getVendors());
  }

  const handleCloseDialog = () => {
    dispatch(setOpenAddUpdateVendorDialog(false));
    dispatch(setOpenViewVendorDialog(false));    
    dispatch(setOpenDeleteConfirmationDialog(false));
    dispatch(setSelectedVendor({}));
  }

  const onViewVendorDetails = (data: IVendor) => {
    dispatch(setOpenViewVendorDialog(true));
    dispatch(setSelectedVendor(data));
    dispatch(setEditVendor(false));
  }

  const addVendor = () => {
    dispatch(setOpenAddUpdateVendorDialog(true));
    dispatch(setEditVendor(false));
  }

  const onUpdateVendor = (data: IVendor) => {
    dispatch(setSelectedVendor(data));
    dispatch(setOpenAddUpdateVendorDialog(true));
    dispatch(setEditVendor(true));
  }

  const onDeleteVendor = (data: IVendor) => {
    dispatch(setOpenDeleteConfirmationDialog(true));
    dispatch(setSelectedVendor(data));    
  }

  const deleteVendorPermanently = () => {
    dispatch(setLoading(true));
    dispatch(deleteVendor(selectedVendor));
  }

  const actionTemplateVendors = (rowData: IVendor) => {
    return <span data-testid="vendor-actions" className="list-action">
      <Link component="button" data-testid="view-vendor" aria-label="view-vendor" variant="body2" onClick={() => onViewVendorDetails(rowData)} > <VisibilityIcon /> </Link>
      <Link component="button" data-testid="update-vendor" variant="body2" onClick={() => onUpdateVendor(rowData)} > <EditIcon /> </Link>
      <Link component="button" data-testid="delete-vendor" aria-label="delete-vendor" variant="body2" onClick={() => onDeleteVendor(rowData)} > <ClearIcon /> </Link>
    </span>;
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchVendorsList(onSearchInTable(vendors, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IVendor,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="vendors">
        <div className="page-header">
          <h2><FormattedMessage id="components.vendors" /></h2>
        </div>
        <Box>
          <TableContainer component={Paper}>
            <div className="table-head-links">
              <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadVendors()} > <CachedIcon /> </Link></div>
              <div data-testid="add-new-btn" className="add-link"><Link component="button" aria-label="add-vendor" variant="body1" underline="none" onClick={() => addVendor()} ><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.vendor" /> <AddIcon className="add-icon" /> </Link></div>
            </div>
            <div data-testid="searchbox" style={{ padding: "10px" }}>
              <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
            </div>
            <Table sx={{ minWidth: 650 }} data-testid="vendor-table" aria-label="vendor table">
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
              />
              <TableBody>
                {stableSort(searchVendorsList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow data-testid="vendor-node" key={row.vendorId} >
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.vendorCode}</TableCell>
                        <TableCell>{row.vendorType}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{row.externalLicensing ? "Custom" : "Standard"}</TableCell>
                        <TableCell>{actionTemplateVendors(row)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    data-testid="pagination-control"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={6} 
                    count={searchVendorsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </div>

      {/*start of add/update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openAddUpdateVendorDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
          {editVendor ?
            <><FormattedMessage id="components.update" /> <FormattedMessage id="components.vendor" /></>
            : <><FormattedMessage id="components.addNew" />  <FormattedMessage id="components.vendor" /></>
          }
        </BootstrapDialogTitle>
        <DialogContent>
          <AddUpdateVendorForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of add/update dialog */}

      {/*start of view details dialog */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewVendorDialog}
        data-testid="view-vendor-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedVendor &&
            <>
              <p><FormattedMessage id="components.vendor" />: <span style={{ fontWeight: 'bold' }}>{selectedVendor.name}</span></p>
              <p><FormattedMessage id="components.vendorCode" />: <span style={{ fontWeight: 'bold' }}>{selectedVendor.vendorCode}</span></p>
              <p><FormattedMessage id="components.vendorType" />: <span style={{ fontWeight: 'bold' }}>{selectedVendor.vendorType}</span></p>
              <p><FormattedMessage id="components.description" />: <span style={{ fontWeight: 'bold' }}>{selectedVendor.description}</span></p>
              <p><FormattedMessage id="components.license" />: <span style={{ fontWeight: 'bold' }}>{selectedVendor.externalLicensing ? "Custom" : "Standard"}</span></p>
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

      {/*Start of delete confirmation dialog */}
      <DeleteConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleCancel={handleCloseDialog}
        title={<FormattedMessage id="components.wantToDelete" />}
        message={<span><FormattedMessage id="components.thisWillDelete" /> <FormattedMessage id="components.vendor" /> <FormattedMessage id="components.permanently" /></span>}
        onConfirm={deleteVendorPermanently}
      />
      {/*end of delete confirmation dialog */}

    </>
  );
};
