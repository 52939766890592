import { createElement, useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { rolesConfig } from "../../services/router-service";
import { Role } from "../../shared/enums/role.enum";
import { Signin } from "../../pages/signin/signin.component";
import { NotFound } from "../not-found/not-found.component";
import { ForgotPassword } from "../signin/forgot-password.component";
import { RootPage } from "./root-page.component";
import { useAppSelector } from "../../store/hooks";
import { useDispatch } from "react-redux";
import {  addRole, setProfile, setRedirectToRoot } from "../../store/slices/profile";
import { AppRoute } from "../../shared/interfaces/app-routes.interface";
import { getUniqueListBy } from "../../shared/methods/unique-list";
import { ResetPassword } from "../signin/reset-password.component";
import moment from "moment";

const MainRoutes = () => {
  const profile = useAppSelector((state) => state.profile.profile);
  const roles = useAppSelector((state) => state.profile.roles);
  const isLoggedIn = useAppSelector((state) => state.profile.loginStatus);
  const loggedInUser = useAppSelector((state) => state.profile.loggedInUser);
  const dispatch = useDispatch();
  const [allowedRoutes, setAllowedRoutes] = useState<any>([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      if (user) {
        dispatch(setProfile(user));
      }      
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      if (!isLoggedIn) {
        navigate("/signin");
      }
      else {
        navigate("/");
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!profile) {
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      if (user) {
        dispatch(setProfile(user));
      }
    }
  }, [profile]);
  
  useEffect(() => {
    if (profile) {
      if (roles.length > 0) {
        if (
          !(
            roles.includes(Role.TRIAL_EXPIRED) ||
            roles.includes(Role.PASSWORD_CHANGE_ONLY) ||
            (loggedInUser && !loggedInUser?.termsAccepted)
          )
        ) {
          dispatch(addRole(Role.COMMON));
        }
      }
    }
  }, [profile, loggedInUser]);

  useEffect(() => {
    if (profile) {
      if (roles.length > 0) {
        let routes: any = [];
        if(roles.includes(Role.PASSWORD_CHANGE_ONLY)){
          routes.push(...rolesConfig[Role.PASSWORD_CHANGE_ONLY].routes);
        } 
        else if(loggedInUser && !loggedInUser?.termsAccepted){
          routes.push(...rolesConfig[Role.TERMS].routes);
        } 
        else if(loggedInUser && loggedInUser?.trial && moment(loggedInUser?.trialExpiration).isBefore(moment())){
          routes.push(...rolesConfig[Role.TRIAL_EXPIRED].routes);
        }
        else {
          roles.forEach((item: string) => {
            routes.push(...rolesConfig[item].routes);
          });
        }
        setAllowedRoutes(getUniqueListBy(routes, "path"));
      }
    }
  }, [profile, roles, loggedInUser]);

  useEffect(() => {
    try {
      if(loggedInUser && allowedRoutes.length > 0){

        if (location.pathname === "/") {
            if (roles.includes(Role.PASSWORD_CHANGE_ONLY)) {
              navigate("/change-password");
              return;
            }
            if (loggedInUser && !loggedInUser?.termsAccepted) {
              navigate("/terms-conditions");
              return;
            }
            if (loggedInUser && loggedInUser?.trial && moment(loggedInUser?.trialExpiration).isBefore(moment())) {
              navigate("/trial-expired");
              return;
            } else {
              if (roles.includes(Role.GLOBAL_GLEASON_ADMIN)) {
                navigate("/dashboard");
              }
              else if (!roles.includes(Role.GLOBAL_GLEASON_ADMIN) && roles.includes(Role.GLEASON_VENDOR)) {
                navigate("/tools");
              } else {
                navigate("/applications");
              }
            }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [loggedInUser, allowedRoutes]);

  return (
    <Routes>
      <Route path="/signin" element={<Signin />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="*" element={<NotFound />} />

      {profile && (
        <Route path="/" element={<RootPage />}>
          {allowedRoutes.map((route: AppRoute, key: number) => (
            <Route key={`${route.path}__${key}`} path={route.path} element={createElement(route.component)} />
          ))}
        </Route>
      )}
    </Routes>
  );
};

export default MainRoutes;
