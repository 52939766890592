import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import { onSearchInTable } from "../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { TablePaginationActions } from "../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";
import { UpdateModulePermissionsForm } from "./update-module-permissions-form.component";
import { setGrantedModulePermissions, setOpenViewModulePermissionsDialog, setSelectedModule, setSelectedPlatform, setSelectedUser, setUpdateModulePermissionsDialog } from "../../store/slices/grant-permission";
import { IGrantedModulePermissions } from "../../shared/interfaces/grant-permission.interface";
import { getGrantedModulePermissions } from "../../store/thunks/grant-permission/grant-permission-thunks";
import { Role } from "../../shared/enums/role.enum";
import { getUsers } from "../../store/thunks/user-management/user-management-thunks";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const GrantPermissions = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const users = useAppSelector(state => state.userManagement.users);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const grantedModulePermissions = useAppSelector(state => state.grantPermission.grantedModulePermissions);
  const selectedUser = useAppSelector(state => state.grantPermission.selectedUser);
  const selectedPlatform = useAppSelector(state => state.grantPermission.selectedPlatform);
  const openViewModulePermissionsDialog = useAppSelector(state => state.grantPermission.openViewModulePermissionsDialog);
  const updateModulePermissionsDialog = useAppSelector(state => state.grantPermission.updateModulePermissionsDialog);
  const selectedModule = useAppSelector(state => state.grantPermission.selectedModule);
  const [searchModulesList, setSearchModulesList] = useState<IGrantedModulePermissions[]>(grantedModulePermissions);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IGrantedModulePermissions>('module');
  const platforms = [
    { label: 'Edge', id: 'Edge' },
    { label: 'Cloud', id: 'Cloud' },
    { label: 'Machine', id: 'Machine' },
    { label: 'Enterprise', id: 'Enterprise' }
  ];

  const columns: Column[] = [
    { id: 'module', label: intl.formatMessage({ id: 'components.module' }) },
    { id: 'code', label: intl.formatMessage({ id: 'components.code' }) },
    { id: 'platform', label: intl.formatMessage({ id: 'components.platform' }) },
    { id: 'permissions', label: intl.formatMessage({ id: 'components.availablePermissions' }) },
    { id: 'grantedPermissions', label: intl.formatMessage({ id: 'components.grantedPermissions' }) },
    { id: 'application', label: intl.formatMessage({ id: 'components.application' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(setSelectedUser(null));
      dispatch(setSelectedPlatform(''));
      dispatch(setGrantedModulePermissions([]));
      dispatch(getUsers(cId));
    }
  }, [selectedAppCustomer]);

  useEffect(() => {
    setSearchModulesList(grantedModulePermissions);
  }, [grantedModulePermissions]);

  useEffect(() => {
    setPage(0);
  },[searchModulesList]);

  const handleUserChange = (data: any) => {
    dispatch(setSelectedUser(data));
    dispatch(setSelectedPlatform(''));
    dispatch(setGrantedModulePermissions([]));
  }

  const handlePlatformChange = (data: any) => {
    dispatch(setSelectedPlatform(data));
    const requestData = {
      userId: selectedUser,
      platform: data
    }
    dispatch(getGrantedModulePermissions(requestData));
  }


  const handleCloseDialog = () => {
    dispatch(setUpdateModulePermissionsDialog(false));
    dispatch(setOpenViewModulePermissionsDialog(false));
    dispatch(setSelectedModule({}));
  }

  const onViewModuleDetails = (data: IGrantedModulePermissions) => {
    dispatch(setOpenViewModulePermissionsDialog(true));
    dispatch(setSelectedModule(data));
  }

  const onUpdateModule = (data: IGrantedModulePermissions) => {
    dispatch(setUpdateModulePermissionsDialog(true));
    dispatch(setSelectedModule(data));
  }

  const actionTemplateModules = (rowData: IGrantedModulePermissions) => {
    return <span data-testid="module-actions" className="list-action">
      <Link component="button" data-testid="view-module" aria-label="view-module" variant="body2" onClick={() => onViewModuleDetails(rowData)} > <VisibilityIcon /> </Link>
      {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
        <Link component="button" data-testid="update-module" variant="body2" onClick={() => onUpdateModule(rowData)} > <EditIcon /> </Link>
      }
    </span>;
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchModulesList(onSearchInTable(grantedModulePermissions, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IGrantedModulePermissions,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
    <>
      <div data-testid="grant-permissions">
        <Box>
          <FormControl variant="standard" sx={{ m: 2, mt: 0, width: 350 }} data-testid="user-select-combobox">
            <InputLabel id="select-user"><FormattedMessage id="components.user" /></InputLabel>
            <Select
              labelId="select-user"
              role="combobox"
              aria-label="select user"
              inputProps={{ "data-testid": "select-user" }}
              value={selectedUser}
              onChange={(e) => handleUserChange(e.target.value)}
            >
              {users.map((row: any) => (
                <MenuItem key={row.userId} value={row.userId}>{row.userName}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" sx={{ m: 2, mt: 0, width: 350 }} data-testid="platform-select-combobox">
            <InputLabel id="select-platform"><FormattedMessage id="components.platform" /></InputLabel>
            <Select
              labelId="select-platform"
              role="combobox"
              aria-label="select platform"
              inputProps={{ "data-testid": "select-platform" }}
              value={selectedPlatform}
              onChange={(e) => handlePlatformChange(e.target.value)}
            >
              {platforms.map((row: any) => (
                <MenuItem key={row.id} value={row.id}>{row.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedPlatform ?
            <TableContainer component={Paper}>
              <div data-testid="searchbox" style={{ padding: "10px" }}>
                <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
              </div>
              <Table sx={{ minWidth: 650 }} data-testid="module-table" aria-label="module table">
                <SortableTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                />
                <TableBody>
                  {stableSort(searchModulesList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow data-testid="module-node" key={row.moduleId} >
                          <TableCell>{row.module}</TableCell>
                          <TableCell>{row.code}</TableCell>
                          <TableCell>{row.platform}</TableCell>
                          <TableCell>{row.permissions.join(", ")}</TableCell>
                          <TableCell>{row.grantedPermissions.join(", ")}</TableCell>
                          <TableCell>{row.application}</TableCell>
                          <TableCell>{actionTemplateModules(row)}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      data-testid="pagination-control"
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={columns.length}
                      count={searchModulesList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            :
            <Box sx={{ p: 2, pb: 3 }}>
              <FormattedMessage id="components.selectCPM" />
            </Box>
          }
        </Box>
      </div>

      {/*start of update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={updateModulePermissionsDialog}
        data-testid="update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
          <FormattedMessage id="components.updatePermissions" />
        </BootstrapDialogTitle>
        <DialogContent>
          <UpdateModulePermissionsForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of update dialog */}

      {/*start of view details dialog */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewModulePermissionsDialog}
        data-testid="view-mp-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedModule &&
            <>
              <p><FormattedMessage id="components.module" />: <span style={{ fontWeight: 'bold' }}>{selectedModule.module}</span></p>
              <p><FormattedMessage id="components.code" />: <span style={{ fontWeight: 'bold' }}>{selectedModule.code}</span></p>
              <p><FormattedMessage id="components.platform" />: <span style={{ fontWeight: 'bold' }}>{selectedModule.platform}</span></p>
              <p><FormattedMessage id="components.availablePermissions" />: <span style={{ fontWeight: 'bold' }}>{selectedModule.permissions?.join(", ")}</span></p>
              <p><FormattedMessage id="components.grantedPermissions" />: <span style={{ fontWeight: 'bold' }}>{selectedModule.grantedPermissions?.join(", ")}</span></p>
              <p><FormattedMessage id="components.application" />: <span style={{ fontWeight: 'bold' }}>{selectedModule.application}</span></p>
              <p><FormattedMessage id="components.description" />: <span style={{ fontWeight: 'bold' }}>{selectedModule.description}</span></p>
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

    </>
  );
};
