import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IEdgeDevice } from "../../../shared/interfaces/edge-device.interface";
import { onSearchInTable } from "../../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../shared/dialog-components/custom-dialog";
import { DeleteConfirmationDialog } from "../../../shared/dialog-components/delete-confirmation-dialog";
import { deleteEdgeDevice, getEdgeDeviceDetails, getEdgeDevices } from "../../../store/thunks/edge-devices/edge-devices-thunks";
import { TablePaginationActions } from "../../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../../shared/table-sorting/table-sort";
import { AddUpdateEdgeDeviceForm } from "./add-update-edge-device-form.component";
import { setOpenDeleteConfirmationDialog, setOpenAddUpdateEdgeDeviceDialog, setOpenViewEdgeDeviceDialog, setSelectedEdgeDevice, setEditEdgeDevice } from "../../../store/slices/edge-devices";
import { IHardware } from "../../../shared/interfaces/hardware.interface";
import { Role } from "../../../shared/enums/role.enum";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const EdgeDevices = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const edgeDevices = useAppSelector(state => state.edgeDevice.edgeDevices);
  const selectedEdgeDevice = useAppSelector(state => state.edgeDevice.selectedEdgeDevice);
  const openViewEdgeDeviceDialog = useAppSelector(state => state.edgeDevice.openViewEdgeDeviceDialog);
  const openAddUpdateEdgeDeviceDialog = useAppSelector(state => state.edgeDevice.openAddUpdateEdgeDeviceDialog);
  const openDeleteConfirmationDialog = useAppSelector(state => state.edgeDevice.openDeleteConfirmationDialog);
  const editEdgeDevice = useAppSelector(state => state.edgeDevice.editEdgeDevice);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const [searchEdgeDevicesList, setSearchEdgeDevicesList] = useState<IEdgeDevice[]>(edgeDevices);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IEdgeDevice>('azureIotdeviceName');

  const columns: Column[] = [
    { id: 'azureIotdeviceName', label: intl.formatMessage({ id: 'components.iotDeviceName' }) },
    { id: 'oempartNumber', label: intl.formatMessage({ id: 'components.oemPartNumber' }) },
    { id: 'oemserialNumber', label: intl.formatMessage({ id: 'components.oemSerialNumber' }) },
    { id: 'description', label: intl.formatMessage({ id: 'components.description' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(getEdgeDevices(cId));
    }
  }, [selectedAppCustomer]);

  useEffect(() => {
    setSearchEdgeDevicesList(edgeDevices);
  }, [edgeDevices]);

  useEffect(() => {
    setPage(0);
  },[searchEdgeDevicesList]);

  const loadEdgeDevices = () => {
    const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
    dispatch(getEdgeDevices(cId))
  }

  const handleCloseDialog = () => {
    dispatch(setOpenViewEdgeDeviceDialog(false));
    dispatch(setOpenAddUpdateEdgeDeviceDialog(false));
    dispatch(setOpenDeleteConfirmationDialog(false));
    dispatch(setSelectedEdgeDevice({}));
  }

  const onViewEdgeDeviceDetails = (data: IEdgeDevice) => {
    dispatch(setOpenViewEdgeDeviceDialog(true));
    dispatch(getEdgeDeviceDetails(data.edgeDeviceId));
    dispatch(setEditEdgeDevice(false));
  }

  const addEdgeDevice = () => {
    dispatch(setOpenAddUpdateEdgeDeviceDialog(true));
    dispatch(setEditEdgeDevice(false));
  }

  const onUpdateEdgeDevice = (data: IEdgeDevice) => {
    dispatch(getEdgeDeviceDetails(data.edgeDeviceId));
    dispatch(setOpenAddUpdateEdgeDeviceDialog(true));
    dispatch(setEditEdgeDevice(true));
  }

  const onDeleteEdgeDevice = (data: IEdgeDevice) => {
    dispatch(setOpenDeleteConfirmationDialog(true));
    dispatch(setSelectedEdgeDevice(data));
  }

  const deleteEdgeDevicePermanently = () => {
    dispatch(setOpenDeleteConfirmationDialog(false));
    dispatch(deleteEdgeDevice(selectedEdgeDevice));
  }

  const actionTemplateEdgeDevices = (rowData: IEdgeDevice) => {
    return <span data-testid="ed-actions" className="list-action">
      <Link role="button" data-testid="view-ed" aria-label="view-ed" component="button" variant="body2" onClick={() => onViewEdgeDeviceDetails(rowData)} > <VisibilityIcon /> </Link>
      {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
        <>
          <Link role="button" data-testid="update-ed" component="button" variant="body2" onClick={() => onUpdateEdgeDevice(rowData)} > <EditIcon /> </Link>
          <Link role="button" data-testid="delete-ed" component="button" variant="body2" onClick={() => onDeleteEdgeDevice(rowData)} > <ClearIcon /> </Link>
        </>
      }
    </span>;
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchEdgeDevicesList(onSearchInTable(edgeDevices, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IEdgeDevice,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const imageTemplate = (rowData: IHardware) => {
    return <img src={`data:image/png;base64,${rowData.thumbNailImage}`} width="50" alt={rowData.thumbNailImageName} />;
  }

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="edge-devices">
        <Box>
          <TableContainer component={Paper}>
            <div className="table-head-links">
              <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadEdgeDevices()} > <CachedIcon /> </Link></div>
              {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
                <div data-testid="add-new-btn" className="add-link"><Link component="button" role="button" aria-label="add-ed" variant="body1" underline="none" onClick={() => addEdgeDevice()} ><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.edgeDevice" /> <AddIcon className="add-icon" /> </Link></div>
              }
            </div>
            <div data-testid="searchbox" style={{ padding: "10px" }}>
              <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
            </div>
            <Table sx={{ minWidth: 650 }} data-testid="edge-device-table" aria-label="edge devices table">
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
              />
              <TableBody>
                {stableSort(searchEdgeDevicesList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow data-testid="ed-node" key={row.edgeDeviceId} >
                        <TableCell>{row.azureIotdeviceName}</TableCell>
                        <TableCell>{row.oempartNumber}</TableCell>
                        <TableCell>{row.oemserialNumber}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{actionTemplateEdgeDevices(row)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    data-testid="pagination-control"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={columns.length}
                    count={searchEdgeDevicesList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </div>

      {/*start of add/update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openAddUpdateEdgeDeviceDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
          {editEdgeDevice ?
            <><FormattedMessage id="components.update" /> <FormattedMessage id="components.edgeDevice" /></>
            : <><FormattedMessage id="components.addNew" />  <FormattedMessage id="components.edgeDevice" /></>
          }
        </BootstrapDialogTitle>
        <DialogContent>
          <AddUpdateEdgeDeviceForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of add/update dialog */}

      {/*start of view details dialog */}
      <BootstrapDialog
        maxWidth="md"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewEdgeDeviceDialog}
        data-testid="view-ed-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedEdgeDevice &&
            <>
              <p><FormattedMessage id="components.iotDeviceName" />: <span style={{ fontWeight: 'bold' }}>{selectedEdgeDevice.azureIotdeviceName}</span></p>
              <p><FormattedMessage id="components.oemPartNumber" />: <span style={{ fontWeight: 'bold' }}>{selectedEdgeDevice.oempartNumber}</span></p>
              <p><FormattedMessage id="components.oemSerialNumber" />: <span style={{ fontWeight: 'bold' }}>{selectedEdgeDevice.oemserialNumber}</span></p>
              <p><FormattedMessage id="components.customer" />: <span style={{ fontWeight: 'bold' }}>{selectedEdgeDevice.customerName}</span></p>
              <p><FormattedMessage id="components.description" />: <span style={{ fontWeight: 'bold' }}>{selectedEdgeDevice.description}</span></p>
              {selectedEdgeDevice?.hardware &&
                <>
                  <FormattedMessage id="components.hardwareDetails" />:
                  <TableContainer component={Paper} sx={{ maxHeight: 240 }}>
                    <Table stickyHeader data-testid="hardware-table" aria-label="hardware table">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell><FormattedMessage id="components.hardware" /></TableCell>
                          <TableCell><FormattedMessage id="components.oemPartNumber" /></TableCell>
                          <TableCell><FormattedMessage id="components.classification" /></TableCell>
                          <TableCell><FormattedMessage id="components.createdTime" /></TableCell>
                          <TableCell><FormattedMessage id="components.active" /></TableCell>
                          <TableCell><FormattedMessage id="components.vendor" /></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{imageTemplate(selectedEdgeDevice.hardware)}</TableCell>
                          <TableCell>{selectedEdgeDevice.hardware.name}</TableCell>
                          <TableCell>{selectedEdgeDevice.hardware.oemPartNumber}</TableCell>
                          <TableCell>{selectedEdgeDevice.hardware.hardwareClassification}</TableCell>
                          <TableCell>{selectedEdgeDevice.hardware.createDtTm}</TableCell>
                          <TableCell>{selectedEdgeDevice.hardware.isActive ? "Yes" : "No"}</TableCell>
                          <TableCell>{selectedEdgeDevice.hardware.vendorName}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              }
              <br />
              <FormattedMessage id="components.assetObjects" />:
              <TableContainer component={Paper} sx={{ maxHeight: 240 }}>
                <Table stickyHeader data-testid="asset-objects-table" aria-label="asset objects table">
                  <TableHead>
                    <TableRow>
                      <TableCell><FormattedMessage id="components.id" /></TableCell>
                      <TableCell><FormattedMessage id="components.customerAssetId" /></TableCell>
                      <TableCell><FormattedMessage id="components.type" /></TableCell>
                      <TableCell><FormattedMessage id="components.subType" /></TableCell>
                      <TableCell><FormattedMessage id="components.productionState" /></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedEdgeDevice?.edgeDeviceAssetObjects?.map((row: any) => {
                      return (
                        <TableRow
                          data-testid="asset-object-node"
                          key={row.id}
                          hover
                        >
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.customerAssetId}</TableCell>
                          <TableCell>{row.type}</TableCell>
                          <TableCell>{row.subType}</TableCell>
                          <TableCell>{row.productionState}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

      {/*Start of delete confirmation dialog */}
      <DeleteConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleCancel={handleCloseDialog}
        title={<FormattedMessage id="components.wantToDelete" />}
        message={<span><FormattedMessage id="components.thisWillDelete" /> <FormattedMessage id="components.edgeDevice" /> <FormattedMessage id="components.permanently" /></span>}
        onConfirm={deleteEdgeDevicePermanently}
      />
      {/*end of delete confirmation dialog */}

    </>
  );
};
