import { ReactElement, SyntheticEvent, useState } from "react";
import { FormattedMessage } from "react-intl";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Paper, Box, Tab } from "@mui/material";
import { EdgeDevices } from "./edge-devices/edge-devices.component";
import { ProvisionDevices } from "./provision-devices/provision-devices.component";
import { ProvisionHistory } from "./provision-history/provision-history.component";
import { IotPlatforms } from "./iot-platforms/iot-platforms.component";
import { Templates } from "./templates/templates.component";
import { Hardwares } from "./hardwares/hardwares.component";
import { useAppSelector } from "../../store/hooks";
import { Role } from "../../shared/enums/role.enum";

export const EdgeDeviceManagement = (): ReactElement => {
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const [value, setValue] = useState('1');

  const handleEDMTabChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="edge-device-management">
        <div className="page-header">
          <h2><FormattedMessage id="components.edgeDeviceManagement" /></h2>
        </div>
        <Paper>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleEDMTabChange} variant="fullWidth" aria-label="edm tabs" data-testid="edm-tabs">
                <Tab label={<FormattedMessage id="components.edgeDevices" />} value="1" />
                <Tab label={<FormattedMessage id="components.provisionDevices" />} value="2" />
                <Tab label={<FormattedMessage id="components.provisionHistory" />} value="3" />
                {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
                  <Tab label={<FormattedMessage id="components.iotPlatforms" />} value="4" />
                }
                {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
                  <Tab label={<FormattedMessage id="components.templates" />} value="5" />
                }
                {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
                  <Tab label={<FormattedMessage id="components.hardwares" />} value="6" />
                }
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: 0, pt: 3 }} data-testid="edge-devices"><EdgeDevices /></TabPanel>
            <TabPanel value="2" sx={{ p: 0, pt: 3 }} data-testid="provision-devices"><ProvisionDevices /></TabPanel>
            <TabPanel value="3" sx={{ p: 0, pt: 3 }} data-testid="provision-history"><ProvisionHistory /></TabPanel>
            {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
              <>
                <TabPanel value="4" sx={{ p: 0, pt: 3 }} data-testid="iot-platforms"><IotPlatforms /></TabPanel>
                <TabPanel value="5" sx={{ p: 0, pt: 3 }} data-testid="templates"><Templates /></TabPanel>
                <TabPanel value="6" sx={{ p: 0, pt: 3 }} data-testid="hardwares"><Hardwares /></TabPanel>
              </>
            }
          </TabContext>
        </Paper>
      </div>
    </>
  );
};
