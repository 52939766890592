import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Grid, Button, TextField, Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateOrderPackages } from "../../store/thunks/order-packages/order-packages-thunks";
import { ComposeValidators, EndDateValidationDevice, Required } from "../../shared/form-validations/form-validations";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers";

interface IProps {
  selectedOrder: string
}

export const UpdateMultipleOrderPackageForm = (props: IProps) => {
  const dispatch = useAppDispatch();
  const selectedOrderPackages = useAppSelector(state => state.orderPackage.selectedOrderPackages);

  const onSubmit = (data: any) => {
    const orderPackages = selectedOrderPackages.map(item =>
      item.licenseType === 'lease'
        ? { ...item, endDate: data.endDate }
        : item
    );

    const finalData = {
      orderPackages : orderPackages,
      purchaseOrderId: props.selectedOrder
    }
    dispatch(updateOrderPackages(finalData));
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit} data-testid="update-multiple-order-packages-form">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={12}>
                <Field name="endDate" validate={ComposeValidators(Required, EndDateValidationDevice)}>
                  {({ input, meta }) => (
                    <>
                      <DesktopDatePicker
                        label={<FormattedMessage id="components.endDate" />}
                        inputFormat="MM/DD/YYYY"
                        {...input}
                        renderInput={(params: any) =>
                          <TextField
                            {...params}
                            variant="standard"
                            fullWidth
                            error={meta.error && meta.touched}
                          />
                        }
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
            </Grid>
          </LocalizationProvider>
          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
              <FormattedMessage id="components.reset" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
              <FormattedMessage id="components.update" />
            </Button>
          </Box>
        </form>
      )}
    />
  );
};
