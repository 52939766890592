import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IUser } from "../../shared/interfaces/user.interface";
import { onSearchInTable } from "../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { DeleteConfirmationDialog } from "../../shared/dialog-components/delete-confirmation-dialog";
import { deleteUser, getUsers } from "../../store/thunks/user-management/user-management-thunks";
import {
  setSelectedUser,
  setOpenAddUpdateUserDialog,
  setOpenViewUserDialog,
  setOpenDeleteConfirmationDialog,
  setEditUser
} from "../../store/slices/user-management"
import { TablePaginationActions } from "../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";
import { AddUpdateUserForm } from "./add-update-user-form.component";
import { Role } from "../../shared/enums/role.enum";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const Users = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const users = useAppSelector(state => state.userManagement.users);
  const editUser = useAppSelector(state => state.userManagement.editUser);
  const selectedUser = useAppSelector(state => state.userManagement.selectedUser);
  const openViewUserDialog = useAppSelector(state => state.userManagement.openViewUserDialog);
  const openAddUserDialog = useAppSelector(state => state.userManagement.openAddUpdateUserDialog);
  const openDeleteConfirmationDialog = useAppSelector(state => state.userManagement.openDeleteConfirmationDialog);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const [searchUsersList, setSearchUsersList] = useState<IUser[]>(users);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IUser>('displayName');

  const columns: Column[] = [
    { id: 'displayName', label: intl.formatMessage({ id: 'components.user' }) },
    { id: 'email', label: intl.formatMessage({ id: 'components.emailAddress' }) },
    { id: 'userRoleNames', label: intl.formatMessage({ id: 'components.roles' }) },
    { id: 'trial', label: intl.formatMessage({ id: 'components.trialUser' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    loadUsers();
  }, [selectedAppCustomer]);

  useEffect(() => {
    setSearchUsersList(users);
  }, [users]);

  useEffect(() => {
    setPage(0);
  },[searchUsersList]);

  const loadUsers = () => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(getUsers(cId));
    }
  }

  const handleCloseDialog = () => {
    dispatch(setOpenViewUserDialog(false));
    dispatch(setOpenAddUpdateUserDialog(false));
    dispatch(setOpenDeleteConfirmationDialog(false));
    dispatch(setSelectedUser({}));
    dispatch(setEditUser(false));
  }

  const onViewUserDetails = (data: IUser) => {
    dispatch(setOpenViewUserDialog(true));
    dispatch(setSelectedUser(data));
  }

  const addUser = () => {
    dispatch(setOpenAddUpdateUserDialog(true));
  }

  const onUpdateUser = (data: IUser) => {
    dispatch(setOpenAddUpdateUserDialog(true));
    dispatch(setEditUser(true));
    dispatch(setSelectedUser(data));
  }

  const onDeleteUser = (data: IUser) => {
    dispatch(setOpenDeleteConfirmationDialog(true));
    dispatch(setSelectedUser(data));
  }

  const deleteUserPermanently = () => {
    dispatch(deleteUser(selectedUser));
  }

  const actionTemplateUsers = (rowData: IUser) => {
    return <span data-testid="user-actions" className="list-action">
      <Link role="button" data-testid="view-user" aria-label="view-user" component="button" variant="body2" onClick={() => onViewUserDetails(rowData)} > <VisibilityIcon /> </Link>
      {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
        <>
          <Link component="button" data-testid="update-user" variant="body2" onClick={() => onUpdateUser(rowData)} > <EditIcon /> </Link>
          <Link role="button" data-testid="delete-user" component="button" variant="body2" onClick={() => onDeleteUser(rowData)} > <ClearIcon /> </Link>
        </>
      }
    </span>;
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchUsersList(onSearchInTable(users, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IUser,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const displayUser = (data: any) => {
    return <div>
      <span style={{ fontWeight: '500' }}>{data.displayName}</span>
      <br /><span style={{ fontSize: '13px' }}> {data.userName}</span>
    </div>;
  }

  return (
    <>
      <div data-testid="users">
        <Box>
          <TableContainer component={Paper}>
            <div className="table-head-links">
              <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadUsers()} > <CachedIcon /> </Link></div>
              {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
                <div data-testid="add-new-btn" className="add-link"><Link component="button" role="button" aria-label="add-user" variant="body1" underline="none" onClick={() => addUser()} ><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.user" /> <AddIcon className="add-icon" /> </Link></div>
              }
            </div>
            <div data-testid="searchbox" style={{ padding: "10px" }}>
              <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
            </div>
            <Table sx={{ minWidth: 650 }} data-testid="users-table" aria-label="users table">
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
              />
              <TableBody>
                {stableSort(searchUsersList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow data-testid="user-node" key={row.userId} >
                        <TableCell>{displayUser(row)}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.userRoleNames.join(", ")}</TableCell>
                        <TableCell>{row.trial ? "Yes" : "No"}</TableCell>
                        <TableCell>{actionTemplateUsers(row)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    data-testid="pagination-control"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={columns.length}
                    count={searchUsersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </div>

      {/*start of add/update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openAddUserDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
          {editUser ?
            <><FormattedMessage id="components.update" /> <FormattedMessage id="components.user" /></>
            : <><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.user" /></>
          }
        </BootstrapDialogTitle>
        <DialogContent>
          <AddUpdateUserForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of add/update dialog */}

      {/*start of view details dialog */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewUserDialog}
        data-testid="view-user-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedUser &&
            <>
              <p><FormattedMessage id="components.username" />: <span style={{ fontWeight: 'bold' }}>{selectedUser.userName}</span></p>
              <p><FormattedMessage id="components.name" />: <span style={{ fontWeight: 'bold' }}>{selectedUser.firstName + ' ' + selectedUser.lastName}</span></p>
              <p><FormattedMessage id="components.emailAddress" />: <span style={{ fontWeight: 'bold' }}>{selectedUser.email}</span></p>
              <p><FormattedMessage id="components.customer" />: <span style={{ fontWeight: 'bold' }}>{selectedUser.customerName}</span></p>
              <p><FormattedMessage id="components.roles" />: <span style={{ fontWeight: 'bold' }}>{selectedUser.userRoleNames?.join(", ")}</span></p>
              <p><FormattedMessage id="components.trialUser" />: <span style={{ fontWeight: 'bold' }}>{selectedUser.trial ? "Yes" : "No"}</span></p>
              {selectedUser.trial && selectedUser.trialExpiration &&
                <p><FormattedMessage id="components.trialExpiration" />: <span className="fw-bold">{selectedUser.trialExpiration.toString()}</span></p>
              }
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

      {/*Start of delete confirmation dialog */}
      <DeleteConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleCancel={handleCloseDialog}
        title={<FormattedMessage id="components.wantToDelete" />}
        message={<span><FormattedMessage id="components.thisWillDelete" /> <FormattedMessage id="components.user" /> <FormattedMessage id="components.permanently" /></span>}
        onConfirm={deleteUserPermanently}
      />
      {/*end of delete confirmation dialog */}

    </>
  );
};
