import {
  Box,
  FormControl,
  Link,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Radio,
  Checkbox,
  Button,
  IconButton,
  TableSortLabel,
  TableHead
} from "@mui/material";
import { ChangeEvent, Fragment, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getPurchaseOrders } from "../../store/thunks/purchase-orders/purchase-orders-thunks";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";
import { setOrderPackages } from "../../store/slices/order-packages";
import { getOrderPackages } from "../../store/thunks/order-packages/order-packages-thunks";
import { getUsers } from "../../store/thunks/user-management/user-management-thunks";
import { IUser } from "../../shared/interfaces/user.interface";
import { getAssignedLicenses, updateAssignedLicenses } from "../../store/thunks/license-users/license-users-thunks";
import { IAssignedLicense } from "../../shared/interfaces/assigned-license.interface";
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UploadIcon from '@mui/icons-material/Upload';
import { setSelectedOrder } from "../../store/slices/license-users";
import { Role } from "../../shared/enums/role.enum";
import { visuallyHidden } from '@mui/utils';
import { setPurchaseOrders } from "../../store/slices/purchase-orders";

type Column = {
  id: string,
  label: string,
  sortable?: string,
  width?: string
}

interface ColumnUser {
  id: keyof ColumnData,
  label: string,
  sortable?: string,
  width?: string
}

type Order = 'asc' | 'desc';

interface ColumnData {
  displayName: string;
  customLicense: any;
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof ColumnData
  ) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  orderUser: Order;
  orderByUser: string;
  rowCount: number;
  disabledCheck: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const intl = useIntl();
  const { onSelectAllClick, orderUser, orderByUser, numSelected, rowCount, disabledCheck, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof ColumnData) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const columns: readonly ColumnUser[] = [
    { id: 'displayName', label: intl.formatMessage({ id: 'components.user' }) },
    { id: 'customLicense', label: intl.formatMessage({ id: 'components.customLicense' }), sortable: 'disable', width: '130px' },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            disabled={disabledCheck}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all users',
            }}
          />
        </TableCell>
        {columns.map((headCell) => (
          <Fragment key={headCell.id}>
            {headCell.sortable === 'disable' ?
              <TableCell width={headCell.width}>
                {headCell.label}
              </TableCell>
              :
              <TableCell
                width={headCell.width}
                key={headCell.id}
                sortDirection={orderByUser === headCell.id ? orderUser : false}
              >
                <TableSortLabel
                  active={orderByUser === headCell.id}
                  direction={orderByUser === headCell.id ? orderUser : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderByUser === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {orderUser === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

            }
          </Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const AssignLicense = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const users = useAppSelector(state => state.userManagement.users);
  const assignedLicenses = useAppSelector(state => state.licenseUser.assignedLicenses);
  const selectedOrder = useAppSelector(state => state.licenseUser.selectedOrder);
  const purchaseOrders = useAppSelector(state => state.purchaseOrder.purchaseOrders);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const [order, setOrder] = useState<Order>('asc');
  const [orderUser, setOrderUser] = useState<Order>('asc');
  const [orderByUser, setOrderByUser] = useState<keyof IUser>('displayName');
  const [orderBy, setOrderBy] = useState<keyof IAssignedLicense>('packageName');
  const [selectedOrderPackage, setSelectedOrderPackage] = useState<IAssignedLicense | null>(null);
  const [assignedLicensesUpdated, setAssignedLicensesUpdated] = useState<IAssignedLicense[]>([]);

  const columns: Column[] = [
    { id: 'r-btn', label: '', sortable: 'disable' },
    { id: 'packageName', label: intl.formatMessage({ id: 'components.package' }) },
    { id: 'license', label: intl.formatMessage({ id: 'components.license' }) },
    { id: 'customLicense', label: intl.formatMessage({ id: 'components.customLicense' }), sortable: 'disable', width: '130px' },
    { id: 'assignment', label: intl.formatMessage({ id: 'components.assignment' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(setPurchaseOrders([]));      
      dispatch(setOrderPackages([]));
      dispatch(setSelectedOrder(null));
      dispatch(getPurchaseOrders(cId));
      dispatch(getUsers(cId));
    }
  }, [selectedAppCustomer]);

  useEffect(() => {
    setAssignedLicensesUpdated(assignedLicenses);
  }, [assignedLicenses]);

  const handleOrderChange = (value: any) => {
    const order = purchaseOrders.find(item => item.purchaseOrderId === value);
    setSelectedOrderPackage(null);
    dispatch(setOrderPackages([]));
    dispatch(setSelectedOrder(order));
    dispatch(getOrderPackages(value));
    dispatch(getAssignedLicenses(value));
  }

  const onCancelLicense = () => {
    const selectedOP: any = assignedLicenses.find(item => item.orderPackageId === selectedOrderPackage?.orderPackageId);
    setSelectedOrderPackage(selectedOP);
    setAssignedLicensesUpdated(assignedLicenses);
  }

  const onSaveLicense = () => {
    const finalData = {
      purchaseOrderId: selectedOrder?.purchaseOrderId,
      assignLicenses: assignedLicensesUpdated
    };

    dispatch(updateAssignedLicenses(finalData))
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IAssignedLicense,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRequestSortUser = (
    event: MouseEvent<unknown>,
    property: keyof IUser,
  ) => {
    const isAsc = orderByUser === property && orderUser === 'asc';
    setOrderUser(isAsc ? 'desc' : 'asc');
    setOrderByUser(property);
  };

  //async file reader
  const packageLicenseUpload = (event: any) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    if (file) {
      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          const result = reader.abort;
          reject("Error in reading file");
        };
        reader.onload = () => {
          const finalData = assignedLicensesUpdated.map(obj =>
            obj.orderPackageId === selectedOrderPackage?.orderPackageId
              ? { ...obj, packageLicense: reader.result?.toString().split(',').pop() } : obj
          );
          setAssignedLicensesUpdated(finalData);
        };
        reader.readAsText(file, "UTF-8");
      });
    }
  }

  const packagesActionTemplate = (data: any) => {
    if (!selectedOrderPackage || !data.externalLicensing) {
      return '';
    }
    let result: any;
    if (selectedOrderPackage.externalLicensing) {
      if (data.packageLicense) {
        let fileName = selectedOrder?.orderNo + "_" + data.packageName + "_License.txt";
        result = <span>
          <Link
            sx={{ cursor: 'pointer', mr: 1 }}
            data-testid="view-license"
            download={fileName}
            title={intl.formatMessage({ id: 'components.downloadLicenseFile' })}
            href={`data:text/plain;charset=utf-8,${data.packageLicense}`}
            onClick={(event) => event.stopPropagation()} >
            <VisibilityIcon />
          </Link>
          <Link
            sx={{ cursor: 'pointer' }}
            data-testid="delete-license"
            onClick={(e) => deletePackageLicense(e)}
            title={intl.formatMessage({ id: "components.deleteLicenseFile" })} >
            <ClearIcon />
          </Link>
        </span>;
      }
      return <div className="list-action">
        <IconButton sx={{ verticalAlign: 'baseline', mr: 1, p: 0 }} color="primary" aria-label="upload license" component="label">
          <input hidden accept=".txt" type="file" onChange={(e) => packageLicenseUpload(e)} />
          <UploadIcon />
        </IconButton>
        {result}
      </div>;
    }

  }
  const deletePackageLicense = (e: any) => {
    const finalData = assignedLicensesUpdated.map(obj =>
      obj.orderPackageId === selectedOrderPackage?.orderPackageId
        ? { ...obj, packageLicense: '' } : obj
    );
    setAssignedLicensesUpdated(finalData);
  }

  const assignmentUsers = (data: any) => {
    return data.assignedUserLicenseDetails.length + ' of ' + data.userLimit;
  }

  const onOrderPackageSelect = (data: any) => {
    if (data === selectedOrderPackage) {
      setSelectedOrderPackage(null);
    } else {
      setSelectedOrderPackage(data);
    }
  }

  const deleteUserLicense = (e: any, data: any) => {
    const selectedOP = assignedLicensesUpdated.find(item => item.orderPackageId === selectedOrderPackage?.orderPackageId);
    const userLicenseData = selectedOP?.assignedUserLicenseDetails.map(item =>
      item.userId === data.userId ?
        { ...item, userLicense: '' } : item
    );

    const finalData: any = assignedLicensesUpdated.map(obj =>
      obj.orderPackageId === selectedOrderPackage?.orderPackageId
        ?
        { ...obj, assignedUserLicenseDetails: userLicenseData }
        : obj
    );
    setAssignedLicensesUpdated(finalData);

  }

  const getLicenseFile = (userId: string) => {
    let result: any = '';

    assignedLicensesUpdated.map(obj => {
      if (obj.orderPackageId === selectedOrderPackage?.orderPackageId) {
        obj.assignedUserLicenseDetails.map(item => {
          if (item.userId === userId) {
            return result = item.userLicense;
          }
        })
      }
    })
    return result;
  }

  //async file reader
  const userLicenseUpload = (event: any, userId: string) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    if (file) {
      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          const result = reader.abort;
          reject("Error in reading file");
        };
        reader.onload = () => {
          const selectedOP = assignedLicensesUpdated.find(item => item.orderPackageId === selectedOrderPackage?.orderPackageId);
          const userLicenseData = selectedOP?.assignedUserLicenseDetails.map(item =>
            item.userId === userId ?
              { ...item, userLicense: reader.result?.toString().split(',').pop() } : item
          );

          const finalData: any = assignedLicensesUpdated.map(obj =>
            obj.orderPackageId === selectedOrderPackage?.orderPackageId
              ?
              { ...obj, assignedUserLicenseDetails: userLicenseData }
              : obj
          );
          setAssignedLicensesUpdated(finalData);
        };
        reader.readAsText(file, "UTF-8");
      });
    }
  }

  const usersActionTemplate = (data: any) => {
    if (!selectedOrderPackage || !selectedOrderPackage.externalLicensing) {
      return '';
    }

    let license = '';
    let result: any = '';
    if (selectedOrderPackage !== null) {
      license = getLicenseFile(data.userId);

      if (license !== '' && license !== undefined && license !== null) {
        let fileName = data.email + "_" + selectedOrder?.orderNo + "_" + selectedOrderPackage.packageName + "_License.txt";
        result = <span>
          <Link
            sx={{ cursor: 'pointer', mr: 1 }}
            data-testid="view-license"
            download={fileName}
            title={intl.formatMessage({ id: 'components.downloadLicenseFile' })}
            href={`data:text/plain;charset=utf-8,${license}`}
            onClick={(event) => event.stopPropagation()} >
            <VisibilityIcon />
          </Link>
          <Link
            sx={{ cursor: 'pointer' }}
            data-testid="delete-license"
            onClick={(e) => deleteUserLicense(e, data)}
            title={intl.formatMessage({ id: "components.deleteLicenseFile" })} >
            <ClearIcon />
          </Link>
        </span>
      }
      return <div className="list-action">
        <IconButton sx={{ verticalAlign: 'baseline', mr: 1, p: 0 }} color="primary" aria-label="upload license" component="label">
          <input hidden accept=".txt" type="file" onChange={(e) => userLicenseUpload(e, data.userId)} />
          <UploadIcon />
        </IconButton>
        {result}
      </div>;
    }
  }

  const displayUser = (data: any) => {
    return <div>
      <span style={{ fontWeight: '500' }}>{data.displayName}</span>
      <br /><span style={{ fontSize: '13px' }}> {data.email}</span>
    </div>;
  }

  const isSelected = (userId: string) => {
    if (selectedOrderPackage) {
      const selectedOP = assignedLicensesUpdated.find(item => item.orderPackageId === selectedOrderPackage?.orderPackageId);
      return selectedOP?.assignedUserLicenseDetails.findIndex(item => item.userId === userId) !== -1;
    }
    else {
      return false;
    }
  }

  const handleClick = (event: MouseEvent<unknown>, userId: string) => {
    const selectedOP = assignedLicensesUpdated.find(item => item.orderPackageId === selectedOrderPackage?.orderPackageId);
    const selectedIndex = selectedOP?.assignedUserLicenseDetails.findIndex(item => item.userId === userId);

    if (selectedOP && selectedOP.assignedUserLicenseDetails?.length >= selectedOP?.userLimit) {
      if (selectedIndex === -1) {
        return;
      }
    }

    let newSelected: any[] = [];

    if (!selectedOP || selectedIndex === -1) {
      newSelected = newSelected.concat(selectedOP?.assignedUserLicenseDetails, { userId: userId });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedOP?.assignedUserLicenseDetails.slice(1));
    } else if (selectedIndex === selectedOP?.assignedUserLicenseDetails.length - 1) {
      newSelected = newSelected.concat(selectedOP?.assignedUserLicenseDetails.slice(0, -1));
    } else if (selectedIndex && selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedOP?.assignedUserLicenseDetails.slice(0, selectedIndex),
        selectedOP?.assignedUserLicenseDetails.slice(selectedIndex + 1),
      );
    }

    const finalData = assignedLicensesUpdated.map(obj =>
      obj.orderPackageId === selectedOrderPackage?.orderPackageId
        ? { ...obj, assignedUserLicenseDetails: newSelected } : obj
    );

    let tempOrderPackage: any = selectedOrderPackage;
    tempOrderPackage = { ...tempOrderPackage, assignedUserLicenseDetails: newSelected };
    setSelectedOrderPackage(tempOrderPackage);

    setAssignedLicensesUpdated(finalData);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    let tempOrderPackage: any = selectedOrderPackage;
    if (event.target.checked) {
      const selectedOP = assignedLicensesUpdated.find(item => item.orderPackageId === selectedOrderPackage?.orderPackageId);
      const availableLimit = selectedOP?.userLimit;

      const newSelected = users.slice(0, availableLimit);
      tempOrderPackage = { ...tempOrderPackage, assignedUserLicenseDetails: newSelected };

      const finalData: any = assignedLicensesUpdated.map(obj =>
        obj.orderPackageId === selectedOrderPackage?.orderPackageId
          ? { ...obj, assignedUserLicenseDetails: newSelected } : obj
      );
      setSelectedOrderPackage(tempOrderPackage);
      setAssignedLicensesUpdated(finalData);
      return;
    }
    tempOrderPackage = { ...tempOrderPackage, assignedUserLicenseDetails: [] };
    const finalDataUncheck: any = assignedLicensesUpdated.map(obj =>
      obj.orderPackageId === selectedOrderPackage?.orderPackageId
        ? { ...obj, assignedUserLicenseDetails: [] } : obj
    );
    setSelectedOrderPackage(tempOrderPackage);
    setAssignedLicensesUpdated(finalDataUncheck);
  };

  return (
    <>
      <div data-testid="assign-license">
        <Box>
          <FormControl variant="standard" sx={{ m: 2, mt: 0, width: 350 }} data-testid="order-select-combobox">
            <InputLabel id="select-order"><FormattedMessage id="components.order" /></InputLabel>
            <Select
              labelId="select-order"
              role="combobox"
              aria-label="select order"
              inputProps={{ "data-testid": "select-order" }}
              value={selectedOrder ? selectedOrder.purchaseOrderId : null}
              onChange={(e) => handleOrderChange(e.target.value)}
            >
              {purchaseOrders.map((row: any) => (
                <MenuItem key={row.purchaseOrderId} value={row.purchaseOrderId}>{row.orderNo}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedOrder ?
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12} sm={7}>
                <Box data-testid="purchases-head" sx={{ ml: 2 }}>
                  <strong><FormattedMessage id="components.purchases" /></strong>
                </Box>
                <TableContainer component={Paper} sx={{ mt: 0, maxHeight: 340 }}>
                  <Table size="small" stickyHeader data-testid="purchases-table" aria-label="user licenses table">
                    <SortableTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      columns={columns}
                    />
                    <TableBody>
                      {stableSort(assignedLicensesUpdated, getComparator(order, orderBy))
                        .map((row, index) => {
                          return (
                            <TableRow data-testid="op-node" key={index} >
                              <TableCell><Radio checked={row.orderPackageId === selectedOrderPackage?.orderPackageId} onClick={() => onOrderPackageSelect(row)} disabled={!loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN)} /></TableCell>
                              <TableCell>{row.packageName}</TableCell>
                              <TableCell>{row.license}</TableCell>
                              <TableCell>{packagesActionTemplate(row)}</TableCell>
                              <TableCell>{assignmentUsers(row)}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box data-testid="users-head" sx={{ ml: 2 }}>
                  <strong><FormattedMessage id="components.users" /></strong>
                </Box>

                <TableContainer component={Paper} sx={{ maxHeight: 340 }}>
                  <Table size="small" stickyHeader data-testid="users-table" aria-label="users table">
                    <EnhancedTableHead
                      numSelected={selectedOrderPackage ? selectedOrderPackage?.assignedUserLicenseDetails.length : 0}
                      orderUser={orderUser}
                      orderByUser={orderByUser}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSortUser}
                      rowCount={users.length}
                      disabledCheck={(selectedOrderPackage ? false : true) || !loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN)}
                    />
                    <TableBody>
                      {stableSort(users, getComparator(orderUser, orderByUser))
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.userId);
                          const labelId = `user-checkbox-${index}`;
                          return (
                            <TableRow data-testid="user-node" key={row.userId} >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  onClick={(event) => handleClick(event, row.userId)}
                                  disabled={(selectedOrderPackage ? false : true) || !loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN)}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                              </TableCell>
                              <TableCell>{displayUser(row)}</TableCell>
                              <TableCell>{isItemSelected ? usersActionTemplate(row) : ''}</TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>

              </Grid>
              <Grid item xs={12} sm={12}>
                {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
                  <Box display="flex" justifyContent="flex-end" sx={{ mb: 2, mr: 2 }}>
                    <Button onClick={() => onCancelLicense()} variant="contained" color="error" sx={{ mr: 1 }} data-testid="cancel-button" disabled={selectedOrderPackage === null}>
                      <FormattedMessage id="components.reset" />
                    </Button>
                    <Button onClick={() => onSaveLicense()} autoFocus variant="contained" color="primary" data-testid="save-button" disabled={selectedOrderPackage === null}>
                      <FormattedMessage id="components.save" />
                    </Button>
                  </Box>
                }
              </Grid>
            </Grid>
            :
            <Box sx={{ p: 2, pb: 3 }}>
              <FormattedMessage id="components.pleaseSelectOrder" />
            </Box>
          }
        </Box>
      </div>
    </>
  );
};