import { ReactElement, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box, Select, InputLabel, MenuItem, FormControl } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ICustomer } from "../../shared/interfaces/customer.interface";
import { addCustomer, updateCustomer } from "../../store/thunks/customers/customers-thunks";
import { ComposeValidators, EmailDomains, IpAddress, Required } from "../../shared/form-validations/form-validations";
import { getDataCenters } from "../../store/thunks/data-centers/data-centers-thunks";
import { getTenantType } from "../../store/thunks/tenant-detail/tenant-detail-thunks";
import { getEmailDomainBlocklist } from "../../store/thunks/email-domain-blocklist/email-domain-blocklist-thunks";

export const AddUpdateCustomerForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedCustomer = useAppSelector(state => state.customer.selectedCustomer);
  const editCustomer = useAppSelector(state => state.customer.editCustomer);
  const dataCenters = useAppSelector(state => state.dataCenter.dataCenters);
  const tenantTypes = useAppSelector(state => state.tenantDetail.tenantType);
  const emailDomainBlocklist = useAppSelector(state => state.emailDomainBlocklist.emailDomainBlocklist);

  useEffect(() => {
    dispatch(getDataCenters());
    dispatch(getTenantType());
    dispatch(getEmailDomainBlocklist());
  }, []);
  
  const onSubmit = (data: ICustomer) => {
    if (editCustomer) {
      dispatch(updateCustomer(data));
    }
    else {
      dispatch(addCustomer(data));
    }
  };
 
  return (
      <Form
        onSubmit={onSubmit}
        initialValues={selectedCustomer}
        render={({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit} data-testid="customer-form">
            <Field name="name" validate={Required}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.customer" />}
                    variant="standard"
                    fullWidth
                    role="textbox"                    
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '0px' }}
                    disabled={editCustomer}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Field name="companyCode" validate={Required}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.sapMasterNumber" />}
                    variant="standard"
                    fullWidth
                    role="textbox"
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '15px' }}
                    disabled={editCustomer}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Field name="dataCenterId" validate={Required}>
              {({ input, meta }) => (
                <FormControl variant="standard" fullWidth sx={{marginTop: '15px' }}>
                  <InputLabel id="dataCenter" error={meta.error && meta.touched}><FormattedMessage id="components.dataCenter" /></InputLabel>
                  <Select
                    {...input}                    
                    labelId="dataCenter"
                    error={meta.error && meta.touched}
                  >
                    {dataCenters && dataCenters.map((row) => (
                      <MenuItem key={row.dataCenterId} value={row.dataCenterId}>{row.name}</MenuItem>
                    ))}
                  </Select>
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </FormControl>
              )}
            </Field>
            <Field name="isolatedEc2ip" validate={ComposeValidators(Required, IpAddress)}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    label={<FormattedMessage id="components.isolatedEc2IP" />}
                    variant="standard"
                    fullWidth
                    role="textbox"
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '15px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Field name="emailDomainWhiteList" validate={ComposeValidators(Required, EmailDomains(emailDomainBlocklist))}>
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}             
                    label={<FormattedMessage id="components.allowedEmailDomains" />}
                    variant="standard"
                    fullWidth
                    role="textbox"
                    aria-label="customer-email-domain"
                    error={meta.error && meta.touched}
                    autoComplete='off'
                    sx={{ marginTop: '15px' }}
                  />
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                </>
              )}
            </Field>
            <Field name="tenantType" validate={Required}>
              {({ input, meta }) => (
                <FormControl variant="standard" fullWidth sx={{ marginTop: '15px' }}>
                  <InputLabel id="tenantType" error={meta.error && meta.touched}><FormattedMessage id="components.tenantType" /></InputLabel>
                  <Select
                    {...input}                    
                    labelId="tenantType"
                    disabled={editCustomer && selectedCustomer && selectedCustomer.isTenantVMDeployed}
                    error={meta.error && meta.touched}
                  >
                    {tenantTypes && tenantTypes.map((row) => (
                      <MenuItem key={row.tenantTypeCode} value={row.tenantTypeCode}>{row.tenantTypeDisplayName}</MenuItem>
                    ))}
                  </Select>
                  {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </FormControl>
              )}
            </Field>

            <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
              <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }} data-testid="reset-button">
                <FormattedMessage id="components.reset" />
              </Button>
              <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button">
                {editCustomer ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
              </Button>
            </Box>

          </form>
        )}
      />
  );
};
