import { ReactElement, SyntheticEvent, useState } from "react";
import { FormattedMessage } from "react-intl";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Paper, Box, Tab } from "@mui/material";
import { PurchaseOrders } from "./purchase-orders.component";
import { OrderPackages } from "./order-packages.component";

export const Purchases = (): ReactElement => {
  const [value, setValue] = useState('1');

  const handlePurchaseTabChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="purchases">
        <div className="page-header">
          <h2><FormattedMessage id="components.purchases" /></h2>
        </div>
        <Paper>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handlePurchaseTabChange} variant="fullWidth" aria-label="purchases tabs" data-testid="purchases-tabs">
                <Tab label={<FormattedMessage id="components.orders" />} value="1" />
                <Tab label={<FormattedMessage id="components.packages" />} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: 0, pt:3 }} data-testid="purchase-orders-panel"><PurchaseOrders  /></TabPanel>
            <TabPanel value="2" sx={{ p: 0, pt:3 }} data-testid="order-packages-panel"><OrderPackages /></TabPanel>
          </TabContext>
        </Paper>
      </div>
    </>
  );
};
