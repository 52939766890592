import { dataAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setEditEdgeDevice, setOpenAddUpdateEdgeDeviceDialog, setOpenDeleteConfirmationDialog, setSelectedEdgeDevice } from "../../slices/edge-devices";
import moment from "moment";

export const getEdgeDevices = createAsyncThunk(
  "edge-device/getEdgeDevices",
  async (customerId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`EdgeDevice/GetEdgeDevicesByCustomerId?CustomerId=${customerId}`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getEdgeDeviceDetails = createAsyncThunk(
  "edge-device/getEdgeDeviceDetails",
  async (edgeDeviceId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`EdgeDevice/GetEdgeDeviceDetails?EdgeDeviceId=${edgeDeviceId}`);
      dispatch(setLoading(false));
      let finalData = result.data;
      finalData.hardware.createDtTm = finalData?.hardware?.createDtTm ? moment(finalData?.hardware.createDtTm).format("MM/DD/YYYY, H:mm:ss") : "";
      return finalData;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addEdgeDevice = createAsyncThunk(
  "edge-device/addEdgeDevice",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`EdgeDevice/AddEdgeDevice`, requestData);
      dispatch(setOpenAddUpdateEdgeDeviceDialog(false));
      dispatch(getEdgeDevices(requestData.customerId));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.edgeDeviceCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateEdgeDevice = createAsyncThunk(
  "edge-device/updateEdgeDevice",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`EdgeDevice/UpdateEdgeDevice`, requestData);
      dispatch(setSelectedEdgeDevice({}));
      dispatch(setOpenAddUpdateEdgeDeviceDialog(false));
      dispatch(setEditEdgeDevice(false));
      dispatch(getEdgeDevices(requestData.customerId));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.edgeDeviceUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteEdgeDevice = createAsyncThunk(
  "edge-device/deleteEdgeDevice",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`EdgeDevice/DeleteEdgeDevice?EdgeDeviceId=` + requestData.edgeDeviceId);
        dispatch(setSelectedEdgeDevice({}));
        dispatch(setOpenDeleteConfirmationDialog(false));
        dispatch(getEdgeDevices(requestData.customerId));
        dispatch(setSnackbarAlert({ messageType: 'success', message: "components.edgeDeviceDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
