import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination
} from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import { IEdgeDevice } from "../../../shared/interfaces/edge-device.interface";
import { onSearchInTable } from "../../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../shared/dialog-components/custom-dialog";
import { getEdgeDevices } from "../../../store/thunks/edge-devices/edge-devices-thunks";
import { TablePaginationActions } from "../../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../../shared/table-sorting/table-sort";
import { ProvisionEdgeDeviceForm } from "./provision-edge-device-form.component";
import { IHardware } from "../../../shared/interfaces/hardware.interface";
import { setEdgeDeviceConfigChangeDetail, setIotPlatformMicroServiceForEdgeDevice, setOpenViewProvisionDeviceDialog, setSelectedTemplate } from "../../../store/slices/provision-edge-devices";
import { setSelectedEdgeDevice } from "../../../store/slices/edge-devices";
import { deleteEdgeDeviceConfigChangeDetails } from "../../../store/thunks/provision-edge-devices/provision-edge-devices-thunks";
import { Role } from "../../../shared/enums/role.enum";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const ProvisionDevices = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const edgeDevices = useAppSelector(state => state.edgeDevice.edgeDevices);
  const openViewProvisionDeviceDialog = useAppSelector(state => state.provisionEdgeDevice.openViewProvisionDeviceDialog);
  const edgeDeviceConfigChangeDetail = useAppSelector(state => state.provisionEdgeDevice.edgeDeviceConfigChangeDetail);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const [searchEdgeDevicesList, setSearchEdgeDevicesList] = useState<IEdgeDevice[]>(edgeDevices);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IEdgeDevice>('azureIotdeviceName');

  const columns: Column[] = [
    { id: 'azureIotdeviceName', label: intl.formatMessage({ id: 'components.iotDeviceName' }) },
    { id: 'oempartNumber', label: intl.formatMessage({ id: 'components.oemPartNumber' }) },
    { id: 'oemserialNumber', label: intl.formatMessage({ id: 'components.oemSerialNumber' }) },
    { id: 'ioTplatformName', label: intl.formatMessage({ id: 'components.iotPlatform' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(getEdgeDevices(cId));
    }
  }, [selectedAppCustomer]);

  useEffect(() => {
    setSearchEdgeDevicesList(edgeDevices);   
  }, [edgeDevices]);

  useEffect(() => {
    setPage(0);
  },[searchEdgeDevicesList]);

  const loadEdgeDevices = () => {
    const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
    dispatch(getEdgeDevices(cId))
  }

  const requestProvisionDetails = (data: any) => {
    dispatch(setOpenViewProvisionDeviceDialog(true));
    dispatch(setSelectedEdgeDevice(data));
  }

  const handleCloseDialog = () => {
    dispatch(deleteEdgeDeviceConfigChangeDetails(edgeDeviceConfigChangeDetail.edgeDeviceConfigChangeDetailsId));
    dispatch(setOpenViewProvisionDeviceDialog(false));
    dispatch(setSelectedEdgeDevice({}));
    dispatch(setIotPlatformMicroServiceForEdgeDevice(null));
    dispatch(setEdgeDeviceConfigChangeDetail({}));
    dispatch(setSelectedTemplate(''));
  }

  const actionTemplateProvisionEdgeDevices = (rowData: IEdgeDevice) => {
    if(loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN)) {
      return <span data-testid="ed-actions" className="list-action">
        <Link role="button" data-testid="provision-ed" aria-label="view-ed" component="button" variant="body2" onClick={() => requestProvisionDetails(rowData)} > <FormattedMessage id="components.provision" /> </Link>
      </span>;
    }
    else {
      return '';
    }
    
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchEdgeDevicesList(onSearchInTable(edgeDevices, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IEdgeDevice,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const imageTemplate = (rowData: IHardware) => {
    return <img src={`data:image/png;base64,${rowData.thumbNailImage}`} width="50" alt={rowData.thumbNailImageName} />;
  }

  return (
    <>
      <div data-testid="provision-devices">
      <Box>
          <TableContainer component={Paper}>
            <div className="table-head-links">
              <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadEdgeDevices()} > <CachedIcon /> </Link></div>
              <div></div>
            </div>
            <div data-testid="searchbox" style={{ padding: "10px" }}>
              <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
            </div>
            <Table sx={{ minWidth: 650 }} data-testid="edge-device-table" aria-label="edge devices table">
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
              />
              <TableBody>
                {stableSort(searchEdgeDevicesList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow data-testid="ed-node" key={row.edgeDeviceId} >
                        <TableCell>{row.azureIotdeviceName}</TableCell>
                        <TableCell>{row.oempartNumber}</TableCell>
                        <TableCell>{row.oemserialNumber}</TableCell>
                        <TableCell>{row.ioTplatformName}</TableCell>
                        <TableCell>{actionTemplateProvisionEdgeDevices(row)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    data-testid="pagination-control"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={columns.length} 
                    count={searchEdgeDevicesList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </div>

      {/*start of provision details dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="lg"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewProvisionDeviceDialog}
        data-testid="provision-details-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
           <FormattedMessage id="components.provisionDeviceDetails" />
        </BootstrapDialogTitle>
        <DialogContent>
          <ProvisionEdgeDeviceForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of provision details dialog */}
    </>
  );
};
