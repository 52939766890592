import { createSlice } from '@reduxjs/toolkit';
import { IOrderPackageMinLimit } from '../../shared/interfaces/order-package-min-limit.interface';
import { IOrderPackage } from "../../shared/interfaces/order-package.interface";
import { addOrderPackage, deleteOrderPackage, getOrderPackageMinLimit, getOrderPackages, updateOrderPackage } from '../thunks/order-packages/order-packages-thunks';

interface OrderPackageState {
    orderPackages: IOrderPackage[],
    orderPackageMinLimit: IOrderPackageMinLimit | null
    selectedOrderPackage: any,
    selectedOrderPackages: IOrderPackage[],
    openViewOrderPackageDialog: boolean,
    openAddUpdateOrderPackageDialog: boolean,
    openUpdateMultiRowOrderPackageDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    editOrderPackage: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: OrderPackageState = {
    orderPackages: [],
    orderPackageMinLimit: null,
    selectedOrderPackage: null,
    selectedOrderPackages: [],
    openViewOrderPackageDialog: false,
    openAddUpdateOrderPackageDialog: false,
    openUpdateMultiRowOrderPackageDialog: false,
    openDeleteConfirmationDialog: false,
    editOrderPackage: false,
    status: 'idle',
    error: undefined
}

export const orderPackagesSlice = createSlice({    
    name: "orderPackage",
    initialState,
    reducers: {
        setOrderPackages: (state, action) => {
            state.orderPackages = action.payload
        },
        setOrderPackageMinLimit: (state, action) => {
            state.orderPackageMinLimit = action.payload
        },
        setSelectedOrderPackage: (state, action) => {
            state.selectedOrderPackage = action.payload
        },
        setOpenViewOrderPackageDialog: (state, action) => {
            state.openViewOrderPackageDialog = action.payload
        },
        setOpenAddUpdateOrderPackageDialog: (state, action) => {
            state.openAddUpdateOrderPackageDialog = action.payload
        },
        setOpenUpdateMultiRowOrderPackageDialog: (state, action) => {
            state.openUpdateMultiRowOrderPackageDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditOrderPackage: (state, action) => {
            state.editOrderPackage = action.payload
        },
        setSelectedOrderPackages: (state, action) => {
            state.selectedOrderPackages = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getOrderPackages.fulfilled, (state, action) => {            
            state.orderPackages = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getOrderPackages.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getOrderPackages.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getOrderPackageMinLimit.fulfilled, (state, action) => {            
            state.orderPackageMinLimit = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getOrderPackageMinLimit.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getOrderPackageMinLimit.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addOrderPackage.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addOrderPackage.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addOrderPackage.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updateOrderPackage.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(updateOrderPackage.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateOrderPackage.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deleteOrderPackage.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deleteOrderPackage.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteOrderPackage.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setOrderPackages,
    setOrderPackageMinLimit,
    setSelectedOrderPackage,
    setSelectedOrderPackages,
    setOpenViewOrderPackageDialog,
    setOpenAddUpdateOrderPackageDialog,
    setOpenUpdateMultiRowOrderPackageDialog,
    setOpenDeleteConfirmationDialog,
    setEditOrderPackage
} = orderPackagesSlice.actions;
export default orderPackagesSlice.reducer;
