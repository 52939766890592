import * as React from 'react';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@mui/lab/TreeItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SearchIcon from '@mui/icons-material/Search';
import { SvgIconProps } from '@mui/material/SvgIcon';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import FactoryIcon from '@mui/icons-material/Factory';
import CategoryIcon from '@mui/icons-material/Category';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Link, ListItemIcon, ListItemText } from '@mui/material';
import { getAssetObjectsForSubType, getClientObjectRegistryTreeNode } from '../../store/thunks/machine-management/machine-management-thunks';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

type StyledTreeItemProps = TreeItemProps & {
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
};

function StyledTreeItem(props: StyledTreeItemProps) {
  const {
    labelIcon: LabelIcon,
    labelText,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 0 }} >
          <ListItemIcon>
            {<LabelIcon />}
          </ListItemIcon>
          <ListItemText primary={labelText} sx={{wordWrap: "break-word" }} />
        </Box>
      }
      {...other}
    />
  );
}

export default function MachineObjectTreeNode() {
  const dispatch = useAppDispatch();
  const treeStatus = useAppSelector(state => state.machineManagement.status);
  const clientObjectRegistryTreeNode = useAppSelector((state) => state.machineManagement.clientObjectRegistryTreeNode);
const selectedAppCustomer = useAppSelector((state) => state.app.selectedAppCustomer);

  useEffect(() => {
    if(selectedAppCustomer && (treeStatus === 'idle' || treeStatus === 'failed')){
      // dispatch(getClientObjectRegistryTreeNode());
    }    
  }, [selectedAppCustomer]);

  const getAssetObject = (data: string) => {
    const finalData = {
      subType: data,
      customerId: ""
    }
      dispatch(getAssetObjectsForSubType(finalData));
  }

  return (
    <TreeView
      aria-label="machine"
      className="sidebar-treeview"
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      sx={{ flexGrow: 1, overflowY: 'auto' }}
    >
      <StyledTreeItem nodeId="Machine" labelText="Machine" labelIcon={PrecisionManufacturingIcon}>
      <Link component="button" role="button" variant="body1" underline="none" onClick={() => getAssetObject("Machine")} ><SearchIcon /> </Link>
        {(Array.isArray(clientObjectRegistryTreeNode?.entry?.children) && clientObjectRegistryTreeNode?.entry?.children.length > 0)
          ? clientObjectRegistryTreeNode?.entry?.children.map((item: any) => {
            return (
              <StyledTreeItem
                labelText={item.entry.translation.name.EN_en}
                labelIcon={FactoryIcon} nodeId={item.entry.groupID}
              >
                <Link component="button" role="button" variant="body1" underline="none" onClick={() => getAssetObject(item.entry.groupID)} ><SearchIcon /> </Link>
                {(Array.isArray(item.children) && item.children.length > 0)
                  ? item.children.map((child: any) => {
                    return (
                      <StyledTreeItem
                        labelText={child.entry.groupID.includes('.') ? child.entry.groupID : child.entry.translation.name.EN_en}
                        labelIcon={CategoryIcon} nodeId={child.entry.groupID}
                      >
                        <Link component="button" role="button" variant="body1" underline="none" onClick={() => getAssetObject(child.entry.groupID)} ><SearchIcon /> </Link>
                        {(Object.keys(child.entry.typeSubTypeHeirarchy).length > 0)
                          ? Object.keys(child.entry.typeSubTypeHeirarchy).map((type: string) => {
                            return (
                              <StyledTreeItem
                                labelText={type}
                                labelIcon={ConstructionIcon} nodeId={type}
                              >
                                <Link component="button" role="button" variant="body1" underline="none" onClick={() => getAssetObject(type)} ><SearchIcon /> </Link>
                                {child.entry.typeSubTypeHeirarchy[type].map((subtype: string) => {
                                  return (
                                    <Link sx={{display: 'block'}} component="button" role="button" variant="body1" underline="none" onClick={() => getAssetObject(subtype)} >
                                      <StyledTreeItem
                                        labelText={subtype}
                                        labelIcon={PrecisionManufacturingIcon} nodeId={subtype}
                                      />
                                    </Link>
                                  )
                                })}
                              </StyledTreeItem>
                            )
                          })
                          : null}
                      </StyledTreeItem>
                    )
                  })
                  : null}
              </StyledTreeItem>
            )
          })
          : null}
      </StyledTreeItem>
    </TreeView>
  );
}