import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IHardware } from "../../../shared/interfaces/hardware.interface";
import { onSearchInTable } from "../../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../shared/dialog-components/custom-dialog";
import { DeleteConfirmationDialog } from "../../../shared/dialog-components/delete-confirmation-dialog";
import { AddUpdateHardwareForm } from "./add-update-hardware-form.component";
import { deleteHardware, getHardwares } from "../../../store/thunks/hardwares/hardwares-thunks";
import {
  setSelectedHardware,
  setOpenAddUpdateHardwareDialog,
  setOpenViewHardwareDialog,
  setOpenDeleteConfirmationDialog,
  setEditHardware,
  setSelectedVendor
} from "../../../store/slices/hardwares"
import { TablePaginationActions } from "../../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../../shared/table-sorting/table-sort";
import { getVendors } from "../../../store/thunks/vendors/vendors-thunks";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const Hardwares = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const hardwares = useAppSelector(state => state.hardware.hardwares);
  const vendors = useAppSelector(state => state.vendor.vendors);
  const vendorStatus = useAppSelector(state => state.vendor.status);
  const selectedVendor = useAppSelector(state => state.hardware.selectedVendor);
  const selectedHardware = useAppSelector(state => state.hardware.selectedHardware);
  const openViewHardwareDialog = useAppSelector(state => state.hardware.openViewHardwareDialog);
  const openAddUpdateHardwareDialog = useAppSelector(state => state.hardware.openAddUpdateHardwareDialog);
  const openDeleteConfirmationDialog = useAppSelector(state => state.hardware.openDeleteConfirmationDialog);
  const editHardware = useAppSelector(state => state.hardware.editHardware);
  const [searchHardwaresList, setSearchHardwaresList] = useState<IHardware[]>(hardwares);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IHardware>('name');
  const hardwareVendors = vendors.filter(item => item.vendorType === "Hardware");

  const columns: Column[] = [
    { id: 'image', label: '', sortable: 'disable' },
    { id: 'name', label: intl.formatMessage({ id: 'components.hardware' }) },
    { id: 'oemPartNumber', label: intl.formatMessage({ id: 'components.oemPartNumber' }) },
    { id: 'hardwareClassification', label: intl.formatMessage({ id: 'components.classification' }) },
    { id: 'createDtTm', label: intl.formatMessage({ id: 'components.createdTime' }) },
    { id: 'isActive', label: intl.formatMessage({ id: 'components.active' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if (vendorStatus === 'idle' || vendorStatus === 'failed') {
      dispatch(getVendors());
    }
  }, []);

  useEffect(() => {
    setSearchHardwaresList(hardwares);
  }, [hardwares]);

  useEffect(() => {
    setPage(0);
  },[searchHardwaresList]);

  const loadHardwares = () => {
    dispatch(getHardwares(selectedVendor.vendorId))
  }

  const handleVendorChange = (value: any) => {
    dispatch(setSelectedVendor(value));
    dispatch(getHardwares(value.vendorId));
  }

  const handleCloseDialog = () => {
    dispatch(setOpenViewHardwareDialog(false));
    dispatch(setOpenAddUpdateHardwareDialog(false));
    dispatch(setOpenDeleteConfirmationDialog(false));
    dispatch(setSelectedHardware({}));
  }

  const onViewHardwareDetails = (data: IHardware) => {
    dispatch(setOpenViewHardwareDialog(true));
    dispatch(setSelectedHardware(data));
    dispatch(setEditHardware(false));
  }

  const addHardware = () => {
    dispatch(setOpenAddUpdateHardwareDialog(true));
    dispatch(setEditHardware(false));
  }

  const onUpdateHardware = (data: IHardware) => {
    dispatch(setSelectedHardware(data));
    dispatch(setOpenAddUpdateHardwareDialog(true));
    dispatch(setEditHardware(true));
  }

  const onDeleteHardware = (data: IHardware) => {
    dispatch(setOpenDeleteConfirmationDialog(true));
    dispatch(setSelectedHardware(data));
  }

  const deleteHardwarePermanently = () => {
    dispatch(deleteHardware(selectedHardware));
    dispatch(setOpenDeleteConfirmationDialog(false));
  }

  const actionTemplateHardwares = (rowData: IHardware) => {
    return <span data-testid="hc-actions" className="list-action">
      <Link role="button" data-testid="view-hc" aria-label="view-hc" component="button" variant="body2" onClick={() => onViewHardwareDetails(rowData)} > <VisibilityIcon /> </Link>
      <Link role="button" data-testid="update-hc" component="button" variant="body2" onClick={() => onUpdateHardware(rowData)} > <EditIcon /> </Link>
      <Link role="button" data-testid="delete-hc" component="button" variant="body2" onClick={() => onDeleteHardware(rowData)} > <ClearIcon /> </Link>
    </span>;
  }

  const imageTemplate = (rowData: IHardware) => {
    return <img src={`data:image/png;base64,${rowData.thumbNailImage}`} width="50" alt={rowData.thumbNailImageName} />;
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchHardwaresList(onSearchInTable(hardwares, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IHardware,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div data-testid="hardwares">
        <Box>
          <FormControl variant="standard" sx={{ m: 2, mt: 0, width: 350 }}>
            <InputLabel id="select-vendor"><FormattedMessage id="components.vendor" /></InputLabel>
            <Select
              labelId="select-vendor"
              role="combobox"
              aria-label="select vendor"
              inputProps={{ "data-testid": "select-vendor" }}
              value={selectedVendor}
              onChange={(e) => handleVendorChange(e.target.value)}
            >
              {hardwareVendors.map((row: any) => (
                <MenuItem key={row.vendorId} value={row}>{row.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {selectedVendor ?
          <Box>
            <TableContainer component={Paper}>
              <div className="table-head-links">
                <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadHardwares()} > <CachedIcon /> </Link></div>
                <div data-testid="add-new-btn" className="add-link"><Link component="button" role="button" aria-label="add-hc" variant="body1" underline="none" onClick={() => addHardware()} ><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.hardware" /> <AddIcon className="add-icon" /> </Link></div>
              </div>
              <div data-testid="searchbox" style={{ padding: "10px" }}>
                <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
              </div>
              <Table sx={{ minWidth: 650 }} data-testid="hardware-table" aria-label="hardwares table">
                <SortableTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                />
                <TableBody>
                  {stableSort(searchHardwaresList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow data-testid="hc-node" key={row.hardwareId} >
                          <TableCell>{imageTemplate(row)}</TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.oemPartNumber}</TableCell>
                          <TableCell>{row.hardwareClassification}</TableCell>
                          <TableCell>{row.createDtTm}</TableCell>
                          <TableCell>{row.isActive ? "Yes" : "No"}</TableCell>
                          <TableCell>{actionTemplateHardwares(row)}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      data-testid="pagination-control"
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={columns.length}
                      count={searchHardwaresList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
          :
          <Box sx={{ p: 2, pb: 3 }}>
            <FormattedMessage id="components.selectVendorToDisplayHardwares" />
          </Box>
        }
      </div>

      {/*start of add/update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openAddUpdateHardwareDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
          {editHardware ?
            <><FormattedMessage id="components.update" /> <FormattedMessage id="components.hardware" /></>
            : <><FormattedMessage id="components.addNew" />  <FormattedMessage id="components.hardware" /></>
          }
        </BootstrapDialogTitle>
        <DialogContent>
          <AddUpdateHardwareForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of add/update dialog */}

      {/*start of view details dialog */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewHardwareDialog}
        data-testid="view-hc-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedHardware &&
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={4}>
                <img src={`data:image/png;base64,${selectedHardware.thumbNailImage}`} width="100%" alt={selectedHardware.thumbNailImageName} />
              </Grid>
              <Grid item xs={8}>
                <p><FormattedMessage id="components.hardware" />: <span style={{ fontWeight: 'bold' }}>{selectedHardware.name}</span></p>
                <p><FormattedMessage id="components.oemPartNumber" />: <span style={{ fontWeight: 'bold' }}>{selectedHardware.oemPartNumber}</span></p>
                <p><FormattedMessage id="components.classification" />: <span style={{ fontWeight: 'bold' }}>{selectedHardware.hardwareClassification}</span></p>
                <p><FormattedMessage id="components.createdTime" />: <span style={{ fontWeight: 'bold' }}>{selectedHardware.createDtTm}</span></p>
                <p><FormattedMessage id="components.active" />: <span style={{ fontWeight: 'bold' }}>{selectedHardware.isActive ? "Yes" : "No"}</span></p>
                <p><FormattedMessage id="components.vendor" />: <span style={{ fontWeight: 'bold' }}>{selectedHardware.vendorName}</span></p>
                <p><FormattedMessage id="components.description" />: <span style={{ fontWeight: 'bold' }}>{selectedHardware.description}</span></p>
              </Grid>
            </Grid>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

      {/*Start of delete confirmation dialog */}
      <DeleteConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleCancel={handleCloseDialog}
        title={<FormattedMessage id="components.wantToDelete" />}
        message={<span><FormattedMessage id="components.thisWillDelete" /> <FormattedMessage id="components.hardware" /> <FormattedMessage id="components.permanently" /></span>}
        onConfirm={deleteHardwarePermanently}
      />
      {/*end of delete confirmation dialog */}
    </>
  );
};
