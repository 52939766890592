import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ThemeType } from '../shared/enums/theme-enum';
import { Locale } from "../shared/enums/locale-enum";
import { ICustomer } from '../shared/interfaces/customer.interface';

interface ISnackbar {
    message: string,
    messageType: "success" | "error" | "info" | "warning"
}

interface AppState {
    isSidebarOpen: boolean;
    currentTheme: ThemeType;
    locale: Locale;
    isLoading: boolean;
    errorMessage?: string,
    snackbarAlert: ISnackbar | null,
    selectedAppCustomer: ICustomer | null
}

const initialState : AppState  = {
    isSidebarOpen: true,
    currentTheme: ThemeType.light,
    locale: Locale.ENGLISH,
    isLoading: false,
    snackbarAlert: null,
    selectedAppCustomer: null
}

export const appSlice = createSlice({
    name:"app",
    initialState,
    reducers:{
        setDarkTheme:(state)=> {
            state.currentTheme = ThemeType.dark
        },
        setLightTheme:(state)=> {
            state.currentTheme = ThemeType.light
        },
        setSidebarOpen:(state)=> {
            state.isSidebarOpen = true;
        },
        setSidebarClose:(state)=> {
            state.isSidebarOpen = false;
        },
        switchLocale: (state, action:PayloadAction<Locale> ) => {
            state.locale = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setSnackbarAlert: (state, action) => {
            state.snackbarAlert = action.payload
        },
        setSelectedAppCustomer: (state, action) => {
            state.selectedAppCustomer = action.payload
        }
    }
})

export const {
    setDarkTheme, 
    setLoading, 
    setLightTheme, 
    setSidebarOpen, 
    setSidebarClose, 
    switchLocale, 
    setSnackbarAlert,
    setSelectedAppCustomer
} = appSlice.actions;
export default appSlice.reducer;