import { createSlice } from '@reduxjs/toolkit';
import { IModuleConfigSettingIsModified } from '../../shared/interfaces/module-config-setting-modified.interface';
import { IModule } from "../../shared/interfaces/module.interface"
import { IStandardModule } from '../../shared/interfaces/standard-module.interface';
import { addModule, checkIfModuleConfigSettingIsModified, deleteModule, getConfigurationSettingParameter, getDeviceLicensedModules, getModuleConfigSettingSchema, getModules, getStandardLicenseModule, updateModule } from '../thunks/modules/modules-thunks';

interface ModuleState {
    modules: IModule[],
    selectedModule: any,    
    configurationSettingParameter: any,
    deviceLicensedModules: any[],
    openViewModuleDialog: boolean,
    openAddUpdateModuleDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    editModule: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined,
    moduleConfigSettingSchemaStatus: 'idle' | 'loading' | 'succeeded' | 'failed',
    moduleConfigSettingSchema: string,
    moduleConfigSettingIsModified: IModuleConfigSettingIsModified | null,
    standardLicenseModule: IStandardModule[],
    standardModuleStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
}

const initialState: ModuleState = {
    modules: [],
    selectedModule: null,
    configurationSettingParameter: null,
    deviceLicensedModules: [],
    openViewModuleDialog: false,
    openAddUpdateModuleDialog: false,
    openDeleteConfirmationDialog: false,
    editModule: false,
    status: 'idle',
    error: undefined,
    moduleConfigSettingSchemaStatus: 'idle',
    moduleConfigSettingSchema: "",
    moduleConfigSettingIsModified: null,
    standardLicenseModule: [],
    standardModuleStatus: 'idle'
}

export const modulesSlice = createSlice({    
    name: "module",
    initialState,
    reducers: {
        setModules: (state, action) => {
            state.modules = action.payload
        },
        setSelectedModule: (state, action) => {
            state.selectedModule = action.payload
        },
        setOpenViewModuleDialog: (state, action) => {
            state.openViewModuleDialog = action.payload
        },
        setOpenAddUpdateModuleDialog: (state, action) => {
            state.openAddUpdateModuleDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditModule: (state, action) => {
            state.editModule = action.payload
        },
        setModuleConfigSettingSchema: (state, action) => {
            state.moduleConfigSettingSchema = action.payload
        },
        setModuleConfigSettingIsModified: (state, action) => {
            state.moduleConfigSettingIsModified = action.payload
        },
        setDeviceLicensedModules: (state, action) => {
            state.deviceLicensedModules = action.payload
        },
        setConfigurationSettingParameter: (state, action) => {
            state.configurationSettingParameter = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getModules.fulfilled, (state, action) => {            
            state.modules = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getModules.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getModules.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addModule.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addModule.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addModule.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updateModule.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(updateModule.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateModule.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deleteModule.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deleteModule.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteModule.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getModuleConfigSettingSchema.fulfilled, (state, action) => {  
            state.moduleConfigSettingSchemaStatus = 'succeeded'          
            state.moduleConfigSettingSchema = action.payload;
        })
        .addCase(getModuleConfigSettingSchema.pending, (state, action) => {
            state.moduleConfigSettingSchemaStatus = 'loading'
        })
        .addCase(getModuleConfigSettingSchema.rejected, (state, action) => {
            state.moduleConfigSettingSchemaStatus = 'failed'
            state.error = action.error.message
        })
        .addCase(checkIfModuleConfigSettingIsModified.fulfilled, (state, action) => {            
            state.moduleConfigSettingIsModified = action.payload;
        })
        .addCase(checkIfModuleConfigSettingIsModified.pending, (state, action) => {
        })
        .addCase(checkIfModuleConfigSettingIsModified.rejected, (state, action) => {
            state.error = action.error.message
        })
        .addCase(getStandardLicenseModule.fulfilled, (state, action) => {            
            state.standardLicenseModule = action.payload;
            state.moduleConfigSettingSchemaStatus = 'succeeded';
        })
        .addCase(getStandardLicenseModule.pending, (state, action) => {
            state.moduleConfigSettingSchemaStatus = 'loading';
        })
        .addCase(getStandardLicenseModule.rejected, (state, action) => {
            state.error = action.error.message;
            state.moduleConfigSettingSchemaStatus = 'failed';
        })
        .addCase(getDeviceLicensedModules.fulfilled, (state, action) => {            
            state.deviceLicensedModules = action.payload;
        })
        .addCase(getDeviceLicensedModules.pending, (state, action) => {
        })
        .addCase(getDeviceLicensedModules.rejected, (state, action) => {
            state.error = action.error.message;
        })        
        .addCase(getConfigurationSettingParameter.fulfilled, (state, action) => {            
            state.configurationSettingParameter = action.payload;
        })
        .addCase(getConfigurationSettingParameter.pending, (state, action) => {
        })
        .addCase(getConfigurationSettingParameter.rejected, (state, action) => {
            state.error = action.error.message
        });
    }
})

export const { 
    setModules,
    setSelectedModule,    
    setConfigurationSettingParameter,
    setOpenViewModuleDialog,
    setOpenAddUpdateModuleDialog,
    setOpenDeleteConfirmationDialog,
    setEditModule,
    setModuleConfigSettingIsModified,
    setModuleConfigSettingSchema
} = modulesSlice.actions;
export default modulesSlice.reducer;
