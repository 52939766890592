import { createAsyncThunk } from "@reduxjs/toolkit";
import { IApplication } from "../../../shared/interfaces/application.interface";
import { dataAPI } from "../../../shared/dataApi";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setEditApplication, setOpenAddUpdateApplicationDialog, setOpenDeleteConfirmationDialog, setSelectedApplication } from "../../slices/applications";

export const getApplications = createAsyncThunk(
  "applications/getApplications",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Applications/GetApplications`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addApplication = createAsyncThunk(
  'applications/addApplication',
  async (requestData: IApplication, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`Applications/AddApplication`, requestData);
      dispatch(setOpenAddUpdateApplicationDialog(false));
      dispatch(getApplications());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.applicationCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateApplication = createAsyncThunk(
  'applications/updateApplication',
  async (requestData: IApplication, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`Applications/UpdateApplication`, requestData);
      dispatch(setSelectedApplication({}));
      dispatch(setOpenAddUpdateApplicationDialog(false));
      dispatch(setEditApplication(false));
      dispatch(getApplications());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.applicationUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteApplication = createAsyncThunk(
  'applications/deleteApplication',
  async (requestData: IApplication, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`Applications/DeleteApplication?ApplicationId=` + requestData.applicationId);
      dispatch(setSelectedApplication({}));
      dispatch(setOpenDeleteConfirmationDialog(false));
      dispatch(getApplications());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.applicationDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getCustomLicenseApplication = createAsyncThunk(
  "applications/getCustomLicenseApplication",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Applications/GetCustomLicenseApplication`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
