import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box, Select, InputLabel, MenuItem, FormControl, Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateModulePermissionForUser } from "../../store/thunks/grant-permission/grant-permission-thunks";
import { IGrantedModulePermissions } from "../../shared/interfaces/grant-permission.interface";

export const UpdateModulePermissionsForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedModule = useAppSelector(state => state.grantPermission.selectedModule);
  const selectedUser = useAppSelector(state => state.grantPermission.selectedUser);
  const selectedPlatform = useAppSelector(state => state.grantPermission.selectedPlatform);

  const onSubmit = async (data: IGrantedModulePermissions) => {
    const finalData ={
      userId: selectedUser,
      moduleId: selectedModule.moduleId,
      platform: selectedPlatform,
      permissions: data.grantedPermissions
    }
    dispatch(updateModulePermissionForUser(finalData));
  };
  
  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={selectedModule}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} data-testid="grant-permissions-form">
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                <Field name="name">
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.module" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        disabled
                      />
                    </>
                  )}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Field name="code">
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.code" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        disabled
                      />
                    </>
                  )}
                </Field>
              </Grid>

              <Grid item xs={12}>
              <Field name="platform">
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.platform" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        disabled
                      />
                    </>
                  )}
                </Field>
              </Grid>

              <Grid item xs={12}>
              <Field name="grantedPermissions" >
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="grantedPermissions" error={meta.error && meta.touched}><FormattedMessage id="components.permissions" /></InputLabel>
                      <Select
                        {...input}
                        labelId="grantedPermissions"
                        error={meta.error && meta.touched}
                        multiple
                      >
                        {selectedModule?.permissions?.map((item: string) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Grid>

            </Grid>
            <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
              <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }} data-testid="reset-button">
                <FormattedMessage id="components.reset" />
              </Button>
              <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button">
                <FormattedMessage id="components.update" /> 
              </Button>
            </Box>

          </form>
        )}
      />
    </>
  );
};
