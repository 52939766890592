import { Box, Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { IRegisteredApplication } from "../../shared/interfaces/registered-application.interface";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getAppStreamUrl, getRegisteredApplications } from "../../store/thunks/registered-application/registered-applications-thunks";
import GEMSImage from "../../assets/images/gems-icon.png";

export const Applications = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const apps = useAppSelector(state => state.registeredApplication.registeredApplications);
  const registeredApplicationStatus = useAppSelector(state => state.registeredApplication.status);
  const [selectedApp, setSelectedApp] = useState<IRegisteredApplication | null>(null);

  useEffect(() => {
    if (registeredApplicationStatus === 'idle' || registeredApplicationStatus === 'failed') {
      dispatch(getRegisteredApplications());
    }
  }, []);

  const onAppClick = (app: any) => {
    if (app.id === "GEMSCloud") {
      if (loggedInUser) {
        const email = loggedInUser.email ? loggedInUser.email : '';
        dispatch(getAppStreamUrl(email))
      }
    }
    else {
      setSelectedApp(app);
    }
  }

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="applications">
        <div className="page-header">
          <h2><FormattedMessage id="components.applications" /></h2>
        </div>
        {selectedApp ?
          <Box sx={{ position: "relative" }}>
            <IconButton
              aria-label="close"
              onClick={() => setSelectedApp(null)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }} >
              <CloseIcon />
            </IconButton>
            <iframe
              style={{ border: "none" }}
              title="iFrame"
              width="100%"
              height="600"
              src={selectedApp.url + '?token=' + String(sessionStorage.getItem("user"))}
              sandbox="allow-pointer-lock allow-scripts allow-same-origin"
            >
            </iframe>
          </Box>
          :
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {apps.map((app: any, index: number) => (
              <Grid item xs={6} md={3} key={index}>
                <Box boxShadow={3} sx={{ p: 2, cursor: "pointer", borderRadius: 2, backgroundColor: theme.palette.secondary.main }} onClick={() => onAppClick(app)}>
                  <div style={{ textAlign: 'center', marginBottom: 10 }} >
                    <div style={{ fontWeight: 'bold', display: 'inline-block', margin: '0 auto' }}>{app.name}</div>
                  </div>
                  <div>
                  {Object.keys(app.iconResource).length === 0 || app.iconResource === "" ?
                    <img style={{ maxWidth: '340px', width: '100%', margin: '0 auto', display: 'block' }} src={GEMSImage} alt="application icon" />
                    :
                    <img style={{ maxWidth: '340px', width: '100%', margin: '0 auto', display: 'block' }} src={app.iconResource} alt="application icon" />
                  }
                  </div>               
                </Box>
              </Grid>
            ))
            }
          </Grid>
        }
      </div>
    </>
  );
};
