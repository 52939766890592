import { dataAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setSelectedModule, setUpdateModulePermissionsDialog } from "../../slices/grant-permission";

export const getGrantedModulePermissions = createAsyncThunk(
  "grantPermission/getGrantedModulePermissions",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`GrantPermission/GetGrantedModulePermissions?UserId=`+ requestData.userId + `&Platform=`+ requestData.platform);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateModulePermissionForUser = createAsyncThunk(
  "grantPermission/updateModulePermissionForUser",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`GrantPermission/UpdateModulePermissionForUser`, requestData);
      dispatch(setUpdateModulePermissionsDialog(false));
      dispatch(setSelectedModule({}));
      dispatch(getGrantedModulePermissions(requestData));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.modulePermissionsUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
