/**
 * Pre Loader
 */
import { CircularProgress, Box } from '@mui/material';

export const CircularIndeterminate = () => {
  return (
    <Box sx={styles.root}>
      <CircularProgress />
    </Box>
  );
}

const styles: any = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    backgroundColor: "secondary.dark",
    opacity: '0.6',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '9999',
    alignItems: 'center',
    overflow: 'hidden',
    margin: '0 auto'
  }
};
