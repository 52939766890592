import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { ICustomer } from "../../shared/interfaces/customer.interface";
import { onSearchInTable } from "../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { DeleteConfirmationDialog } from "../../shared/dialog-components/delete-confirmation-dialog";
import { AddUpdateCustomerForm } from "./add-update-customer-form.component";
import { deleteCustomer, getCustomerWithTenantTypeDetail } from "../../store/thunks/customers/customers-thunks";
import {
  setSelectedCustomer,
  setOpenAddUpdateCustomerDialog,
  setOpenViewCustomerDialog,
  setOpenDeleteConfirmationDialog,
  setEditCustomer,
  setOpenTenantDetailsDialog
} from "../../store/slices/customers"
import { TablePaginationActions } from "../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";
import { setLoading } from "../../store/app-slice";
import { TenantDetailsForm } from "./tenant-details-form.component";
import { setTenantDetails } from "../../store/slices/tenant-detail";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const Customers = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const customers = useAppSelector(state => state.customer.customers);
  const customerStatus = useAppSelector(state => state.customer.status);
  const selectedCustomer = useAppSelector(state => state.customer.selectedCustomer);
  const openViewCustomerDialog = useAppSelector(state => state.customer.openViewCustomerDialog);
  const openAddUpdateCustomerDialog = useAppSelector(state => state.customer.openAddUpdateCustomerDialog);
  const openDeleteConfirmationDialog = useAppSelector(state => state.customer.openDeleteConfirmationDialog);
  const editCustomer = useAppSelector(state => state.customer.editCustomer);
  const openTenantDetailsDialog = useAppSelector(state => state.customer.openTenantDetailsDialog);
  const [searchCustomersList, setSearchCustomersList] = useState<ICustomer[]>(customers);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof ICustomer>('name');

  const columns: Column[] = [
    { id: 'name', label: intl.formatMessage({ id: 'components.customer' }) },
    { id: 'companyCode', label: intl.formatMessage({ id: 'components.sapMasterNumber' }) },
    { id: 'dataCenterName', label: intl.formatMessage({ id: 'components.dataCenter' }) },
    { id: 'isolatedEc2ip', label: intl.formatMessage({ id: 'components.isolatedEc2IP' }) },
    { id: 'tenantDetails', label: intl.formatMessage({ id: 'components.tenantDetails' }), sortable: 'disable' },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if(customerStatus === 'idle' || customerStatus === 'failed'){
      loadCustomers();
    }
  }, []);

  useEffect(() => {
    setSearchCustomersList(customers);   
  }, [customers]);

  useEffect(() => {
    setPage(0);
  },[searchCustomersList]);

  const loadCustomers = () => {
    dispatch(getCustomerWithTenantTypeDetail());
  }

  const handleCloseDialog = () => {
    dispatch(setOpenViewCustomerDialog(false));
    dispatch(setOpenAddUpdateCustomerDialog(false));
    dispatch(setOpenTenantDetailsDialog(false));
    dispatch(setOpenDeleteConfirmationDialog(false));
    dispatch(setTenantDetails({}));    
    dispatch(setSelectedCustomer(null));
  }

  const onViewCustomerDetails = (data: ICustomer) => {
    dispatch(setOpenViewCustomerDialog(true));
    dispatch(setSelectedCustomer(data));
    dispatch(setEditCustomer(false));
  }

  const addCustomer = () => {
    dispatch(setOpenAddUpdateCustomerDialog(true));
    dispatch(setEditCustomer(false));
  }

  const onUpdateCustomer = (data: ICustomer) => {
    dispatch(setSelectedCustomer(data));
    dispatch(setOpenAddUpdateCustomerDialog(true));
    dispatch(setEditCustomer(true));
  }

  const onDeleteCustomer = (data: ICustomer) => {
    dispatch(setOpenDeleteConfirmationDialog(true));
    dispatch(setSelectedCustomer(data));    
  }

  const deleteCustomerPermanently = () => {
    dispatch(setLoading(true));
    dispatch(deleteCustomer(selectedCustomer));
  }

  const onEditTenant = (data: ICustomer) => {
    dispatch(setSelectedCustomer(data));
    dispatch(setOpenTenantDetailsDialog(true));
  }

  const tenantTemplate= (rowData: ICustomer) => {
    if (!rowData.hasTenantVM) {
      return <span>Not Applicable</span>
    }
    else {
      return <span>
        <Link component="button" onClick={() => onEditTenant(rowData)}>View/Update</Link>
      </span>;
    }
  }

  const actionTemplateCustomers = (rowData: ICustomer) => {
    return <span data-testid="customer-actions" className="list-action">
      <Link component="button" data-testid="view-customer" aria-label="view-customer" variant="body2" onClick={() => onViewCustomerDetails(rowData)} > <VisibilityIcon /> </Link>
      <Link component="button" data-testid="update-customer" variant="body2" onClick={() => onUpdateCustomer(rowData)} > <EditIcon /> </Link>
      <Link component="button" data-testid="delete-customer" variant="body2" onClick={() => onDeleteCustomer(rowData)} > <ClearIcon /> </Link>
    </span>;
  }

  const onSearchChangeHandler = (searched: string) => {
   setSearchCustomersList(onSearchInTable(customers, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof ICustomer,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="customers">
        <div className="page-header">
          <h2><FormattedMessage id="components.customers" /></h2>
        </div>
        <Box>
          <TableContainer component={Paper}>
            <div className="table-head-links">
              <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadCustomers()} > <CachedIcon /> </Link></div>
              <div data-testid="add-new-btn" className="add-link"><Link component="button" aria-label="add-customer" variant="body1" underline="none" onClick={() => addCustomer()} ><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.customer" /> <AddIcon className="add-icon" /> </Link></div>
            </div>
            <div data-testid="searchbox" style={{ padding: "10px" }}>
              <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
            </div>
            <Table sx={{ minWidth: 650 }} data-testid="customer-table" aria-label="customer table">
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
              />
              <TableBody>
                {stableSort(searchCustomersList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow data-testid="customer-node" key={row.customerId} >
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.companyCode}</TableCell>
                        <TableCell>{row.dataCenterName}</TableCell>
                        <TableCell>{row.isolatedEc2ip}</TableCell>
                        <TableCell>{tenantTemplate(row)}</TableCell>
                        <TableCell>{actionTemplateCustomers(row)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    data-testid="pagination-control"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={columns.length} 
                    count={searchCustomersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </div>

      {/*start of add/update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openAddUpdateCustomerDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
          {editCustomer ?
            <><FormattedMessage id="components.update" /> <FormattedMessage id="components.customer" /></>
            : <><FormattedMessage id="components.addNew" />  <FormattedMessage id="components.customer" /></>
          }
        </BootstrapDialogTitle>
        <DialogContent>
          <AddUpdateCustomerForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of add/update dialog */}

      {/*start of view details dialog */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewCustomerDialog}
        data-testid="view-customer-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedCustomer &&
            <>
              <p><FormattedMessage id="components.customer" />: <span style={{ fontWeight: 'bold' }}>{selectedCustomer.name}</span></p>
              <p><FormattedMessage id="components.sapMasterNumber" />: <span style={{ fontWeight: 'bold' }}>{selectedCustomer.companyCode}</span></p>
              <p><FormattedMessage id="components.dataCenter" />: <span style={{ fontWeight: 'bold' }}>{selectedCustomer.dataCenterName}</span></p>
              <p><FormattedMessage id="components.isolatedEc2IP" />: <span style={{ fontWeight: 'bold' }}>{selectedCustomer.isolatedEc2ip}</span></p>
              <p><FormattedMessage id="components.allowedEmailDomains" />: <span style={{ fontWeight: 'bold' }}>{selectedCustomer.emailDomainWhiteList}</span></p>
              <p><FormattedMessage id="components.tenantType" />: <span style={{ fontWeight: 'bold' }}>{selectedCustomer.tenantTypeDisplayName}</span></p>
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

      {/*Start of delete confirmation dialog */}
      <DeleteConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleCancel={handleCloseDialog}
        title={<FormattedMessage id="components.wantToDelete" />}
        message={<span><FormattedMessage id="components.thisWillDelete" /> <FormattedMessage id="components.customer" /> <FormattedMessage id="components.permanently" /></span>}
        onConfirm={deleteCustomerPermanently}
      />
      {/*end of delete confirmation dialog */}


       {/*start of tenant details dialog */}
       <BootstrapDialog
        fullScreen
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openTenantDetailsDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
            <FormattedMessage id="components.tenantDetails" /> 
        </BootstrapDialogTitle>
        <DialogContent>
          <TenantDetailsForm /> 
        </DialogContent>
      </BootstrapDialog>
      {/*end of tenant details dialog */}

    </>
  );
};
