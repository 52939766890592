import { FormattedMessage } from "react-intl";

export const TrialExpired = () => {
  return (
    <div style={{ paddingBottom: '20px' }}>
      <div>
        <h2><FormattedMessage id="components.userTrialExpired" /></h2>
      </div>
      <div className="profile-wrapper text-center" style={{ fontSize: '1.1em', color: '#fd5456', paddingTop: '20px', paddingBottom: '20px' }}>
        <FormattedMessage id="components.trialExpiredMsg" />
      </div>
    </div>
  );
};
