import { dataAPI, dataSessionAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import moment from "moment";
import { setHardwareCongifurationSettings, setModuleCongifurationSettings, setOpenHardwareConfigSettingsDialog, setOpenModuleConfigSettingsDialog, setSelectedHardwareForEditConfig, setSelectedModuleForEditConfig } from "../../slices/provision-edge-devices";

export const getProvisionHistory = createAsyncThunk(
  "provision-edge-devices/getProvisionHistory",
  async (edgeDeviceId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`ProvisionEdgeDevice/GetProvisionHistories?EdgeDeviceId=${edgeDeviceId}`);
      dispatch(setLoading(false));
      let finalResult = result.data;
      finalResult.map((item: any) => {
        item.provisionDateTime = item.provisionDateTime ? moment(item.provisionDateTime).format("MM/DD/YYYY, H:mm:ss") : "";
      });   
      return finalResult;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getIotPlatformMicroServiceForEdgeDevice = createAsyncThunk(
  "provision-edge-devices/getIotPlatformMicroServiceForEdgeDevice",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`ProvisionEdgeDevice/GetIotPlatformMicroServiceForEdgeDevice?EdgeDeviceId=${requestData.edgeDeviceId}&IoTplatformId=${requestData.ioTplatformId}`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const saveEdgeDeviceProvisioningDetails = createAsyncThunk(
  "provision-edge-devices/saveEdgeDeviceProvisioningDetails",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`ProvisionEdgeDevice/SaveEdgeDeviceProvisioningDetails`, requestData);
      dispatch(setLoading(false));
      dispatch(getEdgeDeviceConfigChangeDetail(requestData.edgeDeviceId));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.provisionDetailsSaved" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const provisionEdgeDevice = createAsyncThunk(
  "provision-edge-devices/provisionEdgeDevice",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataSessionAPI(user).post(`ProvisionEdgeDevice/ProvisionEdgeDevice`, requestData);
      dispatch(setLoading(false));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.requestedForProvision" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getEdgeDeviceConfigChangeDetail = createAsyncThunk(
  "provision-edge-devices/getEdgeDeviceConfigChangeDetail",
  async (edgeDeviceId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`ProvisionEdgeDevice/GetEdgeDeviceConfigChangeDetail?EdgeDeviceId=${edgeDeviceId}`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteEdgeDeviceConfigChangeDetails = createAsyncThunk(
  "provision-edge-devices/deleteEdgeDeviceConfigChangeDetails",
  async (edgeDeviceConfigChangeDetailsId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`ProvisionEdgeDevice/DeleteEdgeDeviceConfigChangeDetails?EdgeDeviceConfigChangeDetailsId=` + edgeDeviceConfigChangeDetailsId);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getModuleConfigurationForEdgeDeviceModule = createAsyncThunk(
  "provision-edge-devices/getModuleConfigurationForEdgeDeviceModule",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`ProvisionEdgeDevice/GetModuleConfigurationForEdgeDeviceModule?ModuleId=${requestData.moduleId}&EdgeDeviceId=${requestData.edgeDeviceId}&ChangeDetailsId=${requestData.changeDetailsId}&TemplateId=${requestData.templateId}`);
      dispatch(setLoading(false));
      const moduleCS = result.data.configuration.map((item: any) =>
        item = { ...item, validated: false } 
      );
      const finalResult = {
        configuration: moduleCS,
        stagingId: result.data.stagingId
      }; 
      return finalResult;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const stageEdgeDeviceModuleConfiguration = createAsyncThunk(
  "provision-edge-devices/stageEdgeDeviceModuleConfiguration",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`ProvisionEdgeDevice/StageEdgeDeviceModuleConfiguration`, requestData);
      dispatch(setModuleCongifurationSettings({}));
      dispatch(setSelectedModuleForEditConfig({}));
      dispatch(setOpenModuleConfigSettingsDialog(false));
      dispatch(setLoading(false));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.moduleConfigurationSaved" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getHardwareConfigurationForEdgeDevice = createAsyncThunk(
  "provision-edge-devices/getHardwareConfigurationForEdgeDevice",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`ProvisionEdgeDevice/GetHardwareConfigurationForEdgeDevice?OemPartNumber=${requestData.oemPartNumber}&EdgeDeviceId=${requestData.edgeDeviceId}&ChangeDetailId=${requestData.changeDetailId}`);
      dispatch(setLoading(false));
      const hardwareCS = result.data.configuration.map((item: any) =>
        item = { ...item, validated: false } 
      );
      const finalResult = {
        configuration: hardwareCS,
        stagingId: result.data.stagingId
      }; 
      return finalResult;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const stageEdgeDeviceHardwareConfiguration = createAsyncThunk(
  "provision-edge-devices/stageEdgeDeviceHardwareConfiguration",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`ProvisionEdgeDevice/StageEdgeDeviceHardwareConfiguration`, requestData);
      dispatch(setHardwareCongifurationSettings({}));
      dispatch(setSelectedHardwareForEditConfig({}));
      dispatch(setOpenHardwareConfigSettingsDialog(false));
      dispatch(setLoading(false));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.hardwareConfigurationSaved" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
