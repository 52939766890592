import { createSlice } from '@reduxjs/toolkit';
import { IGrantedModulePermissions } from "../../shared/interfaces/grant-permission.interface"
import { getGrantedModulePermissions } from '../thunks/grant-permission/grant-permission-thunks';

interface GrantPermissionState {
    grantedModulePermissions: IGrantedModulePermissions[],
    selectedUser: any,
    updateModulePermissionsDialog: boolean,
    openViewModulePermissionsDialog: boolean,
    selectedPlatform: any,
    selectedModule: any,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: GrantPermissionState = {
    grantedModulePermissions: [],
    selectedUser: null,
    updateModulePermissionsDialog: false,
    openViewModulePermissionsDialog: false,
    selectedModule: null,
    selectedPlatform: null,
    status: 'idle',
    error: undefined
}

export const grantPermissionSlice = createSlice({    
    name: "grantPermission",
    initialState,
    reducers: {
        setGrantedModulePermissions: (state, action) => {
            state.grantedModulePermissions = action.payload
        },
        setUpdateModulePermissionsDialog: (state, action) => {
            state.updateModulePermissionsDialog = action.payload
        },
        setOpenViewModulePermissionsDialog: (state, action) => {
            state.openViewModulePermissionsDialog = action.payload
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload
        },
        setSelectedModule: (state, action) => {
            state.selectedModule = action.payload
        },
        setSelectedPlatform: (state, action) => {
            state.selectedPlatform = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getGrantedModulePermissions.fulfilled, (state, action) => {            
            state.grantedModulePermissions = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getGrantedModulePermissions.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getGrantedModulePermissions.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setGrantedModulePermissions,
    setUpdateModulePermissionsDialog,
    setOpenViewModulePermissionsDialog,
    setSelectedUser,
    setSelectedModule,
    setSelectedPlatform
} = grantPermissionSlice.actions;
export default grantPermissionSlice.reducer;
