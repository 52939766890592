import { MouseEvent, ReactElement, SetStateAction, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box, Select, InputLabel, MenuItem, FormControl, Grid, InputAdornment, IconButton, Input } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ComposeValidators, IpAddress, NumbersOnly, Required, SubnetIpAddressCidr, VMLinuxOSDiskSizeMinLimit, VMNameValidation, VMWindowsOSDiskSizeMinLimit } from "../../shared/form-validations/form-validations";
import { ITenantDetails } from "../../shared/interfaces/tenant-details.interface";
import { deployTenantVm, getTenantDetails, getTenantVmAllowedParameters, getVmSizeDetails, saveTenantDetails } from "../../store/thunks/tenant-detail/tenant-detail-thunks";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export const TenantDetailsForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const selectedCustomer = useAppSelector(state => state.customer.selectedCustomer);
  const tenantDetails = useAppSelector(state => state.tenantDetail.tenantDetails);
  const tenantVmAllowedParameters = useAppSelector(state => state.tenantDetail.tenantVmAllowedParameters);
  const tenantVmAllowedParametersStatus = useAppSelector(state => state.tenantDetail.tenantVmAllowedParametersStatus);
  const vmSizeDetails = useAppSelector(state => state.tenantDetail.vmSizeDetails);
  const [tenantValues, setTenantValues] = useState<ITenantDetails>();
  const [showAdminPassword, setShowAdminPassword] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);
  const [tenantOsDiskSizeLimit, setTenantOsDiskSizeLimit] = useState<number>(0);
  
  const handleClickShowAdminPassword = () => setShowAdminPassword((show) => !show);
  const handleClickShowSecretKey = () => setShowSecretKey((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (tenantVmAllowedParametersStatus === 'idle' || tenantVmAllowedParametersStatus === 'failed') {
      dispatch(getTenantVmAllowedParameters());
    }
  }, [tenantVmAllowedParametersStatus]);

  useEffect(() => {
    dispatch(getTenantDetails(selectedCustomer));
  }, []);

  useEffect(() => {
    if (tenantValues?.location && tenantValues?.storageAccountTypeId) {
      const data = {
        location: tenantValues?.location,
        storageAccountTypeId: tenantValues?.storageAccountTypeId
      }
      dispatch(getVmSizeDetails(data));
    }
  }, [tenantValues?.location, tenantValues?.storageAccountTypeId]);

  useEffect(() => {
    tenantVmAllowedParameters?.tenantOsDiskSizeLimits.map((item: { tenantOperatingSystemId: number | undefined; diskSizeLimit: SetStateAction<number>; }) => {
      if (tenantValues?.tenantOperatingSystemId === item.tenantOperatingSystemId) {
        setTenantOsDiskSizeLimit(item.diskSizeLimit);
        return;
      }
    })    
  }, [tenantValues?.tenantOperatingSystemId]);

  const onSubmit = (data: ITenantDetails) => {
    const finalData = {...data, customerName: data.name, currentUserId: loggedInUser?.userId}
    dispatch(deployTenantVm(finalData));
  };

  const onSaveTenantDetails = (data: ITenantDetails) => {
    dispatch(saveTenantDetails(data));
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={tenantDetails}
      render={({ handleSubmit, form, submitting, pristine, values }) => {
        setTenantValues(values);
        return (
          <form onSubmit={handleSubmit} data-testid="tenant-details-form">
            {tenantDetails &&
            <>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6} sm={4}>
                <Field name="name" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.customer" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        sx={{ marginTop: '0px' }}
                        disabled
                      />
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field name="tenantVmName" validate={ComposeValidators(Required, VMNameValidation)}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.vmName" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        disabled={values.publicIp !== undefined && values.publicIp !== ''}
                        autoComplete='off'
                        sx={{ marginTop: '0px' }}
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field name="tenantOperatingSystemName" validate={Required}>
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth >
                      <InputLabel id="osType" error={meta.error && meta.touched}><FormattedMessage id="components.osType" /></InputLabel>
                      <Select
                        {...input}
                        labelId="osType"
                        error={meta.error && meta.touched}
                        disabled={true}
                      >
                        {tenantVmAllowedParameters?.tenantOperatingSystems?.map((row: any) => (
                          <MenuItem key={row.tenantOperatingSystemId} value={row.tenantOperatingSystemName}>{row.tenantOperatingSystemName}</MenuItem>
                        ))}
                      </Select>
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field name="subnetIp" validate={ComposeValidators(Required, SubnetIpAddressCidr)}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.subnetIP" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        disabled={values.publicIp !== undefined && values.publicIp !== ''}
                        autoComplete='off'
                        sx={{ marginTop: '0px' }}
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field name="privateIp" validate={ComposeValidators(Required, IpAddress)}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.privateIP" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        disabled={values.publicIp !== undefined && values.publicIp !== ''}
                        autoComplete='off'
                        sx={{ marginTop: '0px' }}
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field name="location" validate={Required}>
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="location" error={meta.error && meta.touched}><FormattedMessage id="components.location" /></InputLabel>
                      <Select
                        {...input}
                        labelId="location"
                        error={meta.error && meta.touched}
                        disabled={values.publicIp !== undefined && values.publicIp !== ''}
                      >
                        {tenantVmAllowedParameters?.tenantVMLocations?.map((row: any) => (
                          <MenuItem key={row.tenantVMLocationName} value={row.tenantVMLocationName}>{row.tenantVMLocationName}</MenuItem>
                        ))}
                      </Select>
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </FormControl>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field name="storageAccountTypeId" validate={Required}>
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="vmStorageAccountType" error={meta.error && meta.touched}><FormattedMessage id="components.vmStorageAccountType" /></InputLabel>
                      <Select
                        {...input}
                        labelId="vmStorageAccountType"
                        error={meta.error && meta.touched}
                        disabled={values.publicIp !== undefined && values.publicIp !== ''}
                      >
                        {tenantVmAllowedParameters?.storageAccounts?.map((row: any) => (
                          <MenuItem key={row.storageAccountTypeId} value={row.storageAccountTypeId}>{row.storageAccountTypeName}</MenuItem>
                        ))}
                      </Select>
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </FormControl>
                  )}
                </Field>
              </Grid>
              {(values.location !== '' && vmSizeDetails?.length > 0) &&
                <Grid item xs={6} sm={4}>
                  <Field name="vmAllowedMemorySizeId" validate={Required}>
                    {({ input, meta }) => (
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="vmSize" error={meta.error && meta.touched}><FormattedMessage id="components.vmSize" /></InputLabel>
                        <Select
                          {...input}
                          labelId="vmSize"
                          error={meta.error && meta.touched}
                          disabled={values.publicIp !== undefined && values.publicIp !== ''}
                        >
                          {vmSizeDetails && vmSizeDetails?.map((row: any) => (
                            <MenuItem key={row.vmAllowedMemorySizeId} value={row.vmAllowedMemorySizeId}>{row.vmAllowedMemorySizeName}</MenuItem>
                          ))}
                        </Select>
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </FormControl>
                    )}
                  </Field>
                </Grid>
              }
              {!(values.location !== '' && vmSizeDetails?.length > 0) &&
                <Grid item xs={6} sm={4}>
                  <Field name="vmAllowedMemorySizeName" validate={Required}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.vmSize" />}
                          variant="standard"
                          fullWidth
                          role="textbox"
                          error={meta.error && meta.touched}
                          autoComplete='off'
                          sx={{ marginTop: '0px' }}
                          disabled={true}
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
              }
              {values?.tenantOperatingSystemId === 1 &&
                <Grid item xs={6} sm={4}>
                  <Field name="vmOsDiskSize" validate={ComposeValidators(Required, NumbersOnly, VMLinuxOSDiskSizeMinLimit(tenantOsDiskSizeLimit))}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.vmOsDiskSizeinGB" />}
                          variant="standard"
                          fullWidth
                          role="textbox"
                          error={meta.error && meta.touched}
                          disabled={values.publicIp !== undefined && values.publicIp !== ''}
                          autoComplete='off'
                          sx={{ marginTop: '0px' }}
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
              }
              {values?.tenantOperatingSystemId === 2 &&
                <Grid item xs={6} sm={4}>
                  <Field name="vmOsDiskSize" validate={ComposeValidators(Required, NumbersOnly, VMWindowsOSDiskSizeMinLimit(tenantOsDiskSizeLimit))}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.vmOsDiskSizeinGB" />}
                          variant="standard"
                          fullWidth
                          role="textbox"
                          error={meta.error && meta.touched}
                          disabled={values.publicIp !== undefined && values.publicIp !== ''}
                          autoComplete='off'
                          sx={{ marginTop: '0px' }}
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
              }

              <Grid item xs={6} sm={4}>
                <Field name="tenantUrl" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.url" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                        sx={{ marginTop: '0px' }}
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field name="tenantAccessKey" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.accessKey" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                        sx={{ marginTop: '0px' }}
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={4}>
              <Field name="tenantSecretKey" validate={Required}>
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="tenantSecretKey"><FormattedMessage id="components.secretKey" /></InputLabel>
                    <Input
                      {...input}
                      id="tenantSecretKey"
                      type={showSecretKey ? 'text' : 'password'}
                      error={meta.error && meta.touched}
                      disabled={values.publicIp !== undefined && values.publicIp !== ''}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowSecretKey}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showSecretKey ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </FormControl>

                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field name="adminUsername" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.vmAdminUsername" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                        sx={{ marginTop: '0px' }}
                        disabled={values.publicIp !== undefined && values.publicIp !== ''}
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Field name="adminPassword" validate={Required}>
                  {({ input, meta }) => (
                    <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="vmAdminPassword"><FormattedMessage id="components.vmAdminPassword" /></InputLabel>
                    <Input
                      {...input}
                      id="vmAdminPassword"
                      type={showAdminPassword ? 'text' : 'password'}
                      error={meta.error && meta.touched}
                      disabled={values.publicIp !== undefined && values.publicIp !== ''}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowAdminPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showAdminPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </FormControl>

                  )}
                </Field>
              </Grid>
              {values.publicIp && values.publicIp !== "" &&
                <Grid item xs={6} sm={4}>
                  <Field name="publicIp">
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.publicIP" />}
                          variant="standard"
                          fullWidth
                          role="textbox"
                          error={meta.error && meta.touched}
                          autoComplete='off'
                          sx={{ marginTop: '0px' }}
                          disabled={true}
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
              }
              {values.tenantVmFqdn && values.tenantVmFqdn !== '' &&
                <Grid item xs={6} sm={4}>
                  <Field name="tenantVmFqdn">
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.tenantVmFqdn" />}
                          variant="standard"
                          fullWidth
                          role="textbox"
                          error={meta.error && meta.touched}
                          autoComplete='off'
                          sx={{ marginTop: '0px' }}
                          disabled={true}
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
              }
              {values.tenantVmDeploymentDtTm !== '' &&
                <Grid item xs={6} sm={4}>
                  <Field name="tenantVmDeploymentDtTm">
                    {({ input, meta }) => (
                      <>
                        <TextField
                          {...input}
                          label={<FormattedMessage id="components.tenantVmDeploymentDtTm" />}
                          variant="standard"
                          fullWidth
                          role="textbox"
                          error={meta.error && meta.touched}
                          autoComplete='off'
                          sx={{ marginTop: '0px' }}
                          disabled={true}
                        />
                        {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                      </>
                    )}
                  </Field>
                </Grid>
              }
            </Grid>

            <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
              <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }} data-testid="reset-button">
                <FormattedMessage id="components.reset" />
              </Button>
              <Button onClick={() => onSaveTenantDetails(values)} variant="contained" color="success" sx={{ marginRight: '5px' }} data-testid="save-button">
                <FormattedMessage id="components.save" />
              </Button>
              <Button autoFocus type="submit" variant="contained" color="primary" data-testid="deploy-button">
                <FormattedMessage id="components.deploy" />
              </Button>
            </Box>
            </>
          }          
          </form>
        )
      }}
    />
  );
};
