import { createTheme } from "@mui/material/styles";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#007AC2",
      light: "#ebedf2",
      dark: "#007AC2"
    },
    secondary: {
      main: "#ffffff",
      light: "rgba(0, 0, 0, 0.12)",
      dark: "rgba(255, 255, 255, 0.8)"
    },
    text: {
      primary: "#464d69"
    },
    background: {
      default: "#f8f9fa"
    }
  },  
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "rgba(255, 255, 255, 1)",
          color: "rgba(0, 0, 0, 0.65)"
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&[disabled]': {
            color: '#bdbdbd',
            pointerEvents: 'none',
          },
        },
      },
    },
  }
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#007AC2",
      light: "#121212",
      dark: "#000000"
    },
    secondary: {
      main: "#000000",
      light: "rgba(255, 255, 255, 0.12)",
      dark: "rgba(0, 0, 0, 0.8)"
    }    
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          '&[disabled]': {
            color: '#616161',
            pointerEvents: 'none',
          },
        },
      },
    },
  }
});
