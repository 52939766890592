import { createSlice } from '@reduxjs/toolkit';
import { INotification } from "../../shared/interfaces/notification.interface"
import { getNotifications } from '../thunks/notifications/notifications-thunks';

interface NotificationState {
    notifications: INotification[],
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: NotificationState = {
    notifications: [],
    status: 'idle',
    error: undefined
}

export const notificationsSlice = createSlice({    
    name: "notification",
    initialState,
    reducers: {
        setNotifications: (state, action) => {
            state.notifications = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getNotifications.fulfilled, (state, action) => {            
            state.notifications = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getNotifications.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getNotifications.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
    }
})

export const { 
    setNotifications
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
