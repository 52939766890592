import { createAsyncThunk } from "@reduxjs/toolkit";
import { dataAPI } from "../../../shared/dataApi";
import { IEmailDomainBlocklist } from "../../../shared/interfaces/email-domain-blocklist.interface";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setOpenAddEmailDomainBlocklistDialog, setOpenDeleteConfirmationDialog, setSelectedEmailDomainBlocklist } from "../../slices/email-domain-blocklist";

export const getEmailDomainBlocklist = createAsyncThunk(
  "emailDomainBlocklist/getEmailDomainBlocklist",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`EmailDomainBlocklist/GetEmailDomainBlocklist`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addEmailDomainBlocklist = createAsyncThunk(
  "emailDomainBlocklist/addEmailDomainBlocklist",
  async (requestData: IEmailDomainBlocklist, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`EmailDomainBlocklist/AddEmailDomainBlocklist`, requestData);
      dispatch(setOpenAddEmailDomainBlocklistDialog(false));
      dispatch(getEmailDomainBlocklist());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.emailDomainBlocklistCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteEmailDomainBlocklist = createAsyncThunk(
  "emailDomainBlocklist/deleteEmailDomainBlocklist",
  async (requestData: IEmailDomainBlocklist, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`EmailDomainBlocklist/DeleteEmailDomainBlocklist?EmailDomainBlocklistId=` + requestData.emailDomainBlocklistId);
      dispatch(setSelectedEmailDomainBlocklist(null));
      dispatch(setOpenDeleteConfirmationDialog(false));
      dispatch(getEmailDomainBlocklist());
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.emailDomainBlocklistDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
