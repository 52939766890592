import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Grid, Button, TextField, Box, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IOrderPackage } from "../../shared/interfaces/order-package.interface";
import { addOrderPackage, getOrderPackageMinLimit, updateOrderPackage } from "../../store/thunks/order-packages/order-packages-thunks";
import { ComposeValidators, EndDateValidation, EndDateValidationDevice, NumbersOnly, Required, StartDateValidation, UserCount } from "../../shared/form-validations/form-validations";
import { getPackages } from "../../store/thunks/packages/packages-thunks";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";

interface IProps {
  selectedOrder: string
}

export const AddUpdateOrderPackageForm = (props: IProps) => {
  const dispatch = useAppDispatch();
  const selectedOrderPackage = useAppSelector(state => state.orderPackage.selectedOrderPackage);
  const editOrderPackage = useAppSelector(state => state.orderPackage.editOrderPackage);
  const packages = useAppSelector(state => state.package.packages);
  const packageStatus = useAppSelector(state => state.package.status);
  const purchaseOrders = useAppSelector(state => state.purchaseOrder.purchaseOrders);
  const orderPackageMinLimit = useAppSelector(state => state.orderPackage.orderPackageMinLimit);
  const [selectedOrderNo, setSelectedOrderNo] = useState('');

  useEffect(() => {
    if (packageStatus === 'idle' || packageStatus === 'failed') {
      dispatch(getPackages());
    }
  }, []);

  useEffect(() => {
    if (editOrderPackage) {
      dispatch(getOrderPackageMinLimit(selectedOrderPackage.orderPackageId));
    }
  }, []);

  useEffect(() => {
    const order = purchaseOrders.find(o => o.purchaseOrderId === props.selectedOrder);
    if (order) {
      setSelectedOrderNo(order?.orderNo)
    }
  }, []);

  const onSubmit = (data: IOrderPackage) => {
    const finalData: any = {
      orderPackageId: data.orderPackageId,
      licenseType: data.licenseType,
      startDate: data.startDate && data.licenseType === 'lease' ? moment(data.startDate).format("MM/DD/YYYY, H:mm:ss") : "",
      endDate: data.endDate && data.licenseType === 'lease' ? moment(data.endDate).format("MM/DD/YYYY, H:mm:ss") : "",
      userLimit: data.userLimit,
      deploymentType: data.deploymentType,
      packageId: data.packageId,
      purchaseOrderId: props.selectedOrder
    }

    if (editOrderPackage) {
      dispatch(updateOrderPackage(finalData));
    }
    else {
      dispatch(addOrderPackage(finalData));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={selectedOrderPackage}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} data-testid="order-packages-form">
          <Field name="orderNo">
            {() => (
              <TextField
                value={selectedOrderNo}
                label={<FormattedMessage id="components.order#" />}
                variant="standard"
                role="textbox"
                aria-label="op-orderno"
                fullWidth
                disabled
                autoComplete='off'
                sx={{ marginTop: '0px' }}
              />
            )}
          </Field>
          <Field name="packageId" validate={Required}>
            {({ input, meta }) => (
              <FormControl variant="standard" fullWidth sx={{ marginTop: '15px' }}>
                <InputLabel id="package" error={meta.error && meta.touched}><FormattedMessage id="components.package" /></InputLabel>
                <Select
                  {...input}
                  labelId="package"
                  disabled={editOrderPackage}
                  error={meta.error && meta.touched}
                >
                  {packages?.map((row: any) => (
                    <MenuItem key={row.packageId} value={row.packageId}>{row.name}</MenuItem>
                  ))}
                </Select>
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </FormControl>
            )}
          </Field>
          <Field name="userLimit" validate={ComposeValidators(Required, NumbersOnly, UserCount(values.deploymentType, orderPackageMinLimit?.requiredLicenseLimitCount))}>
            {({ input, meta }) => (
              <>
                <TextField
                  {...input}
                  label={<FormattedMessage id="components.userLimit" />}
                  variant="standard"
                  fullWidth
                  role="textbox"
                  aria-label="op-userlimit"
                  error={meta.error && meta.touched}
                  autoComplete='off'
                  sx={{ marginTop: '15px' }}
                />
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </>
            )}
          </Field>
          <Field name="deploymentType" validate={Required}>
            {({ input, meta }) => (
              <FormControl variant="standard" sx={{ marginTop: '15px' }}>
                <FormLabel id="deploymentType" error={meta.error && meta.touched}><FormattedMessage id="components.deploymentType" /></FormLabel>
                <RadioGroup {...input} row aria-labelledby="deployment-type-label" name="deploymentType" >
                  <FormControlLabel value="User" control={<Radio />} label={<FormattedMessage id="components.user" />} />
                  <FormControlLabel value="Device" control={<Radio />} label={<FormattedMessage id="components.device" />} />
                </RadioGroup>
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </FormControl>
            )}
          </Field>
          <br />
          <Field name="licenseType" validate={Required}>
            {({ input, meta }) => (
              <FormControl variant="standard" sx={{ marginTop: '15px' }}>
                <FormLabel id="licenseType" error={meta.error && meta.touched}><FormattedMessage id="components.licenseType" /></FormLabel>
                <RadioGroup {...input} row aria-labelledby="license-type-label" name="licenseType" >
                  <FormControlLabel value="open" control={<Radio />} label={<FormattedMessage id="components.open" />} />
                  <FormControlLabel value="lease" control={<Radio />} label={<FormattedMessage id="components.lease" />} />
                </RadioGroup>
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </FormControl>
            )}
          </Field>
          {values.licenseType === 'lease' &&
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={6}>
                {editOrderPackage ?
                <>
                {selectedOrderPackage?.startDate ? 
                <Field name="startDate" validate={Required}>
                {({ input, meta }) => (
                  <>
                    <DesktopDatePicker
                      label={<FormattedMessage id="components.startDate" />}
                      inputFormat="MM/DD/YYYY"
                      {...input}
                      disabled
                      renderInput={(params: any) =>
                        <TextField
                          {...params}
                          variant="standard"
                          fullWidth
                          error={meta.error && meta.touched}
                        />
                      }
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
                :
                <Field name="startDate" validate={ComposeValidators(Required, StartDateValidation)}>
                {({ input, meta }) => (
                  <>
                    <DesktopDatePicker
                      label={<FormattedMessage id="components.startDate" />}
                      inputFormat="MM/DD/YYYY"
                      {...input}
                      renderInput={(params: any) =>
                        <TextField
                          {...params}
                          variant="standard"
                          fullWidth
                          error={meta.error && meta.touched}
                        />
                      }
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
                
                }
                </>
                    
                    :
                    <Field name="startDate" validate={ComposeValidators(Required, StartDateValidation)}>
                      {({ input, meta }) => (
                        <>
                          <DesktopDatePicker
                            label={<FormattedMessage id="components.startDate" />}
                            inputFormat="MM/DD/YYYY"
                            {...input}
                            renderInput={(params: any) =>
                              <TextField
                                {...params}
                                variant="standard"
                                fullWidth
                                error={meta.error && meta.touched}
                              />
                            }
                          />
                          {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                        </>
                      )}
                    </Field>
                  }

                </Grid>
                <Grid item xs={12} sm={6}>
                  {editOrderPackage && values.deploymentType === 'Device' ?
                    <Field name="endDate" validate={ComposeValidators(Required, EndDateValidation(values.startDate), EndDateValidationDevice)}>
                      {({ input, meta }) => (
                        <>
                          <DesktopDatePicker
                            label={<FormattedMessage id="components.endDate" />}
                            inputFormat="MM/DD/YYYY"
                            {...input}
                            disabled={!values.startDate}
                            renderInput={(params: any) =>
                              <TextField
                                {...params}
                                variant="standard"
                                fullWidth
                                error={meta.error && meta.touched}
                              />
                            }
                          />
                          {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                        </>
                      )}
                    </Field>
                    :
                    <Field name="endDate" validate={ComposeValidators(Required, EndDateValidation(values.startDate))}>
                      {({ input, meta }) => (
                        <>
                          <DesktopDatePicker
                            label={<FormattedMessage id="components.endDate" />}
                            inputFormat="MM/DD/YYYY"
                            {...input}
                            disabled={!values.startDate}
                            renderInput={(params: any) =>
                              <TextField
                                {...params}
                                variant="standard"
                                fullWidth
                                error={meta.error && meta.touched}
                              />
                            }
                          />
                          {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                        </>
                      )}
                    </Field>
                  }
                </Grid>
              </Grid>
            </LocalizationProvider>
          }

          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
              <FormattedMessage id="components.reset" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
              {editOrderPackage ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
            </Button>
          </Box>
        </form>
      )}
    />
  );
};
