import { dataAPI, dataSessionAPI, sessionAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Locale } from "../../../shared/enums/locale-enum";
import { roles } from "../../../test-utils/mocks/mock-data";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setAddNewItem, setEditAssetObject, setInputValue, setOpenAddUpdateAssetObjectDialog, setOpenDeleteConfirmationDialog, setSelectedAssetObject, setSelectedNode, setUpdateNameModal } from "../../slices/machine-management";
import { IAssetObject } from "../../../shared/interfaces/asset-object.interface";

export const getClientObjectRegistryTreeNode = createAsyncThunk(
  "machineManagement/getClientObjectRegistryTreeNode",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`MachineManagement/GetClientObjectRegistryTreeNode`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addClientObjectRegistryEntry = createAsyncThunk(
  'machineManagement/addClientObjectRegistryEntry',
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataSessionAPI(user).post(`MachineManagement/AddClientObjectRegistryEntry`, requestData);
      dispatch(setAddNewItem(false));
      dispatch(setInputValue(''));
      dispatch(getClientObjectRegistryTreeNode());
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const setTranslationsForMachineNode = createAsyncThunk(
  'machineManagement/setTranslationsForMachineNode',
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataSessionAPI(user).post(`MachineManagement/SetTranslationsForMachineNode`, requestData);
      dispatch(setUpdateNameModal(false));
      dispatch(setSelectedNode(null));
      dispatch(getClientObjectRegistryTreeNode());
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const removeClientObjectRegistryEntry = createAsyncThunk(
  'machineManagement/removeClientObjectRegistryEntry',
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`MachineManagement/RemoveClientObjectRegistryEntry?GroupID=` + requestData.groupID);
      dispatch(getClientObjectRegistryTreeNode());
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getAssetObjectsForSubType = createAsyncThunk(
  "machineManagement/getAssetObjectsForSubType",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`MachineManagement/GetAssetObjectsForSubType?SubType=${requestData.subType}&CustomerId=${requestData.customerId}`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addAssetObject = createAsyncThunk(
  "machineManagement/addAssetObject",
  async (requestData: IAssetObject, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`MachineManagement/AddAssetObject`, requestData);
      dispatch(setOpenAddUpdateAssetObjectDialog(false));
      dispatch(getAssetObjectsForSubType({}));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.assetObjectCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const updateAssetObject = createAsyncThunk(
  "machineManagement/updateAssetObject",
  async (requestData: IAssetObject, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`MachineManagement/UpdateAssetObject`, requestData);
      dispatch(setSelectedAssetObject(null));
      dispatch(setOpenAddUpdateAssetObjectDialog(false));
      dispatch(setEditAssetObject(false));
      dispatch(getAssetObjectsForSubType({}));
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.assetObjectUpdated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const deleteAssetObject = createAsyncThunk(
  "machineManagement/deleteAssetObject",
  async (requestData: IAssetObject, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).delete(`MachineManagement/DeleteAssetObject?AssetObjectId=` + requestData.assetObjectId);
        dispatch(setSelectedAssetObject(null));
        dispatch(setOpenDeleteConfirmationDialog(false));
        dispatch(getAssetObjectsForSubType({}));
        dispatch(setSnackbarAlert({ messageType: 'success', message: "components.assetObjectDeleted" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
