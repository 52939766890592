import { ReactElement, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box, Select, InputLabel, MenuItem, FormControl } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IApplication } from "../../shared/interfaces/application.interface";
import { addApplication, updateApplication } from "../../store/thunks/applications/applications-thunks";
import { Required } from "../../shared/form-validations/form-validations";
import { getVendors } from "../../store/thunks/vendors/vendors-thunks";

export const AddUpdateApplicationForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const vendorStatus = useAppSelector(state => state.vendor.status);
  const vendors = useAppSelector(state => state.vendor.vendors);
  const selectedApplication = useAppSelector(state => state.application.selectedApplication);
  const editApplication = useAppSelector(state => state.application.editApplication);
  const filteredVendors = vendors.filter(d => d.vendorType.toLowerCase() === "Software".toLowerCase())

  useEffect(() => {
    if(vendorStatus === 'idle' || vendorStatus === 'failed'){
      dispatch(getVendors());
    }
  }, []);

  const onSubmit = (data: IApplication) => {
    if (editApplication) {
      dispatch(updateApplication(data));
    }
    else {
      dispatch(addApplication(data));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={selectedApplication}
      render={({ handleSubmit, form, submitting, pristine }) => (
        <form onSubmit={handleSubmit} data-testid="application-form">
          <Field name="name" validate={Required}>
            {({ input, meta }) => (
              <>
                <TextField
                  {...input}
                  label={<FormattedMessage id="components.application" />}
                  variant="standard"
                  fullWidth
                  role="textbox"
                  error={meta.error && meta.touched}
                  autoComplete='off'
                  sx={{ marginTop: '0px' }}
                  disabled={editApplication}
                />
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </>
            )}
          </Field>
          <Field name="vendorId" validate={Required}>
            {({ input, meta }) => (
              <FormControl variant="standard" fullWidth sx={{ marginTop: '15px' }}>
                <InputLabel id="vendor" error={meta.error && meta.touched}><FormattedMessage id="components.vendor" /></InputLabel>
                <Select
                  {...input}
                  labelId="vendor"
                  error={meta.error && meta.touched}
                >
                  {filteredVendors && filteredVendors.map((row) => (
                    <MenuItem data-testid="select-option" key={row.vendorId} value={row.vendorId}>{row.name}</MenuItem>
                  ))}
                </Select>
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </FormControl>
            )}
          </Field>
          <Field name="description" validate={Required}>
            {({ input, meta }) => (
              <>
                <TextField
                  {...input}
                  label={<FormattedMessage id="components.description" />}
                  variant="standard"
                  fullWidth
                  role="textbox"
                  multiline
                  maxRows={3}
                  error={meta.error && meta.touched}
                  autoComplete='off'
                  sx={{ marginTop: '15px' }}
                />
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </>
            )}
          </Field>

          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }} data-testid="reset-button">
              <FormattedMessage id="components.reset" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button">
              {editApplication ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
            </Button>
          </Box>

        </form>
      )}
    />
  );
};
