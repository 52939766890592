import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IEmailDomainBlocklist } from "../../shared/interfaces/email-domain-blocklist.interface";
import { onSearchInTable } from "../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { DeleteConfirmationDialog } from "../../shared/dialog-components/delete-confirmation-dialog";
import { AddEmailDomainBlocklistForm } from "./add-email-domain-blocklist-form.component";
import { deleteEmailDomainBlocklist, getEmailDomainBlocklist } from "../../store/thunks/email-domain-blocklist/email-domain-blocklist-thunks";
import {
  setSelectedEmailDomainBlocklist,
  setOpenAddEmailDomainBlocklistDialog,
  setOpenDeleteConfirmationDialog
} from "../../store/slices/email-domain-blocklist"
import { TablePaginationActions } from "../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const EmailDomainBlocklist = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const emailDomainBlocklist = useAppSelector(state => state.emailDomainBlocklist.emailDomainBlocklist);
  const emailDomainBlocklistStatus = useAppSelector(state => state.emailDomainBlocklist.status);
  const selectedEmailDomainBlocklist = useAppSelector(state => state.emailDomainBlocklist.selectedEmailDomainBlocklist);
  const openAddEmailDomainBlocklistDialog = useAppSelector(state => state.emailDomainBlocklist.openAddEmailDomainBlocklistDialog);
  const openDeleteConfirmationDialog = useAppSelector(state => state.emailDomainBlocklist.openDeleteConfirmationDialog);
  const [searchEmailDomainBlocklist, setSearchEmailDomainBlocklist] = useState<IEmailDomainBlocklist[]>(emailDomainBlocklist);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IEmailDomainBlocklist>('emailDomain');

  const columns: Column[] = [
    { id: 'emailDomain', label: intl.formatMessage({ id: 'components.emailDomain' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if(emailDomainBlocklistStatus === 'idle' || emailDomainBlocklistStatus === 'failed'){
      loadEmailDomainBlocklist();
    }
  }, []);

  useEffect(() => {
    setSearchEmailDomainBlocklist(emailDomainBlocklist);   
  }, [emailDomainBlocklist]);

  useEffect(() => {
    setPage(0);
  },[searchEmailDomainBlocklist]);

  const loadEmailDomainBlocklist = () => {
    dispatch(getEmailDomainBlocklist())
  }

  const handleCloseDialog = () => {
    dispatch(setOpenAddEmailDomainBlocklistDialog(false));
    dispatch(setOpenDeleteConfirmationDialog(false));
    dispatch(setSelectedEmailDomainBlocklist(null));
  }

  const addEmailDomainBlocklist = () => {
    dispatch(setOpenAddEmailDomainBlocklistDialog(true));
  }

  const onDeleteEmailDomainBlocklist = (data: IEmailDomainBlocklist) => {
    dispatch(setOpenDeleteConfirmationDialog(true));
    dispatch(setSelectedEmailDomainBlocklist(data));
  }

  const deleteEmailDomainBlocklistPermanently = () => {
    dispatch(deleteEmailDomainBlocklist(selectedEmailDomainBlocklist));
  }

  const actionTemplateEmailDomainBlocklist = (rowData: IEmailDomainBlocklist) => {
    return <span data-testid="ed-actions" className="list-action">
      <Link role="button" data-testid="delete-ed" component="button" variant="body2" onClick={() => onDeleteEmailDomainBlocklist(rowData)} > <ClearIcon /> </Link>
    </span>;
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchEmailDomainBlocklist(onSearchInTable(emailDomainBlocklist, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IEmailDomainBlocklist,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="email-domain-blocklist">
        <div className="page-header">
          <h2><FormattedMessage id="components.emailDomainBlocklist" /></h2>
        </div>
        <Box>
          <TableContainer component={Paper}>
            <div className="table-head-links">
              <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadEmailDomainBlocklist()} > <CachedIcon /> </Link></div>
              <div data-testid="add-new-btn" className="add-link"><Link component="button" role="button" aria-label="add-ed" variant="body1" underline="none" onClick={() => addEmailDomainBlocklist()} ><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.emailDomainBlocklist" /> <AddIcon className="add-icon" /> </Link></div>
            </div>
            <div data-testid="searchbox" style={{ padding: "10px" }}>
              <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
            </div>
            <Table sx={{ minWidth: 650 }} data-testid="email-domain-blocklist-table" aria-label="data centers table">
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
              />
              <TableBody>
                {stableSort(searchEmailDomainBlocklist, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow data-testid="ed-node" key={row.emailDomainBlocklistId} >
                        <TableCell>{row.emailDomain}</TableCell>
                        <TableCell>{actionTemplateEmailDomainBlocklist(row)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    data-testid="pagination-control"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={columns.length} 
                    count={searchEmailDomainBlocklist.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </div>

      {/*start of add/update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openAddEmailDomainBlocklistDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
           <><FormattedMessage id="components.addNew" />  <FormattedMessage id="components.emailDomainBlocklist" /></>
        </BootstrapDialogTitle>
        <DialogContent>
          <AddEmailDomainBlocklistForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of add/update dialog */}

      {/*Start of delete confirmation dialog */}
      <DeleteConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleCancel={handleCloseDialog}
        title={<FormattedMessage id="components.wantToDelete" />}
        message={<span><FormattedMessage id="components.thisWillDelete" /> <FormattedMessage id="components.emailDomainBlocklist" /> <FormattedMessage id="components.permanently" /></span>}
        onConfirm={deleteEmailDomainBlocklistPermanently}
      />
      {/*end of delete confirmation dialog */}

    </>
  );
};
