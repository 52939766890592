import { Box, Button, FormControl, TextField } from "@mui/material";
import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { pushLicenses } from "../../store/thunks/license-users/license-users-thunks";

export const ReloadLicenses = (): ReactElement => {
  const dispatch = useAppDispatch();
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  
  const pushLicensing = () => {
    const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
    dispatch(pushLicenses(cId));
  }

  return (
    <>
      <div data-testid="reload-licenses">
        <Box>
          <FormControl variant="standard" sx={{ m: 2, mt: 0, width: 350 }} data-testid="customer-selected">
            <TextField
              id="customer"
              label={<FormattedMessage id="components.customer" />}
              defaultValue={selectedAppCustomer?.name}
              variant="standard"
              disabled
            />
          </FormControl>
        </Box>
        <Box display="flex" justifyContent="flex-end" sx={{ m: 2, pb: 2}}>
          <Button autoFocus onClick={() => pushLicensing()} variant="contained" color="primary" data-testid="push-license-button" >
            <FormattedMessage id="components.pushLicense" />
          </Button>
        </Box>
      </div>
    </>
  );
};
