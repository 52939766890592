import { ChangeEvent, ReactElement, useState } from "react";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import GleasonLogo from "../../assets/images/gleason-logo.svg";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';
import { Field, Form } from "react-final-form";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ComposeValidators, EmailAddress, Required } from "../../shared/form-validations/form-validations";
import { requestResetPassword } from "../../store/thunks/user-management/user-management-thunks";

export const ForgotPassword = (): ReactElement => {
  const dispatch = useAppDispatch();
  const resetPasswordEmailSent = useAppSelector(state => state.profile.resetPasswordEmailSent);

  const onSubmit = (data: any) => {
    dispatch(requestResetPassword(data.emailAddress));
  };

  return (
    <>
      <div style={{ backgroundColor: "#f4f7fa", width: "100%", height: "100vh" }}>
        <Box sx={{ padding: "30px 0 30px 100px" }}>
          <div className="session-logo">
            <Link underline="none" href="/">
              <img src={GleasonLogo} alt="gleason-logo" className="img-fluid" width="110" height="35" />
            </Link>
          </div>
        </Box>
        <Card sx={{ margin: "0 auto", maxWidth: "400px", backgroundColor: "#ffffff", padding: "20px 80px" }}>
          <div style={{ marginBottom: "20px", textAlign: "center" }}>
            <Typography variant="h5" component="h5"> GEMS Web Portal </Typography>
            <Typography component="p"> Forgot Password </Typography>
          </div>
          <>
            {resetPasswordEmailSent ?
              <Typography variant="body1" style={{ color: "green", margin: "1em auto", fontSize: "1em", textAlign: "center" }}>
                Check your email for a link to reset your password.
              </Typography>
              :
              <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, form, submitting, pristine }) => (
                  <form onSubmit={handleSubmit} data-testid="forget-password-form">
                    <Field name="emailAddress" validate={ComposeValidators(Required, EmailAddress)}>
                      {({ input, meta }) => (
                        <FormControl sx={{ marginBottom: "10px", width: '100%' }} variant="outlined">
                          <InputLabel htmlFor="emailAddress">Email Address</InputLabel>
                          <OutlinedInput
                            id="emailAddress"
                            type="text"
                            {...input}
                            endAdornment={
                              <InputAdornment position="end">
                                <EmailIcon />
                              </InputAdornment>
                            }
                            label="Email Address"
                            error={meta.error && meta.touched}
                          />
                          {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                        </FormControl>
                      )}
                    </Field>
                    <Button type="submit" disabled={submitting} size="large" variant="contained" sx={{ width: "100%", marginBottom: "10px" }} aria-label="request password" >Request New Password</Button>
                    <Button size="large" variant="contained" href="/signin" sx={{ width: "100%", marginBottom: "20px" }} color="success" aria-label="already have account" >Already have account?</Button>
                  </form>
                )}
              />
            }
          </>
        </Card>
      </div>
    </>
  );
};
