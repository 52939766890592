import { createSlice } from '@reduxjs/toolkit';
import { IVendor } from "../../shared/interfaces/vendor.interface"
import { addVendor, deleteVendor, getVendors, updateVendor } from '../thunks/vendors/vendors-thunks';

interface VendorState {
    vendors: IVendor[],
    selectedVendor: any,
    openViewVendorDialog: boolean,
    openAddUpdateVendorDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    editVendor: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined,
    standardVendors: IVendor[]
}

const initialState: VendorState = {
    vendors: [],
    selectedVendor: null,
    openViewVendorDialog: false,
    openAddUpdateVendorDialog: false,
    openDeleteConfirmationDialog: false,
    editVendor: false,
    status: 'idle',
    error: undefined,
    standardVendors: []
}

export const vendorsSlice = createSlice({    
    name: "vendor",
    initialState,
    reducers: {
        setVendors: (state, action) => {
            state.vendors = action.payload
        },
        setSelectedVendor: (state, action) => {
            state.selectedVendor = action.payload
        },
        setOpenViewVendorDialog: (state, action) => {
            state.openViewVendorDialog = action.payload
        },
        setOpenAddUpdateVendorDialog: (state, action) => {
            state.openAddUpdateVendorDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditVendor: (state, action) => {
            state.editVendor = action.payload
        },
        setStandardVendors: (state, action) => {
            state.standardVendors = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getVendors.fulfilled, (state, action) => {            
            state.vendors = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getVendors.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getVendors.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addVendor.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addVendor.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addVendor.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updateVendor.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(updateVendor.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateVendor.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deleteVendor.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deleteVendor.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteVendor.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setVendors,
    setSelectedVendor,
    setOpenViewVendorDialog,
    setOpenAddUpdateVendorDialog,
    setOpenDeleteConfirmationDialog,
    setEditVendor,
    setStandardVendors
} = vendorsSlice.actions;
export default vendorsSlice.reducer;
