import axios from 'axios'

const apiUrl = process.env.REACT_APP_BASE_API_URL;

export const dataAPI = (user: any) => {
  return axios.create({
    baseURL: apiUrl,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${user.bearerToken}`
    },
  });
}

export const dataSessionAPI = (user: any) => {
  return axios.create({
    baseURL: apiUrl,
    headers: {
      "Content-Type": "application/json",
      "SessionId": user.sessionId,
      "Authorization": `Bearer ${user.bearerToken}`
    },
  });
}

export const sessionAPI = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json"
  },
});

export const testAPI = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImtpZCI6IlFJKyJ9.eyJzdWIiOjMsImlhdCI6MTY4NjAyNDQ2MiwiZXhwIjoxNjg3MjM0MDYyLCJlbWFpbCI6Im1hcnVua3VtYXJAZ2xlYXNvbi5jb20iLCJnaXZlbl9uYW1lIjoiQXJ1biBLdW1hciIsImZhbWlseV9uYW1lIjoiTSIsIm5hbWUiOiJBcnVuIEt1bWFyIE0iLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJBcnVuX0V4cCIsInJvbGVzIjpbIkdsb2JhbCBHbGVhc29uIEFkbWluIiwiVXNlciJdLCJqdGkiOjIyfQ.w1E0AuM5Vpbu-_KQmBWheOqLdxa4H50HiPii6kcwK64`
  },
});