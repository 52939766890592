import { ReactElement } from "react";
import { Box, CssBaseline } from "@mui/material";
import { ApplicationBar } from "./components/appbar.component";
import { SidebarDrawer } from "./components/drawer.component";
import { MainComponent } from "./components/main-content.component";
import { SignalRConnection } from "./components/signalr-connection.component";

interface Props {
  children: any;
}
export const LayoutComponent = (props: Props): ReactElement<Props> => {
 
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SignalRConnection />        
      <ApplicationBar />
      <SidebarDrawer />
      <MainComponent>{props.children}</MainComponent>
    </Box>
  );
};
