import { ReactElement, SyntheticEvent, useState } from "react";
import { FormattedMessage } from "react-intl";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Paper, Box, Tab } from "@mui/material";
import { ViewUserLicense } from "./view-user-license.component";
import { AssignLicense } from "./assign-license.component";
import { ReloadLicenses } from "./reload-licenses.component";
import { GenerateLicense } from "./generate-license.component";
import { useAppSelector } from "../../store/hooks";
import { Role } from "../../shared/enums/role.enum";

export const LicensedUsers = (): ReactElement => {
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const [value, setValue] = useState('1');

  const handlePurchaseTabChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="licensed-users">
        <div className="page-header">
          <h2><FormattedMessage id="components.licensedUsers" /></h2>
        </div>
        <Paper>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handlePurchaseTabChange} variant="fullWidth" aria-label="licensed users tabs" data-testid="licensed-users-tabs">
                <Tab label={<FormattedMessage id="components.assignLicense" />} value="1" />
                <Tab label={<FormattedMessage id="components.viewAssignedLicense" />} value="2" />
                {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
                    <Tab label={<FormattedMessage id="components.reloadLicenses" />} value="3" />
                }
                {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
                    <Tab label={<FormattedMessage id="components.generateLicense" />} value="4" />
                }
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: 0, pt: 3 }} data-testid="assign-license-panel"><AssignLicense /></TabPanel>
            <TabPanel value="2" sx={{ p: 0, pt: 3 }} data-testid="view-assigned-license-panel"><ViewUserLicense /></TabPanel>
            {loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) &&
              <>
                <TabPanel value="3" sx={{ p: 0, pt: 3 }} data-testid="reload-license-panel"><ReloadLicenses /></TabPanel>
                <TabPanel value="4" sx={{ p: 0, pt: 3 }} data-testid="generate-license-panel"><GenerateLicense /></TabPanel>
              </>
            }
          </TabContext>
        </Paper>
      </div>
    </>
  );
};
