import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";
import { Grid, Button, TextField, Box, FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ComposeValidators, EmailAddress, Required, StartDateValidation, AllowedEmailDomains } from "../../shared/form-validations/form-validations";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { IUser } from "../../shared/interfaces/user.interface";
import { addUser, updateUser } from "../../store/thunks/user-management/user-management-thunks";
import { getVendors } from "../../store/thunks/vendors/vendors-thunks";

export const AddUpdateVendorUserForm = () => {
  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector(state => state.userManagement.selectedUser);
  const editUser = useAppSelector(state => state.userManagement.editUser);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const vendors = useAppSelector(state => state.vendor.vendors);
  const vendorStatus = useAppSelector(state => state.vendor.status);

  useEffect(() => {
    if (vendorStatus === 'idle' || vendorStatus === 'failed') {
      dispatch(getVendors());
    }
  }, []);

  const onSubmit = (data: IUser) => {
    let finalData: any = data;
    finalData = {
      ...finalData,
      trialExpiration: data.trialExpiration ? moment(data.trialExpiration).format("MM/DD/YYYY, H:mm:ss") : null,
      terms: data.trial ? 'trial' : 'default'
    }
    if (editUser) {
      dispatch(updateUser(finalData));
    }
    else {
      finalData = {
        ...finalData,
        userRoles: [7]
      }
      dispatch(addUser(finalData));
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={selectedUser}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} data-testid="users-form">
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
            <Field name="vendorId" validate={Required}>
            {({ input, meta }) => (
              <FormControl variant="standard" fullWidth>
                <InputLabel id="vendor" error={meta.error && meta.touched}><FormattedMessage id="components.vendor" /></InputLabel>
                <Select
                  {...input}
                  labelId="vendor"
                  error={meta.error && meta.touched}
                >
                  {vendors && vendors.map((row) => (
                    <MenuItem data-testid="select-option" key={row.vendorId} value={row.vendorId}>{row.name}</MenuItem>
                  ))}
                </Select>
                {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
              </FormControl>
            )}
          </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="userName" validate={Required}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.username" />}
                      variant="standard"
                      fullWidth
                      role="textbox"
                      error={meta.error && meta.touched}
                      autoComplete='off'
                      disabled={editUser}
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
            </Grid>
              <Grid item xs={12}>
                <Field name="email" validate={ComposeValidators(Required, EmailAddress, AllowedEmailDomains(selectedAppCustomer?.emailDomainWhiteList))}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.emailAddress" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                        disabled={editUser}
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="firstName" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.firstName" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="lastName" validate={Required}>
                  {({ input, meta }) => (
                    <>
                      <TextField
                        {...input}
                        label={<FormattedMessage id="components.lastName" />}
                        variant="standard"
                        fullWidth
                        role="textbox"
                        error={meta.error && meta.touched}
                        autoComplete='off'
                      />
                      {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                    </>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="trial" type="checkbox" >
                  {({ input }) => (
                    <FormControlLabel sx={{ display: 'block', marginTop: '6px' }}
                      control={<Checkbox {...input.onChange} {...input} />}
                      label={<FormattedMessage id="components.trialUser" />}
                    />
                  )}
                </Field>
                {values.trial === true &&
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Field name="trialExpiration" validate={ComposeValidators(Required, StartDateValidation)}>
                      {({ input, meta }) => (
                        <>
                          <DesktopDatePicker
                            label={<FormattedMessage id="components.trialExpiration" />}
                            inputFormat="MM/DD/YYYY"
                            {...input}
                            renderInput={(params: any) =>
                              <TextField
                                {...params}
                                variant="standard"
                                fullWidth
                                error={meta.error && meta.touched}
                              />
                            }
                          />
                          {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                        </>
                      )}
                    </Field>
                  </LocalizationProvider>
                }
              </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button autoFocus onClick={() => form.reset()} disabled={submitting || pristine} variant="contained" color="error" sx={{ marginRight: '5px' }}>
              <FormattedMessage id="components.reset" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
              {editUser ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
            </Button>
          </Box>
        </form>
      )}
    />
  );
};
