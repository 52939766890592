/**
 * Delete Confirmation Dialog
 */
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type DeleteDialogProps = {
    title: any,
    message: any,
    onConfirm: () => any,
    open: boolean
    handleCancel: () => any
}

export const DeleteConfirmationDialog = (props: DeleteDialogProps) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            data-testid="delete-dlg"
        >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleCancel} variant="contained" color="error">
                    <FormattedMessage id="components.cancel" />
                </Button>
                <Button onClick={props.onConfirm} variant="contained" color="primary" autoFocus>
                    <FormattedMessage id="components.yes" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
