import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import { IToolingObject } from "../../shared/interfaces/tooling-object.interface";
import { onSearchInTable } from "../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { TablePaginationActions } from "../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";
import { setEditToolingObject, setOpenAddUpdateToolingObjectDialog, setOpenViewToolingObjectDialog, setSelectedToolingObject, setSelectedCustomer } from "../../store/slices/tooling-objects";
import { getToolingObjects, getToolingObjectForVendor } from "../../store/thunks/tooling-objects/tooling-objects-thunks";
import { AddUpdateToolingObjectForm } from "./add-update-tooling-object-form.component";
import { Role } from "../../shared/enums/role.enum";
import { getCustomerWithTenantTypeDetail } from "../../store/thunks/customers/customers-thunks";
import { setSnackbarAlert } from "../../store/app-slice";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const ToolingObjects = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const toolingObjects = useAppSelector(state => state.toolingObject.toolingObjects);
  const selectedToolingObject = useAppSelector(state => state.toolingObject.selectedToolingObject);
  const openViewToolingObjectDialog = useAppSelector(state => state.toolingObject.openViewToolingObjectDialog);
  const openAddUpdateToolingObjectDialog = useAppSelector(state => state.toolingObject.openAddUpdateToolingObjectDialog);
  const editToolingObject = useAppSelector(state => state.toolingObject.editToolingObject);
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const customers = useAppSelector(state => state.customer.customers);
  const customerStatus = useAppSelector(state => state.customer.status);
  const selectedCustomer = useAppSelector(state => state.toolingObject.selectedCustomer);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IToolingObject>('oem');
  const [searchToolingObjectsList, setSearchToolingObjectsList] = useState<IToolingObject[]>(toolingObjects);
  const [columns, setColumns] = useState<Column[]>([]);

  const adminColumns: Column[] = [
    { id: 'oem', label: intl.formatMessage({ id: 'components.vendor' }) },
    { id: 'oemPartNumber', label: intl.formatMessage({ id: 'components.oemPartNumber' }) },
    { id: 'oemSerialNumber', label: intl.formatMessage({ id: 'components.oemSerialNumber' }) },
    { id: 'customerName', label: intl.formatMessage({ id: 'components.customer' }) },
    { id: 'productionState', label: intl.formatMessage({ id: 'components.productionState' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  const vendorColumns: Column[] = [
    { id: 'oemPartNumber', label: intl.formatMessage({ id: 'components.oemPartNumber' }) },
    { id: 'oemSerialNumber', label: intl.formatMessage({ id: 'components.oemSerialNumber' }) },
    { id: 'customerName', label: intl.formatMessage({ id: 'components.customer' }) },
    { id: 'productionState', label: intl.formatMessage({ id: 'components.productionState' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  const customerColumns: Column[] = [
    { id: 'oem', label: intl.formatMessage({ id: 'components.vendor' }) },
    { id: 'oemPartNumber', label: intl.formatMessage({ id: 'components.oemPartNumber' }) },
    { id: 'oemSerialNumber', label: intl.formatMessage({ id: 'components.oemSerialNumber' }) },
    { id: 'productionState', label: intl.formatMessage({ id: 'components.productionState' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    loadToolingObjects("all");
    if (loggedInUser?.userRoleNames.includes(Role.GLEASON_VENDOR) && (customerStatus === 'idle' || customerStatus === 'failed')) {
      dispatch(getCustomerWithTenantTypeDetail());
    }
  }, [loggedInUser]);

  useEffect(() => {
    setSearchToolingObjectsList(toolingObjects);
  }, [toolingObjects]);

  useEffect(() => {
    setPage(0);
  },[searchToolingObjectsList]);

  const loadToolingObjects = (value: any) => {
    if (loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN)) {
      setColumns(adminColumns);
      dispatch(getToolingObjects(value.companyCode ? value.companyCode : ""));
    }
    else if (loggedInUser?.userRoleNames.includes(Role.GLEASON_VENDOR)) {
      setColumns(vendorColumns);
      const requestDataVendor = {
        oem: loggedInUser ? loggedInUser.vendorName : "",
        customerNumber: value.companyCode ? value.companyCode : ""
      }
      if (requestDataVendor.oem !== "") {
        dispatch(getToolingObjectForVendor(requestDataVendor));
      }
    }
    else if (loggedInUser?.userRoleNames.includes(Role.CUSTOMER_ADMIN)) {
      setColumns(customerColumns);
      const companyCode = loggedInUser ? loggedInUser.companyCode : "";
      if (companyCode !== "") {
        dispatch(getToolingObjects(companyCode));
      }
    }
  }

  const handleCustomerChange = (value: any) => {
    dispatch(setSelectedCustomer(value));
    loadToolingObjects(value);
  }

  const onViewToolingObjectDetails = (data: any) => {
    dispatch(setSelectedToolingObject(data));
    dispatch(setOpenViewToolingObjectDialog(true));
  }

  const onUpdateToolingObject = (data: any) => {
    dispatch(setSelectedToolingObject(data));
    dispatch(setOpenAddUpdateToolingObjectDialog(true));
    dispatch(setEditToolingObject(true));
  }

  const addToolingObject = () => {
    if(selectedCustomer === "all"){
      dispatch(
        setSnackbarAlert({ messageType: "error", message: intl.formatMessage({ id:"components.selectCustomerToAddTools"}) })
      );
      return;
    }
    dispatch(setOpenAddUpdateToolingObjectDialog(true));
    dispatch(setEditToolingObject(false));
  }

  const handleCloseDialog = () => {
    dispatch(setOpenViewToolingObjectDialog(false));
    dispatch(setOpenAddUpdateToolingObjectDialog(false));
    dispatch(setSelectedToolingObject(null));
  }

  const onDeleteToolingObject = (data: any) => {
    //
  }

  const actionTemplateToolingObjects = (rowData: any) => {
    return <span data-testid="to-actions" className="list-action">
      <Link role="button" data-testid="view-to" aria-label="view-to" component="button" variant="body2" onClick={() => onViewToolingObjectDetails(rowData)} > <VisibilityIcon /> </Link>
      <Link role="button" data-testid="update-to" component="button" variant="body2" onClick={() => onUpdateToolingObject(rowData)} > <EditIcon /> </Link>
      {loggedInUser?.userRoleNames.includes(Role.GLEASON_VENDOR) &&
        <Link role="button" data-testid="delete-to" component="button" variant="body2" onClick={() => onDeleteToolingObject(rowData)} > <ClearIcon /> </Link>
      }
    </span>;
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchToolingObjectsList(onSearchInTable(toolingObjects, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IToolingObject,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="tooling-object">
        <div className="page-header">
          <h2><FormattedMessage id="components.tools" /></h2>
        </div>
        <Box>
          <TableContainer component={Paper}>
            {(loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) || loggedInUser?.userRoleNames.includes(Role.GLEASON_VENDOR)) &&
              <Box>
                <FormControl variant="standard" sx={{ m: 2, mt: 2, width: 350 }}>
                  <InputLabel id="select-customer"><FormattedMessage id="components.select" /> <FormattedMessage id="components.customer" /></InputLabel>
                  <Select
                    labelId="select-customer"
                    role="combobox"
                    aria-label="select customer"
                    inputProps={{ "data-testid": "select-customer" }}
                    value={selectedCustomer}
                    onChange={(e) => handleCustomerChange(e.target.value)}
                  >
                    <MenuItem value="all">All</MenuItem>
                    {customers.map((row: any) => (
                      <MenuItem key={row.customerId} value={row}>{row.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            }
            <div className="table-head-links">
              <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadToolingObjects(selectedCustomer)} > <CachedIcon /> </Link></div>
              <div data-testid="add-new-btn" className="add-link">
                {(loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) || loggedInUser?.userRoleNames.includes(Role.GLEASON_VENDOR)) &&
                  <Link component="button" role="button" aria-label="add-to" variant="body1" underline="none" onClick={() => addToolingObject()} ><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.tool" /> <AddIcon className="add-icon" /> </Link>
                }
              </div>
            </div>
            <div data-testid="searchbox" style={{ padding: "10px" }}>
              <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
            </div>
            <Table sx={{ minWidth: 650 }} data-testid="tooling-object-table" aria-label="tooling object table">
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
              />
              <TableBody>
                {stableSort(searchToolingObjectsList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow data-testid="to-node" key={`${row.oemPartNumber}__${row.oemSerialNumber}`} >
                        {!loggedInUser?.userRoleNames.includes(Role.GLEASON_VENDOR) &&
                          <TableCell>{row.oem}</TableCell>
                        }
                        <TableCell>{row.oemPartNumber}</TableCell>
                        <TableCell>{row.oemSerialNumber}</TableCell>
                        {(loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN) || loggedInUser?.userRoleNames.includes(Role.GLEASON_VENDOR)) &&
                          <TableCell>{row.customerName}</TableCell>
                        }
                        <TableCell>{row.productionState}</TableCell>
                        <TableCell>{actionTemplateToolingObjects(row)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    data-testid="pagination-control"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={columns.length}
                    count={searchToolingObjectsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </div>

      {/*start of add/update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openAddUpdateToolingObjectDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
          {editToolingObject ?
            <><FormattedMessage id="components.update" /> <FormattedMessage id="components.tool" /></>
            : <><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.tool" /></>
          }
        </BootstrapDialogTitle>
        <DialogContent>
          <AddUpdateToolingObjectForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of add/update dialog */}

      {/*start of view details dialog */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewToolingObjectDialog}
        data-testid="view-to-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedToolingObject &&
            <>
              <p><FormattedMessage id="components.vendor" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.oem}</span></p>
              <p><FormattedMessage id="components.oemPartNumber" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.oemPartNumber}</span></p>
              <p><FormattedMessage id="components.oemSerialNumber" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.oemSerialNumber}</span></p>
              <p><FormattedMessage id="components.customerPartNumber" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.customerPartNumber}</span></p>
              <p><FormattedMessage id="components.productionState" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.productionState}</span></p>
              <p><FormattedMessage id="components.orderNumber" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.orderNumber}</span></p>
              <p><FormattedMessage id="components.trackingNumber" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.trackingNumber}</span></p>
              <p><FormattedMessage id="components.customerName" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.customerName}</span></p>
              <p><FormattedMessage id="components.customerNumber" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.customerNumber}</span></p>
              <p><FormattedMessage id="components.customerDescription" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.customerDescription}</span></p>
              <p><FormattedMessage id="components.locationType" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.locationType}</span></p>
              <p><FormattedMessage id="components.locationSubType" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.locationSubType}</span></p>
              <p><FormattedMessage id="components.locationValue" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.locationValue}</span></p>
              <p><FormattedMessage id="components.comments" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.comments}</span></p>
              <p><FormattedMessage id="components.type" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.type}</span></p>
              <p><FormattedMessage id="components.subType" />: <span style={{ fontWeight: 'bold' }}>{selectedToolingObject.subType}</span></p>
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

    </>
  );
};
