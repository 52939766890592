import { createSlice } from '@reduxjs/toolkit';
import { IDataCenter } from "../../shared/interfaces/data-center.interface";
import { addDataCenter, deleteDataCenter, getDataCenters, updateDataCenter } from '../thunks/data-centers/data-centers-thunks';

interface DataCenterState {
    dataCenters: IDataCenter[],
    selectedDataCenter: any,
    openViewDataCenterDialog: boolean,
    openAddUpdateDataCenterDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    editDataCenter: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: DataCenterState = {
    dataCenters: [],
    selectedDataCenter: null,
    openViewDataCenterDialog: false,
    openAddUpdateDataCenterDialog: false,
    openDeleteConfirmationDialog: false,
    editDataCenter: false,
    status: 'idle',
    error: undefined
}

export const dataCentersSlice = createSlice({    
    name: "dataCenter",
    initialState,
    reducers: {
        setDataCenters: (state, action) => {
            state.dataCenters = action.payload
        },
        setSelectedDataCenter: (state, action) => {
            state.selectedDataCenter = action.payload
        },
        setOpenViewDataCenterDialog: (state, action) => {
            state.openViewDataCenterDialog = action.payload
        },
        setOpenAddUpdateDataCenterDialog: (state, action) => {
            state.openAddUpdateDataCenterDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        },
        setEditDataCenter: (state, action) => {
            state.editDataCenter = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getDataCenters.fulfilled, (state, action) => {            
            state.dataCenters = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getDataCenters.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getDataCenters.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addDataCenter.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addDataCenter.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addDataCenter.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(updateDataCenter.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(updateDataCenter.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(updateDataCenter.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deleteDataCenter.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deleteDataCenter.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deleteDataCenter.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setDataCenters,
    setSelectedDataCenter,
    setOpenViewDataCenterDialog,
    setOpenAddUpdateDataCenterDialog,
    setOpenDeleteConfirmationDialog,
    setEditDataCenter
} = dataCentersSlice.actions;
export default dataCentersSlice.reducer;
