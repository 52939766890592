import { createAsyncThunk } from "@reduxjs/toolkit";
import { dataAPI } from "../../../shared/dataApi";
import { setLoading, setSnackbarAlert } from "../../app-slice";

export const getRegisteredApplications = createAsyncThunk(
  "registeredApplication/getRegisteredApplications",
  async (requestData, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`RegisteredApplication/GetRegisteredApplications`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(setLoading(false));
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }      
      throw error;
    }
  }
);

export const getAppStreamUrl = createAsyncThunk(
  "registeredApplication/getAppStreamUrl",
  async (email: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`RegisteredApplication/GetAppStreamUrl?EmailAddress=`+ email);
      dispatch(setLoading(false));
      let win = window.open(result.data.appStreamUrl, '_blank');
      win?.focus();
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);