import { createSlice } from '@reduxjs/toolkit';
import { IPurchaseOrder } from "../../shared/interfaces/purchase-order.interface";
import { addPurchaseOrder, deletePurchaseOrder, duplicatePurchaseOrder, getPurchaseOrders } from '../thunks/purchase-orders/purchase-orders-thunks';

interface PurchaseOrderState {
    purchaseOrders: IPurchaseOrder[],
    selectedPurchaseOrder: any,
    openViewPurchaseOrderDialog: boolean,
    openAddPurchaseOrderDialog: boolean,
    openDuplicateOrderDialog: boolean,
    openDeleteConfirmationDialog: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: PurchaseOrderState = {
    purchaseOrders: [],
    selectedPurchaseOrder: null,
    openViewPurchaseOrderDialog: false,
    openAddPurchaseOrderDialog: false,
    openDuplicateOrderDialog: false,
    openDeleteConfirmationDialog: false,
    status: 'idle',
    error: undefined
}

export const purchaseOrdersSlice = createSlice({    
    name: "purchaseOrder",
    initialState,
    reducers: {
        setPurchaseOrders: (state, action) => {
            state.purchaseOrders = action.payload
        },
        setSelectedPurchaseOrder: (state, action) => {
            state.selectedPurchaseOrder = action.payload
        },
        setOpenViewPurchaseOrderDialog: (state, action) => {
            state.openViewPurchaseOrderDialog = action.payload
        },
        setOpenAddPurchaseOrderDialog: (state, action) => {
            state.openAddPurchaseOrderDialog = action.payload
        },
        setOpenDuplicateOrderDialog: (state, action) => {
            state.openDuplicateOrderDialog = action.payload
        },
        setOpenDeleteConfirmationDialog: (state, action) => {
            state.openDeleteConfirmationDialog = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getPurchaseOrders.fulfilled, (state, action) => {            
            state.purchaseOrders = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getPurchaseOrders.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getPurchaseOrders.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(addPurchaseOrder.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(addPurchaseOrder.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(addPurchaseOrder.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(deletePurchaseOrder.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(deletePurchaseOrder.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(deletePurchaseOrder.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(duplicatePurchaseOrder.fulfilled, (state, action) => {
            state.status = 'succeeded'
        })
        .addCase(duplicatePurchaseOrder.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(duplicatePurchaseOrder.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        });
    }
})

export const { 
    setPurchaseOrders,
    setSelectedPurchaseOrder,
    setOpenViewPurchaseOrderDialog,
    setOpenAddPurchaseOrderDialog,
    setOpenDuplicateOrderDialog,
    setOpenDeleteConfirmationDialog
} = purchaseOrdersSlice.actions;
export default purchaseOrdersSlice.reducer;
