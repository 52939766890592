import { MouseEvent, ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Field } from "react-final-form";
import { Button, TextField, Box, Grid, Link, TableContainer, FormLabel, Table, TableBody, TableCell, TableRow, Paper, TableFooter, TablePagination, Checkbox } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IIotPlatform, IIotPlatformMicroServices } from "../../../shared/interfaces/iot-platform.interface";
import { ComposeValidators, Required, VersionValidation } from "../../../shared/form-validations/form-validations";
import { addIotPlatform, getIotPlatformMicroServices, updateIotPlatform } from "../../../store/thunks/iot-platforms/iot-platforms-thunks";
import { SortableTableHead } from "../../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../../shared/table-sorting/table-sort";
import { setIotPlatformMicroServices } from "../../../store/slices/iot-platforms";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const AddUpdateIotPlatformForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const selectedIotPlatform = useAppSelector(state => state.iotPlatform.selectedIotPlatform);
  const editIotPlatform = useAppSelector(state => state.iotPlatform.editIotPlatform);
  const iotPlatformMicroServices = useAppSelector(state => state.iotPlatform.iotPlatformMicroServices);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IIotPlatformMicroServices>('microServiceName');
  
  const columns: Column[] = [
    { id: 'isCoreService', label: intl.formatMessage({ id: 'components.isCoreService' }) },
    { id: 'microServiceName', label: intl.formatMessage({ id: 'components.microServiceName' }) },
    { id: 'dependsOn', label: intl.formatMessage({ id: 'components.dependsOn' }) }
  ];

  const onSubmit = (data: IIotPlatform) => {
    let finalData: any = data;
    finalData.iotPlatformMicroServices = iotPlatformMicroServices;
    finalData.currentuserId = loggedInUser?.userId;
    if (editIotPlatform) {      
      dispatch(updateIotPlatform(finalData));
    }
    else {
      dispatch(addIotPlatform(finalData));
    }
  };

  useEffect(() => {
    if(editIotPlatform){
      dispatch(setIotPlatformMicroServices(selectedIotPlatform.iotPlatformMicroServices));
    }    
  }, []);

  const getMicroServices = (filePath: string) => {    
    if(filePath && filePath.trim()){
      dispatch(getIotPlatformMicroServices(filePath.trim()));
    }
  }

  const actionDependsOn = (data: any) => {
    return data.dependsOn.join(", ");
  }

  const handleClick = (event: MouseEvent<unknown>, data: any) => {
    const iotPlatformMS = iotPlatformMicroServices.map(obj =>
      obj.microServiceName === data.microServiceName
        ? { ...obj, isCoreService : !obj.isCoreService } : obj
    );
    dispatch(setIotPlatformMicroServices(iotPlatformMS));
  };

  const resetMicroServices = () => {
    if(editIotPlatform){
      dispatch(setIotPlatformMicroServices(selectedIotPlatform.iotPlatformMicroServices));
    }
    else {
      dispatch(setIotPlatformMicroServices([]));
    }    
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IIotPlatformMicroServices,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={selectedIotPlatform}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} data-testid="iot-platforms-form">
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <Field name="name" validate={Required}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.iotPlatform" />}
                      variant="standard"
                      role="textbox"
                      aria-label="ip-name"
                      fullWidth
                      error={meta.error && meta.touched}
                      disabled={editIotPlatform}
                      autoComplete='off'
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
              <Field name="version" validate={ComposeValidators(Required, VersionValidation)}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.version" />}
                      variant="standard"
                      fullWidth
                      role="textbox"
                      aria-label="ip-region"
                      error={meta.error && meta.touched}
                      autoComplete='off'
                      sx={{mt: 2}}
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
              <Field name="filePath" validate={Required}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.filePath" />}
                      variant="standard"
                      fullWidth
                      role="textbox"
                      aria-label="ip-fleet"
                      error={meta.error && meta.touched}
                      autoComplete='off'
                      sx={{mt: 2}}
                      disabled={editIotPlatform}
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
              <Field name="type" validate={Required}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.type" />}
                      variant="standard"
                      fullWidth
                      role="textbox"
                      aria-label="ip-stack"
                      error={meta.error && meta.touched}
                      autoComplete='off'
                      sx={{mt: 2}}
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
              <Field name="description" validate={Required}>
                {({ input, meta }) => (
                  <>
                    <TextField
                      {...input}
                      label={<FormattedMessage id="components.description" />}
                      variant="standard"
                      fullWidth
                      role="textbox"
                      multiline
                      maxRows={3}
                      error={meta.error && meta.touched}
                      autoComplete='off'
                      sx={{mt: 2}}
                    />
                    {meta.error && meta.touched && <span className="form-error">{meta.error}</span>}
                  </>
                )}
              </Field>
            </Grid>
            <Grid item xs={6}>
              {editIotPlatform || iotPlatformMicroServices.length > 0 ?
              <>
                <FormLabel><FormattedMessage id="components.microServices" /></FormLabel>
                <TableContainer component={Paper} sx={{ mt: 0, maxHeight: 340 }}>
                <Table stickyHeader data-testid="micro-services-table" aria-label="micro services table">
                  <SortableTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    columns={columns}
                  />
                  <TableBody>
                    {stableSort(iotPlatformMicroServices, getComparator(order, orderBy))
                      .map((row, index) => {
                        const isItemSelected = row.isCoreService; 
                          const labelId = `checkbox-${index}`;
                        return (
                          <TableRow data-testid="ms-node" key={index} >
                            <TableCell>
                            <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  onClick={(event) => handleClick(event, row)}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                              </TableCell>
                            <TableCell>{row.microServiceName}</TableCell>
                            <TableCell>{actionDependsOn(row)}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                </TableContainer>
              </>
                :
                <Link
                  sx={{cursor: 'pointer'}}
                  data-testid="micro-services-link"
                  aria-label="micro services link"
                   variant="body2"
                  onClick={() => getMicroServices(values.filePath)}
                >
                  <FormattedMessage id="components.linkToGetMicroServices" />
                </Link>
              }
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button onClick={() => {form.reset(); resetMicroServices()}} disabled={submitting || pristine } variant="contained" color="error" sx={{ marginRight: '5px' }}  data-testid="reset-button">
              <FormattedMessage id="components.reset" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
              {editIotPlatform ? <FormattedMessage id="components.update" /> : <FormattedMessage id="components.add" />}
            </Button>
          </Box>

        </form>
      )}
    />
  );
};
