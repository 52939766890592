import { createSlice } from '@reduxjs/toolkit';
import { ITenantDetails } from '../../shared/interfaces/tenant-details.interface';
import { ITenantType } from '../../shared/interfaces/tenant-type.interface';
import { getTenantVmAllowedParameters, getTenantDetails, getTenantType, getVmSizeDetails, saveTenantDetails } from '../thunks/tenant-detail/tenant-detail-thunks';

interface TenantDetailState {
    tenantType: ITenantType[],
    tenantDetails: ITenantDetails | null,
    tenantVmAllowedParameters: any,
    vmSizeDetails: any[],
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    tenantVmAllowedParametersStatus: 'idle' | 'loading' | 'succeeded' | 'failed',
    vmSizeDetailsStatus: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: TenantDetailState = {
    tenantType: [],
    tenantDetails: null,
    tenantVmAllowedParameters: null,
    vmSizeDetails: [],
    status: 'idle',
    tenantVmAllowedParametersStatus: 'idle',
    vmSizeDetailsStatus: 'idle',
    error: undefined
}

export const tenantDetailSlice = createSlice({    
    name: "customer",
    initialState,
    reducers: {
        setTenantType: (state, action) => {
            state.tenantType = action.payload
        },
        setTenantDetails: (state, action) => {
            state.tenantDetails = action.payload
        },
        setTenantVmAllowedParameters: (state, action) => {
            state.tenantVmAllowedParameters = action.payload
        },
        setVmSizeDetails: (state, action) => {
            state.vmSizeDetails = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getTenantType.fulfilled, (state, action) => {            
            state.tenantType = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getTenantType.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getTenantType.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getTenantDetails.fulfilled, (state, action) => {            
            state.tenantDetails = action.payload;
            state.status = 'succeeded'
        })
        .addCase(getTenantDetails.pending, (state, action) => {
            state.status = 'loading'
        })
        .addCase(getTenantDetails.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
        })
        .addCase(getTenantVmAllowedParameters.fulfilled, (state, action) => {            
            state.tenantVmAllowedParameters = action.payload;
            state.tenantVmAllowedParametersStatus = 'succeeded'
        })
        .addCase(getTenantVmAllowedParameters.pending, (state, action) => {
            state.tenantVmAllowedParametersStatus = 'loading'
        })
        .addCase(getTenantVmAllowedParameters.rejected, (state, action) => {
            state.tenantVmAllowedParametersStatus = 'failed'
            state.error = action.error.message
        })
        .addCase(getVmSizeDetails.fulfilled, (state, action) => {            
            state.vmSizeDetails = action.payload;
            state.vmSizeDetailsStatus = 'succeeded'
        })
        .addCase(getVmSizeDetails.pending, (state, action) => {
            state.vmSizeDetailsStatus = 'loading'
        })
        .addCase(getVmSizeDetails.rejected, (state, action) => {
            state.vmSizeDetailsStatus = 'failed'
            state.error = action.error.message
        })
    }
})

export const { 
    setTenantType,
    setTenantDetails,
    setTenantVmAllowedParameters,
    setVmSizeDetails
} = tenantDetailSlice.actions;
export default tenantDetailSlice.reducer;
