export const en_US = {
  "components.applications": "Applications",
  "components.application": "Application",
  "components.dashboard": "Dashboard",
  "components.users": "Users",
  "components.user": "User",
  "components.modules": "Modules",
  "components.module": "Module",
  "components.packages": "Packages",
  "components.package": "Package",
  "components.dataCenters": "Data Centers",
  "components.dataCenter": "Data Center",
  "components.app": "App",
  "components.region": "Region",
  "components.fleetName": "Fleet Name",
  "components.stackName": "Stack Name",
  "components.ec2IP": "Ec2 IP",
  "components.actions": "Actions",
  "components.addNew": "Add New",
  "components.update": "Update",
  "components.delete": "Delete",
  "components.edit": "Edit",
  "components.save": "Save",
  "components.submit": "Submit",
  "components.noRecordsFound": "No records found",
  "components.wantToDelete": "Are You Sure Want To Delete?",
  "components.thisWillDelete": "This will delete",
  "components.permanently": "permanently.",
  "components.vmAssociated": "Azure VM '",
  "components.vmAssociatedContd": "' is associated with customer.",
  "components.vmDeleteWarning": "The User has to delete the Azure VM manually.",
  "components.searchHere": "Search here...",
  "components.reset": "Reset",
  "components.add": "Add",
  "components.cancel": "Cancel",
  "components.ok": "Ok",
  "components.close": "Close",
  "components.yes": "Yes",
  "components.customer": "Customer",
  "components.customers": "Customers",
  "components.companyCode": "Company Code",
  "components.isolatedEc2IP": "Isolated Ec2 IP",
  "components.allowedEmailDomains": "Allowed Email Domains",
  "components.tenantType": "Tenant Type",
  "components.userManagement": "User Management",
  "components.viewUsers": "View Users",
  "components.grantPermissions": "Grant Permissions",
  "components.trialUser": "Trial User",
  "components.select": "Select",
  "components.roles": "Roles",
  "components.emailAddress": "Email Address",
  "components.email": "Email",
  "components.username": "Username",
  "components.trialExpiration": "Trial Expiration",
  "components.name": "Name",
  "components.firstName": "First Name",
  "components.lastName": "Last Name",
  "components.grantedPermissions": "Granted Permissions",
  "components.availablePermissions": "Available Permissions",
  "components.code": "Code",
  "components.platform": "Platform",
  "components.edgeModule": "Edge Module",
  "components.selectCPM": "Please select User and Platform to diplay Modules",
  "components.updatePermissions": "Update Permissions",
  "components.permissions": "Permissions",
  "components.license": "License",
  "components.description": "Description",
  "components.vendors": "Vendors",
  "components.vendor": "Vendor",
  "components.customLicensing": "Custom Licensing",
  "components.vendorCode": "Vendor Code",
  "components.applicationCatalog": "Application Catalog",
  "components.all": "All",
  "components.filterByApplication": "Filter By Application",
  "components.materialNumber": "Material Number",
  "components.standard": "Standard",
  "components.custom": "Custom",
  "components.filterModulesBasedOnVendor": "Filter Modules based on Vendor",
  "components.customApplication": "Custom Application",
  "components.purchases": "Purchases",
  "components.purchase": "Purchase",
  "components.orders": "Orders",
  "components.order": "Order",
  "components.order#": "Order#",
  "components.customerPO": "Customer PO",
  "components.orderPackage": "Order Package",
  "components.pleaseSelectCO":
    "Please select Order to diplay Packages",
  "components.pleaseSelectCustomer": "Please select Customer to diplay Orders",
  "components.userLimit": "Limit",
  "components.licenseType": "License Type",
  "components.startDate": "Start Date",
  "components.endDate": "End Date",
  "components.licensedUsers": "Licensed Users",
  "components.assignLicense": "Assign License",
  "components.viewAssignedLicense": "View Assigned License",
  "components.reloadLicenses": "Reload Licenses",
  "components.generateLicense": "Generate License",
  "components.unsavedInformation":
    "You have unsaved information on Assign License tab",
  "components.assignment": "Assignment",
  "components.customLicense": "Custom License",
  "components.selectCUtoviewLA":
    "Select Customer and User to view Licensed Applications.",
  "components.noLicensedApplications":
    "No Licensed Applications for selected User.",
  "components.pushLicense": "Push License",
  "components.selectCustomerUserForJSONfile":
    "Please select Customer and User to generate and download respective .json license file",
  "components.enterEmailtoSendLicense":
    "Enter email address to send license file",
  "components.generateUserLicense": "Generate User License",
  "components.downloadUserLicense": "Download User License File",
  "components.generateCustomerLicense": "Generate Customer License",
  "components.downloadCustomerLicense": "Download Customer License File",
  "components.emailLicense": "Email License",
  "components.edgeDeviceManagement": "Edge Device Management",
  "components.edgeDevice": "Edge Device",
  "components.edgeDevices": "Edge Devices",
  "components.viewEdgeDevices": "View Edge Devices",
  "components.assignModules": "Assign Modules",
  "components.filterByCustomer": "Filter By Customer",
  "components.iotDeviceName": "IOT Device Name",
  "components.oemPartNumber": "OEM Part Number",
  "components.oemSerialNumber": "OEM Serial Number",
  "components.iotHubName": "IOT Hub Name",
  "components.teamViewerID": "TeamViewer ID",
  "components.teamViewerUserName": "TeamViewer UserName",
  "components.teamViewerPassword": "TeamViewer Password",
  "components.blocklistEmailDomains": "Blocklist Email Domains",
  "components.blocklistEmailDomain": "Blocklist Email Domain",
  "components.emailDomain": "Email Domain",
  "components.userProfile": "User Profile",
  "components.personalDetails": "Personal Details",
  "components.emailId": "Email Id",
  "components.profile": "Profile",
  "components.changePassword": "Change Password",
  "components.logOut": "Log Out",
  "components.currentPassword": "Current Password",
  "components.newPassword": "New Password",
  "components.confirmPassword": "Confirm Password",
  "components.updatePassword": "Update Password",
  "components.miniSidebar": "Mini Sidebar",
  "components.boxLayout": "Box Layout",
  "components.rtlLayout": "RTL Layout",
  "components.darkMode": "Dark Mode",
  "components.fullScreen": "Full Screen",
  "components.sidebarToggle": "Sidebar Toggle",
  "components.userTrialExpiresOn": "User Trial Expires on",
  "components.language": "Language",
  "components.languages": "Languages",
  "components.appSetting": "App Setting",
  "components.theme": "Theme",
  "components.termsConditions": "Terms & Conditions",
  "components.userTrialExpired": "User Trial Expired",
  "components.trialExpiredMsg":
    "Your trial has expired. Hence please contact Gleason to extend the trial or purchase a license.",
  "components.acceptAndProceed": "Accept and Proceed",
  "components.termsConditionsAcceptanceMsg":
    "Click here to indicate that you have read and agree to the terms presented in the Terms and Conditions agreement",
  "components.sorryPageNotFound": "Sorry, page not found",
  "components.goToHomePage": "Go To Home Page",
  "components.enter": "Enter",
  "components.startDateValidation": "Start Date sholud be today or future date",
  "components.endDateValidation": "End Date should be future date then and greater Start Date",
  "components.endDateValidationDevice":
    "End Date sholud be today or future date",
  "components.required": "Required",
  "components.requiredAtleastOne": "Atleast one role required",
  "components.confirmPasswordValidation":
    "New Password and Confirm Password must be same",
  "components.passwordValidation":
    "Password must contain minimum 6 characters which include atleast one lowercase letter, one uppercase letter, one numeric digit and one special character",
  "components.vmPasswordValidation":
    "Password must be 12 to 72 characters long, which include atleast one lowercase letter, one uppercase letter, one numeric digit and one special character",
  "components.vmUserName":
    "The Username must be 1 to 64 characters long, must only contain letters, numbers, hyphens, and underscores and may not start with a hyphen or number.",
  "components.ipAddressValidation": "is not a valid IP address.",
  "components.subnetIpAddressValidation": "is not a valid subnet IP address",
  "components.allowedEmailDomainsValidation":
    "domain is not allowed for selected customer",
  "components.domainsCustomerValidation":
    "domain is not allowed for selected customer",
  "components.selectCustomerFirst": "Select customer first",
  "components.userCountValidation":
    "Value should not be less than assigned user count for the package",
  "components.deviceCountValidation":
    "Value should not be less than number of module provisioned from the package",
  "components.onlyNumbersAllowed": "Only numbers allowed",
  "components.onlyAlphanumericAllowed": "Only alphanumeric characters allowed",
  "components.commaSeperatedPermissions":
    "Enter valid permission(s) with comma(,) separator",
  "components.emailDomainValidation": "Enter valid email domain",
  "components.domainBlocked": "domain is blocked",
  "components.commaSeperatedEmailDomains":
    "Enter valid email domain(s) with comma(,) separator",
  "components.isNotValidEmail": "is not a valid email.",
  "components.appstreamTemporarilyUnavailable":
    "Appstream temporarily unavailable",
  "components.somethingWentWrong": "Something went wrong! Try again.",
  "components.referenceOccuredDB":
    "Reference occured in other tables, hence not able to delete",
  "components.created": "Created!",
  "components.updated": "Updated!",
  "components.deleted": "Deleted!",
  "components.passwordUpdatedSuccessfullyReSign":
    "Password Updated Successfully! Please Re-Sign In Again With New Credentials",
  "components.cannotUseCurrentPassword":
    "Can't use Current Password for New Password",
  "components.invalidCurrentPassword": "Invalid Current Password",
  "components.licensefileSentToEmail": "License file sent to email",
  "components.licenseUpdatedSuccessfully": "License Updated Successfully",
  "components.selectOrderAndRespectiveUsers":
    "Select order and respective users",
  "components.deleteLicenseFile": "Delete License File",
  "components.downloadLicenseFile": "Download License File",
  "components.noFileChoosen": "No File Choosen",
  "components.selectTCcheckbox":
    "Select Term and Conditions checkbox to proceed",
  "components.modulePermissionsUpdated":
    "Module Permissions For User Updated Sucessfully!",
  "components.profileUpdatedSucessfully": "Profile Updated Sucessfully!",
  "components.languageSavedSucessfully": "Language Saved Sucessfully!",
  "components.wentWrongWhenLanguageSaved":
    "Something went wrong when saving language",
  "components.themeSavedSucessfully": "Theme Saved Sucessfully!",
  "components.wentWrongWhenThemeSaved":
    "Something went wrong when saving theme settings",
  "components.containerRegistryPath": "Container Registry Path",
  "components.requestedForProvision": "Requested for Provision",
  "components.requestedForProvisionWithWarning":
    "Requested for Provision. Check the notification for warning.",
  "components.sapMasterNumber": "SAP Master Number",
  "components.provisionDevices": "Provision Devices",
  "components.provisionDevice": "Provision Device",
  "components.provisionHistory": "Provision History",
  "components.viewManifest": "View Manifest",
  "components.provision": "Provision",
  "components.dateAndTime": "Date and Time",
  "components.templates": "Templates",
  "components.template": "Template",
  "components.isDocker": "Is Docker",
  "components.dockerImage": "Docker Image",
  "components.standardFile": "Standard File",
  "components.fileType": "File Type",
  "components.storagePath": "Storage Path",
  "components.iotPlatforms": "IoT Platforms",
  "components.iotPlatform": "IoT Platform",
  "components.type": "Type",
  "components.filePath": "File Path",
  "components.version": "Version",
  "components.versionValidation": "is not a valid version",
  "components.assignModulesToDevice": "Assign Modules To Device",
  "components.selectCustomerToDisplayTemplates":
    "Select Customer to display Templates",
  "components.selectCustomerToDisplayPovisionDevices":
    "Select Customer to display Povision Devices",
  "components.defaultSelected": "Default Selected",
  "components.availableTemplates": "Available Templates",
  "components.deploymentType": "Deployment Type",
  "components.deploy": "Deploy",
  "components.provisionDeviceDetails": "Provision Device Details",
  "components.provisionDetailsSaved": "Provision Details Saved",
  "components.updateModuleConfigSettings": "Update Module Config Settings",
  "components.moduleConfigurationSettings": "Module Config Settings",
  "components.moduleConfigSettingsPopupHead":
    "Are You Sure To Replace Config Settings?",
  "components.moduleConfigSettingsPopupMessage":
    "This Module Config Settings is used to provision Edge Device module and template module, continue with update will delete previous configuration setting of module and user has to update the module configuration of modules again, are you sure to proceed.",
  "components.configSettings": "Config Settings",
  "components.newConfigSettings": "New Config Settings",
  "components.uploadConfigSettings":
    "Upload Module configuration settings json file",
  "components.uploadedConfigSettings": "Uploaded Config Settings",
  "components.uploadNewConfigSettings": "Upload New Config Settings",
  "components.jsonSchema": "Json Schema",
  "components.manifestJSON": "Manifest JSON",
  "components.vendorType": "Vendor Type",
  "components.software": "Software",
  "components.hardware": "Hardware",
  "components.hardwares": "Hardwares",
  "components.hardwareConfiguration": "Hardware Configuration",
  "components.classification": "Classification",
  "components.createdTime": "Created Time",
  "components.active": "Active",
  "components.selectVendorToDisplayHardwares":
    "Select Vendor to display Hardwares",
  "components.uploadHardwareImage": "Upload Hardware Image",
  "components.hardwareImage": "Hardware Image",
  "components.hardwareConfigSettings": "Hardware Config Settings",
  "components.hardwareConfigSettingsPopupHead":
    "Are You Sure To Replace Hardware Config Settings?",
  "components.hardwareConfigSettingsPopupMessage":
    "This Hardware Config Settings is used by Edge Device",
  "components.hardwareVendor": "Hardware Vendor",
  "components.updateHardwareConfigurations": "Update Hardware Configurations",
  "components.hardwareConfigurationSettings": "Hardware Configuration Settings",
  "components.settings": "Settings",
  "components.configurations": "Configurations",
  "components.maxLengthValidation63": "Length cannot be more then 63",
  "components.rfc1123LabelValidation":
    "Enter valid Module name with lower case alphanumeric characters or '-' and should start with letter",
  "components.recentNotifications": "Recent Notifications",
  "components.viewAll": "View All",
  "components.viewNotifications": "View Notifications",
  "components.notifications": "Notifications",
  "components.time": "Time",
  "components.actionName": "Action Name",
  "components.message": "Message",
  "components.assetManagement": "Asset Management",
  "components.assetObject": "Asset Object",
  "components.assetObjects": "Asset Objects",
  "components.selectCustomerToDisplayAssetObjects":
    "Select Customer to display Asset Objects",
  "components.customerAssetId": "Customer Asset ID",
  "components.assetId": "Asset ID",
  "components.customerName": "Customer Name",
  "components.subType": "SubType",
  "components.locationType": "Location Type",
  "components.locationSubType": "Location SubType",
  "components.locationValue": "Location Value",
  "components.oem": "OEM",
  "components.productionState": "Production State",
  "components.url": "URL",
  "components.accessKey": "Access Key",
  "components.secretKey": "Secret Key",
  "components.confirmSecretKey": "Confirm Secret Key",
  "components.vmName": "VM Name",
  "components.tenantUrl": "Tenant URL",
  "components.tenantAccessKey": "Tenant Access Key",
  "components.tenantSecretKey": "Tenant Secret Key",
  "components.tenantDetails": "Tenant Details",
  "components.confirmSecretKeyValidation":
    "Confirm Secret Key is not matching with Secret Key",
  "components.changeCustomer": "Change Customer",
  "components.location": "Location",
  "components.subnetIP": "Subnet IP with CIDR subnet mask",
  "components.privateIP": "Private IP",
  "components.osType": "OS Type",
  "components.vmSize": "VM Size",
  "components.vmOsDiskSizeinGB": "VM OS Disk Size in GiB",
  "components.vmStorageAccountType": "VM Storage Account Type",
  "components.vmAdminUsername": "VM Admin Username",
  "components.vmAdminPassword": "VM Admin Password",
  "components.publicIP": "Public IP",
  "components.tenantVmFqdn": "Tenant VM FQDN",
  "components.tenantVmDeploymentDtTm": "Tenant VM Deployment Date Time",
  "components.cloudServerManagement": "Cloud Server Management",
  "components.enterpriseServerManagement": "Enterprise Server Management",
  "components.dataTransferPolicies": "Data Transfer Policies",
  "components.gleason40Subsystem": "Gleason 4.0 Subsystem",
  "components.dataValidation": "Data Validation",
  "components.className": "Class Name",
  "components.transferSchemes": "Transfer Schemes",
  "components.transferScheme": "Transfer Scheme",
  "components.subSystem": "Sub System",
  "components.enable": "Enable",
  "components.set": "Set",
  "components.dryRun": "Dry Run",
  "components.sweep": "Sweep",
  "components.withDryRun": "With Dry Run",
  "components.withoutDryRun": "Without Dry Run",
  "components.upload": "Upload",
  "components.id": "ID",
  "components.appMetadatas": "App Meta Datas",
  "components.appMetadata": "App Meta Data",
  "components.sweepWithDryRunCompleted": "Sweep With DryRun Completed",
  "components.sweepWithoutDryRunCompleted": "Sweep Without DryRun Completed",
  "components.vmNameValidation":
    "Virtual Machine name should be 1 to 15 character long, and name cannot contain special characters (except for _), whitespace, or begin or end with special character",
  "components.vmDeployment": "Deployment of Tenant VM Started.",
  "components.assetObjectFile": "Asset Object File",
  "components.override": "Override",
  "components.resourceKey": "Resource Key",
  "components.appDataKey": "App Data Key",
  "components.uploadedSuccessfully": "Uploaded Successfully",
  "components.tenantVMName": "Tenant VM Name",
  "components.assetObjectAttachment": "Asset Object Attachment",
  "components.assetObjectDeployment": "Deployment of Asset Object started",
  "components.remove": "Remove",
  "components.download": "Download",
  "components.removed": "Removed",
  "components.wantToRemove": "Want To Remove",
  "components.thisWillRemove": "this will remove",
  "components.translations": "Translations",
  "components.translation": "Translation",
  "components.clientObjectRegistry": "Client Object Registry",
  "components.vmLinuxOSDiskSizeMinLimit":
    "The OS disk size for Linux VM should be more then or equal to ",
  "components.vmWindowsOSDiskSizeMinLimit":
    "The OS disk size for Windows VM should be more then or equal to ",
  "components.translationType": "Translation Type",
  "components.groupID": "Group ID",
  "components.isCoreService": "Core Service",
  "components.microServiceName": "Micro Service Name",
  "components.dependsOn": "Depends On",
  "components.microServices": "Micro Services",
  "components.coreMicroServices": "Core Micro Services",
  "components.nonCoreMicroServices": "Non Core Micro Services",
  "components.confirmNoServicesText":
    "No non-core services for IoT platform are selected, do you want continue?",
  "components.linkToGetMicroServices":
    "Enter File Path and click here to get available Micro Services.",
  "components.machine": "Machine",
  "components.machines": "Machines",
  "components.machineManagement": "Machine Management",
  "components.serialNumber": "SerialNumber",
  "components.downloads": "Downloads",
  "components.selectedDownloads": "selected Downloads",
  "components.system": "System",
  "components.payloadBucketName": "Payload Bucket Name",
  "components.targetBucketName": "Target Bucket Name",
  "components.targetBucket": "Target Bucket",
  "components.tenantDownloads": "Tenant Downloads",
  "components.tenantDownload": "Tenant Download",
  "components.successfull": "Successfull",
  "components.expired": "Expired",
  "components.downloadedTime": "Downloaded Time",
  "components.fileName": "File Name",
  "components.moduleServiceName": "Module Service Name",
  "components.downloadHistory": "Download History",
  "components.downloadAttemtFailed": "Download Attemt Failed",
  "components.downloadCount": "Download Count",

  "components.UploadJsonConfigChanges": "Upload JSON config changes.",
  "components.UploadedJsonConfigChanges": "Uploaded JSON config changes.",
  "components.UploadJsonConfigChangeFile": "Upload JSON config changes file.",
  "components.DeleteUploadedJsonConfigChanges":
    "Delete uploaded JSON config changes.",
  "components.UploadYamlConfigChanges": "Upload YAML config changes.",
  "components.UploadedYamlConfigChanges": "Uploaded YAML config changes.",
  "components.UploadYamlConfigChangeFile": "Upload YAML config changes file.",
  "components.DeleteUploadedYamlConfigChanges":
    "Delete uploaded YAML config changes.",
  "components.UploadIniConfigChanges": "Upload Ini config changes.",
  "components.UploadedIniConfigChanges": "Uploaded Ini config changes.",
  "components.UploadIniConfigChangeFile": "Upload Ini config changes file.",
  "components.DeleteUploadedIniConfigChanges":
    "Delete uploaded Ini config changes.",
  "components.MergeStrategyType": "Merge Strategy Type",
  "components.downloadStarted": "Download Started",
  "components.selectUsertoviewLicenseDetails":
    "Please select User to view License details",
  "components.pleaseSelectOrder": "Please select Order",
  "components.send": "Send",
  "components.of": "of",
  "components.rowsPerPage": "Rows per page:",
  "components.emailDomainBlocklist": "Email Domain Blocklist",
  "components.dataCenterCreated": "Data Center Created!",
  "components.dataCenterUpdated": "Data Center Updated!",
  "components.dataCenterDeleted": "Data Center Deleted!",
  "components.customerCreated": "Customer Created!",
  "components.customerUpdated": "Customer Updated!",
  "components.customerDeleted": "Customer Deleted!",
  "components.emailDomainBlocklistCreated": "Email Domain Blocklist Created!",
  "components.emailDomainBlocklistDeleted": "Email Domain Blocklist Deleted!",
  "components.vendorCreated": "Vendor Created!",
  "components.vendorUpdated": "Vendor Updated!",
  "components.vendorDeleted": "Vendor Deleted!",
  "components.applicationCreated": "Application Created!",
  "components.applicationUpdated": "Application Updated!",
  "components.applicationDeleted": "Application Deleted!",
  "components.moduleCreated": "Module Created!",
  "components.moduleUpdated": "Module Updated!",
  "components.moduleDeleted": "Module Deleted!",
  "components.packageCreated": "Package Created!",
  "components.packageUpdated": "Package Updated!",
  "components.packageDeleted": "Package Deleted!",
  "components.orderCreated":"Order Created!",
  "components.orderDeleted": "Order Deleted!",
  "components.open": "Open",
  "components.lease": "Lease",
  "components.device": "Device",
  "components.hardwareCreated": "Hardware Created!",
  "components.hardwareUpdated": "Hardware Updated!",
  "components.hardwareDeleted": "Hardware Deleted!",
  "components.iotPlatformCreated": "IoT Platform Created!",
  "components.iotPlatformUpdated": "IoT Platform Updated!",
  "components.iotPlatformDeleted": "IoT Platform Deleted!",
  "components.templateCreated": "Template Created!",
  "components.templateUpdated": "Template Updated!",
  "components.templateDeleted": "Template Deleted!",
  "components.moduleConfigurationSaved": "Module Configuration Saved",
  "components.hardwareDetails": "Hardware Details",
  "components.edgeDeviceCreated": "Edge Device Created!",
  "components.edgeDeviceUpdated": "Edge Device Updated!",
  "components.edgeDeviceDeleted": "Edge Device Deleted!",
  "components.selectEdgeDeviceToDisplayHistory": "Select Edge Device to display history of Edge Device",
  "components.hardwareConfigurationSaved": "Hardware Configuration Saved",
  "components.tenantDetailsSaved": "Tenant VM Details Saved",
  "components.userCreated": "User Created!",
  "components.userUpdated": "User Updated!",
  "components.userDeleted": "User Deleted!",
  "components.profileUpdated": "Profile Updated!",
  "components.light": "Light",
  "components.dark": "Dark",
  "components.updatedSuccessfully": "Updated Successfully!",
  "components.customerPartNumber": "Customer Part Number",
  "components.orderNumber": "Order Number",
  "components.productionState": "Production State",
  "components.trackingNumber": "Tracking Number",
  "components.tools": "Tools",
  "components.tool": "Tool",
  "components.toolingUpdated": "Tooling Updated!",
  "components.customerNumber": "Customer Number",
  "components.customerDescription": "Customer Description",
  "components.comments": "Comments",
  "components.passwordUpdatedSuccessfully":"Password Updated Successfully!",
  "components.clickHere":"Click here",
  "components.togotoHomePage":"to go to home page",
  "components.vendorUser":"Vendor User",
  "components.selectCustomerToAddTools": "Select Customer to add Tool",
  "components.toolingObjectCreated": "Tool Updated!",
  "components.uploadFile": "Upload File",
  "components.catagory": "Catagory",
  "components.model": "Model",
  "components.editMultipleRows": "Edit Multiple Rows",
  "components.updateMultipleOrderPackages": "Update Multiple Order Packages",
  "components.packagesUpdated": "Packages Updated Successfully!",
  "components.copy": "Copy",
  "components.copyOrderMessage": "*All the Packages with details will be copied or duplicated from the selected Order.",
  "components.selectOrderCopy": "Select Order# to copy",

  "components.dummy": "Dummy",
};
