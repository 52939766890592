import moment from "moment";
import { FormattedMessage } from "react-intl";

//Compose Validators method is used to pass multiple validations 
export const ComposeValidators = (...validators: any) => (value: any) =>
validators.reduce((error: any, validator: any) => error || validator(value), undefined);

export const Required = (value: any) => (value ? undefined : <FormattedMessage id="components.required" />);

export const IpAddress = (value: any) =>{
    const ipAddressRegExp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipAddressRegExp.test(value) 
    ? 
    undefined : 
    <>{value} <FormattedMessage id="components.ipAddressValidation" /></>;
}

export const EmailAddress = (value: any) => {
    if (!value)
        return;
        let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (value !== '' && !regex.test(value)) {
            return <>'{value}' <FormattedMessage id="components.isNotValidEmail" /></>;
        }
};

export const AllowedEmailDomains = (emailDomainWhiteList: any) => (value: any) => {
    let emailDomains = emailDomainWhiteList.toLowerCase().split(/\s*,\s*/);
    var domain = value.substring(value.lastIndexOf("@") + 1);

    let domainExists = emailDomains.indexOf(domain.toLowerCase());
    if (domainExists < 0) {
        return <>'{domain}' <FormattedMessage id="components.allowedEmailDomainsValidation" /></>;
    }
};

export const EmailDomains = (emailDomainBlocklist: any[]) => (value: any) =>{
    if (!value)
        return;
    let regex = /^(?!(https:\/\/|http:\/\/|www\.|mailto:|smtp:|ftp:\/\/|ftps:\/\/))(((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,86}[a-zA-Z0-9]))\.(([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,73}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25})))|((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,162}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25}))))$/;

    let splitDomains = value.split(',').map((item: string) => item.trim());
    for (var i = 0; i < splitDomains.length; ++i) {
        if (splitDomains[i] !== '' && !regex.test(splitDomains[i])) {
            return <FormattedMessage id="components.commaSeperatedEmailDomains" />;        
        }
        
        if (regex.test(splitDomains[i])) {
            let isDomainExists = emailDomainBlocklist.find((item) => item.emailDomain.toLowerCase() === splitDomains[i].toLowerCase());
            if (isDomainExists) {                
                return <>{splitDomains[i]} <FormattedMessage id="components.domainBlocked" /></>                    
            }
        }
    }
}

export const EmailDomain= (value: any) =>{
    let regex = /^(?!(https:\/\/|http:\/\/|www\.|mailto:|smtp:|ftp:\/\/|ftps:\/\/))(((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,86}[a-zA-Z0-9]))\.(([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,73}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25})))|((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,162}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25}))))$/;

    if (value !== '' && !regex.test(value)) {
        return <FormattedMessage id="components.emailDomainValidation" />;
    }
}

export const CommaSeperatedPermissions = (value: any) => {
    let regex = /^[a-z]+((,)(\s?)[a-z]+)*$/i;

    if (value !== '' && !regex.test(value)) {
        return <FormattedMessage id="components.commaSeperatedPermissions" />;
    }
};

export const AlphaNumaric = (value: any) => {
    if (!value)
        return;
    var regx = /^[A-Za-z0-9]+$/;
    if (!regx.test(value)) {
        return <FormattedMessage id="components.onlyAlphanumericAllowed" />;
    }
};

export const RequiredConfigFile = (platform: string[], isDocker: boolean) => (value: any) => {
    if (platform?.includes("Edge") && isDocker == true && !value) {
        return <FormattedMessage id="components.required" />;
    }
};

export const MaxLengthValidation63 = (value: any) => {
    if (value && value.length > 63) {
        return <FormattedMessage id="components.maxLengthValidation63" />;
    }
};

export const RFC1123LabelValidation = (value: any) => {
    const rfc1123LabelRegex = /^[a-z]([-a-z0-9]*[a-z0-9])$/;
    if (value && !rfc1123LabelRegex.test(value)) {
        return <FormattedMessage id="components.rfc1123LabelValidation" />;
    }
};

export const VMNameValidation = (value: any) => {
    const vmName = /^(?=^.{1,15}$)([A-Za-z0-9]([_A-Za-z0-9]*[A-Za-z0-9])*)$/;
    if (value && !vmName.test(value)) {
        return <FormattedMessage id="components.vmNameValidation" />;
    }
};

export const SubnetIpAddressCidr = (value: any) => {
    if (!value)
        return;
    let regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(3[0-2]|[21]?[0-9]|[0-9]?)$/;
    if (!regex.test(value)) {
        return <>'{value}' <FormattedMessage id="components.subnetIpAddressValidation" /></>;
    }
};

export const VMLinuxOSDiskSizeMinLimit = (vmOsDiskSizeLimit: number) => (value: any) => {
    if (!value)
        return;
    if (value < vmOsDiskSizeLimit) {
        return <><FormattedMessage id="components.vmLinuxOSDiskSizeMinLimit" /> '{vmOsDiskSizeLimit}' </>
    }
};

export const VMWindowsOSDiskSizeMinLimit = (vmOsDiskSizeLimit: number) => (value: any) => {
    if (!value)
        return;
    if (value < vmOsDiskSizeLimit) {
        return <><FormattedMessage id="components.vmWindowsOSDiskSizeMinLimit" /> '{vmOsDiskSizeLimit}' </>
    }
};

export const NumbersOnly = (value: any) => {
    var regx = /^[0-9]*$/;
    if (!regx.test(value)) {
        return <FormattedMessage id="components.onlyNumbersAllowed" />;
    }
};

export const UserCount = (deploymentType: any, usersAssignedCount: any) => (value: any) => {
    if (usersAssignedCount > value) {
        if (deploymentType.deploymentType === 'User') {
            return <FormattedMessage id="components.userCountValidation" />;
        }
        else {
            return <FormattedMessage id="components.deviceCountValidation" />;
        }
    }
};

export const StartDateValidation = (value: any) => {
    if (moment(value) < moment().startOf('day')) {
        return <FormattedMessage id="components.startDateValidation" />;
    }
};

export const EndDateValidation = (startDate: any) => (value: any) => {
    if (!(moment(value) > moment(startDate).startOf('day'))) {
        return <FormattedMessage id="components.endDateValidation" />;
    }
};

export const EndDateValidationDevice = (value: any) => {
    if ( moment(value) < moment().startOf('day')) {
        return <FormattedMessage id="components.endDateValidationDevice" />;
    }
};

export const VersionValidation = (value: any) => {
    if (!value)
        return;
    let regex = /^\d+(\.\d+){0,2}$/;

    if (!regex.test(value)) {
        return <>'{value}' <FormattedMessage id="components.versionValidation" /></>;
    }
};

export const Password = (value: any) => {
    let regex = /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/g;

    if (!regex.test(value)) {
        return <FormattedMessage id="components.passwordValidation" />;
    }
};

export const ConfirmPassword = (newPassword: any) => (value: any) => {
    return value !== newPassword ? <FormattedMessage id="components.confirmPasswordValidation" /> : undefined;
};