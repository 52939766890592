import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IDataCenter } from "../../shared/interfaces/data-center.interface";
import { onSearchInTable } from "../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { DeleteConfirmationDialog } from "../../shared/dialog-components/delete-confirmation-dialog";
import { AddUpdateDataCenterForm } from "./add-update-data-center-form.component";
import { deleteDataCenter, getDataCenters } from "../../store/thunks/data-centers/data-centers-thunks";
import {
  setSelectedDataCenter,
  setOpenAddUpdateDataCenterDialog,
  setOpenViewDataCenterDialog,
  setOpenDeleteConfirmationDialog,
  setEditDataCenter
} from "../../store/slices/data-centers"
import { TablePaginationActions } from "../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const DataCenters = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const dataCenters = useAppSelector(state => state.dataCenter.dataCenters);
  const dataCenterStatus = useAppSelector(state => state.dataCenter.status);
  const selectedDataCenter = useAppSelector(state => state.dataCenter.selectedDataCenter);
  const openViewDataCenterDialog = useAppSelector(state => state.dataCenter.openViewDataCenterDialog);
  const openAddUpdateDataCenterDialog = useAppSelector(state => state.dataCenter.openAddUpdateDataCenterDialog);
  const openDeleteConfirmationDialog = useAppSelector(state => state.dataCenter.openDeleteConfirmationDialog);
  const editDataCenter = useAppSelector(state => state.dataCenter.editDataCenter);
  const [searchDataCentersList, setSearchDataCentersList] = useState<IDataCenter[]>(dataCenters);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IDataCenter>('name');

  const columns: Column[] = [
    { id: 'name', label: intl.formatMessage({ id: 'components.dataCenter' }) },
    { id: 'region', label: intl.formatMessage({ id: 'components.region' }) },
    { id: 'fleetName', label: intl.formatMessage({ id: 'components.fleetName' }) },
    { id: 'stackName', label: intl.formatMessage({ id: 'components.stackName' }) },
    { id: 'ec2ip', label: intl.formatMessage({ id: 'components.ec2IP' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if(dataCenterStatus === 'idle' || dataCenterStatus === 'failed'){
      loadDataCenters();
    }    
  }, []);

  useEffect(() => {
    setSearchDataCentersList(dataCenters);   
  }, [dataCenters]);

  useEffect(() => {
    setPage(0);
  },[searchDataCentersList]);

  const loadDataCenters = () => {
    dispatch(getDataCenters())
  }

  const handleCloseDialog = () => {
    dispatch(setOpenViewDataCenterDialog(false));
    dispatch(setOpenAddUpdateDataCenterDialog(false));
    dispatch(setOpenDeleteConfirmationDialog(false));
    dispatch(setSelectedDataCenter(null));
  }

  const onViewDataCenterDetails = (data: IDataCenter) => {
    dispatch(setOpenViewDataCenterDialog(true));
    dispatch(setSelectedDataCenter(data));
    dispatch(setEditDataCenter(false));
  }

  const addDataCenter = () => {
    dispatch(setOpenAddUpdateDataCenterDialog(true));
    dispatch(setEditDataCenter(false));
  }

  const onUpdateDataCenter = (data: IDataCenter) => {
    dispatch(setSelectedDataCenter(data));
    dispatch(setOpenAddUpdateDataCenterDialog(true));
    dispatch(setEditDataCenter(true));
  }

  const onDeleteDataCenter = (data: IDataCenter) => {
    dispatch(setOpenDeleteConfirmationDialog(true));
    dispatch(setSelectedDataCenter(data));
  }

  const deleteDataCenterPermanently = () => {
    dispatch(deleteDataCenter(selectedDataCenter));
  }

  const actionTemplateDataCenters = (rowData: IDataCenter) => {
    return <span data-testid="dc-actions" className="list-action">
      <Link role="button" data-testid="view-dc" aria-label="view-dc" component="button" variant="body2" onClick={() => onViewDataCenterDetails(rowData)} > <VisibilityIcon /> </Link>
      <Link role="button" data-testid="update-dc" component="button" variant="body2" onClick={() => onUpdateDataCenter(rowData)} > <EditIcon /> </Link>
      <Link role="button" data-testid="delete-dc" component="button" variant="body2" onClick={() => onDeleteDataCenter(rowData)} > <ClearIcon /> </Link>
    </span>;
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchDataCentersList(onSearchInTable(dataCenters, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IDataCenter,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="data-centers">
        <div className="page-header">
          <h2><FormattedMessage id="components.dataCenters" /></h2>
        </div>
        <Box>
          <TableContainer component={Paper}>
            <div className="table-head-links">
              <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadDataCenters()} > <CachedIcon /> </Link></div>
              <div data-testid="add-new-btn" className="add-link"><Link component="button" role="button" aria-label="add-dc" variant="body1" underline="none" onClick={() => addDataCenter()} ><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.dataCenter" /> <AddIcon className="add-icon" /> </Link></div>
            </div>
            <div data-testid="searchbox" style={{ padding: "10px" }}>
              <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
            </div>
            <Table sx={{ minWidth: 650 }} data-testid="data-center-table" aria-label="data centers table">
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
              />
              <TableBody>
                {stableSort(searchDataCentersList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow data-testid="dc-node" key={row.dataCenterId} >
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.region}</TableCell>
                        <TableCell>{row.fleetName}</TableCell>
                        <TableCell>{row.stackName}</TableCell>
                        <TableCell>{row.ec2ip}</TableCell>
                        <TableCell>{actionTemplateDataCenters(row)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    data-testid="pagination-control"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={columns.length} 
                    count={searchDataCentersList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </div>

      {/*start of add/update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openAddUpdateDataCenterDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
          {editDataCenter ?
            <><FormattedMessage id="components.update" /> <FormattedMessage id="components.dataCenter" /></>
            : <><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.dataCenter" /></>
          }
        </BootstrapDialogTitle>
        <DialogContent>
          <AddUpdateDataCenterForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of add/update dialog */}

      {/*start of view details dialog */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewDataCenterDialog}
        data-testid="view-dc-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedDataCenter &&
            <>
              <p><FormattedMessage id="components.dataCenter" />: <span style={{ fontWeight: 'bold' }}>{selectedDataCenter.name}</span></p>
              <p><FormattedMessage id="components.region" />: <span style={{ fontWeight: 'bold' }}>{selectedDataCenter.region}</span></p>
              <p><FormattedMessage id="components.fleetName" />: <span style={{ fontWeight: 'bold' }}>{selectedDataCenter.fleetName}</span></p>
              <p><FormattedMessage id="components.stackName" />: <span style={{ fontWeight: 'bold' }}>{selectedDataCenter.stackName}</span></p>
              <p><FormattedMessage id="components.ec2IP" />: <span style={{ fontWeight: 'bold' }}>{selectedDataCenter.ec2ip}</span></p>
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

      {/*Start of delete confirmation dialog */}
      <DeleteConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleCancel={handleCloseDialog}
        title={<FormattedMessage id="components.wantToDelete" />}
        message={<span><FormattedMessage id="components.thisWillDelete" /> <FormattedMessage id="components.dataCenter" /> <FormattedMessage id="components.permanently" /></span>}
        onConfirm={deleteDataCenterPermanently}
      />
      {/*end of delete confirmation dialog */}

    </>
  );
};
