import { ReactElement, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-final-form";
import DownloadIcon from "@mui/icons-material/Download";
import ClearIcon from '@mui/icons-material/Clear';
import { Button, TextField, Box, Grid, Select, Link, MenuItem, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getConfigurationSettingParameter } from "../../../store/thunks/modules/modules-thunks";
import { getModuleConfigurationForEdgeDeviceModule, stageEdgeDeviceModuleConfiguration } from "../../../store/thunks/provision-edge-devices/provision-edge-devices-thunks";
import { setModuleCongifurationSettings, setOpenModuleConfigSettingsDialog, setSelectedModuleForEditConfig } from "../../../store/slices/provision-edge-devices";

export const ModuleConfigSettingsForm = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const selectedEdgeDevice = useAppSelector(state => state.edgeDevice.selectedEdgeDevice);
  const edgeDeviceConfigChangeDetail = useAppSelector(state => state.provisionEdgeDevice.edgeDeviceConfigChangeDetail);
  const moduleCongifurationSettings = useAppSelector(state => state.provisionEdgeDevice.moduleCongifurationSettings);
  const configurationSettingParameter = useAppSelector(state => state.module.configurationSettingParameter);
  const selectedModuleForEditConfig = useAppSelector(state => state.provisionEdgeDevice.selectedModuleForEditConfig);
  const selectedTemplate = useAppSelector(state => state.provisionEdgeDevice.selectedTemplate);

  useEffect(() => {
    dispatch(getConfigurationSettingParameter());
  }, []);

  useEffect(() => {
    if (edgeDeviceConfigChangeDetail) {
      const requestData = {
        moduleId: selectedModuleForEditConfig.moduleId,
        edgeDeviceId: selectedEdgeDevice.edgeDeviceId,
        changeDetailsId: edgeDeviceConfigChangeDetail.edgeDeviceConfigChangeDetailsId,
        templateId: selectedTemplate
      }
      dispatch(getModuleConfigurationForEdgeDeviceModule(requestData));
    }
  }, [configurationSettingParameter]);

  const handleCloseModuleConfigDialog = () => {
    dispatch(setOpenModuleConfigSettingsDialog(false));
    dispatch(setSelectedModuleForEditConfig({}));
    dispatch(setModuleCongifurationSettings({}));
  }

  const validateBeforSubmit = () => {
    let flag: boolean = false;
    const moduleCS = moduleCongifurationSettings?.configuration.map((item: any) =>
      item.required && (item.value === '' || !item.value) ? { ...item, validated: true } : item
    );

    const finalData = {
      configuration: moduleCS,
      stagingId: moduleCongifurationSettings.stagingId
    };
    dispatch(setModuleCongifurationSettings(finalData));

    moduleCS.forEach((item: any) => {
      if (item.validated) {
        flag = true;
      }
    });
    return flag;
  }

  const onSubmit = () => {
    if (validateBeforSubmit()) {
      return;
    }

    const finalData = {
      edgeDeviceModuleConfigStagingId: moduleCongifurationSettings.stagingId,
      moduleConfigurationSettings: moduleCongifurationSettings.configuration
    }
    dispatch(stageEdgeDeviceModuleConfiguration(finalData));
  };

  const inputValidate = (data: any, val: any) => {
    const moduleCS = moduleCongifurationSettings?.configuration.map((item: any) =>
      data.key === item.key && data.required ?
        val === '' ? { ...item, validated: true } : { ...item, validated: false }
        : item
    );

    const finalData = {
      configuration: moduleCS,
      stagingId: moduleCongifurationSettings.stagingId
    };
    dispatch(setModuleCongifurationSettings(finalData));
  };

  const onChangeInput = (data: any, newValue: any) => {
    const moduleCS = moduleCongifurationSettings?.configuration.map((item: any) =>
      data.key === item.key ?
        { ...item, value: newValue } : item
    );
    const finalData = {
      configuration: moduleCS,
      stagingId: moduleCongifurationSettings.stagingId
    };
    dispatch(setModuleCongifurationSettings(finalData));
  };

  const onChangeInputMergeStrategyType = (data: any, newValue: any) => {
    const moduleCS = moduleCongifurationSettings?.configuration.map((item: any) =>
      data.key === item.key ?
        { ...item, mergeStrategyType: newValue } : item
    );
    const finalData = {
      configuration: moduleCS,
      stagingId: moduleCongifurationSettings.stagingId
    };
    dispatch(setModuleCongifurationSettings(finalData));
  }

  const onConfigFileChange = (data: any, fileObject: any) => {
    let reader = new FileReader();
    let file = fileObject[0];

    if (file) {
      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          const result = reader.abort;
          reject("Error in reading file");
        };

        reader.onload = () => {
          const moduleCS = moduleCongifurationSettings?.configuration.map((item: any) =>
            data.key === item.key ?
              { ...item, value: reader.result } : item
          );
          const finalData = {
            configuration: moduleCS,
            stagingId: moduleCongifurationSettings.stagingId
          };
          dispatch(setModuleCongifurationSettings(finalData));
        };
        reader.readAsText(file, "UTF-8");
      });
    }
  }

  const onDeleteConfigFileChange = (data: any, event: any) => {
    event.stopPropagation();
    const moduleCS = moduleCongifurationSettings?.configuration.map((item: any) =>
      data.key === item.key ?
        { ...item, value: null } : item
    );

    const finalData = {
      configuration: moduleCS,
      stagingId: moduleCongifurationSettings.stagingId
    };

    dispatch(setModuleCongifurationSettings(finalData));
  }

  const renderInputComponent = (rowData: any) => {
    let data = { ...rowData, configFileChanges: null };

    switch (data.deploymentType) {
      case 'CommandLine':
      case 'EnvironmentVariable':
      case 'ConfigurationProvider':
      case 'KubernetesConfiguration':
      case 'RegistryProvider':
      case 'Volume': {
        switch (data.dataType) {
          case 'String': return (
            <div>
              <TextField size="small" fullWidth value={data.value} type="text"
                onChange={(event) => { onChangeInput(data, event.currentTarget.value); }}
                onBlur={(event) => { inputValidate(data, event.currentTarget.value); }}
                error={data.validated}
              />
              {data.validated &&
                <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
              }
            </div>
          );

          case 'Double': return (
            <div>
              <TextField size="small" fullWidth value={data.value} type="number"
                onChange={(event) => { onChangeInput(data, event.currentTarget.value); }}
                onBlur={(event) => { inputValidate(data, event.currentTarget.value); }}
                error={data.validated}
              />
              {data.validated &&
                <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
              }
            </div>
          );

          case 'Integer': return (
            <div>
              <TextField size="small" fullWidth value={data.value} type="number" //min="1" //onInput={event => event.currentTarget.value.replace(/\D/, '')}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(event) => { onChangeInput(data, event.currentTarget.value); }}
                onBlur={(event) => { inputValidate(data, event.currentTarget.value); }}
                error={data.validated}
              />
              {data.validated &&
                <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
              }
            </div>
          );

          case 'Boolean': return (
            <div>
              <RadioGroup row
                name={data.key}
                value={data.value}
                onBlur={(event) => { inputValidate(data, (event.target as HTMLInputElement).value) }}
                onChange={(event) => { onChangeInput(data, (event.target as HTMLInputElement).value) }}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
              {data.validated &&
                <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
              }
            </div>
          );

          case 'SingleChoice': return (
            <div>
              <Select size="small" fullWidth
                value={data.value}
                multiple={false}
                onChange={(event) => { onChangeInput(data, event.target.value) }}
                onBlur={(event) => { inputValidate(data, event.target.value); }}
                error={data.validated}
              >
                <MenuItem value="">{intl.formatMessage({ id: "components.select" })}</MenuItem>
                {data.choices.map((item: any) => (<MenuItem key={item} value={item}>{item}</MenuItem>))}
              </Select>
              {data.validated &&
                <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
              }
            </div>
          );

          case 'MultiChoice': return (
            <div>
              <Select size="small" fullWidth
                value={data.value}
                multiple={true}
                onChange={(event) => { onChangeInput(data, event.target.value) }}
                onBlur={(event) => { inputValidate(data, event.target.value) }}
                error={data.validated}
              >
                <MenuItem value="">{intl.formatMessage({ id: "components.select" })}</MenuItem>
                {data.choices.map((item: any) => (<MenuItem key={item} value={item}>{item}</MenuItem>))}
              </Select>
              {data.validated &&
                <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
              }
            </div>
          );

          default: return (
            <div>
              <TextField size="small" fullWidth value={data.value} type="text"
                onChange={(event) => { onChangeInput(data, event.currentTarget.value); }}
                onBlur={(event) => { inputValidate(data, event.currentTarget.value); }}
                error={data.validated}
              />
              {data.validated &&
                <span className="form-error">{intl.formatMessage({ id: 'components.required' })}</span>
              }
            </div>
          );
        }
      }

      case 'JsonSettingsFile':
        return (
          <>
            <Button
              variant="outlined"
              component="label"
            >
              <FormattedMessage id="components.uploadFile" />
              <input
                key={`${data.key}__${data.value}`}
                type="file"
                accept=".json"
                onChange={(event) => { onConfigFileChange(data, event.target.files); }}
                hidden
              />
            </Button>
            {data.value && data.value !== '' &&
              <div>
                <Link
                  download={`${selectedModuleForEditConfig.moduleName}_${data.key}.json`}
                  href={window.URL.createObjectURL(
                    new Blob([data.value]),
                  )}
                  onClick={(event) => event.stopPropagation()} >
                  <DownloadIcon sx={{ position: "relative", top: "6px" }} />
                  <FormattedMessage id="components.UploadedJsonConfigChanges" />
                </Link>
                <br />
                <Link
                  href="#!"
                  onClick={(event) => onDeleteConfigFileChange(data, event)} >
                  <ClearIcon sx={{ position: "relative", top: "6px" }} />
                  <FormattedMessage id="components.DeleteUploadedJsonConfigChanges" />
                </Link>
              </div>
            }
          </>
        );

      case 'Yamlfile':
        return (
          <>
            <Button
              variant="outlined"
              component="label"
            >
              <FormattedMessage id="components.uploadFile" />
              <input
                key={`${data.key}__${data.value}`}
                type="file"
                accept=".yaml"
                onChange={(event) => { onConfigFileChange(data, event.target.files); }}
                hidden
              />
            </Button>
            {data.value && data.value !== '' &&
              <div>
                <Link
                  download={`${selectedModuleForEditConfig.moduleName}_${data.key}.yaml`}
                  href={window.URL.createObjectURL(
                    new Blob([data.value]),
                  )}
                  onClick={(event) => event.stopPropagation()} >
                  <DownloadIcon sx={{ position: "relative", top: "6px" }} />
                  <FormattedMessage id="components.UploadYamlConfigChangeFile" />
                </Link>
                <br />
                <Link
                  href="#!"
                  onClick={(event) => onDeleteConfigFileChange(data, event)} >
                  <ClearIcon sx={{ position: "relative", top: "6px" }} />
                  <FormattedMessage id="components.DeleteUploadedYamlConfigChanges" />
                </Link>
              </div>
            }
          </>
        );

      case 'IniFile':
        return (
          <>
            <Button
              variant="outlined"
              component="label"
            >
              <FormattedMessage id="components.uploadFile" />
              <input
                key={`${data.key}__${data.value}`}
                type="file"
                accept=".ini"
                onChange={(event) => { onConfigFileChange(data, event.target.files); }}
                hidden
              />
            </Button>
            {data.value && data.value !== '' &&
              <div>
                <Link
                  download={`${selectedModuleForEditConfig.moduleName}_${data.key}.ini`}
                  href={window.URL.createObjectURL(
                    new Blob([data.value]),
                  )}
                  onClick={(event) => event.stopPropagation()} >
                  <DownloadIcon sx={{ position: "relative", top: "6px" }} />
                  <FormattedMessage id="components.UploadedIniConfigChanges" />
                </Link>
                <br />
                <Link
                  href="#!"
                  onClick={(event) => onDeleteConfigFileChange(data, event)} >
                  <ClearIcon sx={{ position: "relative", top: "6px" }} />
                  <FormattedMessage id="components.DeleteUploadedIniConfigChanges" />
                </Link>
              </div>
            }
          </>
        );
    }
  };

  const renderMergeStrategyType = (data: any) => {
    return (
      <div>
        <Select size="small" fullWidth
          value={data.mergeStrategyType}
          multiple={false}
          onChange={(event) => { onChangeInputMergeStrategyType(data, event.target.value); }} >
          {configurationSettingParameter?.settingMergeStrategyType.map((item: any) => (<MenuItem key={item} value={item}>{item}</MenuItem>))}
        </Select>
      </div>
    )
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={selectedModuleForEditConfig}
      render={({ handleSubmit, form, submitting }) => (
        <form onSubmit={handleSubmit} data-testid="module-config-settings-form">
          <Grid container rowSpacing={2} sx={{ mb: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={6}><FormattedMessage id="components.module" />: <span style={{ fontWeight: 'bold' }}>{selectedModuleForEditConfig.moduleName}</span></Grid>
            <Grid item xs={12} sm={6}><FormattedMessage id="components.code" />: <span style={{ fontWeight: 'bold' }}>{selectedModuleForEditConfig.code}</span></Grid>
          </Grid>
          <table className="table borderless" style={{ width: '100%' }}>
            <tbody>
              {moduleCongifurationSettings?.configuration?.map((row: any, index: number) => {
                return (<tr key={index} style={{ marginBottom: '10px' }}>
                  <td style={{ padding: '0px', paddingBottom: '10px' }}>{row.key} :</td>
                  <td style={{ padding: '0px', paddingBottom: '10px' }}>
                    {renderInputComponent(row)}
                  </td>
                  <td style={{ padding: '0px', paddingLeft: '10px', paddingBottom: '10px' }}>
                    {renderMergeStrategyType(row)}
                  </td>
                </tr>
                )
              })
              }
            </tbody>
          </table>
          <Box display="flex" justifyContent="flex-end" sx={{ paddingTop: '15px' }}>
            <Button autoFocus onClick={handleCloseModuleConfigDialog} variant="contained" color="error" sx={{ mr: 2 }}>
              <FormattedMessage id="components.cancel" />
            </Button>
            <Button autoFocus type="submit" disabled={submitting} variant="contained" color="primary" data-testid="submit-button" >
              <FormattedMessage id="components.save" />
            </Button>
          </Box>
        </form>
      )}
    />
  );
};
