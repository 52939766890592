import { ThemeType } from './shared/enums/theme-enum';
import { useAppSelector } from './store/hooks';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme, lightTheme } from './theme/theme';
import { IntlProvider } from 'react-intl';
import { Locale } from './shared/enums/locale-enum';
import { messages } from './i18n/messages';
import MainRoutes from './pages/root/routes.component';
import { SnackbarAlert } from "./shared/snackbar/snackbar-alert";
import { CircularIndeterminate } from './layout/components/preloader.component';

function App() {
  const currentTheme: ThemeType = useAppSelector((state) => state.app.currentTheme);
  const locale: Locale = useAppSelector(state => state.app.locale);
  const isLoading = useAppSelector(state => state.app.isLoading);

  return (
    <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={Locale.ENGLISH}>
      <ThemeProvider theme={currentTheme === ThemeType.light ? lightTheme : darkTheme}>
        <SnackbarAlert />
        <MainRoutes />
        {isLoading && <CircularIndeterminate /> }
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
