import { createSlice } from '@reduxjs/toolkit';
import { IProvisionHistory } from "../../shared/interfaces/provision-history.interface";
import { getEdgeDeviceConfigChangeDetail, getHardwareConfigurationForEdgeDevice, getIotPlatformMicroServiceForEdgeDevice, getModuleConfigurationForEdgeDeviceModule, getProvisionHistory } from '../thunks/provision-edge-devices/provision-edge-devices-thunks';

interface ProvisionEdgeDeviceState {
    provisionHistory: IProvisionHistory[],
    iotPlatformMicroServiceForEdgeDevice: any,
    openViewProvisionDeviceDialog: boolean,  
    openViewProvisionHistoryDialog: boolean,
    selectedProvisionHistory: any,
    edgeDeviceConfigChangeDetail: any,
    moduleCongifurationSettings: any,
    selectedProvisionModules: string[],
    openModuleConfigSettingsDialog: boolean,
    openHardwareConfigSettingsDialog: boolean,
    selectedTemplate: string,
    selectedModuleForEditConfig: any,
    selectedHardwareForEditConfig: any,
    hardwareCongifurationSettings: any,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined
}

const initialState: ProvisionEdgeDeviceState = {
    provisionHistory: [],
    openViewProvisionDeviceDialog: false,
    openViewProvisionHistoryDialog: false,
    iotPlatformMicroServiceForEdgeDevice: null,
    selectedProvisionHistory: null,
    moduleCongifurationSettings: null,
    edgeDeviceConfigChangeDetail: null,
    selectedProvisionModules:[],
    selectedModuleForEditConfig: null,
    selectedHardwareForEditConfig: null,
    selectedTemplate:'',
    openModuleConfigSettingsDialog: false,
    openHardwareConfigSettingsDialog: false,
    hardwareCongifurationSettings: null,
    status: 'idle',
    error: undefined
}

export const provisionEdgeDeviceSlice = createSlice({    
    name: "provisionEdgeDevice",
    initialState,
    reducers: {
        setProvisionHistory: (state, action) => {
            state.provisionHistory = action.payload
        },
        setOpenViewProvisionHistoryDialog: (state, action) => {
            state.openViewProvisionHistoryDialog = action.payload
        },
        setSelectedProvisionHistory: (state, action) => {
            state.selectedProvisionHistory = action.payload
        },
        setOpenViewProvisionDeviceDialog: (state, action) => {
            state.openViewProvisionDeviceDialog = action.payload
        },
        setIotPlatformMicroServiceForEdgeDevice: (state, action) => {
            state.iotPlatformMicroServiceForEdgeDevice = action.payload
        },
        setSelectedProvisionModules: (state, action) => {
            state.selectedProvisionModules = action.payload
        },
        setEdgeDeviceConfigChangeDetail: (state, action) => {
            state.edgeDeviceConfigChangeDetail = action.payload
        },
        setOpenModuleConfigSettingsDialog: (state, action) => {
            state.openModuleConfigSettingsDialog = action.payload
        },
        setSelectedModuleForEditConfig: (state, action) => {
            state.selectedModuleForEditConfig = action.payload
        },
        setModuleCongifurationSettings: (state, action) => {
            state.moduleCongifurationSettings = action.payload
        },
        setSelectedTemplate: (state, action) => {
            state.selectedTemplate = action.payload
        },
        setOpenHardwareConfigSettingsDialog: (state, action) => {
            state.openHardwareConfigSettingsDialog = action.payload
        },
        setSelectedHardwareForEditConfig: (state, action) => {
            state.selectedHardwareForEditConfig = action.payload
        },
        setHardwareCongifurationSettings: (state, action) => {
            state.hardwareCongifurationSettings = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getProvisionHistory.fulfilled, (state, action) => {            
            state.provisionHistory = action.payload;
        })
        .addCase(getProvisionHistory.pending, (state, action) => {
        })
        .addCase(getProvisionHistory.rejected, (state, action) => {
            state.error = action.error.message
        })
        .addCase(getIotPlatformMicroServiceForEdgeDevice.fulfilled, (state, action) => {            
            state.iotPlatformMicroServiceForEdgeDevice = action.payload;
        })
        .addCase(getIotPlatformMicroServiceForEdgeDevice.pending, (state, action) => {
        })
        .addCase(getIotPlatformMicroServiceForEdgeDevice.rejected, (state, action) => {
            state.error = action.error.message
        })
        .addCase(getEdgeDeviceConfigChangeDetail.fulfilled, (state, action) => {            
            state.edgeDeviceConfigChangeDetail = action.payload;
        })
        .addCase(getEdgeDeviceConfigChangeDetail.pending, (state, action) => {
        })
        .addCase(getEdgeDeviceConfigChangeDetail.rejected, (state, action) => {
            state.error = action.error.message
        })
        .addCase(getModuleConfigurationForEdgeDeviceModule.fulfilled, (state, action) => {            
            state.moduleCongifurationSettings = action.payload;
        })
        .addCase(getModuleConfigurationForEdgeDeviceModule.pending, (state, action) => {
        })
        .addCase(getModuleConfigurationForEdgeDeviceModule.rejected, (state, action) => {
            state.error = action.error.message
        })
        .addCase(getHardwareConfigurationForEdgeDevice.fulfilled, (state, action) => {            
            state.hardwareCongifurationSettings = action.payload;
        })
        .addCase(getHardwareConfigurationForEdgeDevice.pending, (state, action) => {
        })
        .addCase(getHardwareConfigurationForEdgeDevice.rejected, (state, action) => {
            state.error = action.error.message
        });
    }
})

export const { 
    setProvisionHistory,
    setOpenViewProvisionHistoryDialog,
    setSelectedProvisionHistory,
    setOpenViewProvisionDeviceDialog,
    setIotPlatformMicroServiceForEdgeDevice,
    setSelectedProvisionModules,
    setEdgeDeviceConfigChangeDetail,
    setOpenModuleConfigSettingsDialog,
    setSelectedModuleForEditConfig,
    setModuleCongifurationSettings,
    setSelectedTemplate,
    setOpenHardwareConfigSettingsDialog,
    setSelectedHardwareForEditConfig,
    setHardwareCongifurationSettings
} = provisionEdgeDeviceSlice.actions;
export default provisionEdgeDeviceSlice.reducer;
