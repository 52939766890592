import { Avatar, Box, Card, CardContent, CardHeader, IconButton, DialogContent, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import EditIcon from '@mui/icons-material/Edit';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { getLoggedInUser } from "../../store/thunks/user-management/user-management-thunks";
import { setUpdateProfileDialog } from "../../store/slices/profile";
import { UpdateProfileForm } from "./update-profile-form.component";
import { Role } from "../../shared/enums/role.enum";

export const UserProfile = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.profile.loggedInUser);
  const userStatus = useAppSelector(state => state.profile.status);
  const updateProfileDialog = useAppSelector(state => state.profile.updateProfileDialog);

  useEffect(() => {
    if (userStatus === 'idle' || userStatus === 'failed') {
      dispatch(getLoggedInUser());
    }
  }, []);

  const handleCloseDialog = () => {
    dispatch(setUpdateProfileDialog(false));
  }

  const onUpdateProfile = () => {
    dispatch(setUpdateProfileDialog(true));
  }

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="user-profile">
        <div className="page-header">
          <h2><FormattedMessage id="components.userProfile" /></h2>
        </div>
        <Box>
          {user &&
            <Card sx={{ p: 2 }}>
              <CardHeader
                avatar={
                  <Avatar aria-label="profile">
                    {user?.firstName?.charAt(0)}
                  </Avatar>
                }
                action={
                  <IconButton aria-label="edit" onClick={onUpdateProfile}>
                    <EditIcon />
                  </IconButton>
                }
                title={user?.firstName + ' ' + user?.lastName}
                subheader={user?.userName}
              />
              <CardContent>
                <Typography variant="body1" sx={{ mb: 1 }}><FormattedMessage id="components.username" />: <span style={{ fontWeight: 'bold' }}>{user?.userName}</span></Typography>
                <Typography variant="body1" sx={{ mb: 1 }}><FormattedMessage id="components.firstName" />: <span style={{ fontWeight: 'bold' }}>{user?.firstName}</span></Typography>
                <Typography variant="body1" sx={{ mb: 1 }}><FormattedMessage id="components.lastName" />: <span style={{ fontWeight: 'bold' }}>{user?.lastName}</span></Typography>
                <Typography variant="body1" sx={{ mb: 1 }}><FormattedMessage id="components.emailAddress" />: <span style={{ fontWeight: 'bold' }}>{user?.email}</span></Typography>
                {user?.userRoleNames.includes(Role.GLEASON_VENDOR) ?
                  <Typography variant="body1" sx={{ mb: 1 }}><FormattedMessage id="components.vendor" />: <span style={{ fontWeight: 'bold' }}>{user?.vendorName}</span></Typography>
                  :
                  <Typography variant="body1" sx={{ mb: 1 }}><FormattedMessage id="components.customer" />: <span style={{ fontWeight: 'bold' }}>{user?.customerName}</span></Typography>
                }
                <Typography variant="body1" sx={{ mb: 1 }}><FormattedMessage id="components.roles" />: <span style={{ fontWeight: 'bold' }}>{user?.userRoleNames?.join(", ")}</span></Typography>
                <Typography variant="body1" sx={{ mb: 1 }}><FormattedMessage id="components.trialUser" />: <span style={{ fontWeight: 'bold' }}>{user?.trial ? "Yes" : "No"}</span></Typography>
                {user?.trial &&
                  <Typography variant="body1" sx={{ mb: 1 }}><FormattedMessage id="components.trialExpiration" />: <span className="fw-bold">{user?.trialExpiration.toString()}</span></Typography>
                }
                <Typography variant="body1" sx={{ mb: 1 }}><FormattedMessage id="components.theme" />: <span style={{ fontWeight: 'bold' }}>{user?.darkMode ? <FormattedMessage id="components.dark" /> : <FormattedMessage id="components.light" />}</span></Typography>
                <Typography variant="body1" sx={{ mb: 1 }}><FormattedMessage id="components.language" />: <span style={{ fontWeight: 'bold' }}>{user?.language}</span></Typography>
              </CardContent>
            </Card>
          }
        </Box>
      </div>

      {/*start of update profile dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={updateProfileDialog}
        data-testid="update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
          <><FormattedMessage id="components.update" /> <FormattedMessage id="components.profile" /></>
        </BootstrapDialogTitle>
        <DialogContent>
          <UpdateProfileForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of update profile dialog */}
    </>
  );
};
