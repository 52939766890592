import { dataAPI } from "../../../shared/dataApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IToolingObject } from "../../../shared/interfaces/tooling-object.interface";
import { setLoading, setSnackbarAlert } from "../../app-slice";
import { setEditToolingObject, setOpenAddUpdateToolingObjectDialog, setSelectedToolingObject } from "../../slices/tooling-objects";
import { Role } from "../../../shared/enums/role.enum";

export const getToolingObjects = createAsyncThunk(
  "tooling-objects/getToolingObjectForCustomer",
  async (customerNumber: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`ToolingObject/GetToolingObjectForCustomer?CustomerNumber=${customerNumber}`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const getToolingObjectForVendor = createAsyncThunk(
  "tooling-objects/getToolingObjectForVendor",
  async (requestData: any, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`ToolingObject/GetToolingObjectForVendor?OEM=${requestData.oem}&CustomerNumber=${requestData.customerNumber}`);
      dispatch(setLoading(false));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const addToolingObject = createAsyncThunk(
  "tooling-object/addToolingObject",
  async (requestData: IToolingObject, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).post(`ToolingObject/AddToolingObject`, requestData);
      dispatch(setOpenAddUpdateToolingObjectDialog(false));
      dispatch(setEditToolingObject(false));
      dispatch(setSelectedToolingObject(null));
      if(user?.roles.includes(Role.GLEASON_VENDOR)){
        const requestDataVendor = {
          oem: requestData.oem,
          customerNumber: requestData.customerNumber
        }
        dispatch(getToolingObjectForVendor(requestDataVendor));
      }
      else{
        dispatch(getToolingObjects(requestData.customerNumber));
      }
      
      dispatch(setSnackbarAlert({ messageType: 'success', message: "components.toolingObjectCreated" }));
      return result.data;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);
