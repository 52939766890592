import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { IApplication } from "../../shared/interfaces/application.interface";
import { onSearchInTable } from "../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../shared/dialog-components/custom-dialog";
import { DeleteConfirmationDialog } from "../../shared/dialog-components/delete-confirmation-dialog";
import { AddUpdateApplicationForm } from "./add-update-application-form.component";
import { deleteApplication, getApplications } from "../../store/thunks/applications/applications-thunks";
import {
  setSelectedApplication,
  setOpenAddUpdateApplicationDialog,
  setOpenViewApplicationDialog,
  setOpenDeleteConfirmationDialog,
  setEditApplication
} from "../../store/slices/applications"
import { TablePaginationActions } from "../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../shared/table-sorting/table-sort";
import { setLoading } from "../../store/app-slice";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const ApplicationCatalog = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const applications = useAppSelector(state => state.application.applications);
  const applicationStatus = useAppSelector(state => state.application.status);
  const selectedApplication = useAppSelector(state => state.application.selectedApplication);
  const openViewApplicationDialog = useAppSelector(state => state.application.openViewApplicationDialog);
  const openAddUpdateApplicationDialog = useAppSelector(state => state.application.openAddUpdateApplicationDialog);
  const openDeleteConfirmationDialog = useAppSelector(state => state.application.openDeleteConfirmationDialog);
  const editApplication = useAppSelector(state => state.application.editApplication);
  const [searchApplicationsList, setSearchApplicationsList] =  useState<IApplication[]>(applications);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IApplication>('name');

  const columns: Column[] = [
    { id: 'name', label: intl.formatMessage({ id: 'components.application' }) },
    { id: 'vendorName', label: intl.formatMessage({ id: 'components.vendor' }) },
    { id: 'description', label: intl.formatMessage({ id: 'components.description' }) },
    { id: 'actions', label: intl.formatMessage({ id: 'components.actions' }), sortable: 'disable' }
  ];

  useEffect(() => {
    if(applicationStatus === 'idle' || applicationStatus === 'failed'){
      loadApplications();
    }
  }, [applicationStatus]);  

  useEffect(() => {
    setSearchApplicationsList(applications);   
  }, [applications]);

  useEffect(() => {
    setPage(0);
  },[searchApplicationsList]);

  const loadApplications = () => {
    dispatch(getApplications());
  }

  const handleCloseDialog = () => {
    dispatch(setOpenViewApplicationDialog(false));
    dispatch(setOpenAddUpdateApplicationDialog(false));
    dispatch(setOpenDeleteConfirmationDialog(false));
    dispatch(setSelectedApplication(null));
  }

  const onViewApplicationDetails = (data: IApplication) => {
    dispatch(setOpenViewApplicationDialog(true));
    dispatch(setSelectedApplication(data));
    dispatch(setEditApplication(false));
  }

  const addApplication = () => {
    dispatch(setOpenAddUpdateApplicationDialog(true));
    dispatch(setEditApplication(false));
  }

  const onUpdateApplication = (data: IApplication) => {
    dispatch(setSelectedApplication(data));
    dispatch(setOpenAddUpdateApplicationDialog(true));
    dispatch(setEditApplication(true));
  }

  const onDeleteApplication = (data: IApplication) => {
    dispatch(setOpenDeleteConfirmationDialog(true));
    dispatch(setSelectedApplication(data));    
  }

  const deleteApplicationPermanently = () => {
    dispatch(setLoading(true));
    dispatch(deleteApplication(selectedApplication));
  }

  const actionTemplateApplications = (rowData: IApplication) => {
    return <span data-testid="application-actions" className="list-action">
      <Link component="button" data-testid="view-application" aria-label="view-application" variant="body2" onClick={() => onViewApplicationDetails(rowData)} > <VisibilityIcon /> </Link>
      <Link component="button" data-testid="update-application" variant="body2" onClick={() => onUpdateApplication(rowData)} > <EditIcon /> </Link>
      <Link component="button" data-testid="delete-application" variant="body2" onClick={() => onDeleteApplication(rowData)} > <ClearIcon /> </Link>
    </span>;
  }

  const onSearchChangeHandler = (searched: string) => {
    setSearchApplicationsList(onSearchInTable(applications, searched));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IApplication,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div style={{ paddingBottom: '20px' }} data-testid="applications">
        <div className="page-header">
          <h2><FormattedMessage id="components.applicationCatalog" /></h2>
        </div>
        <Box>
          <TableContainer component={Paper}>
            <div className="table-head-links">
              <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadApplications()} > <CachedIcon /> </Link></div>
              <div data-testid="add-new-btn" className="add-link"><Link component="button" aria-label="add-application" variant="body1" underline="none" onClick={() => addApplication()} ><FormattedMessage id="components.addNew" /> <FormattedMessage id="components.application" /> <AddIcon className="add-icon" /> </Link></div>
            </div>
            <div data-testid="searchbox" style={{ padding: "10px" }}>
              <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchChangeHandler(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
            </div>
            <Table sx={{ minWidth: 650 }} data-testid="application-table" aria-label="application table">
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                columns={columns}
              />
              <TableBody>
                {stableSort(searchApplicationsList, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow data-testid="application-node" key={row.applicationId} >
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.vendorName}</TableCell>
                        <TableCell>{row.description}</TableCell>                        
                        <TableCell>{actionTemplateApplications(row)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              
              <TableFooter>
                <TableRow>
                  <TablePagination
                    data-testid="pagination-control"
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={columns.length} 
                    count={searchApplicationsList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </div>

      {/*start of add/update dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openAddUpdateApplicationDialog}
        data-testid="add-update-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}>
          {editApplication ?
            <><FormattedMessage id="components.update" /> <FormattedMessage id="components.application" /></>
            : <><FormattedMessage id="components.addNew" />  <FormattedMessage id="components.application" /></>
          }
        </BootstrapDialogTitle>
        <DialogContent>
          <AddUpdateApplicationForm />
        </DialogContent>
      </BootstrapDialog>
      {/*end of add/update dialog */}

      {/*start of view details dialog */}
      <BootstrapDialog
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        open={openViewApplicationDialog}
        data-testid="view-application-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseDialog}></BootstrapDialogTitle>
        <DialogContent >
          {selectedApplication &&
            <>
              <p><FormattedMessage id="components.application" />: <span style={{ fontWeight: 'bold' }}>{selectedApplication.name}</span></p>
              <p><FormattedMessage id="components.vendor" />: <span style={{ fontWeight: 'bold' }}>{selectedApplication.vendorName}</span></p>
              <p><FormattedMessage id="components.description" />: <span style={{ fontWeight: 'bold' }}>{selectedApplication.description}</span></p>
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view details dialog */}

      {/*Start of delete confirmation dialog */}
      <DeleteConfirmationDialog
        open={openDeleteConfirmationDialog}
        handleCancel={handleCloseDialog}
        title={<FormattedMessage id="components.wantToDelete" />}
        message={<span><FormattedMessage id="components.thisWillDelete" /> <FormattedMessage id="components.application" /> <FormattedMessage id="components.permanently" /></span>}
        onConfirm={deleteApplicationPermanently}
      />
      {/*end of delete confirmation dialog */}

    </>
  );
};
