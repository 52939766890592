import { ChangeEvent, MouseEvent, ReactElement, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Link,
  DialogContent,
  Paper,
  OutlinedInput,
  InputAdornment,
  Table,
  TableBody,
  TableFooter,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from "@mui/icons-material/Download";
import { onSearchInTable } from "../../../shared/methods/table-search";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../shared/dialog-components/custom-dialog";
import { TablePaginationActions } from "../../../shared/table-pagination/table-pagination";
import { SortableTableHead } from "../../../shared/table-sorting/sortable-table-head";
import { getComparator, stableSort } from "../../../shared/table-sorting/table-sort";
import { getEdgeDevices } from "../../../store/thunks/edge-devices/edge-devices-thunks";
import { setOpenViewProvisionHistoryDialog, setSelectedProvisionHistory } from "../../../store/slices/provision-edge-devices";
import { getProvisionHistory } from "../../../store/thunks/provision-edge-devices/provision-edge-devices-thunks";
import { IProvisionHistory } from "../../../shared/interfaces/provision-history.interface";
import { setSelectedEdgeDevice } from "../../../store/slices/edge-devices";
import { Role } from "../../../shared/enums/role.enum";

type Column = {
  id: string,
  label: string,
  sortable?: string
}

type Order = 'asc' | 'desc';

export const ProvisionHistory = (): ReactElement => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const loggedInUser = useAppSelector(state => state.profile.loggedInUser);
  const edgeDevices = useAppSelector(state => state.edgeDevice.edgeDevices);
  const selectedEdgeDevice = useAppSelector(state => state.edgeDevice.selectedEdgeDevice);
  const provisionHistory = useAppSelector(state => state.provisionEdgeDevice.provisionHistory);
  const selectedProvisionHistory = useAppSelector(state => state.provisionEdgeDevice.selectedProvisionHistory);
  const openViewProvisionHistoryDialog = useAppSelector(state => state.provisionEdgeDevice.openViewProvisionHistoryDialog);
  const selectedAppCustomer = useAppSelector(state => state.app.selectedAppCustomer);
  const [searchProvisionHistoryList, setSearchProvisionHistoryList] = useState<IProvisionHistory[]>(provisionHistory);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof IProvisionHistory>('ioTDeviceName');

  const columns: Column[] = [
    { id: 'ioTDeviceName', label: intl.formatMessage({ id: 'components.iotDeviceName' }) },
    { id: 'oemPartNumber', label: intl.formatMessage({ id: 'components.oemPartNumber' }) },
    { id: 'oemserialNumber', label: intl.formatMessage({ id: 'components.oemSerialNumber' }) },
    { id: 'provisionDateTime', label: intl.formatMessage({ id: 'components.dateAndTime' }) },
    { id: 'actions', label: '', sortable: 'disable' }
  ];

  const onSearchProvisionHistory = (searched: string) => {
    setSearchProvisionHistoryList(onSearchInTable(provisionHistory, searched));
  }

  useEffect(() => {
    setSearchProvisionHistoryList(provisionHistory);
  }, [provisionHistory]);

  useEffect(() => {
    setPage(0);
  },[searchProvisionHistoryList]);

  useEffect(() => {
    if (selectedAppCustomer) {
      const cId = selectedAppCustomer ? selectedAppCustomer.customerId : "";
      dispatch(getEdgeDevices(cId));
      handleEdgeDeviceChange('');
    }
  }, [selectedAppCustomer]);

  const loadProvisionHistory = () => {
    dispatch(getProvisionHistory(selectedEdgeDevice));
  }

  const handleEdgeDeviceChange = (data: any) => {
    dispatch(setSelectedEdgeDevice(data));
    if(data){
      dispatch(getProvisionHistory(data));
    }    
  }

  const handleCloseProvisionHistoryDialog = () => {
    dispatch(setOpenViewProvisionHistoryDialog(false));
    dispatch(setSelectedProvisionHistory(null));
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof IProvisionHistory,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const viewManifest = (data: any) => {
    dispatch(setSelectedProvisionHistory(data));
    dispatch(setOpenViewProvisionHistoryDialog(true));
  }

  const provisionViewTemplate = (data: any) => {
    if(loggedInUser?.userRoleNames.includes(Role.GLOBAL_GLEASON_ADMIN)) {
      return <Link href="#!" onClick={() => viewManifest(data)}>{<FormattedMessage id="components.viewManifest" />}</Link>;
    }
    else {
      return '';
    }
  }

  return (
    <>
      <div data-testid="provision-history">
        <Box>
          <FormControl variant="standard" sx={{ m: 2, mt: 0, width: 350 }}>
            <InputLabel id="select-edge-device"><FormattedMessage id="components.edgeDevice" /></InputLabel>
            <Select
              labelId="select-edge-device"
              role="combobox"
              aria-label="select edge device"
              inputProps={{ "data-testid": "select-edge-device" }}
              value={selectedEdgeDevice}
              onChange={(e) => handleEdgeDeviceChange(e.target.value)}
            >
              {edgeDevices.map((row: any) => (
                <MenuItem key={row.edgeDeviceId} value={row.edgeDeviceId}>{row.azureIotdeviceName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {selectedEdgeDevice !== "" ?
          <Box>
            <TableContainer component={Paper}>
              <div className="table-head-links">
                <div data-testid="reload-btn" className="reload-link"><Link component="button" variant="body2" onClick={() => loadProvisionHistory()} > <CachedIcon /> </Link></div>
                <div data-testid="add-new-btn" className="add-link"></div>
              </div>
              <div data-testid="searchbox" style={{ padding: "10px" }}>
                <OutlinedInput sx={{ width: '100%' }} onChange={(e) => onSearchProvisionHistory(e.target.value)} size="small" startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>} />
              </div>
              <Table sx={{ minWidth: 650 }} data-testid="edge-device-table" aria-label="edge devices table">
                <SortableTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                />
                <TableBody>
                  {stableSort(searchProvisionHistoryList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow data-testid="ph-node" key={row.provisionHistroryId} >
                          <TableCell>{row.ioTDeviceName}</TableCell>
                          <TableCell>{row.oemPartNumber}</TableCell>
                          <TableCell>{row.oemSerialNumber}</TableCell>
                          <TableCell>{row.provisionDateTime}</TableCell>
                          <TableCell>{provisionViewTemplate(row)}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      data-testid="pagination-control"
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={columns.length}
                      count={searchProvisionHistoryList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelRowsPerPage={intl.formatMessage({ id: 'components.rowsPerPage' })}
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${intl.formatMessage({ id: 'components.of' })} ${count}`}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Box>
          :
          <Box sx={{ p: 2, pb: 3 }}>
            <FormattedMessage id="components.selectEdgeDeviceToDisplayHistory" />
          </Box> 
        }
      </div>

      {/*start of manifest details dialog */}
      <BootstrapDialog
        fullWidth={true}
        maxWidth="lg"
        onClose={handleCloseProvisionHistoryDialog}
        aria-labelledby="dialog-title"
        open={openViewProvisionHistoryDialog}
        data-testid="view-manifest-dlg"
      >
        <BootstrapDialogTitle id="dialog-title" onClose={handleCloseProvisionHistoryDialog}>
          {<FormattedMessage id="components.viewManifest" />}: {selectedProvisionHistory?.ioTDeviceName}
        </BootstrapDialogTitle>
        <DialogContent>
          {selectedProvisionHistory &&
            <>
              <div style={{ position: 'absolute', right:'30px' }}>
                <Link download="manifestJSON.json"
                  href={`data:text/json;charset=utf-8,${encodeURIComponent(
                    JSON.stringify(JSON.parse(selectedProvisionHistory?.manifestJson))
                  )}`}
                  onClick={(event) => event.stopPropagation()} >
                  <DownloadIcon sx={{ position: "relative", top: "6px" }} /><FormattedMessage id="components.manifestJSON" />
                </Link>
              </div>
              <pre>{JSON.stringify(JSON.parse(selectedProvisionHistory.manifestJson), null, 2)}</pre>
            </>
          }
        </DialogContent>
      </BootstrapDialog>
      {/*end of view manifest details dialog */}
    </>
  );
};
