import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { dataAPI } from "../../../shared/dataApi";
import { setLoading, setSnackbarAlert } from "../../app-slice";

export const getNotifications = createAsyncThunk(
  "notification/getNotifications",
  async (userId: string, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true));
      const user = JSON.parse(String(sessionStorage.getItem("user")));
      const result = await dataAPI(user).get(`Notification/GetAllNotifications?UserId=`+ userId);
      dispatch(setLoading(false));
      let finalResult = result.data;
      finalResult.map((item: any) => {
        item.notificationDtTm = item.notificationDtTm ? moment(item.notificationDtTm).format("MM/DD/YYYY, H:mm:ss") : "";
      });   
      return finalResult;
    } catch (error: any) {
      if (error.response.data.responseStatus.message) {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
        );
      }
      else {
        dispatch(
          setSnackbarAlert({ messageType: "error", message: error.response.statusText })
        );
      }
      dispatch(setLoading(false));
      throw error;
    }
  }
);

export const markNotificationAsRead = createAsyncThunk(
  'notification/markNotificationAsRead',
  async (requestData: any, { getState, dispatch }) => {
      try {
          dispatch(setLoading(true));
          const user = JSON.parse(String(sessionStorage.getItem("user")));
          const result = await dataAPI(user).post(`Notification/MarkNotificationAsRead`, requestData);
          dispatch(setLoading(false));
          return result.data;
      } catch (error: any) {
          if (error.response.data.responseStatus.message) {
              dispatch(
                  setSnackbarAlert({ messageType: "error", message: error.response.data.responseStatus.message })
              );
          }
          else {
              dispatch(
                  setSnackbarAlert({ messageType: "error", message: error.response.statusText })
              );
          }
          dispatch(setLoading(false));
          throw error;
      }
  }
);
