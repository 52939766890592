import { Role } from '../shared/enums/role.enum';
import { RolesConfig } from '../shared/interfaces/roles-config.interface';
import { Dashboard } from '../pages/dashboard/dashboard.component';
import { Applications } from '../pages/applications/applications.component';
import { UserProfile } from '../pages/user-profile/user-profile.component';
import { ChangePassword } from '../pages/change-password/change-password.component';
import { TermsCondition } from '../pages/terms-conditions/terms-conditions.component';
import { ViewNotifications } from '../pages/view-notifications/view-notifications.component';
import { TrialExpired } from '../pages/trial-expired/trial-expired.component';
import { DataCenters } from '../pages/data-centers/data-centers.component';
import { Customers } from '../pages/customers/customers.component';
import { EmailDomainBlocklist } from '../pages/email-domain-blocklist/email-domain-blocklist.component';
import { Vendors } from '../pages/vendors/vendors.component';
import { ApplicationCatalog } from '../pages/applications-catalog/applications-catalog.component';
import { Modules } from '../pages/modules/modules.component';
import { Packages } from '../pages/packages/packages.component';
import { Purchases } from '../pages/purchases/purchases.component';
import { LicensedUsers } from '../pages/licensed-users/licensed-users.component';
import { EdgeDeviceManagement } from '../pages/edge-device-management/edge-device-management.component';
import { UserManagement } from '../pages/user-management/user-management.component';
import { ToolingObjects } from '../pages/tooling-object/tooling-object.component';
import { MachineManagement } from '../pages/machine-management/machine-management.component';

// import MachineManagement from '../pages/machine-management';
// import CloudServerManagement from '../pages/cloud-server-management';
// import EnterpriseServerManagement from '../pages/enterprise-server-management';
// import TenantDownloads from '../pages/tenant-downloads';

const components = {
  dashboard: {
    path: 'dashboard',
    component: Dashboard
  },
  applications: {
    path: 'applications',
    component: Applications
  },
  dataCenters: {
    path: 'data-centers',
    component: DataCenters
  },
  customers: {
    path: 'customers',
    component: Customers
  },
  emailDomainBlocklist: {
    path: 'email-domain-blocklist',
    component: EmailDomainBlocklist
  },
  vendors: {
    path: 'vendors',
    component: Vendors
  },
  applicationCatalog: {
    path: 'application-catalog',
    component: ApplicationCatalog
  },
  modules: {
    path: 'modules',
    component: Modules
  },
  packages: {
    path: 'packages',
    component: Packages
  },
  purchases: {
    path: 'purchases',
    component: Purchases
  },

  licensedUsers: {
    path: 'licensed-users',
    component: LicensedUsers
  },
  edgeDeviceManagement: {
    path: 'edge-device-management',
    component: EdgeDeviceManagement
  },
  userManagement: {
    path: 'user-management',
    component: UserManagement
  },
  toolingObject: {
    path: 'tools',
    component: ToolingObjects
  },

  machineManagement: {
    path: 'machine-management',
    component: MachineManagement
  },
  // cloudServerManagement: {
  //   path: 'cloud-server-management',
  //   component: CloudServerManagement
  // },
  // enterpriseServerManagement: {
  //   path: 'enterprise-server-management',
  //   component: EnterpriseServerManagement
  // },
  // tenantDownloads: {
  //   path: 'downloads',
  //   component: TenantDownloads
  // }
};

//assign routes based on roles
const rolesConfig: RolesConfig = {
  [Role.GLOBAL_GLEASON_ADMIN]: {
    routes: [...Object.values(components)]
  },
  [Role.USER]: {
    routes: [components.applications]
  },
  [Role.CUSTOMER_ADMIN]: {
    routes: [
      components.applications,
      components.userManagement,
      components.purchases,
      components.licensedUsers,
      components.machineManagement,
      components.edgeDeviceManagement,
      //components.tenantDownloads,
      components.toolingObject      
    ]
  },
  [Role.GLEASON_REGINAL_SALES_MANAGER]: {
    routes: [components.applications]
  },
  [Role.GLEASON_INTERNAL_SALES]: {
    routes: [components.applications]
  },
  [Role.GLEASON_ENGINEER]: {
    routes: [components.applications]
  },
  [Role.GLEASON_VENDOR]: {
    routes: [components.toolingObject]
  },
  [Role.PASSWORD_CHANGE_ONLY]: {
    routes: [
      {
        path: 'change-password',
        component: ChangePassword
      }
    ]
  },
  [Role.TERMS]: {
    routes: [
      {
        path: 'terms-conditions',
        component: TermsCondition
      }
    ]
  },
  [Role.TRIAL_EXPIRED]: {
    routes: [
      {
        path: 'trial-expired',
        component: TrialExpired
      }
    ]
  },
  [Role.COMMON]: {
    routes: [
      {
        path: 'user-profile',
        component: UserProfile
      },
      {
        path: 'change-password',
        component: ChangePassword
      },
      {
        path: 'terms-conditions',
        component: TermsCondition
      },
      {
        path: 'view-notifications',
        component: ViewNotifications
      }
    ]
  }
};

export { rolesConfig };